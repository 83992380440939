import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  IconButton,
  Button,
  Tooltip,
  TextField,
  InputAdornment,
  Menu,
  RadioGroup,
  MenuItem,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";

import {
  filterJobHistory,
  getJobHistories,
} from "../../store/actions/jobHistoryAction";
// import { dateFormatISO } from "../../helpers/dateFormat";
import {
  SET_JOB_HISTORY,
  SET_JOB_HISTORY_PAGE,
} from "../../store/actionTypes/jobHistoryActionType";
import { ascending, descending } from "../../helpers/sorting";
import { colorStyling } from "../../helpers/color";

const JobHistoryFilter = () => {
  const dispatch = useDispatch();

  const [viewerName, setViewerName] = useState("");

  const [completedAtStart, setCompletedAtStart] = useState("");

  const [status, setStatus] = useState("");

  const [type, setType] = useState("");

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const [sortBy, setSortBy] = useState("Newest");

  const { jobHistories } = useSelector((state) => state.jobHistory);

  useEffect(() => {
    const handleSorting = (value) => {
      let sorted;

      switch (value) {
        case "Oldest":
          sorted = ascending(jobHistories, "id");
          break;

        case "Newest":
          sorted = descending(jobHistories, "id");
          break;

        default:
          break;
      }

      dispatch({ type: SET_JOB_HISTORY, payload: sorted });
    };
    handleSorting(sortBy);
  }, [dispatch, jobHistories, sortBy]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRefresh = (e) => {
    e.preventDefault();

    dispatch(getJobHistories());
    resetState();
  };

  const resetState = () => {
    setViewerName("");
    setCompletedAtStart("");
    setType("");
    setStatus("");
    setSortBy("Newest");
  };

  const handleChangeCreatedAtStart = (e) => {
    setCompletedAtStart(e.target.value);
  };

  const handleChangeSortBy = (event) => {
    const value = event.target.value;
    setSortBy(value);
    dispatch({ type: SET_JOB_HISTORY_PAGE, payload: 0 });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const query = [];

    if (viewerName) query.push(`viewerNameLike=${viewerName}`);

    if (completedAtStart) {
      const startDate = completedAtStart + "T00:00:00";
      const endDate = completedAtStart + "T23:59:59";
      query.push(`completedAtStart=${startDate}`);
      query.push(`completedAtEnd=${endDate}`);
    }

    if (type) query.push(`type=${type}`);

    if (status) query.push(`status=${status}`);
    // query.push(`sortBy=id`);
    // sortBy === "Oldest"
    //   ? query.push(`sortOrder=asc`)
    //   : query.push(`sortOrder=desc`);

    const queryPayload = `${query.join("&")}`;

    dispatch(filterJobHistory(queryPayload));
    dispatch({ type: SET_JOB_HISTORY_PAGE, payload: 0 });
  };

  return (
    <Box
      sx={{ display: "flex", justifyContent: "space-between" }}
      component="form"
      onSubmit={handleSubmit}
    >
      <TextField
        fullWidth
        value={viewerName}
        onChange={(event) => setViewerName(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        type="search"
        placeholder="Search viewer..."
        size="small"
      />

      <TextField
        sx={{ mx: 2 }}
        fullWidth
        select
        value={status}
        onChange={(e) => setStatus(e.target.value)}
        SelectProps={{
          native: true,
        }}
        size="small"
      >
        <option disabled value="">
          Select Status
        </option>
        <option value="COMPLETED">Completed</option>
        <option value="IMMEDIATE_COMPLETED">Immediate Completed</option>
        <option value="EXPIRED">Expired</option>
        <option value="IMMEDIATE_EXPIRED">Immediate Expired</option>
        <option value="ANNOUNCEMENT_ON">Announcement On</option>
        <option value="ANNOUNCEMENT_OFF">Announcement Off</option>
      </TextField>

      <TextField
        fullWidth
        value={completedAtStart}
        onChange={handleChangeCreatedAtStart}
        type="date"
        size="small"
      />

      <Button variant="contained" type="submit" sx={{ ml: 2 }}>
        Submit
      </Button>

      <Box sx={{ display: "flex", ml: 2 }}>
        <IconButton sx={{ color: colorStyling.black }} onClick={handleClick}>
          <Tooltip title="Sort">
            <SortIcon fontSize="small" />
          </Tooltip>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openSortButton}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <RadioGroup value={sortBy} onChange={handleChangeSortBy}>
            <MenuItem>
              <FormControlLabel
                disableTypography
                value="Oldest"
                control={<Radio size="small" />}
                label="Oldest"
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem>
              <FormControlLabel
                disableTypography
                value="Newest"
                control={<Radio size="small" />}
                label="Newest"
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
          </RadioGroup>
        </Menu>

        <IconButton
          sx={{ color: colorStyling.black, ml: 2 }}
          onClick={handleRefresh}
        >
          <Tooltip title="Reset Filter">
            <ClearOutlinedIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      </Box>
    </Box>
  );
};

export default JobHistoryFilter;
