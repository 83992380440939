export const API_LIMIT = +process.env.REACT_APP_API_LIMIT;
export const JOB_HISTORY_API_LIMIT =
  +process.env.REACT_APP_JOB_HISTORY_API_LIMIT;

export const instanceDisruptionEventAPI = "/api/event/";
export const instanceDisruptionLineAPI = "/api/line/";
export const instanceDisruptionStationAPI = "/api/station/";
export const instanceDisruptionAnnouncementAPI = "/api/organization/";
export const instanceDisruptionUserAPI = "/api/user/";

// BPLRT
export const instanceBPLRTUserAPI = "/api/user/";
export const instanceSMPAPI = "/api/bplrt/";
export const instanceTickerAPI = "api/bplrt/ticker/";
export const instanceEquipmentBreakdownAPI = "/api/bplrt/equipment/";
export const instanceBPLRTDisruptionAPI = "/api/bplrt/disruption/";

export const assetCMSAPILimit = `/api/asset?limit=${API_LIMIT}`;
export const instanceCMSAssetAPI = "/api/asset";
export const instanceCMSAssetTextAPI = "/api/asset/text";
export const instanceCMSAssetAdsAPI = "/api/asset/advertisement";
export const campaignCMSAPILimit = `/api/campaign?limit=${API_LIMIT}`;

export const contentCMSAPILimit = `/api/content/?limit=${API_LIMIT}`;
export const instanceCMSContentAPI = "/api/content/";
export const instanceCMSViewerAPI = `/api/screen/?limit=${API_LIMIT}`;
export const instanceCMSViewerGroupAPI = `/api/screen-group/?limit=${API_LIMIT}`;
export const instanceCMSScheduleAPI = "/api/schedule/";

export const initiateGroupAPI = "/api/screen-group/";
export const groupAPILimit = `/api/screen-group/?limit=${API_LIMIT}`;

export const initiateViewerAPI = "/api/screen/";
export const viewerAPILimit = `/api/screen/?limit=${API_LIMIT}`;

export const initiateScheduleAPI = "/api/schedule/";
export const scheduleAPILimit = `/api/schedule/?limit=${API_LIMIT}`;

export const initiateAnnouncementAPI = "/api/schedule/?type=ANNOUNCEMENT";
export const announcementAPILimit = `/api/schedule/?limit=${API_LIMIT}&type=ANNOUNCEMENT`;

export const initiateJobHistoryAPI = "/api/history/job/";
export const instanceContentHistoryAPI = `/api/history/content/?limit=${JOB_HISTORY_API_LIMIT}`;
export const instanceAuditLogAPI = `/api/log/audit/`;
export const instanceContentReportAPI = `/api/log/asset/`;

export const initiateAuthAPI = "api/auth/";
export const initiateUserAPI = "/api/user/";
export const initiateUserGroupAPI = "/api/user-group/";

export const initiateOrganizationAPI = "/api/organization/";
export const getOrganizationFeaturesAPI = "/api/auth/features/";

export const initiatePermissionAPI = "/api/permission/";
