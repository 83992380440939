// Enum: "REVEAL_FROM_RIGHT"
export const RevealFromRight = {
  id: "revealFromRight",
  label: "Reveal From Right",
  speed: 100,
  effect: {
    prev: {
      translate: [0, 0, -1],
    },
    next: {
      translate: [0, 0, 0],
    },
  },
  coverBox: {
    direction: "right",
    speed: 2200,
  },
};
