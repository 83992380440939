import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Box, ButtonBase, Grid, Tooltip } from "@mui/material";
import { TypographyLarge, TypographySmall } from "../../customComponent";
import { setErrorHandler } from "../../../store/actions/cmsAction";

const MediaItem = ({
  media,
  order,
  selectedMedia,
  handleSelectMedia,
  handleUnselectMedia,
  onlyOne,
}) => {
  const dispatch = useDispatch();

  const [uiForceUnselect, setUiForceUnselect] = useState(false);

  let fileSrc = media.thumbnailUrl;
  if (!fileSrc) {
    fileSrc = media.src ?? media.url ?? "/media_not_found.png";
  }

  let showElement;
  showElement = (
    <img
      style={{ maxWidth: "100%", maxHeight: "80%", m: 1 }}
      src={fileSrc}
      alt={media.name}
    />
  );

  const handleClickMedia = () => {
    if (media.id !== selectedMedia?.id) {
      handleSelectMedia(media);
      setUiForceUnselect(false);
    } else {
      handleUnselectMedia(media);
      setUiForceUnselect(true);
    }
  };

  const parseTypeBgColor = useMemo(() => {
    // ADDED when needed for other file types:
    // burlywood, darkorange, darksalmon, skyblue, sandybrown, thistle
    switch (media.type.toUpperCase()) {
      case "IMAGE":
        return "sandybrown";

      case "VIDEO":
        return "skyblue";

      default:
        return "gainsboro";
    }
  }, [media]);

  return (
    <Tooltip arrow disableInteractive title={media.name}>
      <Grid item sx={{ height: "fit-content" }}>
        <ButtonBase
          sx={{
            border:
              media.id === selectedMedia?.id
                ? "2px solid rgba(28, 78, 216, 0.8)"
                : "2px solid rgba(132, 137, 151, 0.5)",
            backgroundColor:
              media.id === selectedMedia?.id
                ? "rgba(28, 78, 216, 0.1)"
                : "transparent",
            borderRadius: "8px",
            width: "150px",
            height: "200px",
            ":hover": uiForceUnselect
              ? null
              : {
                  border: "2px solid rgba(28, 78, 216, 1)",
                  backgroundColor: "rgba(28, 78, 216, 0.2)",
                },
          }}
          onMouseLeave={() => setUiForceUnselect(false)}
          onClick={() => {
            handleClickMedia();
            dispatch(setErrorHandler({ selectedMedia: false }));
          }}
        >
          <Box
            display={"flex"}
            alignSelf={"start"}
            position={"absolute"}
            bgcolor={parseTypeBgColor}
            paddingY={"4px"}
            width={"58px"}
            justifyContent={"center"}
            sx={{ top: 0, left: 0 }}
          >
            <TypographySmall fontWeight={"medium"}>
              {media.type.toUpperCase()}
            </TypographySmall>
          </Box>

          {media.id === selectedMedia?.id && (
            <Box
              display={"flex"}
              alignSelf={"start"}
              position={"absolute"}
              sx={{
                right: 0,
                width: 0,
                height: 0,
                borderLeft: "40px solid transparent",
                borderTop: "40px solid blue",
              }}
            >
              {!onlyOne && (
                <Box
                  position={"absolute"}
                  sx={{
                    top: 0,
                    right: 0,
                    transform: "translate(-6px, -36px)",
                  }}
                >
                  <TypographySmall
                    fontWeight={"medium"}
                    sx={{ color: "white" }}
                  >
                    {order + 1}
                  </TypographySmall>
                </Box>
              )}
            </Box>
          )}

          <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            sx={{ width: "100%", height: "100%", p: 2 }}
          >
            <Box
              display={"flex"}
              alignContent={"center"}
              alignItems={"center"}
              sx={{ height: "80%" }}
            >
              {showElement}
            </Box>
            <TypographyLarge>
              {media.name.substring(0, 10) + "..."}
            </TypographyLarge>
          </Box>
        </ButtonBase>
      </Grid>
    </Tooltip>
  );
};

export default MediaItem;
