import {
  Announcement,
  AnnouncementDetail,
  AssetManager,
  ContentReporting,
  ContentTemplate,
  CreateContentTemplate,
  EditContentTemplate,
  ExpiredPassword,
  Group,
  GroupDetail,
  Home,
  Login,
  MyAccount,
  NotFound,
  Organization,
  ResetPassword,
  Schedule,
  ScheduleCalendar,
  ScheduleDetail,
  TwoFactorAuthentication,
  User,
  UserGroup,
  UserHistory,
  UserManual,
  Viewer,
  ViewerDetail,
} from "../components";
import {
  AuthorizationForgotPasswordRoute,
  AuthorizationLoginRoute,
  AuthorizationRoute,
  AuthorizationRouteRequestToken,
} from "../auth/AuthorizationRoute";
import { stellarFlag } from "../externalFeatures/componentsFlag";
import { AdvertisementSchedule } from "../externalFeatures/components";

export const authRoutes = [
  {
    path: "/authorization/request-otp",
    element: <TwoFactorAuthentication />,
    wrapper: AuthorizationRouteRequestToken,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    wrapper: AuthorizationForgotPasswordRoute,
  },
  {
    path: "/expired-password",
    element: <ExpiredPassword />,
    wrapper: null,
  },
  {
    path: "/login",
    element: <Login />,
    wrapper: AuthorizationLoginRoute,
  },
  {
    path: "*",
    element: <NotFound status={404} />,
    wrapper: null,
  },
];

export const normalRoutes = [
  {
    path: "/dashboard",
    element: <Home />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "SCREEN_GROUP",
    path: "/groups",
    element: <Group />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "SCREEN_GROUP",
    path: "/group/detail/:id",
    element: <GroupDetail />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "SCREEN",
    path: "/viewers",
    element: <Viewer />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "SCREEN",
    path: "/viewer/detail/:id",
    element: <ViewerDetail />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "SCHEDULE",
    path: "/schedules",
    element: <Schedule />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "SCHEDULE",
    path: "/schedule/detail/:id",
    element: <ScheduleDetail />,
    wrapper: AuthorizationRoute,
  },
  ...(stellarFlag
    ? [
        {
          flag: "ADVERTISEMENT",
          path: "/rotating-schedules",
          element: <AdvertisementSchedule />,
          wrapper: AuthorizationRoute,
        },
        {
          flag: "ADVERTISEMENT",
          path: "/rotating-schedule/detail/:id",
          element: <ScheduleDetail />,
          wrapper: AuthorizationRoute,
        },
      ]
    : []),
  {
    flag: "SCHEDULE.CALENDAR",
    path: "/schedule/calendar",
    element: <ScheduleCalendar />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "ANNOUNCEMENT",
    path: "/announcements",
    element: <Announcement />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "ANNOUNCEMENT",
    path: "/announcement/detail/:id",
    element: <AnnouncementDetail />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "USER",
    path: "/users",
    element: <User />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "USER_GROUP",
    path: "/user/groups",
    element: <UserGroup />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "LOG.AUDIT",
    path: "/audit-log",
    element: <UserHistory />,
    wrapper: AuthorizationRoute,
  },
  {
    path: "/myAccount",
    element: <MyAccount />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "MANUAL",
    path: "/manual",
    element: <UserManual />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "CONTENT",
    path: "cms/content-template",
    element: <ContentTemplate />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "CONTENT",
    path: "cms/content-template/create",
    element: <CreateContentTemplate />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "CONTENT",
    path: "cms/content-template/edit/:id",
    element: <EditContentTemplate />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "LOG.CONTENT",
    path: "/report/content",
    element: <ContentReporting />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "ASSET",
    path: "/cms/assets",
    element: <AssetManager />,
    wrapper: AuthorizationRoute,
  },
];

export const superRoutes = [
  {
    path: "/viewers",
    element: <Viewer />,
    wrapper: AuthorizationRoute,
  },
  {
    path: "/report/content",
    element: <ContentReporting />,
    wrapper: AuthorizationRoute,
  },
  {
    path: "/users",
    element: <User />,
    wrapper: AuthorizationRoute,
  },
  {
    path: "/user/groups",
    element: <UserGroup />,
    wrapper: AuthorizationRoute,
  },
  {
    path: "/audit-log",
    element: <UserHistory />,
    wrapper: AuthorizationRoute,
  },
  {
    path: "/organizations",
    element: <Organization />,
    wrapper: AuthorizationRoute,
  },
  {
    path: "/myAccount",
    element: <MyAccount />,
    wrapper: AuthorizationRoute,
  },
];
