import { None } from "./none";
import { SlideInFromLeft } from "./slideInFromLeft";
import { SlideInFromRight } from "./slideInFromRight";
import { SlideInFromBottom } from "./slideInFromBottom";
import { SlideInFromTop } from "./slideInFromTop";
import { ExpandFromLeft } from "./expandFromLeft";
import { ExpandFromRight } from "./expandFromRight";
import { ExpandFromTop } from "./expandFromTop";
import { ExpandFromBottom } from "./expandFromBottom";
import { ExpandFromBottomLeft } from "./expandFromBottomLeft";
import { ExpandFromTopLeft } from "./expandFromTopLeft";
import { ExpandFromBottomRight } from "./expandFromBottomRight";
import { ExpandFromTopRight } from "./expandFromTopRight";
import { ExpandInToMiddle } from "./expandInToMiddle";
import { ExpandOutFromMiddle } from "./expandOutFromMiddle";
import { RevealFromLeft } from "./revealFromLeft";
import { RevealFromRight } from "./revealFromRight";
import { RevealFromBottom } from "./revealFromBottom";
import { RevealFromTop } from "./revealFromTop";
import { ExpandInFromBottom } from "./expandInFromBottom";
import { ExpandInFromTop } from "./expandInFromTop";
import { SlideInFromBottomRight } from "./slideInFromBottomRight";
import { SlideInFromTopRight } from "./slideInFromTopRight";
import { SlideInFromBottomLeft } from "./slideInFromBottomLeft";
import { SlideInFromTopLeft } from "./slideInFromTopLeft";

const index = {
  NONE: None,
  SLIDE_RIGHT: SlideInFromLeft,
  SLIDE_LEFT: SlideInFromRight,
  SLIDE_FROM_BOTTOM: SlideInFromBottom,
  SLIDE_FROM_TOP: SlideInFromTop,
  EXPAND_RIGHT: ExpandFromLeft,
  EXPAND_LEFT: ExpandFromRight,
  EXPAND_FROM_TOP: ExpandFromTop,
  EXPAND_FROM_BOTTOM: ExpandFromBottom,
  EXPAND_FROM_BOTTOM_LEFT: ExpandFromBottomLeft,
  EXPAND_FROM_TOP_LEFT: ExpandFromTopLeft,
  EXPAND_FROM_BOTTOM_RIGHT: ExpandFromBottomRight,
  EXPAND_FROM_TOP_RIGHT: ExpandFromTopRight,
  EXPAND_IN_TO_MIDDLE: ExpandInToMiddle,
  EXPAND_OUT_FROM_MIDDLE: ExpandOutFromMiddle,
  EXPAND_IN_FROM_BOTTOM: ExpandInFromBottom,
  EXPAND_IN_FROM_TOP: ExpandInFromTop,
  SLIDE_FROM_BOTTOM_RIGHT: SlideInFromBottomRight,
  SLIDE_FROM_TOP_RIGHT: SlideInFromTopRight,
  SLIDE_FROM_BOTTOM_LEFT: SlideInFromBottomLeft,
  SLIDE_FROM_TOP_LEFT: SlideInFromTopLeft,
  REVEAL_FROM_LEFT: RevealFromLeft,
  REVEAL_FROM_RIGHT: RevealFromRight,
  REVEAL_FROM_BOTTOM: RevealFromBottom,
  REVEAL_FROM_TOP: RevealFromTop,
};

export default index;
