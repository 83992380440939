import React from "react";
import { Box } from "@mui/material";
import { colorStyling } from "../../../helpers/color";
import { TypographyNormal, TypographySmall } from "../../customComponent";
import { parseVariables } from "../../../helpers/localize";

const CalendarEvent = (eventInfo, t) => {
  const events = eventInfo.event;
  const customEvents = events.extendedProps;

  const hourText = () => {
    const startHour = customEvents.startHour;
    let text = startHour;
    if (customEvents.minuteInterval)
      text = parseVariables(t("calendar.eventLabel"), {
        start: startHour,
        interval: customEvents.minuteInterval,
      });
    else if (customEvents.endHour) {
      text = startHour + " - " + customEvents.endHour;
    }

    if (customEvents.type === "ADVERTISEMENT") {
      text =
        startHour +
        " - " +
        customEvents.endHour +
        " [" +
        customEvents.slot +
        "]";
    }
    return text;
  };

  return (
    <Box
      sx={{ ml: 0.75, my: 0.25, width: "100%" }}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-start"}
    >
      {customEvents.startHour && (
        <Box
          sx={{
            mr: 1,
            backgroundColor: colorStyling.white.full,
            borderRadius: 1,
            color: colorStyling.black,
            px: 0.5,
          }}
        >
          <TypographySmall sx={{ fontWeight: 500, wordSpacing: "-2px" }}>
            {hourText()}
          </TypographySmall>
        </Box>
      )}
      <Box>
        <TypographyNormal sx={{ color: colorStyling.white.full }}>
          {eventInfo.event.title}
        </TypographyNormal>
      </Box>
    </Box>
  );
};

export default CalendarEvent;
