import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, CssBaseline, Toolbar, Container, Paper } from "@mui/material";
import { Sidebar } from "..";
import GroupSuccessPrompt from "./GroupSuccessPrompt";
import GroupErrorPrompt from "./GroupErrorPrompt";
import CreateGroup from "./CreateGroup";
import FilterGroup from "./FilterGroup";
import GroupList from "./GroupList";
import { TypographyNormal, TypographyXLarge } from "../customComponent";
import { decryptUser } from "../../helpers/crypto";

const Group = () => {
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);
  const groupPermission = decryptedLoggedUser?.permission.screenGroup;

  const { t } = useTranslation("group");

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              {t("titleExplain")}
            </TypographyNormal>
          </Box>

          <GroupSuccessPrompt />
          <GroupErrorPrompt />

          {groupPermission.create && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <CreateGroup />
            </Box>
          )}

          <Paper
            component="section"
            elevation={2}
            sx={{ p: 2, my: 2, borderRadius: "6px" }}
          >
            <FilterGroup />
          </Paper>

          <GroupList />
        </Container>
      </Box>
    </div>
  );
};

export default Group;
