import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Container, CssBaseline, Paper, Toolbar } from "@mui/material";
import { TypographyLarge, TypographyXLarge } from "../../customComponent";
import Sidebar from "../../sidebar/Sidebar";
import UserGroupList from "./UserGroupList";
import CreateUserGroup from "./CreateUserGroup";
import UserSuccessPrompt from "../UserSuccessPrompt";
import UserErrorPrompt from "../UserErrorPrompt";
import UserGroupFilter from "./UserGroupFilter";
import { decryptUser } from "../../../helpers/crypto";
import Role from "../../../helpers/roles";

const UserGroup = () => {
  const { loggedUser } = useSelector((state) => state.auth);

  const { t } = useTranslation("usergroup");

  const decryptedLoggedUser = decryptUser(loggedUser);
  const userRole = decryptedLoggedUser.type;
  const userGroupPermission = decryptedLoggedUser?.permission.userGroup;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
            <TypographyLarge sx={{ fontWeight: 400 }}>
              {t("titleExplain")}
            </TypographyLarge>
          </Box>

          <UserSuccessPrompt />
          <UserErrorPrompt />

          {(userGroupPermission.create || userRole !== Role.User) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                mb: 2,
              }}
            >
              <CreateUserGroup />
            </Box>
          )}
          <Paper
            component="section"
            elevation={2}
            sx={{ p: 2, my: 2, borderRadius: "6px" }}
          >
            <UserGroupFilter />
          </Paper>

          <UserGroupList />
        </Container>
      </Box>
    </div>
  );
};

export default UserGroup;
