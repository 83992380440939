import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from  "react-i18next";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material/styles";
import { persistor, store } from "./store/store";
import i18n from  './i18n';
import "./index.css";

import App from "./App";
import { theme } from "./theme";

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <Suspense fallback="">
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </Suspense>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </Router>,
  document.getElementById("root")
);
