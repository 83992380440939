import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { deleteViewer } from "../../../store/actions/viewerAction";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";

const DeleteViewer = ({ viewer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation("viewer");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleDelete = (event) => {
    event.preventDefault();

    dispatch(deleteViewer(viewer.id));
    setOpen(false);
    navigate("/viewers");
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        sx={{ ml: 2, boxShadow: 3 }}
        color="error"
        variant="contained"
        startIcon={<DeleteIcon />}
      >
        {t("item.deleteBtn")}
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            {parser(
              parseVariables(t("item.deleteConfirmationText"), {
                name: viewer.name,
              })
            )}
          </DialogContentText>

          <Box>
            <Alert severity="warning" variant="outlined">
              <strong>{tCommon("permanentActionHint")}</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            variant="contained"
            color="error"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={handleDelete}
          >
            {tCommon("confirmBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteViewer;
