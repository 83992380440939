import {
  LinkOutlined as LinkIcon,
  MoreVert as MenuIcon,
  Clear as ClearIcon,
  Done as DoneIcon,
} from "@mui/icons-material";
import {
  alpha,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  styled,
  Switch,
  CircularProgress,
  IconButton,
  Menu,
  DialogContentText,
} from "@mui/material";

import { red } from "@mui/material/colors";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activateAllSMPAnnouncement,
  activateSMPAnnouncement,
  getSMPStation,
} from "../../../../store/actions/bplrt/smpAction";
import { CustomMenuItem } from "../../../../../components/customComponent";
import DetailLinkAnnouncement from "./DetailLinkAnnouncement";
import LinkAnnouncementErrorPrompt from "./LinkAnnouncementErrorPrompt";
import { useTranslation } from "react-i18next";
import parser from "html-react-parser";

const DangerSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: red[600],
    "&:hover": {
      backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: red[600],
  },
}));

const DisruptionActivation = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("bplrtSmp");

  const [open, setOpen] = useState(false);
  const [openActivateAll, setOpenActivateAll] = useState(false);
  const [openDeactivateAll, setOpenDeactivateAll] = useState(false);

  const { stationDisruptions, loadingAnnouncementSMPDisruption } = useSelector(
    (state) => state.bplrtSmp
  );

  useEffect(() => {
    if (open) dispatch(getSMPStation());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenActivateAll = () => {
    setOpenActivateAll(true);
  };

  const handleCloseActivateAll = () => {
    setOpenActivateAll(false);
  };

  const handleOpenDeactivateAll = () => {
    setOpenDeactivateAll(true);
  };

  const handleCloseDeactivateAll = () => {
    setOpenDeactivateAll(false);
  };

  const handleActivateAll = async () => {
    handleCloseActivateAll();
    const payload = {};
    payload.active = true;
    payload.type = "disruptionAnnouncement";
    let announcements = [];
    stationDisruptions.forEach((item) => {
      if (
        item.disruptionAnnouncementId !== null &&
        item.disruptionAnnouncementActive === false
      ) {
        announcements.push({
          id: item.id,
          announcementId: item.disruptionAnnouncementId,
        });
      }
    });

    payload.announcements = announcements;
    if (announcements.length !== 0) {
      dispatch(activateAllSMPAnnouncement(payload));
    }
  };

  const handleDeactivateAll = async () => {
    handleCloseDeactivateAll();
    const payload = {};
    payload.active = false;
    payload.type = "disruptionAnnouncement";
    let announcements = [];
    stationDisruptions.forEach((item) => {
      if (
        item.disruptionAnnouncementId !== null &&
        item.disruptionAnnouncementActive === true
      ) {
        announcements.push({
          id: item.id,
          announcementId: item.disruptionAnnouncementId,
        });
      }
    });

    payload.announcements = announcements;
    if (announcements.length !== 0) {
      dispatch(activateAllSMPAnnouncement(payload));
    }
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
        fullWidth
        sx={{ boxShadow: 3 }}
        color="error"
      >
        {t("disruptionBtn")}
      </Button>

      <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {t("disruptionDialogTitle")}
          <Box>
            <Button
              variant="contained"
              sx={{ boxShadow: 3 }}
              onClick={() => {
                handleOpenActivateAll();
              }}
            >
              {t("disruptionActivateAllBtn")}
            </Button>
            <Dialog
              maxWidth="sm"
              open={openActivateAll}
              onClose={handleCloseActivateAll}
            >
              <DialogContent>
                <DialogContentText
                  sx={{
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  {parser(t("disruptionActivateConfirmHint"))}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  fullWidth
                  startIcon={<ClearIcon />}
                  onClick={handleCloseActivateAll}
                >
                  {t("cancelBtn")}
                </Button>

                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  startIcon={<DoneIcon />}
                  onClick={handleActivateAll}
                >
                  {t("disruptionActivateBtn")}
                </Button>
              </DialogActions>
            </Dialog>

            <Button
              variant="contained"
              sx={{ boxShadow: 3, ml: "8px" }}
              onClick={() => {
                handleOpenDeactivateAll();
              }}
            >
              {t("disruptionDeactivateAllBtn")}
            </Button>
            <Dialog
              maxWidth="sm"
              open={openDeactivateAll}
              onClose={handleCloseDeactivateAll}
            >
              <DialogContent>
                <DialogContentText
                  sx={{
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  {parser(t("disruptionDeactivateConfirmHint"))}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  fullWidth
                  startIcon={<ClearIcon />}
                  onClick={handleCloseDeactivateAll}
                >
                  {t("cancelBtn")}
                </Button>

                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  startIcon={<DoneIcon />}
                  onClick={handleDeactivateAll}
                >
                  {t("disruptionDectivateBtn")}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </DialogTitle>
        <DialogContent>
          <LinkAnnouncementErrorPrompt />
          {loadingAnnouncementSMPDisruption ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size={24} sx={{ color: "#1C4ED8" }} />
            </Box>
          ) : (
            <Grid container>
              {stationDisruptions.map((item) => (
                <Grid item xs={6} key={item.id}>
                  <SwitchItem data={item} />
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="outlined" onClick={handleClose}>
            {t("closeBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const SwitchItem = ({ data }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("bplrtSmp");

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [switchCheck, setSwitchCheck] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (
      data.disruptionAnnouncementId !== null &&
      data.disruptionAnnouncementActive
    ) {
      setSwitchCheck(true);
    }
  }, [dispatch, data]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
    setAnchorEl(null);
  };

  const handleSwitch = (event) => {
    const payload = { id: data.id };
    payload.announcementId = data.disruptionAnnouncementId;
    payload.type = "disruptionAnnouncement";
    payload.active = !switchCheck;

    dispatch(activateSMPAnnouncement(payload));
  };

  const handleOpenDetail = () => {
    setOpenDetail(true);
  };

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"}>
        <FormControlLabel
          value={switchCheck}
          control={<DangerSwitch />}
          checked={switchCheck}
          label={data.code + " Announcement"}
          onChange={handleSwitch}
          disabled={
            data.disruptionAnnouncementId === null ||
            data.disruptionAnnouncementId === ""
              ? true
              : false
          }
        />

        <IconButton
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          color="inherit"
          onClick={handleClick}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <Menu
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <CustomMenuItem dense onClick={() => handleOpenDetail()}>
          <LinkIcon
            sx={{
              width: 20,
              height: 20,
              marginRight: "10px",
              color: "#111827",
            }}
          />
          {t("linkAnnouncementBtn")}
        </CustomMenuItem>
        <DetailLinkAnnouncement
          type="Disruption"
          open={openDetail}
          onClose={handleCloseDetail}
          data={data}
        />
      </Menu>
    </>
  );
};

export default DisruptionActivation;
