import { smpReducer } from "./bplrt/smpReducer";
import { equipmentReducer } from "./bplrt/equipmentReducer";
import { disruptionReducer } from "./smrt/disruptionReducer";

const externalFeatureReducers = {
  smrtDisruption: disruptionReducer,
  bplrtSmp: smpReducer,
  bplrtEquip: equipmentReducer,
};

export default externalFeatureReducers;
