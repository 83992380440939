// Enum: "SLIDE_LEFT"
export const SlideInFromRight = {
  id: "slideInFromRight",
  label: "Slide In From Right",
  speed: 2000,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "right center",
      translate: ["100%", 0, 10],
    },
  },
};
