import {
  SET_EQUIPMENT,
  SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN,
  SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN,
  SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN,
  SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN,
  SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
  SET_BREAKDOWN_ANNOUNCEMENT,
  SET_EDIT_BREAKDOWN_ANNOUNCEMENT,
  SET_EQUIPMENT_CONFIG,
  SET_ERROR_PROMPT_REMARK_DIALOG,
  SET_ERROR_MESSAGE_REMARK_DIALOG,
} from "../../actionTypes/bplrt/equipmentActionType";

import { bplrtAXIOS, initiateAXIOS } from "../../../../config/axios";
import {
  instanceEquipmentBreakdownAPI as equipmentAPI,
  initiateScheduleAPI,
  instanceBPLRTDisruptionAPI,
  instanceSMPAPI,
} from "../../../../api";
import { handleError } from "../../../../store/handleError";
import { logger } from "../../../../helpers/logger";
import Schedule from "../../../../models/ScheduleModel";
import { handleTimeoutPrompt } from "../../../../store/handlePrompt";
import { SET_UPDATE_STATION } from "../../actionTypes/bplrt/smpActionType";

import i18n from "i18next";
import { parseVariables } from "../../../../helpers/localize";
const t = i18n.getFixedT(null, "bplrtEquipment");

export const getEquipment = () => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({
      type: SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
      payload: true,
    });

    try {
      const { data } = await bplrtAXIOS.get(equipmentAPI + "?limit=100", {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const stationData = await bplrtAXIOS.get(
        instanceSMPAPI + "station?limit=100",
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      const stationItem = stationData.data.items;

      const combinedData = data.items.map((item) => {
        const findStation = stationItem.find(
          (station) => station.id === item.stationId
        );

        return {
          ...item,
          station: {
            code: findStation ? findStation.code : null,
            name: findStation ? findStation.name : null,
          },
        };
      });

      dispatch({ type: SET_EQUIPMENT, payload: combinedData });
    } catch (error) {
      logger.log(error);
      const errorMessage = handleError(error);
      dispatch({
        type: SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN,
        payload: errorMessage,
      });
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN);
    } finally {
      dispatch({
        type: SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
        payload: false,
      });
    }
  };
};

export const getEquipmentConfig = () => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({
      type: SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
      payload: true,
    });

    try {
      const { data } = await bplrtAXIOS.get(
        instanceBPLRTDisruptionAPI + "equipment",
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let storePayload = { isPeakHour: data.isPeakHour, announcement: {} };
      if (data.equipmentDisruptionAnnouncementId) {
        const announcementData = await initiateAXIOS.get(
          initiateScheduleAPI + data.equipmentDisruptionAnnouncementId,
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );

        storePayload.announcement = new Schedule(announcementData.data);
      }

      dispatch({ type: SET_EQUIPMENT_CONFIG, payload: storePayload });
    } catch (error) {
      const errorMessage = handleError(error);
      console.log(errorMessage);
      dispatch({
        type: SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN,
        payload: errorMessage,
      });
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN);
    } finally {
      dispatch({
        type: SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
        payload: false,
      });
    }
  };
};

export const editEquipment = (payload) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({
      type: SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
      payload: true,
    });

    try {
      await bplrtAXIOS.patch(equipmentAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({
        type: SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN,
        payload: true,
      });
      dispatch({
        type: SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN,
        payload:
          payload.equipments[0].isDisrupted === false
            ? t("message.removeBreakdownSuccess")
            : t("message.addBreakdownSuccess"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN);
    } catch (error) {
      logger.log(error);
      const errorMessage = handleError(error);
      dispatch({
        type: SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN,
        payload: errorMessage,
      });
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN);
    } finally {
      dispatch(getEquipment());
    }
  };
};

export const editRemark = (payload, callback) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      await Promise.all(
        payload.map(async (item) => {
          const { data } = await bplrtAXIOS.patch(
            instanceSMPAPI + "station/" + item.id,
            { comment: item.comment },
            {
              headers: { authorization: `Bearer ${access_token}` },
            }
          );

          dispatch({ type: SET_UPDATE_STATION, payload: data });
          return;
        })
      );

      dispatch({
        type: SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN,
        payload: true,
      });
      dispatch({
        type: SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN,
        payload: t("message.remarkUpdateSuccess"),
      });
      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN);
      callback(true);
    } catch (error) {
      logger.error(error);
      const errorMessage = handleError(error);
      dispatch({
        type: SET_ERROR_PROMPT_REMARK_DIALOG,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_REMARK_DIALOG,
        payload: errorMessage,
      });
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_REMARK_DIALOG);
      callback(false);
    }
  };
};

export const linkAnnouncement = (payload) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({
      type: SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
      payload: true,
    });

    try {
      const { data } = await bplrtAXIOS.patch(
        instanceBPLRTDisruptionAPI + "equipment",
        payload,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      let breakdownAnnouncement = {};
      if (data.equipmentDisruptionAnnouncementId) {
        const announcementData = await initiateAXIOS.get(
          initiateScheduleAPI + data.equipmentDisruptionAnnouncementId,
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );

        breakdownAnnouncement = new Schedule(announcementData.data);
      }

      dispatch({
        type: SET_BREAKDOWN_ANNOUNCEMENT,
        payload: breakdownAnnouncement,
      });

      dispatch({
        type: SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN,
        payload: true,
      });
      dispatch({
        type: SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN,
        payload: parseVariables(t("message.announcementLinkSuccess"), {
          name: breakdownAnnouncement.name,
        }),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN);
    } catch (error) {
      const errorMessage = handleError(error);
      console.log(errorMessage);
      dispatch({
        type: SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN,
        payload: errorMessage,
      });
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN);
    } finally {
      dispatch({
        type: SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
        payload: false,
      });
    }
  };
};

export const updateBreakdownAnnouncementStatus = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
      payload: true,
    });
    const access_token = sessionStorage.getItem("access_token");

    try {
      await initiateAXIOS.post(
        initiateScheduleAPI +
          payload.announcementId +
          (payload.active ? "/activate" : "/deactivate"),
        {},
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_EDIT_BREAKDOWN_ANNOUNCEMENT,
        payload,
      });

      dispatch({
        type: SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN,
        payload: true,
      });
      dispatch({
        type: SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN,
        payload: payload.active
          ? t("message.announcementActivated")
          : t("message.announcementDeactivated"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN);
    } catch (error) {
      const errorMessage = handleError(error);
      dispatch({ type: SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN,
        payload: errorMessage,
      });
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN);
    } finally {
      dispatch({
        type: SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
        payload: false,
      });
    }
  };
};
