import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { colorStyling } from "../../../helpers/color";
import {
  StyledTableRow,
  TypographyLarge,
  TypographyNormal,
} from "../../customComponent";
import { instanceContentReportAPI } from "../../../api";
import { initiateAXIOS } from "../../../config/axios";
import { calculateDuration } from "../../../helpers/scheduleHelper";
import { ascending } from "../../../helpers/sorting";
import { cleanReportData } from "../../../store/actions/reportingAction.js";
import { parseVariables } from "../../../helpers/localize.js";

const ContentReportDailyDetails = ({ asset, expanded, filter }) => {
  const { t } = useTranslation("contentreport");
  const { t: tCommon } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [dailyData, setDailyData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const tableRef = useRef(null);

  useEffect(() => {
    if (expanded) {
      fetchDailyData();
      setTimeout(() => {
        if (tableRef.current) {
          tableRef.current.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        }
      }, 333);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  const fetchDailyData = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);
    try {
      const query = `?id=${asset.assetId}&createdAtGte=${filter.createdAtGte}&createdAtLte=${filter.createdAtLte}&isDaily=true`;

      const { data } = await initiateAXIOS.get(
        instanceContentReportAPI + query,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const cleanedData = cleanReportData(data.items);
      setDailyData(ascending(cleanedData, "date"));
    } catch (e) {
      const errorData = JSON.parse(await e.response.data.text());
      const errorMessage = errorData.message;
      setIsError(true);
      setErrorMsg(errorMessage);
    } finally {
      setLoading(false);
      if (dailyData?.length === 0 && tableRef.current) {
        tableRef.current.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
    }
  };

  const handleCloseAlert = () => {
    setIsError(false);
  };

  return (
    <Box overflow={"auto"} sx={{ mt: 2, mb: 4, mx: 1, maxHeight: 300 }}>
      {isError && (
        <Alert severity="error" variant="outlined" onClose={handleCloseAlert}>
          {errorMsg}
        </Alert>
      )}

      {loading && dailyData?.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          sx={{ py: 1 }}
        >
          <CircularProgress
            size={20}
            thickness={3}
            sx={{ color: colorStyling.primary }}
          />
          <TypographyNormal
            sx={{
              color: colorStyling.primary,
              marginTop: "15px",
              fontWeight: 300,
            }}
          >
            {tCommon("loadingDataList")}
          </TypographyNormal>
        </Box>
      ) : (
        <Box ref={tableRef}>
          <TypographyLarge sx={{ mt: 1 }}>
            {parseVariables(t("dailyDetails.reportLabel"), {
              start: filter.createdAtGte,
              end: filter.createdAtLte,
            })}
          </TypographyLarge>
          <TypographyLarge sx={{ mb: 1 }}>
            {parseVariables(t("dailyDetails.assetLabel"), {
              name: asset.assetName,
            })}
          </TypographyLarge>
          <Table
            stickyHeader
            size="small"
            sx={{ position: "relative", zIndex: 1 }}
          >
            <TableHead>
              <TableRow>
                <TableCell>{t("dailyDetails.tableHeaderDate")}</TableCell>
                <TableCell align="right">
                  {t("dailyDetails.tableHeaderPlayTime")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dailyData.map((daily) => (
                <StyledTableRow key={daily.date + daily.assetDuration}>
                  <TableCell component="th">{daily.date}</TableCell>
                  <TableCell align="right">
                    {daily.assetDuration
                      ? calculateDuration(daily.assetDuration)
                      : "-"}
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  );
};

export default ContentReportDailyDetails;
