import React, { useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { dateFormatYYYYMMDD, formattedDate } from "../../../helpers/dateFormat";
import { CstTypo14W300, TypographySmall } from "../../customComponent";
import { colorStyling } from "../../../helpers/color";
import { instanceAuditLogAPI } from "../../../api";
import { initiateAXIOS } from "../../../config/axios";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";

const ExportUserHistory = () => {
  const { t } = useTranslation("auditlog");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorHeader, setErrorHeader] = useState("");

  const [checkboxEndDate, setCheckboxEndDate] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [loading, setLoading] = useState(false);

  const csvRef = useRef();
  const [userHistory, setUserHistory] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    resetData();
    setOpen(false);
  };

  const handleCloseErr = () => {
    setIsError(false);
  };

  const resetData = () => {
    setStartDate("");
    setEndDate("");
    setCheckboxEndDate(false);
    setUserHistory([]);
  };

  const handleStartDate = (e) => {
    setStartDate(e.target.value);

    if (checkboxEndDate)
      setEndDate(dayjs(e.target.value).add(1, "d").format("YYYY-MM-DD"));
  };

  const handleEndDate = (e) => {
    setIsError(false);
    if (dayjs(e.target.value).diff(dayjs(startDate), "day") >= 31) {
      setErrorHeader(t("error.periodValidationHeader"));
      setErrorMessage(t("error.maxDurationMsg"));
      setIsError(true);

      setTimeout(handleCloseErr, 5000);
      return;
    }

    setEndDate(
      dayjs(e.target.value).diff(dayjs(startDate)) <= 0
        ? dayjs(startDate).add(1, "d").format("YYYY-MM-DD")
        : e.target.value
    );
  };

  const handleCheckboxEndDate = (checked) => {
    if (checked) {
      setEndDate(dayjs(startDate).add(1, "d").format("YYYY-MM-DD"));
    } else {
      setEndDate("");
    }
    setCheckboxEndDate(checked);
  };

  const headers = useMemo(() => {
    return [
      { label: t("header.id"), key: "id" },
      { label: t("header.userType"), key: "userType" },
      { label: t("header.userName"), key: "userName" },
      { label: t("header.action"), key: "action" },
      { label: t("header.object"), key: "object" },
      { label: t("header.time"), key: "time" },
    ];
  }, [t]);

  useEffect(() => {
    if (userHistory.length !== 0) {
      csvRef.current.link.click();
      handleClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHistory]);

  const setHistoryData = (payload) => {
    setUserHistory(
      payload.map(
        ({
          id,
          userType,
          userName,
          event,
          objectType,
          objectName,
          createdAt,
        }) => ({
          id,
          userType,
          userName: userName ?? "-",
          action: event,
          object: objectType + " [" + objectName + "]",
          time: formattedDate(createdAt),
        })
      )
    );
  };

  const fetchAuditLog = async (query) => {
    const access_token = sessionStorage.getItem("access_token");

    try {
      const { data } = await initiateAXIOS.get(instanceAuditLogAPI + query, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      return { totalItem: data.totalItem, items: data.items };
    } catch (e) {
      setIsError(true);
      setErrorHeader(t("error.commonHeader"));
      setErrorMessage(`[${e?.response?.status}] ${e?.response?.data?.message}`);
      setTimeout(handleCloseErr, 5000);
    }
  };

  /**
   * generate csv function
   */
  const generateCsv = async () => {
    let csvData = [];
    const limit = 100;

    // Cancel function and show error if start date empty
    if (!startDate) {
      setIsError(true);
      setErrorHeader(t("error.periodValidationHeader"));
      setErrorMessage(t("error.missingStartDateMsg"));
      setTimeout(handleCloseErr, 5000);
      return;
    }

    setLoading(true);

    // Start Date in ISO format for filtering date at 00:00
    const startDateISO = new Date(
      dayjs(startDate).format("YYYY-MM-DDT00:00:00")
    ).toISOString();

    // End Date in ISO format for filtering date.
    // using Start Date at 23:59 if empty
    const endDateISO =
      endDate && checkboxEndDate
        ? new Date(dayjs(endDate).format("YYYY-MM-DDT23:59:59")).toISOString()
        : new Date(
            dayjs(startDate).format("YYYY-MM-DDT23:59:59")
          ).toISOString();

    // api call query param
    const query = `&limit=${limit}&createdAtGte=${startDateISO}&createdAtLte=${endDateISO}`;

    // initial fetch for total item
    const initialData = await fetchAuditLog("?offset=0" + query);
    csvData = csvData.concat(initialData.items);

    // loop if total item > limit
    if (initialData.totalItem > 100) {
      for (let i = 1; i <= +initialData.totalItem / 100; i++) {
        const data = await fetchAuditLog(`?offset=${i * limit}${query}`);
        csvData = csvData.concat(data.items);
      }
    }

    setLoading(false);
    setHistoryData(csvData);
  };

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>
        {t("export.btn")}
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {t("export.dialogTitle")}
          <IconButton onClick={handleClose} disabled={loading}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {isError && (
            <Alert
              sx={{ my: 2 }}
              severity="error"
              variant="filled"
              onClose={handleCloseErr}
            >
              <AlertTitle>{errorHeader}</AlertTitle>
              {errorMessage}
            </Alert>
          )}

          {loading ? (
            <Box
              sx={{ height: "20vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <CircularProgress
                size={22}
                thickness={3}
                sx={{ color: colorStyling.primary }}
              />
              <CstTypo14W300
                sx={{ color: colorStyling.primary, marginTop: "15px" }}
              >
                {tCommon("loadingDataList")}
              </CstTypo14W300>
            </Box>
          ) : (
            <>
              <InputLabel>{t("export.selectDateLabel")}</InputLabel>
              <TypographySmall sx={{ color: colorStyling.red }}>
                {t("export.selectDateHint")}
              </TypographySmall>

              <Box sx={{ display: "flex", alignItems: "end", mb: 1, mt: 1 }}>
                <Box sx={{ width: "100%", mb: 0 }}>
                  <InputLabel>{t("export.startLabel")}</InputLabel>

                  <TextField
                    size="small"
                    fullWidth
                    value={startDate}
                    onChange={handleStartDate}
                    required
                    margin="dense"
                    type="date"
                    variant="outlined"
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    ml: 2,
                  }}
                >
                  <Box sx={{ pb: checkboxEndDate ? 0 : 0.5 }}>
                    <FormControlLabel
                      control={<Checkbox />}
                      value={checkboxEndDate}
                      checked={checkboxEndDate}
                      name={"showEndDate"}
                      onChange={(e) => {
                        handleCheckboxEndDate(e.target.checked);
                      }}
                      label={t("export.endCBLabel")}
                    />
                  </Box>

                  {checkboxEndDate && (
                    <Box sx={{ width: "100%" }}>
                      <InputLabel>{t("export.endLabel")}</InputLabel>

                      <TextField
                        size="small"
                        fullWidth
                        value={endDate}
                        onChange={handleEndDate}
                        margin="dense"
                        type="date"
                        variant="outlined"
                        InputProps={{
                          inputProps: {
                            min: dayjs(startDate)
                              .add(1, "d")
                              .format("YYYY-MM-DD"),
                          },
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            fullWidth
            disabled={loading}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              generateCsv();
            }}
            disabled={loading}
          >
            {tCommon("exportBtn")}
          </Button>
        </DialogActions>

        <CSVLink
          data={userHistory}
          headers={headers}
          filename={`audit_log-${dateFormatYYYYMMDD(startDate)}${
            checkboxEndDate ? "-" + dateFormatYYYYMMDD(endDate) : ""
          }.csv`}
          ref={csvRef}
        ></CSVLink>
      </Dialog>
    </>
  );
};

export default ExportUserHistory;
