import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { DangerButton, SecondaryDangerButton } from "../../customComponent";
import { deleteAnnouncement } from "../../../store/actions/announcementAction";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";

const DeleteAnnouncement = ({ announcement }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation("announcement");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteAnnouncement(id, () => navigate("/announcements")));

    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title={announcement.isApproved ? t("item.deleteDisabledTip") : ""}
        placement="top"
      >
        <Box>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon fontSize="small" />}
            sx={{ boxShadow: 3, ml: 2 }}
            disabled={announcement.isApproved ? true : false}
            {...(!announcement.isApproved && { onClick: () => setOpen(true) })}
          >
            {t("item.deleteBtn")}
          </Button>
        </Box>
      </Tooltip>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            {parser(
              parseVariables(t("item.deleteConfirmationText"), {
                name: announcement.name,
              })
            )}
          </DialogContentText>

          <Box>
            <Alert severity="warning" variant="outlined">
              <strong>{tCommon("permanentActionHint")}</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <SecondaryDangerButton
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            {tCommon("cancelBtn")}
          </SecondaryDangerButton>

          <DangerButton
            fullWidth
            startIcon={<DoneIcon />}
            onClick={() => handleDelete(announcement.id)}
          >
            {tCommon("confirmBtn")}
          </DangerButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteAnnouncement;
