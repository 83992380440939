export const SET_EQUIPMENT = "SET_EQUIPMENT";
export const SET_EQUIPMENT_CONFIG = "SET_EQUIPMENT_CONFIG";
export const SET_EQUIPMENT_PEAK_HOUR = "SET_EQUIPMENT_PEAK_HOUR";
export const SET_BREAKDOWN_ANNOUNCEMENT = "SET_BREAKDOWN_ANNOUNCEMENT";
export const SET_EDIT_BREAKDOWN_ANNOUNCEMENT =
  "SET_EDIT_BREAKDOWN_ANNOUNCEMENT";
export const SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN =
  "SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN";
export const SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN =
  "SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN";
export const SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN =
  "SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN";
export const SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN =
  "SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN";
export const SET_ERROR_MESSAGE_REMARK_DIALOG =
  "SET_ERROR_MESSAGE_REMARK_DIALOG";
export const SET_ERROR_PROMPT_REMARK_DIALOG = "SET_ERROR_PROMPT_REMARK_DIALOG";
export const SET_SUCCESS_MESSAGE_REMARK_DIALOG =
  "SET_SUCCESS_MESSAGE_REMARK_DIALOG";
export const SET_SUCCESS_PROMPT_REMARK_DIALOG =
  "SET_SUCCESS_PROMPT_REMARK_DIALOG";
export const SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN =
  "SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN";
