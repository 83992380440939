import * as yup from "yup";
import i18n from 'i18next';
import { parseVariables } from "../helpers/localize";

export const createScheduleSchema = yup.object({
  frequency: yup.string().default("").required(),
  TYPE: yup.string().default("").required(),
  COMMAND: yup.string().default("").required(),
  name: yup
    .string()
    .min(6, ({ min }) => parseVariables(i18n.t("validation.minLengthChars"), { num: min }))
    .max(40, ({ max }) => parseVariables(i18n.t("validation.maxLengthChars"), { num: max }))
    .required(),
});
