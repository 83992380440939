import React from "react";
import { Dialog } from "@mui/material";
import { colorStyling } from "../../../helpers/color";

const AssetsManagerZoom = ({ open, closeDialog, asset, assetType }) => {
  return (
    <>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={() => closeDialog()}
        PaperProps={{
          sx: {
            width: "100%",
            height: assetType.isVideo ? "100%" : "auto",
            overflow: "hidden",
            backgroundColor: assetType.isVideo && colorStyling.black,
          },
        }}
      >
        {assetType.isImage && <img alt={asset.name} src={asset.url} />}
        {assetType.isVideo && (
          <video
            alt={asset.name}
            style={{ width: "100%", height: "100%" }}
            src={asset.url}
            controls
            autoPlay
            onClick={null}
          />
        )}
      </Dialog>
    </>
  );
};

export default AssetsManagerZoom;
