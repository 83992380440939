export const SET_ASSET_FIRST_LOAD = "SET_ASSET_FIRST_LOAD";
export const SET_CONTENT_ASSET = "SET_CONTENT_ASSET";
export const SET_ASSET_FILTER = "SET_ASSET_FILTER";
export const SET_ASSET_SORTING = "SET_ASSET_SORTING";
export const SET_ASSET_ROWS_PER_PAGE = "SET_ASSET_ROWS_PER_PAGE";
export const SET_ASSET_LIST_AFTER_REMOVE = "SET_ASSET_LIST_AFTER_REMOVE";
export const SET_LOADING_CONTENT_ASSET = "SET_LOADING_CONTENT_ASSET";
export const SET_LOADING_UPDATE_ASSET = "SET_LOADING_UPDATE_ASSET";
export const SET_SUCCESS_PROMPT_ASSET_PAGE =
  "SET_SUCCESS_PROMPT_ASSET_PAGE";
export const SET_SUCCESS_MESSAGE_ASSET_PAGE =
  "SET_SUCCESS_MESSAGE_ASSET_PAGE";
export const SET_ERROR_PROMPT_ASSET_PAGE =
  "SET_ERROR_PROMPT_ASSET_PAGE";
export const SET_ERROR_MESSAGE_ASSET_PAGE =
  "SET_ERROR_MESSAGE_ASSET_PAGE";
