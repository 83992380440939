import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { createAnnouncement } from "../../store/actions/announcementAction";
import { initiateAXIOS } from "../../config/axios";
import { contentCMSAPILimit } from "../../api";
import { CustomTextField, TypographySmall } from "../customComponent";
import { colorStyling } from "../../helpers/color";
import DispatchErrorPromptAlert from "../DispatchErrorPromptAlert";
import {
  SET_ERROR_MESSAGE_ANNOUNCEMENT_DIALOG,
  SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG,
} from "../../store/actionTypes/announcementActionType";
import { handleTimeoutPrompt } from "../../store/handlePrompt";
import { logger } from "../../helpers/logger";
import { parseVariables } from "../../helpers/localize";

const CreateAnnouncement = () => {
  const dispatch = useDispatch();
  const {
    isErrorPromptAnnouncementDialog: isError,
    errorMessageAnnouncementDialog: errorMessage,
  } = useSelector((state) => state.announcement);

  const { t } = useTranslation("announcement");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingContentList, setLoadingContentList] = useState(false);

  const [name, setName] = useState("");
  const [nameValidation, setNameValidation] = useState("");
  const [customContentList, setCustomContentList] = useState([]);
  const [selectCustomContent, setSelectCustomContent] = useState("");
  const [selectPriority, setSelectPriority] = useState(2);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    handleClearData();
    setOpen(false);
  };

  const handleOpenErr = (message) => {
    dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG, payload: true });
    dispatch({ type: SET_ERROR_MESSAGE_ANNOUNCEMENT_DIALOG, payload: message });
  };

  const handleInputName = (input) => {
    if (input.length > 40)
      setNameValidation(
        parseVariables(tCommon("validation.maxLengthChars"), {
          num: 40,
        })
      );
    else {
      setName(input);
      if (nameValidation) setNameValidation("");
    }
  };

  /**
   * Reset form data
   */
  const handleClearData = () => {
    setName("");
    setCustomContentList([]);
    setSelectCustomContent("");
    setSelectPriority(2);
    dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG, payload: false });
  };

  const handleFinishSubmit = (stateSubmit) => {
    setLoading(false);
    if (stateSubmit === "success") {
      handleClickClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name.trim() === "") {
      setNameValidation(tCommon("validation.fieldMustNotEmpty"));
      return;
    }
    if (name.trim().length > 40) {
      setNameValidation(
        parseVariables(tCommon("validation.maxLengthChars"), {
          num: 40,
        })
      );
      return;
    }

    let payload = {
      name: name.trim(),
      command: "PLAY_CONTENT",
      type: "ANNOUNCEMENT",
      contentId: +selectCustomContent,
      priority: +selectPriority,
    };

    setLoading(true);
    dispatch(createAnnouncement(payload, handleFinishSubmit));
  };

  const getCMSContent = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoadingContentList(true);
    try {
      const { data } = await initiateAXIOS.get(contentCMSAPILimit, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setCustomContentList(data.items);
    } catch (error) {
      logger.error(error);

      handleOpenErr(t("validation.listContentsError"));
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG);
    } finally {
      setLoadingContentList(false);
    }
  };

  useEffect(() => {
    if (open) {
      getCMSContent();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Button
        sx={{ boxShadow: 3 }}
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddIcon fontSize="small" />}
      >
        {t("createBtn")}
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {t("form.newDialogTitle")}
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider />

          <DialogContent>
            <DispatchErrorPromptAlert
              isError={isError}
              errorMessage={errorMessage}
              closeActionType={SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG}
            />

            <Box sx={{ mb: 1, mt: 1 }}>
              <InputLabel>{t("form.nameLabel")}</InputLabel>

              <TextField
                id="announcement-name"
                autoFocus
                fullWidth
                margin="dense"
                placeholder={t("form.namePlaceholder")}
                value={name}
                onChange={(e) => handleInputName(e.target.value)}
                required
                disabled={loading}
              />
              <TypographySmall sx={{ color: colorStyling.red }}>
                {nameValidation}
              </TypographySmall>
            </Box>

            <Box sx={{ mb: 1 }}>
              <InputLabel>{t("form.contentLabel")}</InputLabel>

              {loadingContentList && customContentList.length === 0 ? (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ width: "100%", height: "56px" }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                <CustomTextField
                  select
                  required
                  SelectProps={{ native: true }}
                  fullWidth
                  margin="dense"
                  name="contentSelect"
                  value={selectCustomContent}
                  onChange={(e) => {
                    setSelectCustomContent(e.target.value);
                  }}
                  disabled={loading}
                >
                  <option value="" disabled>
                    {t("form.contentSelect")}
                  </option>
                  {customContentList.map((content) => (
                    <option value={content.id} key={content.id}>
                      {content.name}
                    </option>
                  ))}
                </CustomTextField>
              )}
            </Box>

            <Box>
              <InputLabel>{t("form.priorityLabel")}</InputLabel>
              <CustomTextField
                select
                required
                SelectProps={{ native: true }}
                fullWidth
                margin="dense"
                name="contentSelect"
                value={selectPriority}
                onChange={(e) => {
                  setSelectPriority(+e.target.value);
                }}
                disabled={loading}
              >
                <option value="0">{t("priority.Highest")}</option>
                <option value="1">{t("priority.High")}</option>
                <option value="2">{t("priority.Normal")}</option>
                <option value="3">{t("priority.Low")}</option>
                <option value="4">{t("priority.Lowest")}</option>
              </CustomTextField>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleClickClose}
              disabled={loading}
            >
              {tCommon("cancelBtn")}
            </Button>
            <Button
              fullWidth
              type="submit"
              variant="contained"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : tCommon("createBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateAnnouncement;
