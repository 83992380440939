import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Link, Tooltip } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { colorStyling } from "../../../helpers/color";

const AnnouncementBreadCrumb = ({ announcement }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("announcement");

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon
          sx={{ color: colorStyling.primary }}
          fontSize="small"
        />
      }
      aria-label="breadcrumb"
    >
      <Link
        onClick={() => navigate("/announcements")}
        underline="always"
        sx={{ cursor: "pointer" }}
      >
        {t("mainBreadcrumb")}
      </Link>
      <Tooltip
        title={announcement?.name?.length > 30 ? announcement?.name : ""}
        placement="top-start"
      >
        <Link href="#" underline="hover" sx={{ cursor: "pointer" }}>
          {announcement?.name?.length > 30
            ? announcement?.name.substring(0, 27).trim() + "..."
            : announcement?.name}
        </Link>
      </Tooltip>
    </Breadcrumbs>
  );
};

export default AnnouncementBreadCrumb;
