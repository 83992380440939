import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Popper, Link, Paper, CircularProgress } from "@mui/material";
import { TypographySmall } from "./customComponent";
import { logger } from "../helpers/logger";
import { initiateAXIOS } from "../config/axios";

const Versioning = ({ textColor }) => {
  const { t: tCommon } = useTranslation();
  const dashboardVer = require("../../package.json").version;

  const [latestVersion, setLatestVersion] = useState("");
  const [backendVersion, setBackendVersion] = useState("");

  const [anchorVersionPopper, setAnchorVersionPopper] = useState(null);
  const openVersionPopper = Boolean(anchorVersionPopper);
  const handleOpenVersionPopper = (event) => {
    if (openVersionPopper) {
      setAnchorVersionPopper(null);
      return;
    }
    setAnchorVersionPopper(event.currentTarget);
  };
  const handleCloseVersionPopper = (event) => {
    setAnchorVersionPopper(null);
  };

  const fetchLatestVersion = async () => {
    const getExisting = sessionStorage.getItem("latest_version");
    if (getExisting) {
      setLatestVersion(getExisting);
      return;
    }

    const token =
      process.env.GH_TOKEN ??
      process.env.GITHUB_TOKEN ??
      process.env.TOKEN_GITHUB;
    try {
      const { data } = await initiateAXIOS.get(
        "https://api.github.com/repos/Einstech-Itxcast/itxcast2020-dashboard/releases/latest",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      logger.log(`fetchLatestVersion:`, data);
      const version = data["name"] ?? "error";
      setLatestVersion(version);
      sessionStorage.setItem("latest_version", version);
    } catch (error) {
      setLatestVersion("error");
    }
  };

  const fetchBackendVersion = async () => {
    const getExisting = sessionStorage.getItem("backend_version");
    if (getExisting) {
      setBackendVersion(getExisting);
      return;
    }

    try {
      const { data } = await initiateAXIOS.get("/api/version");
      logger.log(`fetchBackendVersion:`, data);

      const version = data;
      setBackendVersion(version);
      sessionStorage.setItem("backend_version", version);
    } catch (error) {
      setBackendVersion("error");
    }
  };

  useEffect(() => {
    if (false) {
      fetchLatestVersion();
    }

    fetchBackendVersion();
    window.addEventListener("beforeunload", () => {
      sessionStorage.removeItem("latest_version");
      sessionStorage.removeItem("backend_version");
    });
  }, []);

  return (
    <>
      <Box sx={{ position: "absolute", bottom: 0, left: 0, ml: 0.5 }}>
        <Link
          href="#"
          underline="none"
          component={"div"}
          onMouseEnter={handleOpenVersionPopper}
          onMouseLeave={handleCloseVersionPopper}
          sx={{
            ml: "4px",
            mb: "4px",
            cursor: "pointer",
            ":hover": {
              opacity: 0.8,
            },
          }}
        >
          <TypographySmall sx={{ color: textColor }}>
            {dashboardVer}
          </TypographySmall>
        </Link>

        <Popper
          open={openVersionPopper}
          anchorEl={anchorVersionPopper}
          placement="bottom-start"
          style={{ zIndex: 1200 }}
        >
          <Paper sx={{ marginBottom: 1, marginLeft: 2 }}>
            <Box sx={{ py: 1, px: 2, width: "170px" }}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <TypographySmall>
                  {tCommon("version.currentVersionLabel")}
                </TypographySmall>
                <TypographySmall>
                  <b>{dashboardVer}</b>
                </TypographySmall>
              </Box>

              {/* TODO: Will be added after Amplify S3 implemented */}
              {false && (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <TypographySmall>
                    {tCommon("version.latestVersionLabel")}
                  </TypographySmall>
                  {latestVersion.length > 0 ? (
                    <TypographySmall>
                      <b>{latestVersion}</b>
                    </TypographySmall>
                  ) : (
                    <CircularProgress
                      size={11}
                      sx={{ ml: 1, color: "black" }}
                    />
                  )}
                </Box>
              )}

              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <TypographySmall>
                  {tCommon("version.apiCurrentVersionLabel")}
                </TypographySmall>
                {backendVersion.length > 0 ? (
                  <TypographySmall>
                    <b>{backendVersion}</b>
                  </TypographySmall>
                ) : (
                  <CircularProgress size={11} sx={{ ml: 1, color: "black" }} />
                )}
              </Box>
            </Box>
          </Paper>
        </Popper>
      </Box>
    </>
  );
};

export default Versioning;
