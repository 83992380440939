import L from "../locales/index";
import i18n from "../i18n";
import parser from "html-react-parser";
import { logger } from "../helpers/logger";

let localized;

export const handleError = (e, ns, codeBypass = false) => {
  logger.log(`handleError:`, e);
  const status = e.response?.status;
  const detailKeyword = e.response?.data?.detail?.keyword;
  const errorCode = e.response?.data.code;
  let errorMessage = e.response?.data.message;

  if (detailKeyword === "expiredPassword") {
    window.localStorage.clear();
    window.location.replace("/expired-password");

    return errorMessage;
  } else if (detailKeyword === "expiredSession") {
    window.localStorage.clear();
    window.sessionStorage.clear();

    // setTimeout(() => {
    window.location.replace("/login");
    // }, 6000);

    return errorMessage;
  } else {
    if (codeBypass === true) {
      if (errorCode) {
        const tKey = ns?.length > 0 ? `${ns}.${errorCode}` : `${errorCode}`;
        errorMessage = i18n.t(tKey, { ns: "error", defaultValue: null });
        if (errorMessage) {
          return errorMessage;
        }
      } else {
        localized = L[i18n.language];
        if (e.response.data.message[0].includes("password must match")) {
          localized = localized["user"];
          errorMessage = parser(localized["common"]["passwordReqHints"]);
          return errorMessage;
        }
        if (errorMessage.includes("reuse")) {
          errorMessage = localized["error"][ns]["1002Reuse"];
          return errorMessage;
        }
      }

      errorMessage = i18n.t("unknown", { ns: "error" });
      return errorMessage;
    }

    localized = L[i18n.language]["error"];
    switch (status) {
      case 401:
        errorMessage = e.response.data.message;
        window.localStorage.clear();
        window.sessionStorage.clear();

        // setTimeout(() => {
        window.location.replace("/login");
        // }, 5000);
        break;

      case 500:
      case undefined:
        errorMessage = i18n.t("unknown", { ns: "error" });
        break;

      case 400:
        if (errorCode) {
          // TODO: return localized[ns][errorCode];
          switch (errorCode) {
            case "1002":
              if (ns === "user") {
                if (errorMessage.includes("Invalid")) {
                  errorMessage = localized[ns]["1002Invalid"];
                  return errorMessage;
                }
                if (errorMessage.includes("reuse")) {
                  errorMessage = localized[ns]["1002Reuse"];
                  return errorMessage;
                }
              }
              break;

            default:
              break;
          }

          //// TODO: handles common error, uncomment if needed.
          // errorMessage = localized["unknown"];
          return errorMessage;
        }

        localized = L[i18n.language]["user"];
        if (e.response.data.message[0].includes("password must match")) {
          errorMessage = parser(localized["common"]["passwordReqHints"]);
          break;
        }

        if (e.response.data.message[0].includes("name must match")) {
          errorMessage = parser(localized["common"]["userReqHints"]);
          break;
        }
        break;

      default:
        errorMessage = e.response.data.message;
        break;
    }

    return errorMessage;
  }
};
