import i18n from "i18next";
const t = i18n.getFixedT(null, "bplrtSmp");

export const mapData = [
  {
    type: "platform",
    id: "2",
    name: "BP1 " + t("mapTooltip.topPlatform"),
    data: {
      shape:
        "M48 81C48 76.5817 51.5817 73 56 73H92C96.4183 73 100 76.5817 100 81V98H48V81Z",
      text: { coor: { x: "62.1191", y: "89.8636" }, name: "BP1" },
    },
  },
  {
    type: "platform",
    id: "1",
    name: "BP1 " + t("mapTooltip.bottomPlatform"),
    data: {
      shape:
        "M48 100H100V117C100 121.418 96.4183 125 92 125H56C51.5817 125 48 121.418 48 117V100Z",
      text: { coor: { x: "59.2582", y: "116.864" }, name: "CCK" },
    },
  },
  {
    type: "track",
    id: "18",
    name: "BP1 " + t("mapTooltip.track") + " 1",
    data: {
      shape:
        "M60.5 130C56.3579 130 53 133.358 53 137.5C53 141.642 56.3579 145 60.5 145C64.6421 145 68 141.642 68 137.5C68 133.358 64.6421 130 60.5 130ZM68 157.5C68 153.358 64.6421 150 60.5 150C56.3579 150 53 153.358 53 157.5C53 161.642 56.3579 165 60.5 165C64.6421 165 68 161.642 68 157.5ZM60.5 170C64.6421 170 68 173.358 68 177.5C68 181.642 64.6421 185 60.5 185C56.3579 185 53 181.642 53 177.5C53 173.358 56.3579 170 60.5 170Z",
      orientation: { type: "vertical", reverse: false },
      clickable: {
        x: "52",
        y: "130",
        width: "17",
        height: "55",
        rx: "5",
        transform: "",
      },
    },
  },
  {
    type: "track",
    id: "1",
    name: "BP1 " + t("mapTooltip.track") + " 2",
    data: {
      shape:
        "M87.5 130C83.3579 130 80 133.358 80 137.5C80 141.642 83.3579 145 87.5 145C91.6421 145 95 141.642 95 137.5C95 133.358 91.6421 130 87.5 130ZM95 157.5C95 153.358 91.6421 150 87.5 150C83.3579 150 80 153.358 80 157.5C80 161.642 83.3579 165 87.5 165C91.6421 165 95 161.642 95 157.5ZM87.5 170C91.6421 170 95 173.358 95 177.5C95 181.642 91.6421 185 87.5 185C83.3579 185 80 181.642 80 177.5C80 173.358 83.3579 170 87.5 170Z",
      orientation: { type: "vertical", reverse: false },
      clickable: {
        x: "79",
        y: "130",
        width: "17",
        height: "55",
        rx: "5",
        transform: "",
      },
    },
  },
  {
    type: "platform",
    id: "4",
    name: "BP2 " + t("mapTooltip.topPlatform"),
    data: {
      shape:
        "M48 198C48 193.582 51.5817 190 56 190H92C96.4183 190 100 193.582 100 198V215H48V198Z",
      text: { coor: { x: "61.2754", y: "206.864" }, name: "BP2" },
    },
  },
  {
    type: "platform",
    id: "3",
    name: "BP2 " + t("mapTooltip.bottomPlatform"),
    data: {
      shape:
        "M48 217H100V234C100 238.418 96.4183 242 92 242H56C51.5817 242 48 238.418 48 234V217Z",
      text: { coor: { x: "59.9219", y: "233.864" }, name: "SHV" },
    },
  },
  {
    type: "track",
    id: "17",
    name: "BP2 " + t("mapTooltip.track") + " 1",
    data: {
      shape:
        "M60.5 247C56.3579 247 53 250.358 53 254.5C53 258.642 56.3579 262 60.5 262C64.6421 262 68 258.642 68 254.5C68 250.358 64.6421 247 60.5 247ZM68 274.5C68 270.358 64.6421 267 60.5 267C56.3579 267 53 270.358 53 274.5C53 278.642 56.3579 282 60.5 282C64.6421 282 68 278.642 68 274.5ZM60.5 287C64.6421 287 68 290.358 68 294.5C68 298.642 64.6421 302 60.5 302C56.3579 302 53 298.642 53 294.5C53 290.358 56.3579 287 60.5 287Z",
      orientation: { type: "vertical", reverse: false },
      clickable: {
        x: "52",
        y: "247",
        width: "17",
        height: "55",
        rx: "5",
        transform: "",
      },
    },
  },
  {
    type: "track",
    id: "2",
    name: "BP2 " + t("mapTooltip.track") + " 2",
    data: {
      shape:
        "M87.5 247C83.3579 247 80 250.358 80 254.5C80 258.642 83.3579 262 87.5 262C91.6421 262 95 258.642 95 254.5C95 250.358 91.6421 247 87.5 247ZM95 274.5C95 270.358 91.6421 267 87.5 267C83.3579 267 80 270.358 80 274.5C80 278.642 83.3579 282 87.5 282C91.6421 282 95 278.642 95 274.5ZM87.5 287C91.6421 287 95 290.358 95 294.5C95 298.642 91.6421 302 87.5 302C83.3579 302 80 298.642 80 294.5C80 290.358 83.3579 287 87.5 287Z",
      orientation: { type: "vertical", reverse: false },
      clickable: {
        x: "79",
        y: "247",
        width: "17",
        height: "55",
        rx: "5",
        transform: "",
      },
    },
  },
  {
    type: "platform",
    id: "6",
    name: "BP3 " + t("mapTooltip.topPlatform"),
    data: {
      shape:
        "M48 315C48 310.582 51.5817 307 56 307H92C96.4183 307 100 310.582 100 315V332H48V315Z",
      text: { coor: { x: "61.0996", y: "323.864" }, name: "BP3" },
    },
  },
  {
    type: "platform",
    id: "5",
    name: "BP3 " + t("mapTooltip.bottomPlatform"),
    data: {
      shape:
        "M48 334H100V351C100 355.418 96.4183 359 92 359H56C51.5817 359 48 355.418 48 351V334Z",
      text: { coor: { x: "60.2383", y: "350.864" }, name: "KTH" },
    },
  },
  {
    type: "track",
    id: "16",
    name: "BP3 " + t("mapTooltip.track") + " 1",
    data: {
      shape:
        "M112.5 354C108.358 354 105 350.642 105 346.5C105 342.358 108.358 339 112.5 339C116.642 339 120 342.358 120 346.5C120 350.642 116.642 354 112.5 354ZM172.5 339C168.358 339 165 342.358 165 346.5C165 350.642 168.358 354 172.5 354C176.642 354 180 350.642 180 346.5C180 342.358 176.642 339 172.5 339ZM152.5 354C156.642 354 160 350.642 160 346.5C160 342.358 156.642 339 152.5 339C148.358 339 145 342.358 145 346.5C145 350.642 148.358 354 152.5 354ZM132.5 354C128.358 354 125 350.642 125 346.5C125 342.358 128.358 339 132.5 339C136.642 339 140 342.358 140 346.5C140 350.642 136.642 354 132.5 354Z",
      orientation: { type: "horizontal", reverse: false },
      clickable: {
        x: "180",
        y: "338",
        width: "17",
        height: "75",
        rx: "5",
        transform: "rotate(90 180 338)",
      },
    },
  },
  {
    type: "track",
    id: "3",
    name: "BP3 " + t("mapTooltip.track") + " 2",
    data: {
      shape:
        "M112.5 327C108.358 327 105 323.642 105 319.5C105 315.358 108.358 312 112.5 312C116.642 312 120 315.358 120 319.5C120 323.642 116.642 327 112.5 327ZM172.5 312C168.358 312 165 315.358 165 319.5C165 323.642 168.358 327 172.5 327C176.642 327 180 323.642 180 319.5C180 315.358 176.642 312 172.5 312ZM152.5 327C156.642 327 160 323.642 160 319.5C160 315.358 156.642 312 152.5 312C148.358 312 145 315.358 145 319.5C145 323.642 148.358 327 152.5 327ZM132.5 327C128.358 327 125 323.642 125 319.5C125 315.358 128.358 312 132.5 312C136.642 312 140 315.358 140 319.5C140 323.642 136.642 327 132.5 327Z",
      orientation: { type: "horizontal", reverse: false },
      clickable: {
        x: "180",
        y: "311",
        width: "17",
        height: "75",
        rx: "5",
        transform: "rotate(90 180 311)",
      },
    },
  },
  {
    type: "platform",
    id: "8",
    name: "BP4 " + t("mapTooltip.topPlatform"),
    data: {
      shape:
        "M185 315C185 310.582 188.582 307 193 307H229C233.418 307 237 310.582 237 315V332H185V315Z",
      text: { coor: { x: "197.965", y: "323.864" }, name: "BP4" },
    },
  },
  {
    type: "platform",
    id: "7",
    name: "BP4 " + t("mapTooltip.bottomPlatform"),
    data: {
      shape:
        "M185 334H237V351C237 355.418 233.418 359 229 359H193C188.582 359 185 355.418 185 351V334Z",
      text: { coor: { x: "195.34", y: "350.864" }, name: "TKW" },
    },
  },
  {
    type: "track",
    id: "15",
    name: "BP4 " + t("mapTooltip.track") + " 1",
    data: {
      shape:
        "M249.5 354C245.358 354 242 350.642 242 346.5C242 342.358 245.358 339 249.5 339C253.642 339 257 342.358 257 346.5C257 350.642 253.642 354 249.5 354ZM309.5 339C305.358 339 302 342.358 302 346.5C302 350.642 305.358 354 309.5 354C313.642 354 317 350.642 317 346.5C317 342.358 313.642 339 309.5 339ZM289.5 354C293.642 354 297 350.642 297 346.5C297 342.358 293.642 339 289.5 339C285.358 339 282 342.358 282 346.5C282 350.642 285.358 354 289.5 354ZM269.5 354C265.358 354 262 350.642 262 346.5C262 342.358 265.358 339 269.5 339C273.642 339 277 342.358 277 346.5C277 350.642 273.642 354 269.5 354Z",
      orientation: { type: "horizontal", reverse: false },
      clickable: {
        x: "317",
        y: "338",
        width: "17",
        height: "75",
        rx: "5",
        transform: "rotate(90 317 338)",
      },
    },
  },
  {
    type: "track",
    id: "4",
    name: "BP4 " + t("mapTooltip.track") + " 2",
    data: {
      shape:
        "M249.5 327C245.358 327 242 323.642 242 319.5C242 315.358 245.358 312 249.5 312C253.642 312 257 315.358 257 319.5C257 323.642 253.642 327 249.5 327ZM309.5 312C305.358 312 302 315.358 302 319.5C302 323.642 305.358 327 309.5 327C313.642 327 317 323.642 317 319.5C317 315.358 313.642 312 309.5 312ZM289.5 327C293.642 327 297 323.642 297 319.5C297 315.358 293.642 312 289.5 312C285.358 312 282 315.358 282 319.5C282 323.642 285.358 327 289.5 327ZM269.5 327C265.358 327 262 323.642 262 319.5C262 315.358 265.358 312 269.5 312C273.642 312 277 315.358 277 319.5C277 323.642 273.642 327 269.5 327Z",
      orientation: { type: "horizontal", reverse: false },
      clickable: {
        x: "317",
        y: "311",
        width: "17",
        height: "75",
        rx: "5",
        transform: "rotate(90 317 311)",
      },
    },
  },
  {
    type: "platform",
    id: "10",
    name: "BP5 " + t("mapTooltip.topPlatform"),
    data: {
      shape:
        "M322 315C322 310.582 325.582 307 330 307H366C370.418 307 374 310.582 374 315V332H322V315Z",
      text: { coor: { x: "335.158", y: "323.864" }, name: "BP5" },
    },
  },
  {
    type: "platform",
    id: "9",
    name: "BP5 " + t("mapTooltip.bottomPlatform"),
    data: {
      shape:
        "M322 334H374V351C374 355.418 370.418 359 366 359H330C325.582 359 322 355.418 322 351V334Z",
      text: { coor: { x: "334.279", y: "350.864" }, name: "PNX" },
    },
  },
  {
    type: "track",
    id: "14",
    name: "BP5 " + t("mapTooltip.track") + " 1",
    data: {
      shape:
        "M386.5 354C382.358 354 379 350.642 379 346.5C379 342.358 382.358 339 386.5 339C390.642 339 394 342.358 394 346.5C394 350.642 390.642 354 386.5 354ZM446.5 339C442.358 339 439 342.358 439 346.5C439 350.642 442.358 354 446.5 354C450.642 354 454 350.642 454 346.5C454 342.358 450.642 339 446.5 339ZM426.5 354C430.642 354 434 350.642 434 346.5C434 342.358 430.642 339 426.5 339C422.358 339 419 342.358 419 346.5C419 350.642 422.358 354 426.5 354ZM406.5 354C402.358 354 399 350.642 399 346.5C399 342.358 402.358 339 406.5 339C410.642 339 414 342.358 414 346.5C414 350.642 410.642 354 406.5 354Z",
      orientation: { type: "horizontal", reverse: false },
      clickable: {
        x: "454",
        y: "338",
        width: "17",
        height: "75",
        rx: "5",
        transform: "rotate(90 454 338)",
      },
    },
  },
  {
    type: "track",
    id: "5",
    name: "BP5 " + t("mapTooltip.track") + " 2",
    data: {
      shape:
        "M386.5 327C382.358 327 379 323.642 379 319.5C379 315.358 382.358 312 386.5 312C390.642 312 394 315.358 394 319.5C394 323.642 390.642 327 386.5 327ZM446.5 312C442.358 312 439 315.358 439 319.5C439 323.642 442.358 327 446.5 327C450.642 327 454 323.642 454 319.5C454 315.358 450.642 312 446.5 312ZM426.5 327C430.642 327 434 323.642 434 319.5C434 315.358 430.642 312 426.5 312C422.358 312 419 315.358 419 319.5C419 323.642 422.358 327 426.5 327ZM406.5 327C402.358 327 399 323.642 399 319.5C399 315.358 402.358 312 406.5 312C410.642 312 414 315.358 414 319.5C414 323.642 410.642 327 406.5 327Z",
      orientation: { type: "horizontal", reverse: false },
      clickable: {
        x: "454",
        y: "311",
        width: "17",
        height: "75",
        rx: "5",
        transform: "rotate(90 454 311)",
      },
    },
  },
  {
    type: "platform",
    id: "12",
    name: "BP6 " + t("mapTooltip.topPlatform"),
    data: {
      shape:
        "M459 315C459 310.582 462.582 307 467 307H503C507.418 307 511 310.582 511 315V332H459V315Z",
      text: { coor: { x: "472.064", y: "323.864" }, name: "BP6" },
    },
  },
  {
    type: "platform",
    id: "11",
    name: "BP6 " + t("mapTooltip.bottomPlatform"),
    data: {
      shape:
        "M459 334H511V351C511 355.418 507.418 359 503 359H467C462.582 359 459 355.418 459 351V334Z",
      text: { coor: { x: "473.207", y: "350.864" }, name: "BPJ" },
    },
  },
  {
    type: "track",
    id: "26",
    name: "BP6 " + t("mapTooltip.track") + " 1",
    data: {
      shape:
        "M579.159 436.552C582.088 439.481 582.088 444.23 579.159 447.159C576.23 450.088 571.481 450.088 568.552 447.159C565.623 444.23 565.623 439.481 568.552 436.552C571.481 433.623 576.23 433.623 579.159 436.552ZM497.841 376.448C500.77 379.377 505.519 379.377 508.448 376.448C511.377 373.519 511.377 368.77 508.448 365.841C505.519 362.912 500.77 362.912 497.841 365.841C494.912 368.77 494.912 373.519 497.841 376.448ZM522.59 379.983C519.661 377.055 514.912 377.055 511.984 379.983C509.055 382.912 509.055 387.661 511.984 390.59C514.912 393.519 519.661 393.519 522.59 390.59C525.519 387.661 525.519 382.912 522.59 379.983ZM536.732 394.126C539.661 397.055 539.661 401.803 536.732 404.732C533.803 407.661 529.055 407.661 526.126 404.732C523.197 401.803 523.197 397.055 526.126 394.126C529.055 391.197 533.803 391.197 536.732 394.126ZM550.874 408.268C547.945 405.339 543.197 405.339 540.268 408.268C537.339 411.197 537.339 415.945 540.268 418.874C543.197 421.803 547.945 421.803 550.874 418.874C553.803 415.945 553.803 411.197 550.874 408.268ZM565.017 422.41C567.945 425.339 567.945 430.088 565.017 433.016C562.088 435.945 557.339 435.945 554.41 433.016C551.481 430.088 551.481 425.339 554.41 422.41C557.339 419.481 562.088 419.481 565.017 422.41Z",
      orientation: { type: "vertical", reverse: false },
      clickable: {
        x: "491.831",
        y: "371.852",
        width: "17",
        height: "115",
        rx: "5",
        transform: "rotate(-45 491.831 371.852)",
      },
    },
  },
  {
    type: "track",
    id: "6",
    name: "BP6 " + t("mapTooltip.track") + " 2",
    data: {
      shape:
        "M599.159 416.552C602.088 419.481 602.088 424.23 599.159 427.159C596.23 430.088 591.481 430.088 588.552 427.159C585.623 424.23 585.623 419.481 588.552 416.552C591.481 413.623 596.23 413.623 599.159 416.552ZM517.841 356.448C520.77 359.377 525.519 359.377 528.448 356.448C531.377 353.519 531.377 348.77 528.448 345.841C525.519 342.912 520.77 342.912 517.841 345.841C514.912 348.77 514.912 353.519 517.841 356.448ZM542.59 359.983C539.661 357.055 534.912 357.055 531.984 359.983C529.055 362.912 529.055 367.661 531.984 370.59C534.912 373.519 539.661 373.519 542.59 370.59C545.519 367.661 545.519 362.912 542.59 359.983ZM556.732 374.126C559.661 377.055 559.661 381.803 556.732 384.732C553.803 387.661 549.055 387.661 546.126 384.732C543.197 381.803 543.197 377.055 546.126 374.126C549.055 371.197 553.803 371.197 556.732 374.126ZM570.874 388.268C567.945 385.339 563.197 385.339 560.268 388.268C557.339 391.197 557.339 395.945 560.268 398.874C563.197 401.803 567.945 401.803 570.874 398.874C573.803 395.945 573.803 391.197 570.874 388.268ZM585.017 402.41C587.945 405.339 587.945 410.088 585.017 413.016C582.088 415.945 577.339 415.945 574.41 413.016C571.481 410.088 571.481 405.339 574.41 402.41C577.339 399.481 582.088 399.481 585.017 402.41Z",
      orientation: { type: "vertical", reverse: true },
      clickable: {
        x: "511.831",
        y: "351.852",
        width: "17",
        height: "115",
        rx: "5",
        transform: "rotate(-45 511.831 351.852)",
      },
    },
  },
  {
    type: "platform",
    id: "13",
    name: "BP7 " + t("mapTooltip.topPlatform"),
    data: {
      shape:
        "M586 442C586 437.582 589.582 434 594 434H630C634.418 434 638 437.582 638 442V459H586V442Z",
      text: { coor: { x: "599.486", y: "450.864" }, name: "BP7" },
    },
  },
  {
    type: "platform",
    id: "14",
    name: "BP7 " + t("mapTooltip.bottomPlatform"),
    data: {
      shape:
        "M586 461H638V478C638 482.418 634.418 486 630 486H594C589.582 486 586 482.418 586 478V461Z",
      text: { coor: { x: "599.053", y: "477.864" }, name: "PTR" },
    },
  },
  {
    type: "track",
    id: "25",
    name: "BP7 " + t("mapTooltip.track") + " 1",
    data: {
      shape:
        "M643 473.5C643 477.642 646.358 481 650.5 481C654.642 481 658 477.642 658 473.5C658 469.358 654.642 466 650.5 466C646.358 466 643 469.358 643 473.5ZM670.5 466C666.358 466 663 469.358 663 473.5C663 477.642 666.358 481 670.5 481C674.642 481 678 477.642 678 473.5C678 469.358 674.642 466 670.5 466ZM683 473.5C683 469.358 686.358 466 690.5 466C694.642 466 698 469.358 698 473.5C698 477.642 694.642 481 690.5 481C686.358 481 683 477.642 683 473.5Z",
      orientation: { type: "horizontal", reverse: false },
      clickable: {
        x: "643",
        y: "482",
        width: "17",
        height: "55",
        rx: "5",
        transform: "rotate(-90 643 482)",
      },
    },
  },
  {
    type: "track",
    id: "7",
    name: "BP7 " + t("mapTooltip.track") + " 2",
    data: {
      shape:
        "M643 446.5C643 450.642 646.358 454 650.5 454C654.642 454 658 450.642 658 446.5C658 442.358 654.642 439 650.5 439C646.358 439 643 442.358 643 446.5ZM670.5 439C666.358 439 663 442.358 663 446.5C663 450.642 666.358 454 670.5 454C674.642 454 678 450.642 678 446.5C678 442.358 674.642 439 670.5 439ZM683 446.5C683 442.358 686.358 439 690.5 439C694.642 439 698 442.358 698 446.5C698 450.642 694.642 454 690.5 454C686.358 454 683 450.642 683 446.5Z",
      orientation: { type: "horizontal", reverse: true },
      clickable: {
        x: "643",
        y: "455",
        width: "17",
        height: "55",
        rx: "5",
        transform: "rotate(-90 643 455)",
      },
    },
  },
  {
    type: "platform",
    id: "15",
    name: "BP8 " + t("mapTooltip.topPlatform"),
    data: {
      shape:
        "M703 442C703 437.582 706.582 434 711 434H747C751.418 434 755 437.582 755 442V459H703V442Z",
      text: { coor: { x: "716.047", y: "450.864" }, name: "BP8" },
    },
  },
  {
    type: "platform",
    id: "16",
    name: "BP8 " + t("mapTooltip.bottomPlatform"),
    data: {
      shape:
        "M703 461H755V478C755 482.418 751.418 486 747 486H711C706.582 486 703 482.418 703 478V461Z",
      text: { coor: { x: "715.326", y: "477.864" }, name: "PND" },
    },
  },
  {
    type: "track",
    id: "24",
    name: "BP8 " + t("mapTooltip.track") + " 1",
    data: {
      shape:
        "M760 473.5C760 477.642 763.358 481 767.5 481C771.642 481 775 477.642 775 473.5C775 469.358 771.642 466 767.5 466C763.358 466 760 469.358 760 473.5ZM787.5 466C783.358 466 780 469.358 780 473.5C780 477.642 783.358 481 787.5 481C791.642 481 795 477.642 795 473.5C795 469.358 791.642 466 787.5 466ZM800 473.5C800 469.358 803.358 466 807.5 466C811.642 466 815 469.358 815 473.5C815 477.642 811.642 481 807.5 481C803.358 481 800 477.642 800 473.5Z",
      orientation: { type: "horizontal", reverse: false },
      clickable: {
        x: "760",
        y: "482",
        width: "17",
        height: "55",
        rx: "5",
        transform: "rotate(-90 760 482)",
      },
    },
  },
  {
    type: "track",
    id: "8",
    name: "BP8 " + t("mapTooltip.track") + " 2",
    data: {
      shape:
        "M760 446.5C760 450.642 763.358 454 767.5 454C771.642 454 775 450.642 775 446.5C775 442.358 771.642 439 767.5 439C763.358 439 760 442.358 760 446.5ZM787.5 439C783.358 439 780 442.358 780 446.5C780 450.642 783.358 454 787.5 454C791.642 454 795 450.642 795 446.5C795 442.358 791.642 439 787.5 439ZM800 446.5C800 442.358 803.358 439 807.5 439C811.642 439 815 442.358 815 446.5C815 450.642 811.642 454 807.5 454C803.358 454 800 450.642 800 446.5Z",
      orientation: { type: "horizontal", reverse: true },
      clickable: {
        x: "760",
        y: "455",
        width: "17",
        height: "55",
        rx: "5",
        transform: "rotate(-90 760 455)",
      },
    },
  },
  {
    type: "platform",
    id: "17",
    name: "BP9 " + t("mapTooltip.topPlatform"),
    data: {
      shape:
        "M820 442C820 437.582 823.582 434 828 434H864C868.418 434 872 437.582 872 442V459H820V442Z",
      text: { coor: { x: "833.064", y: "450.864" }, name: "BP9" },
    },
  },
  {
    type: "platform",
    id: "18",
    name: "BP9 " + t("mapTooltip.bottomPlatform"),
    data: {
      shape:
        "M820 461H872V478C872 482.418 868.418 486 864 486H828C823.582 486 820 482.418 820 478V461Z",
      text: { coor: { x: "832.742", y: "477.864" }, name: "BKT" },
    },
  },
  {
    type: "track",
    id: "23",
    name: "BP9 " + t("mapTooltip.track") + " 1",
    data: {
      shape:
        "M949.721 366.01C952.65 363.081 957.399 363.081 960.328 366.01C963.257 368.939 963.257 373.688 960.328 376.617C957.399 379.546 952.65 379.546 949.721 376.617C946.793 373.688 946.793 368.939 949.721 366.01ZM889.617 447.328C892.546 444.399 892.546 439.65 889.617 436.721C886.688 433.792 881.94 433.792 879.011 436.721C876.082 439.65 876.082 444.399 879.011 447.328C881.94 450.257 886.688 450.257 889.617 447.328ZM893.153 422.579C890.224 425.508 890.224 430.257 893.153 433.186C896.082 436.114 900.831 436.114 903.759 433.186C906.688 430.257 906.688 425.508 903.759 422.579C900.831 419.65 896.082 419.65 893.153 422.579ZM907.295 408.437C910.224 405.508 914.973 405.508 917.902 408.437C920.831 411.366 920.831 416.114 917.902 419.043C914.973 421.972 910.224 421.972 907.295 419.043C904.366 416.114 904.366 411.366 907.295 408.437ZM921.437 394.295C918.508 397.224 918.508 401.972 921.437 404.901C924.366 407.83 929.115 407.83 932.044 404.901C934.973 401.972 934.973 397.224 932.044 394.295C929.115 391.366 924.366 391.366 921.437 394.295ZM935.579 380.153C938.508 377.224 943.257 377.224 946.186 380.153C949.115 383.081 949.115 387.83 946.186 390.759C943.257 393.688 938.508 393.688 935.579 390.759C932.65 387.83 932.65 383.081 935.579 380.153Z",
      orientation: { type: "vertical", reverse: true },
      clickable: {
        x: "885.021",
        y: "453.338",
        width: "17",
        height: "115",
        rx: "5",
        transform: "rotate(-135 885.021 453.338)",
      },
    },
  },
  {
    type: "track",
    id: "9",
    name: "BP9 " + t("mapTooltip.track") + " 2",
    data: {
      shape:
        "M929.721 346.01C932.65 343.081 937.399 343.081 940.328 346.01C943.257 348.939 943.257 353.688 940.328 356.617C937.399 359.546 932.65 359.546 929.721 356.617C926.793 353.688 926.793 348.939 929.721 346.01ZM869.617 427.328C872.546 424.399 872.546 419.65 869.617 416.721C866.688 413.792 861.94 413.792 859.011 416.721C856.082 419.65 856.082 424.399 859.011 427.328C861.94 430.257 866.688 430.257 869.617 427.328ZM873.153 402.579C870.224 405.508 870.224 410.257 873.153 413.186C876.082 416.114 880.831 416.114 883.759 413.186C886.688 410.257 886.688 405.508 883.759 402.579C880.831 399.65 876.082 399.65 873.153 402.579ZM887.295 388.437C890.224 385.508 894.973 385.508 897.902 388.437C900.831 391.366 900.831 396.114 897.902 399.043C894.973 401.972 890.224 401.972 887.295 399.043C884.366 396.114 884.366 391.366 887.295 388.437ZM901.437 374.295C898.508 377.224 898.508 381.972 901.437 384.901C904.366 387.83 909.115 387.83 912.044 384.901C914.973 381.972 914.973 377.224 912.044 374.295C909.115 371.366 904.366 371.366 901.437 374.295ZM915.579 360.153C918.508 357.224 923.257 357.224 926.186 360.153C929.115 363.081 929.115 367.83 926.186 370.759C923.257 373.688 918.508 373.688 915.579 370.759C912.65 367.83 912.65 363.081 915.579 360.153Z",
      orientation: { type: "vertical", reverse: false },
      clickable: {
        x: "865.021",
        y: "433.338",
        width: "17",
        height: "115",
        rx: "5",
        transform: "rotate(-135 865.021 433.338)",
      },
    },
  },
  {
    type: "platform",
    id: "19",
    name: "BP10 " + t("mapTooltip.topPlatform"),
    data: {
      shape:
        "M947.169 315.169C947.169 310.751 950.751 307.169 955.169 307.169H991.169C995.587 307.169 999.169 310.751 999.169 315.169V332.169H947.169V315.169Z",
      text: { coor: { x: "957.034", y: "324.033" }, name: "BP10" },
    },
  },
  {
    type: "platform",
    id: "20",
    name: "BP10 " + t("mapTooltip.bottomPlatform"),
    data: {
      shape:
        "M947.169 334.169H999.169V351.169C999.169 355.587 995.587 359.169 991.169 359.169H955.169C950.751 359.169 947.169 355.587 947.169 351.169V334.169Z",
      text: { coor: { x: "961.622", y: "351.033" }, name: "FJR" },
    },
  },
  {
    type: "track",
    id: "22",
    name: "BP10 " + t("mapTooltip.track") + " 1",
    data: {
      shape:
        "M960.328 289.721C963.257 292.65 963.257 297.399 960.328 300.328C957.399 303.257 952.65 303.257 949.721 300.328C946.792 297.399 946.792 292.65 949.721 289.721C952.65 286.792 957.399 286.792 960.328 289.721ZM879.01 229.617C881.939 232.546 886.688 232.546 889.617 229.617C892.546 226.688 892.546 221.939 889.617 219.01C886.688 216.081 881.939 216.081 879.01 219.01C876.082 221.939 876.082 226.688 879.01 229.617ZM903.759 233.152C900.83 230.224 896.082 230.224 893.153 233.152C890.224 236.081 890.224 240.83 893.153 243.759C896.082 246.688 900.83 246.688 903.759 243.759C906.688 240.83 906.688 236.081 903.759 233.152ZM917.901 247.295C920.83 250.224 920.83 254.972 917.901 257.901C914.972 260.83 910.224 260.83 907.295 257.901C904.366 254.972 904.366 250.224 907.295 247.295C910.224 244.366 914.972 244.366 917.901 247.295ZM932.043 261.437C929.115 258.508 924.366 258.508 921.437 261.437C918.508 264.366 918.508 269.114 921.437 272.043C924.366 274.972 929.115 274.972 932.043 272.043C934.972 269.114 934.972 264.366 932.043 261.437ZM946.186 275.579C949.115 278.508 949.115 283.257 946.186 286.185C943.257 289.114 938.508 289.114 935.579 286.185C932.65 283.257 932.65 278.508 935.579 275.579C938.508 272.65 943.257 272.65 946.186 275.579Z",
      orientation: { type: "vertical", reverse: true },
      clickable: {
        x: "873",
        y: "225.021",
        width: "17",
        height: "115",
        rx: "5",
        transform: "rotate(-45 873 225.021)",
      },
    },
  },
  {
    type: "track",
    id: "10",
    name: "BP10 " + t("mapTooltip.track") + " 2",
    data: {
      shape:
        "M940.328 309.721C943.257 312.65 943.257 317.399 940.328 320.328C937.399 323.257 932.65 323.257 929.721 320.328C926.792 317.399 926.792 312.65 929.721 309.721C932.65 306.792 937.399 306.792 940.328 309.721ZM859.01 249.617C861.939 252.546 866.688 252.546 869.617 249.617C872.546 246.688 872.546 241.939 869.617 239.01C866.688 236.081 861.939 236.081 859.01 239.01C856.082 241.939 856.082 246.688 859.01 249.617ZM883.759 253.152C880.83 250.224 876.082 250.224 873.153 253.152C870.224 256.081 870.224 260.83 873.153 263.759C876.082 266.688 880.83 266.688 883.759 263.759C886.688 260.83 886.688 256.081 883.759 253.152ZM897.901 267.295C900.83 270.224 900.83 274.972 897.901 277.901C894.972 280.83 890.224 280.83 887.295 277.901C884.366 274.972 884.366 270.224 887.295 267.295C890.224 264.366 894.972 264.366 897.901 267.295ZM912.043 281.437C909.115 278.508 904.366 278.508 901.437 281.437C898.508 284.366 898.508 289.114 901.437 292.043C904.366 294.972 909.115 294.972 912.043 292.043C914.972 289.114 914.972 284.366 912.043 281.437ZM926.186 295.579C929.115 298.508 929.115 303.257 926.186 306.185C923.257 309.114 918.508 309.114 915.579 306.185C912.65 303.257 912.65 298.508 915.579 295.579C918.508 292.65 923.257 292.65 926.186 295.579Z",
      orientation: { type: "vertical", reverse: false },
      clickable: {
        x: "853",
        y: "245.021",
        width: "17",
        height: "115",
        rx: "5",
        transform: "rotate(-45 853 245.021)",
      },
    },
  },
  {
    type: "platform",
    id: "22",
    name: "BP11 " + t("mapTooltip.topPlatform"),
    data: {
      shape:
        "M820 188C820 183.582 823.582 180 828 180H864C868.418 180 872 183.582 872 188V205H820V188Z",
      text: { coor: { x: "832.137", y: "196.864" }, name: "SGR" },
    },
  },
  {
    type: "platform",
    id: "21",
    name: "BP11 " + t("mapTooltip.bottomPlatform"),
    data: {
      shape:
        "M820 207H872V224C872 228.418 868.418 232 864 232H828C823.582 232 820 228.418 820 224V207Z",
      text: { coor: { x: "830.137", y: "223.864" }, name: "BP11" },
    },
  },
  {
    type: "track",
    id: "21",
    name: "BP11 " + t("mapTooltip.track") + " 1",
    data: {
      shape:
        "M760 192.5C760 196.642 763.358 200 767.5 200C771.642 200 775 196.642 775 192.5C775 188.358 771.642 185 767.5 185C763.358 185 760 188.358 760 192.5ZM787.5 185C783.358 185 780 188.358 780 192.5C780 196.642 783.358 200 787.5 200C791.642 200 795 196.642 795 192.5C795 188.358 791.642 185 787.5 185ZM800 192.5C800 188.358 803.358 185 807.5 185C811.642 185 815 188.358 815 192.5C815 196.642 811.642 200 807.5 200C803.358 200 800 196.642 800 192.5Z",
      orientation: { type: "horizontal", reverse: true },
      clickable: {
        x: "760",
        y: "201",
        width: "17",
        height: "55",
        rx: "5",
        transform: "rotate(-90 760 201)",
      },
    },
  },
  {
    type: "track",
    id: "11",
    name: "BP11 " + t("mapTooltip.track") + " 2",
    data: {
      shape:
        "M760 219.5C760 223.642 763.358 227 767.5 227C771.642 227 775 223.642 775 219.5C775 215.358 771.642 212 767.5 212C763.358 212 760 215.358 760 219.5ZM787.5 212C783.358 212 780 215.358 780 219.5C780 223.642 783.358 227 787.5 227C791.642 227 795 223.642 795 219.5C795 215.358 791.642 212 787.5 212ZM800 219.5C800 215.358 803.358 212 807.5 212C811.642 212 815 215.358 815 219.5C815 223.642 811.642 227 807.5 227C803.358 227 800 223.642 800 219.5Z",
      orientation: { type: "horizontal", reverse: false },
      clickable: {
        x: "760",
        y: "228",
        width: "17",
        height: "55",
        rx: "5",
        transform: "rotate(-90 760 228)",
      },
    },
  },
  {
    type: "platform",
    id: "24",
    name: "BP12 " + t("mapTooltip.topPlatform"),
    data: {
      shape:
        "M703 188C703 183.582 706.582 180 711 180H747C751.418 180 755 183.582 755 188V205H703V188Z",
      text: { coor: { x: "717.595", y: "196.864" }, name: "JLP" },
    },
  },
  {
    type: "platform",
    id: "23",
    name: "BP12 " + t("mapTooltip.bottomPlatform"),
    data: {
      shape:
        "M703 207H755V224C755 228.418 751.418 232 747 232H711C706.582 232 703 228.418 703 224V207Z",
      text: { coor: { x: "713", y: "223.864" }, name: "BP12" },
    },
  },
  {
    type: "track",
    id: "20",
    name: "BP12 " + t("mapTooltip.track") + " 1",
    data: {
      shape:
        "M643 192.5C643 196.642 646.358 200 650.5 200C654.642 200 658 196.642 658 192.5C658 188.358 654.642 185 650.5 185C646.358 185 643 188.358 643 192.5ZM670.5 185C666.358 185 663 188.358 663 192.5C663 196.642 666.358 200 670.5 200C674.642 200 678 196.642 678 192.5C678 188.358 674.642 185 670.5 185ZM683 192.5C683 188.358 686.358 185 690.5 185C694.642 185 698 188.358 698 192.5C698 196.642 694.642 200 690.5 200C686.358 200 683 196.642 683 192.5Z",
      orientation: { type: "horizontal", reverse: true },
      clickable: {
        x: "643",
        y: "201",
        width: "17",
        height: "55",
        rx: "5",
        transform: "rotate(-90 643 201)",
      },
    },
  },
  {
    type: "track",
    id: "12",
    name: "BP12 " + t("mapTooltip.track") + " 2",
    data: {
      shape:
        "M643 219.5C643 223.642 646.358 227 650.5 227C654.642 227 658 223.642 658 219.5C658 215.358 654.642 212 650.5 212C646.358 212 643 215.358 643 219.5ZM670.5 212C666.358 212 663 215.358 663 219.5C663 223.642 666.358 227 670.5 227C674.642 227 678 223.642 678 219.5C678 215.358 674.642 212 670.5 212ZM683 219.5C683 215.358 686.358 212 690.5 212C694.642 212 698 215.358 698 219.5C698 223.642 694.642 227 690.5 227C686.358 227 683 223.642 683 219.5Z",
      orientation: { type: "horizontal", reverse: false },
      clickable: {
        x: "643",
        y: "228",
        width: "17",
        height: "55",
        rx: "5",
        transform: "rotate(-90 643 228)",
      },
    },
  },
  {
    type: "platform",
    id: "26",
    name: "BP13 " + t("mapTooltip.topPlatform"),
    data: {
      shape:
        "M586 188C586 183.582 589.582 180 594 180H630C634.418 180 638 183.582 638 188V205H586V188Z",
      text: { coor: { x: "598.187", y: "196.864" }, name: "SNJ" },
    },
  },
  {
    type: "platform",
    id: "25",
    name: "BP13 " + t("mapTooltip.bottomPlatform"),
    data: {
      shape:
        "M586 207H638V224C638 228.418 634.418 232 630 232H594C589.582 232 586 228.418 586 224V207Z",
      text: { coor: { x: "595.117", y: "223.864" }, name: "BP13" },
    },
  },
  {
    type: "track",
    id: "19",
    name: "BP13 " + t("mapTooltip.track") + " 1",
    data: {
      shape:
        "M568.552 218.841C571.481 215.913 576.23 215.913 579.159 218.841C582.088 221.77 582.088 226.519 579.159 229.448C576.23 232.377 571.481 232.377 568.552 229.448C565.623 226.519 565.623 221.77 568.552 218.841ZM508.448 300.159C511.377 297.23 511.377 292.481 508.448 289.552C505.519 286.623 500.77 286.623 497.841 289.552C494.913 292.481 494.913 297.23 497.841 300.159C500.77 303.088 505.519 303.088 508.448 300.159ZM511.984 275.41C509.055 278.339 509.055 283.088 511.984 286.017C514.913 288.946 519.661 288.946 522.59 286.017C525.519 283.088 525.519 278.339 522.59 275.41C519.661 272.481 514.913 272.481 511.984 275.41ZM526.126 261.268C529.055 258.339 533.803 258.339 536.732 261.268C539.661 264.197 539.661 268.946 536.732 271.874C533.803 274.803 529.055 274.803 526.126 271.874C523.197 268.946 523.197 264.197 526.126 261.268ZM540.268 247.126C537.339 250.055 537.339 254.803 540.268 257.732C543.197 260.661 547.946 260.661 550.874 257.732C553.803 254.803 553.803 250.055 550.874 247.126C547.946 244.197 543.197 244.197 540.268 247.126ZM554.41 232.984C557.339 230.055 562.088 230.055 565.017 232.984C567.946 235.913 567.946 240.661 565.017 243.59C562.088 246.519 557.339 246.519 554.41 243.59C551.481 240.661 551.481 235.913 554.41 232.984Z",
      orientation: { type: "vertical", reverse: false },
      clickable: {
        x: "503.852",
        y: "306.169",
        width: "17",
        height: "115",
        rx: "5",
        transform: "rotate(-135 503.852 306.169)",
      },
    },
  },
  {
    type: "track",
    id: "13",
    name: "BP13 " + t("mapTooltip.track") + " 2",
    data: {
      shape:
        "M588.552 238.841C591.481 235.913 596.23 235.913 599.159 238.841C602.088 241.77 602.088 246.519 599.159 249.448C596.23 252.377 591.481 252.377 588.552 249.448C585.623 246.519 585.623 241.77 588.552 238.841ZM528.448 320.159C531.377 317.23 531.377 312.481 528.448 309.552C525.519 306.623 520.77 306.623 517.841 309.552C514.913 312.481 514.913 317.23 517.841 320.159C520.77 323.088 525.519 323.088 528.448 320.159ZM531.984 295.41C529.055 298.339 529.055 303.088 531.984 306.017C534.913 308.946 539.661 308.946 542.59 306.017C545.519 303.088 545.519 298.339 542.59 295.41C539.661 292.481 534.913 292.481 531.984 295.41ZM546.126 281.268C549.055 278.339 553.803 278.339 556.732 281.268C559.661 284.197 559.661 288.946 556.732 291.874C553.803 294.803 549.055 294.803 546.126 291.874C543.197 288.946 543.197 284.197 546.126 281.268ZM560.268 267.126C557.339 270.055 557.339 274.803 560.268 277.732C563.197 280.661 567.946 280.661 570.874 277.732C573.803 274.803 573.803 270.055 570.874 267.126C567.946 264.197 563.197 264.197 560.268 267.126ZM574.41 252.984C577.339 250.055 582.088 250.055 585.017 252.984C587.946 255.913 587.946 260.661 585.017 263.59C582.088 266.519 577.339 266.519 574.41 263.59C571.481 260.661 571.481 255.913 574.41 252.984Z",
      orientation: { type: "vertical", reverse: true },
      clickable: {
        x: "523.852",
        y: "326.169",
        width: "17",
        height: "115",
        rx: "5",
        transform: "rotate(-135 523.852 326.169)",
      },
    },
  },
];
