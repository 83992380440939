import {
  assignAnnouncementToGroup,
  assignAnnouncementToViewer,
} from "../store/actions/announcementAction";
import { groupAPILimit, scheduleAPILimit, viewerAPILimit } from "../api";
import Viewer from "../models/ViewerModel";
import Group from "../models/GroupModel";
import {
  assignScheduleToGroup,
  assignScheduleToViewer,
} from "../store/actions/scheduleAction";
import {
  assignGroupToSchedule,
  assignGroupToViewer,
} from "../store/actions/groupAction";
import {
  assignViewerToGroup,
  assignViewerToSchedule,
} from "../store/actions/viewerAction";
import Schedule from "../models/ScheduleModel";

export const assignData = (type, get) => {
  let returnData = {};

  // Get
  switch (get) {
    case "viewer":
      switch (type) {
        case "announcement":
          returnData["submitCall"] = assignAnnouncementToViewer;
          break;

        case "schedule":
          returnData["submitCall"] = assignScheduleToViewer;
          break;

        case "group":
          returnData["submitCall"] = assignGroupToViewer;
          break;
        default:
          break;
      }
      returnData["fetchingAPI"] = viewerAPILimit;
      returnData["model"] = Viewer;
      returnData["getVarName"] = "screens";
      break;

    case "group":
      switch (type) {
        case "announcement":
          returnData["submitCall"] = assignAnnouncementToGroup;
          break;

        case "schedule":
          returnData["submitCall"] = assignScheduleToGroup;
          break;

        case "viewer":
          returnData["submitCall"] = assignViewerToGroup;
          break;

        default:
          break;
      }
      returnData["fetchingAPI"] = groupAPILimit;
      returnData["model"] = Group;
      returnData["getVarName"] = "screenGroups";
      break;

    case "schedule":
      switch (type) {
        case "viewer":
          returnData["submitCall"] = assignViewerToSchedule;
          break;

        case "group":
          returnData["submitCall"] = assignGroupToSchedule;
          break;

        default:
          break;
      }
      returnData["fetchingAPI"] = scheduleAPILimit + "&isApproved=false";
      returnData["model"] = Schedule;
      returnData["getVarName"] = "schedules";
      break;

    default:
      break;
  }

  return returnData;
};
