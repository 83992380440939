import React from "react";
import { useTranslation } from "react-i18next";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Link, Tooltip } from "@mui/material";
import { TypographyLarge } from "../../customComponent";
import { colorStyling } from "../../../helpers/color";
import { useNavigate } from "react-router-dom";

const ViewerBreadCrumb = ({ viewer }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("viewer");

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon
          sx={{ color: colorStyling.primary }}
          fontSize="small"
        />
      }
    >
      <Link
        component="button"
        underline="hover"
        onClick={() => navigate("/viewers")}
      >
        <TypographyLarge
          sx={{
            color: colorStyling.primary,
            cursor: "pointer",
            fontWeight: 400,
          }}
        >
          {t("mainBreadcrumb")}
        </TypographyLarge>
      </Link>
      <Link underline="always">
        <Tooltip
          title={viewer?.name?.length > 30 ? viewer?.name : ""}
          placement="top-start"
        >
          <TypographyLarge
            sx={{ color: colorStyling.primary, cursor: "pointer" }}
          >
            {viewer?.name?.length > 30
              ? viewer?.name?.substring(0, 27).trim() + "..."
              : viewer?.name}
          </TypographyLarge>
        </Tooltip>
      </Link>
    </Breadcrumbs>
  );
};

export default ViewerBreadCrumb;
