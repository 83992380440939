import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import {
  CssBaseline,
  Box,
  CircularProgress,
  Alert,
  Paper,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  CstTypo20W600,
  CustomTextField,
  PrimaryButton,
} from "../components/customComponent";
import { GlobalSuccessPrompt } from "../components";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { expiredPasswordSchema } from "../schemas/expiredPasswordSchema";
import { changeSelfPassword } from "../store/actions/userAction";
import { colorStyling } from "../helpers/color";
import DispatchErrorPromptAlert from "./DispatchErrorPromptAlert";
import { SET_ERROR_PROMPT_USER_DIALOG } from "../store/actionTypes/userActionType";
import parser from "html-react-parser";

const ExpiredPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loadingUser,
    isErrorPromptUserDialog: isError,
    errorMessageUserDialog: errorMessage,
  } = useSelector((state) => state.user);

  const { t } = useTranslation("user");
  const { t: tCommon } = useTranslation();

  const [form, setForm] = useState({
    showOldPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });

  const handleClickShowOldPassword = () => {
    setForm({
      ...form,
      showOldPassword: !form.showOldPassword,
    });
  };

  const handleClickShowNewPassword = () => {
    setForm({
      ...form,
      showNewPassword: !form.showNewPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setForm({
      ...form,
      showConfirmPassword: !form.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(expiredPasswordSchema) });

  const handleExpiredPassword = async (data) => {
    const payload = {
      currentPassword: data["Current Password"],
      password: data["New Password"],
    };
    dispatch(changeSelfPassword(payload, handleCallbackChangePassword)).then(
      () =>
        reset({
          "Current Password": "",
          "New Password": "",
          "Confirm Password": "",
        })
    );
  };

  const handleCallbackChangePassword = (success, error) => {
    if (success) {
      sessionStorage.clear();
      navigate("/login");
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        minWidth: "100wh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CssBaseline />
      <GlobalSuccessPrompt />

      <Box
        component="main"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
        }}
      >
        <Paper
          component="div"
          elevation={2}
          sx={{
            p: 2,
            border: "1px solid lightgrey",
            width: "37.5rem",
            borderRadius: "6px",
          }}
        >
          <Alert severity="warning" sx={{ mb: 1 }}>
            {parser(t("expired.warningHint"))}
          </Alert>

          <DispatchErrorPromptAlert
            isError={isError}
            errorMessage={errorMessage}
            closeActionType={SET_ERROR_PROMPT_USER_DIALOG}
          />

          <Box
            sx={{ px: 4, py: 2 }}
            component="form"
            onSubmit={handleSubmit(handleExpiredPassword)}
          >
            <CstTypo20W600 textAlign="center">
              {t("expired.title")}
            </CstTypo20W600>
            <Alert severity="info" sx={{ mt: 1 }}>
              {parser(t("common.passwordReqHints"))}
            </Alert>

            <CustomTextField
              fullWidth
              margin="normal"
              label={t("expired.currentPasswordPlaceholder")}
              type={form.showOldPassword ? "text" : "password"}
              required
              {...register("Current Password")}
              error={errors["Current Password"]}
              helperText={errors["Current Password"]?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowOldPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {form.showOldPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <CustomTextField
              fullWidth
              margin="normal"
              label={t("expired.newPasswordPlaceholder")}
              type={form.showNewPassword ? "text" : "password"}
              required
              {...register("New Password")}
              error={errors["New Password"]}
              helperText={errors["New Password"]?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {form.showNewPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <CustomTextField
              fullWidth
              margin="normal"
              label={t("expired.confirmPasswordPlaceholder")}
              type={form.showConfirmPassword ? "text" : "password"}
              required
              {...register("Confirm Password")}
              error={errors["Confirm Password"]}
              helperText={
                errors["Confirm Password"] &&
                t("expired.confirmPasswordMustMatch")
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {form.showConfirmPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {loadingUser ? (
              <PrimaryButton size="large" sx={{ mt: 1 }} disabled fullWidth>
                <CircularProgress
                  size={20}
                  style={{ color: colorStyling.white.full }}
                />
              </PrimaryButton>
            ) : (
              <PrimaryButton
                size="large"
                sx={{ mt: 1 }}
                fullWidth
                variant="outlined"
                type="submit"
              >
                {tCommon("submitBtn")}
              </PrimaryButton>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default ExpiredPassword;
