import React, { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import MuiAlert from "@mui/material/Alert";
import { Snackbar } from "@mui/material";
import { ReportRounded } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import { SET_GLOBAL_ERROR } from "../../store/actionTypes/globalActionType";

const Alert = forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      icon={<ReportRounded fontSize="inherit" sx={{ color: "white" }} />}
      elevation={6}
      ref={ref}
      variant="standard"
      {...props}
    />
  );
});

const GlobalErrorPrompt = () => {
  const dispatch = useDispatch();
  const { isError, errorMessage } = useSelector((state) => state.global);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: SET_GLOBAL_ERROR, payload: false });
  };

  const vertical = "bottom";
  const horizontal = "center";

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={isError}
        autoHideDuration={7000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          sx={{ backgroundColor: red[700], color: "white", fontWeight: 500 }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GlobalErrorPrompt;
