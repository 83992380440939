import advertschedule from "./advertschedule.json";
import announcement from "./announcement.json";
import assetmanager from "./assetmanager.json";
import auditlog from "./auditlog.json";
import cms from "./cms.json";
import common from "./common.json";
import contentreport from "./contentreport.json";
import error from "./error.json";
import group from "./group.json";
import home from "./home.json";
import schedule from "./schedule.json";
import smp from "./smp.json";
import user from "./user.json";
import usergroup from "./usergroup.json";
import usermanual from "./usermanual.json";
import viewer from "./viewer.json";
import organization from "./organization.json";
import bplrtSmp from "./bplrtSmp.json";
import bplrtEquipment from "./bplrtEquipment.json";

const index = {
  advertschedule,
  announcement,
  assetmanager,
  auditlog,
  cms,
  common,
  contentreport,
  error,
  group,
  home,
  schedule,
  smp,
  user,
  usergroup,
  usermanual,
  viewer,
  organization,
  bplrtSmp,
  bplrtEquipment,
};

export default index;
