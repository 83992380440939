import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { Box, Button, Grid, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import {
  DisplayIcon,
  ClockIcon,
  GroupBlueIcon,
  ViewerBlueIcon,
  ScheduleYellowIcon,
  ScheduleGreyIcon,
  AnnouncementPurpleIcon,
} from "../../../icons";
import { formattedDate } from "../../../helpers/dateFormat";
import {
  Typography2XLarge,
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
  TypographyXLarge,
} from "../../customComponent";
import Role from "../../../helpers/roles";
import { useSelector } from "react-redux";
import { decryptUser } from "../../../helpers/crypto";
import { colorStyling } from "../../../helpers/color";
import { purple } from "@mui/material/colors";
import { getViewerStatus } from "../../../helpers/viewerHelper";
import PlayingStatus from "../../viewer/id/PlayingStatus";
import ViewerStatusIndicator from "../../viewer/ViewerStatusIndicator";

const RenderViewDetailViewer = ({ viewer, onClose }) => {
  const viewerIsActive = getViewerStatus(viewer?.activeAt);
  const viewerIsUpdated = getViewerStatus(viewer?.status?.updatedAt);
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggUser = decryptUser(loggedUser);
  const userRole = decryptedLoggUser.type;

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <ViewerBlueIcon />
          <Box ml={2}>
            <Tooltip
              title={viewer?.name?.length > 30 ? viewer?.name : ""}
              placement="top-start"
            >
              <Typography2XLarge sx={{ fontWeight: 600 }}>
                {viewer?.name?.length > 30
                  ? viewer?.name?.substring(0, 27).trim() + "..."
                  : viewer?.name}
              </Typography2XLarge>
            </Tooltip>

            <Box display="flex" alignItems="center" mt={1}>
              <ViewerStatusIndicator size={22} viewer={viewer} />
            </Box>
          </Box>
        </Box>

        <IconButton onClick={() => onClose(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {viewer?.status?.contentId && viewerIsActive && viewerIsUpdated && (
          <Grid item xs={12}>
            <PlayingStatus viewer={viewer} />
          </Grid>
        )}
        <Grid item xs={12}>
          <ViewerInfo viewer={viewer} userRole={userRole} />
        </Grid>

        <Grid item xs={12} lg={6}>
          <GroupInfo viewer={viewer} />
        </Grid>

        <Grid item xs={12} lg={6}>
          <ScheduleInfo viewer={viewer} />
        </Grid>

        <Grid item xs={12}>
          <Link to={`/viewer/detail/${viewer.id}`}>
            <Button sx={{ mt: 2 }} fullWidth variant="contained">
              More Details
            </Button>
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default RenderViewDetailViewer;

const ViewerInfo = ({ viewer, userRole }) => {
  return (
    <Box>
      <TypographyXLarge sx={{ fontWeight: 500, mb: 1 }}>
        Details
      </TypographyXLarge>

      <Box
        sx={{
          border: `1px solid ${colorStyling.white.border}`,
          borderRadius: "6px",
          display: "flex",
          justifyContent: "space-between",
          p: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Box sx={{ display: "flex" }}>
              <DisplayIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal>ID</TypographyNormal>
                <TypographyNormal sx={{ fontWeight: 600 }}>
                  {viewer.id}
                </TypographyNormal>
              </Box>
            </Box>
          </Grid>

          {userRole === Role.Admin && viewer.status?.ipAddress && (
            <Grid item xs={12} lg={6}>
              <Box sx={{ display: "flex" }}>
                <DisplayIcon />

                <Box sx={{ ml: 2 }}>
                  <TypographyNormal>Ip Address</TypographyNormal>
                  <TypographyNormal sx={{ fontWeight: 600 }}>
                    {viewer.status?.ipAddress ?? "-"}
                  </TypographyNormal>
                </Box>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} lg={6}>
            <Box sx={{ display: "flex" }}>
              <ScheduleGreyIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal>Last Active Time</TypographyNormal>
                <TypographyNormal
                  sx={{ fontWeight: 600, textTransform: "lowercase" }}
                >
                  {viewer.activeAt ? formattedDate(viewer.activeAt) : "-"}
                </TypographyNormal>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const GroupInfo = ({ viewer }) => {
  const navigate = useNavigate();
  return (
    <Box>
      <TypographyXLarge sx={{ fontWeight: 500, mb: 1 }}>Group</TypographyXLarge>

      <Box
        sx={{
          maxHeight: "300px",
          overflowY: "auto",
          border: `1px solid ${colorStyling.white.border}`,
          borderRadius: "6px",
          p: 2,
        }}
      >
        {Array.isArray(viewer.screenGroups) && viewer.screenGroups.length ? (
          <Grid container spacing={2}>
            {viewer.screenGroups.map((group) => (
              <Grid
                key={group.id}
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box display="flex" alignItems="center">
                  <GroupBlueIcon />
                  <Tooltip
                    title={group?.name?.length > 30 ? group?.name : ""}
                    placement="top-start"
                  >
                    <TypographyLarge
                      sx={{
                        fontWeight: 600,
                        ml: 2,
                        cursor: "pointer",
                        "&:hover": {
                          color: colorStyling.primary,
                        },
                      }}
                      onClick={() => {
                        navigate("/group/detail/" + group.id);
                      }}
                    >
                      {group?.name?.length > 30
                        ? group?.name?.substring(0, 27).trim() + "..."
                        : group?.name}
                    </TypographyLarge>
                  </Tooltip>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Link to={"/group/detail/" + group.id}>
                    <IconButton sx={{ color: colorStyling.black, ml: 4 }}>
                      <Tooltip title="See Details">
                        <NavigateNextIcon fontSize="small" />
                      </Tooltip>
                    </IconButton>
                  </Link>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TypographyLarge sx={{ textAlign: "center", fontWeight: 300 }}>
            No group
          </TypographyLarge>
        )}
      </Box>
    </Box>
  );
};

const ScheduleInfo = ({ viewer }) => {
  const navigate = useNavigate();
  return (
    <Box>
      <TypographyXLarge sx={{ fontWeight: 500, mb: 1 }}>
        Schedules
      </TypographyXLarge>

      <Box
        sx={{
          maxHeight: "300px",
          overflowY: "auto",
          border: `1px solid ${colorStyling.white.border}`,
          borderRadius: "6px",
          p: 2,
        }}
      >
        {Array.isArray(viewer.schedules) && viewer.schedules.length ? (
          <Grid container spacing={2}>
            {viewer.schedules.map((schedule) => (
              <Grid
                key={schedule.id}
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {schedule.type === "ANNOUNCEMENT" ? (
                    <AnnouncementPurpleIcon />
                  ) : (
                    <ScheduleYellowIcon />
                  )}

                  <Box sx={{ ml: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Tooltip
                        title={
                          schedule?.name?.length > 30 ? schedule?.name : ""
                        }
                        placement="top-start"
                      >
                        <TypographyNormal
                          sx={{
                            fontWeight: 600,
                            cursor: "pointer",
                            "&:hover": {
                              color: colorStyling.primary,
                            },
                          }}
                          onClick={() => {
                            navigate(
                              (schedule.type === "ANNOUNCEMENT"
                                ? "/announcement/detail/"
                                : "/schedule/detail/") + schedule.id
                            );
                          }}
                        >
                          {schedule?.name?.length > 30
                            ? schedule?.name?.substring(0, 27).trim() + "..."
                            : schedule?.name}
                        </TypographyNormal>{" "}
                      </Tooltip>
                    </Box>

                    {schedule.type === "SCHEDULE" && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TypographySmall
                          sx={{
                            fontWeight: 600,
                            color: purple[600],
                            textTransform: "capitalize",
                            mr: 1,
                          }}
                        >
                          {schedule.command.replace(/_/g, " ").toLowerCase()}
                        </TypographySmall>
                        <ClockIcon />
                        <TypographySmall sx={{ ml: 0.5, fontWeight: 600 }}>
                          {schedule.frequency === "OFF"
                            ? "ONCE"
                            : schedule.frequency}
                        </TypographySmall>
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Link
                    to={
                      schedule.type === "ANNOUNCEMENT"
                        ? `/announcement/detail/${schedule.id}`
                        : `/schedule/detail/${schedule.id}`
                    }
                  >
                    <IconButton sx={{ color: colorStyling.black, ml: 4 }}>
                      <Tooltip title="See Details">
                        <NavigateNextIcon fontSize="small" />
                      </Tooltip>
                    </IconButton>
                  </Link>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <TypographyLarge sx={{ textAlign: "center", fontWeight: 300 }}>
            No schedules
          </TypographyLarge>
        )}
      </Box>
    </Box>
  );
};
