export const SET_USER = "SET_USER";
export const SET_USER_FILTER = "SET_USER_FILTER";
export const SET_USER_HISTORY = "SET_USER_HISTORY";
export const SET_USER_GROUP = "SET_USER_GROUP";
export const SET_USER_GROUP_FILTER = "SET_USER_GROUP_FILTER";
export const SET_NEW_USER = "SET_NEW_USER";
export const SET_NEW_USER_GROUP = "SET_NEW_USER_GROUP";
export const SET_DELETE_USER = "SET_DELETE_USER";
export const SET_DELETE_USER_GROUP = "SET_DELETE_USER_GROUP";
export const SET_EDIT_USER = "SET_EDIT_USER";
export const SET_EDIT_USER_GROUP = "SET_EDIT_USER_GROUP";
export const SET_LOADING_USER = "SET_LOADING_USER";
export const SET_ERROR_PROMPT_USER_PAGE = "SET_ERROR_PROMPT_USER_PAGE";
export const SET_ERROR_MESSAGE_USER_PAGE = "SET_ERROR_MESSAGE_USER_PAGE";
export const SET_SUCCESS_PROMPT_USER_PAGE = "SET_SUCCESS_PROMPT_USER_PAGE";
export const SET_SUCCESS_MESSAGE_USER_PAGE = "SET_SUCCESS_MESSAGE_USER_PAGE";
export const SET_ERROR_PROMPT_USER_PERMISSION =
  "SET_ERROR_PROMPT_USER_PERMISSION";
export const SET_ERROR_MESSAGE_USER_PERMISSION =
  "SET_ERROR_MESSAGE_USER_PERMISSION";
export const SET_SUCCESS_PROMPT_USER_PERMISSION =
  "SET_SUCCESS_PROMPT_USER_PERMISSION";
export const SET_SUCCESS_MESSAGE_USER_PERMISSION =
  "SET_SUCCESS_MESSAGE_USER_PERMISSION";
export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_AUTH_FEATURES = "SET_AUTH_FEATURES";
export const SET_USER_PAGE = "SET_USER_PAGE";
export const SET_USER_ROWS_PER_PAGE = "SET_USER_ROWS_PER_PAGE";
export const SET_PREFETCH_USER = "SET_PREFETCH_USER";
export const SET_ERROR_PROMPT_USER_DIALOG = "SET_ERROR_PROMPT_USER_DIALOG";
export const SET_ERROR_MESSAGE_USER_DIALOG = "SET_ERROR_MESSAGE_USER_DIALOG";
export const SET_USER_GROUP_PAGE = "SET_USER_GROUP_PAGE";
export const SET_USER_GROUP_ROWS_PER_PAGE = "SET_USER_GROUP_ROWS_PER_PAGE";
export const SET_PREFETCH_USER_GROUP = "SET_PREFETCH_USER_GROUP";
export const SET_USER_HISTORY_FIRST_LOAD = "SET_USER_HISTORY_FIRST_LOAD";
export const SET_USER_HISTORY_FILTER = "SET_USER_HISTORY_FILTER";
export const SET_USER_HISTORY_ROWS_PER_PAGE = "SET_USER_HISTORY_ROWS_PER_PAGE";
export const SET_PASSWORD_ERROR_HANDLER = "SET_PASSWORD_ERROR_HANDLER";
