import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  submitContent,
  setLoadingSubmit,
} from "../../../../store/actions/cmsAction";
import { colorStyling } from "../../../../helpers/color";
import { SET_CMS_FILTER } from "../../../../store/actionTypes/cmsActionType";
import { parseVariables } from "../../../../helpers/localize";

const BackToContent = ({ setNameValidation }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    layerMedias,
    contentLayers,
    contentRes,
    contentName,
    isEditing,
    contentDetail,
    loadingDetail,
    loadingSubmit,
    widthRatio,
    heightRatio,
  } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);

  const backNavigate = () => {
    navigate("/cms/content-template");
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitNewContent = async () => {
    // validate attributes in detail
    if (contentName.length > 40) {
      const errorText = parseVariables(tCommon("validation.maxLengthChars"), {
        num: 40,
      });
      setNameValidation(errorText);
      return;
    }

    dispatch(setLoadingSubmit(true));

    const submitPayload = {
      name: contentName,
      width: contentRes.width,
      height: contentRes.height,
      contentLayers,
      layerMedias,
      widthRatio,
      heightRatio,
    };

    if (isEditing) {
      if (contentName === contentDetail.name) {
        submitPayload["sameName"] = true;
      }
      submitPayload["editID"] = contentDetail.id;
    }

    dispatch(
      submitContent(submitPayload, () => {
        dispatch({
          type: SET_CMS_FILTER,
          payload: {
            nameLike: "",
            sortBy: "idDesc",
            searchByUser: "",
            searchByUserIDs: [],
          },
        });
        navigate("/cms/content-template");
      })
    );
  };

  return (
    <Box
      sx={{
        px: 1,
        pt: 2,
        pb: 1,
        borderTop: `2px solid ${colorStyling.primary}`,
      }}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ px: 2 }}
      >
        <Button onClick={handleOpen} startIcon={<ChevronLeftIcon />}>
          {t("designer.backBtn")}
        </Button>

        <Button
          variant="contained"
          sx={{ boxShadow: 3 }}
          disabled={loadingDetail || loadingSubmit}
          onClick={() => submitNewContent()}
        >
          {t("designer.submitBtn")}
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            {t("designer.backConfirmationTitle")}
          </DialogContentText>

          <Box>
            <Alert severity="warning" variant="outlined">
              <strong>{t("designer.backConfirmationHint")}</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClose}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={backNavigate}
          >
            {tCommon("continueBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BackToContent;
