import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import {
  approveAnnouncement,
  unapproveAnnouncement,
} from "../../../store/actions/announcementAction";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";

const ApproveAnnouncement = ({ announcement }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("announcement");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      announcement.isApproved
        ? unapproveAnnouncement(announcement.id)
        : approveAnnouncement(announcement.id)
    );
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        color={announcement.isApproved ? "warning" : "success"}
        variant="contained"
        startIcon={
          announcement.isApproved ? (
            <UnpublishedIcon fontSize="small" />
          ) : (
            <CheckCircleIcon fontSize="small" />
          )
        }
        sx={{ boxShadow: 3, mr: 2 }}
      >
        {announcement.isApproved ? t("item.suspendBtn") : t("item.approveBtn")}
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            {announcement.isApproved
              ? parser(
                  parseVariables(t("item.suspendConfirmationText"), {
                    name: announcement.name,
                  })
                )
              : parser(
                  parseVariables(t("item.approveConfirmationText"), {
                    name: announcement.name,
                  })
                )}
          </DialogContentText>

          {announcement.isApproved && (
            <Box>
              <Alert severity="warning" variant="outlined">
                <strong>{t("item.suspendWarningHint")}</strong>
              </Alert>
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            color={announcement.isApproved ? "warning" : "primary"}
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={handleSubmit}
          >
            {tCommon("continueBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApproveAnnouncement;
