import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Select,
  FormControl,
  CircularProgress,
  MenuItem,
  OutlinedInput,
  IconButton,
  Button,
  Paper,
  Tooltip,
} from "@mui/material";
import { FiberManualRecord, NavigateNext } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
} from "../customComponent";
import { getPendingActiveSchedules } from "../../store/actions/homeAction";
import { ScheduleYellowIcon, AdsScheduleGreenIcon } from "../../icons";
import {
  dateOnlyFormatDDMMYYYY,
  formattedDate,
} from "../../helpers/dateFormat";
import {
  switchBackgroundColorBasedOnStatus,
  switchColorOnScheduleStatus,
} from "../../helpers/switchColor";
import { colorStyling } from "../../helpers/color";
import { parseExpression } from "cron-parser";
import { calculateDuration } from "../../helpers/scheduleHelper";

const HomeScheduleList = () => {
  const dispatch = useDispatch();
  const {
    pendingActiveSchedulesData: schedules,
    loadingPendingActiveSchedulesData: loading,
    pendingActiveSchedulesLimit: limit,
  } = useSelector((state) => state.home);

  const { t } = useTranslation("home");
  const { t: tCommon } = useTranslation();

  const [integer, setInteger] = useState(limit);

  const handleChange = (e) => {
    setInteger(e.target.value);
    dispatch(getPendingActiveSchedules(e.target.value));
  };

  if (loading && schedules.length === 0)
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height={250}
      >
        <CircularProgress
          size={22}
          thickness={3}
          sx={{ color: colorStyling.primary }}
        />
        <TypographyNormal
          sx={{
            color: colorStyling.primary,
            marginTop: "15px",
            fontWeight: 300,
          }}
        >
          {tCommon("loadingDataList")}
        </TypographyNormal>
      </Box>
    );

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <TypographyLarge sx={{ fontWeight: 600 }}>
            {t("recentLabel")}
          </TypographyLarge>

          <Box display="flex" alignItems="center">
            <FormControl>
              <Select
                input={<OutlinedInput size="small" />}
                onChange={handleChange}
                value={integer}
              >
                <MenuItem dense value={5}>
                  5
                </MenuItem>
                <MenuItem dense value={10}>
                  10
                </MenuItem>
              </Select>
            </FormControl>
            <TypographyLarge sx={{ ml: 2, fontWeight: 600 }}>
              {t("scheduleListLabel")}
            </TypographyLarge>
          </Box>
        </Box>

        {Array.isArray(schedules) && !schedules.length ? (
          <TypographyNormal align="center">
            {t("noScheduleList")}
          </TypographyNormal>
        ) : (
          <Paper
            elevation={2}
            sx={{
              maxHeight: 425,
              overflowY: "auto",
            }}
          >
            {schedules.map((schedule) => (
              <>
                {schedule.type === "ADVERTISEMENT" ? (
                  <HomeAdvScheduleItem
                    key={schedule.name + schedule.id}
                    schedule={schedule}
                  />
                ) : (
                  <HomeScheduleItem
                    key={schedule.name + schedule.id}
                    schedule={schedule}
                  />
                )}
              </>
            ))}
          </Paper>
        )}
      </Box>

      <Link to="/schedules" style={{ textDecoration: "none" }}>
        <Button variant="contained" fullWidth>
          {t("viewAllScheduleBtn")}
        </Button>
      </Link>
    </>
  );
};

const HomeScheduleItem = ({ schedule }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("home");
  const { t: tSchedule } = useTranslation("schedule");

  const handleOpenDetail = () => {
    navigate("/schedule/detail/" + schedule.id);
  };

  return (
    <>
      <Box
        onClick={handleOpenDetail}
        sx={{
          borderRadius: "6px",
          mb: 1,
          "&:hover": {
            backgroundColor: colorStyling.white.panel,
            cursor: "pointer",
          },
        }}
        p={1}
        boxShadow="0px 4px 20px rgba(158, 181, 195, 0.18)"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex">
          <Box sx={{ width: "48px" }}>
            <ScheduleYellowIcon />
          </Box>

          <Box display="flex" flexDirection="column" ml={2}>
            <TypographyNormal sx={{ fontWeight: 600, mb: 0.5 }}>
              {schedule.name}
            </TypographyNormal>

            <Box display="inline-flex" flexWrap="wrap" alignItems="center">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 4.33333V7L9 9M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
                  stroke={colorStyling.primary}
                  strokeWidth="1.6"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <TypographySmall
                display="flex"
                alignItems="center"
                sx={{
                  mx: 0.5,
                  textTransform: "uppercase",
                  color: colorStyling.primary,
                  fontWeight: 600,
                }}
              >
                {schedule.frequency === "OFF" ? (
                  <>
                    {schedule.type.includes("CONTENT") ? (
                      <>
                        {tSchedule("frequency.LOOP")}
                        <Tooltip
                          arrow
                          disableInteractive
                          placement="top"
                          title={tSchedule("item.explainLOOP")}
                        >
                          <InfoOutlinedIcon
                            fontSize="1rem"
                            sx={{ marginLeft: "3px" }}
                          />
                        </Tooltip>
                      </>
                    ) : (
                      tSchedule("frequency.ONCE")
                    )}
                  </>
                ) : (
                  tSchedule(`frequency.${schedule.frequency}`, {
                    defaultValue: null,
                  }) ?? schedule.frequency
                )}
              </TypographySmall>

              {schedule.frequency !== "OFF" && (
                <>
                  <svg
                    width="6"
                    height="6"
                    viewBox="0 0 6 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="3"
                      cy="3"
                      r="3"
                      fill={colorStyling.gray.normal}
                    />
                  </svg>

                  <TypographySmall sx={{ ml: 0.5, fontWeight: 400 }}>
                    {new Date(schedule.startAt) > new Date()
                      ? t("schedule.startAtPrefix") +
                        formattedDate(schedule.startAt)
                      : !schedule.endAt ||
                        new Date(schedule.endAt) >
                          new Date(
                            parseExpression(schedule?.cron, {
                              tz: "GMT",
                            })?.next()
                          )
                      ? t("schedule.nextAtPrefix") +
                        formattedDate(
                          parseExpression(schedule?.cron, { tz: "GMT" })?.next()
                        )
                      : t("schedule.finishedHint")}
                  </TypographySmall>
                </>
              )}
            </Box>
          </Box>
        </Box>

        <Box display="inline-flex" alignItems="center">
          <TypographySmall
            sx={{
              p: 1,
              mr: 2,
              width: "77px",
              fontWeight: 500,
              borderRadius: "15px",
              color: switchColorOnScheduleStatus(
                schedule.isActive ? "ACTIVE" : "PENDING"
              ),
              backgroundColor: switchBackgroundColorBasedOnStatus(
                schedule.isActive ? "ACTIVE" : "PENDING"
              ),
            }}
          >
            <FiberManualRecord
              sx={{
                color: switchColorOnScheduleStatus(
                  schedule.isActive ? "ACTIVE" : "PENDING"
                ),
                fontSize: 8,
                marginRight: "5px",
              }}
            />
            {schedule.isActive
              ? t("schedule.activeHint")
              : t("schedule.inactiveHint")}
          </TypographySmall>

          <IconButton color="inherit">
            <NavigateNext />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

const HomeAdvScheduleItem = ({ schedule }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("home");
  const { t: tSchedule } = useTranslation("schedule");

  const handleOpenDetail = () => {
    navigate("/schedule/detail/" + schedule.id);
  };

  return (
    <>
      <Box
        onClick={handleOpenDetail}
        sx={{
          borderRadius: "6px",
          mb: 1,
          "&:hover": {
            backgroundColor: colorStyling.white.panel,
            cursor: "pointer",
          },
        }}
        p={1}
        boxShadow="0px 4px 20px rgba(158, 181, 195, 0.18)"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex">
          <Box sx={{ width: "48px" }}>
            <AdsScheduleGreenIcon />
          </Box>

          <Box display="flex" flexDirection="column" ml={2}>
            <TypographyNormal sx={{ fontWeight: 600, mb: 0.5 }}>
              {schedule.name}
            </TypographyNormal>

            <Box display="inline-flex" flexWrap="wrap" alignItems="center">
              <TypographySmall
                display="flex"
                alignItems="center"
                sx={{
                  mx: 0.5,
                  fontWeight: 400,
                }}
              >
                {dateOnlyFormatDDMMYYYY(schedule.startAt) +
                  " - " +
                  (schedule.endAt
                    ? dateOnlyFormatDDMMYYYY(schedule.endAt)
                    : tSchedule("item.endTimeIndefinitely"))}
              </TypographySmall>

              <svg
                width="6"
                height="6"
                viewBox="0 0 6 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="3" cy="3" r="3" fill={colorStyling.gray.normal} />
              </svg>

              <TypographySmall
                display="flex"
                alignItems="center"
                sx={{
                  mx: 0.5,
                  fontWeight: 400,
                }}
              >
                {"Slot " + calculateDuration(+schedule.advertDuration)}
              </TypographySmall>
            </Box>
          </Box>
        </Box>

        <Box display="inline-flex" alignItems="center">
          <TypographySmall
            sx={{
              p: 1,
              mr: 2,
              width: "77px",
              fontWeight: 500,
              borderRadius: "15px",
              color: switchColorOnScheduleStatus(
                schedule.isActive ? "ACTIVE" : "PENDING"
              ),
              backgroundColor: switchBackgroundColorBasedOnStatus(
                schedule.isActive ? "ACTIVE" : "PENDING"
              ),
            }}
          >
            <FiberManualRecord
              sx={{
                color: switchColorOnScheduleStatus(
                  schedule.isActive ? "ACTIVE" : "PENDING"
                ),
                fontSize: 8,
                marginRight: "5px",
              }}
            />
            {schedule.isActive
              ? t("schedule.activeHint")
              : t("schedule.inactiveHint")}
          </TypographySmall>

          <IconButton color="inherit">
            <NavigateNext />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default HomeScheduleList;
