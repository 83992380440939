import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import ScreenshotIcon from "@mui/icons-material/Screenshot";
import { initiateAXIOS } from "../../../config/axios";
import { initiateScheduleAPI, initiateViewerAPI } from "../../../api";
import { TypographyNormal } from "../../customComponent";
import { formattedDate } from "../../../helpers/dateFormat";
import { colorStyling } from "../../../helpers/color";
import { useSelector } from "react-redux";
import { decryptUser } from "../../../helpers/crypto";

const ViewerScreenshot = ({ viewer, size, disabled }) => {
  const { loggedUser } = useSelector((state) => state.auth);

  const { t } = useTranslation("viewer");
  const { t: tCommon } = useTranslation();

  const decryptedLoggedUser = decryptUser(loggedUser);
  const permission = decryptedLoggedUser?.permission;
  const schedulePermission = permission.schedule;
  const delay = 20000;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [screenshotData, setScreenshotData] = useState({
    image: null,
    lastModified: null,
  });
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleScreenshot = async () => {
    getScreenshot();
    setOpen(true);
  };

  const getScreenshot = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);
    const payload = {
      id: viewer.id,
      command: "SCREENSHOT",
    };

    try {
      await initiateAXIOS.post(
        initiateScheduleAPI + "instant",
        { screens: [{ id: payload.id }], command: payload.command },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      // Function delay
      // const delay = Number.isNaN(parseInt(viewer.connectionInterval))
      //   ? 15000
      //   : parseInt(viewer.connectionInterval) + 5000;
      await new Promise((resolve) => setTimeout(resolve, delay));

      const dataScreen = await initiateAXIOS.get(
        initiateViewerAPI + payload.id + "/screenshot",
        {
          headers: { authorization: `Bearer ${access_token}` },
          responseType: "blob",
        }
      );

      setScreenshotData({
        image: dataScreen.data,
        lastModified: formattedDate(dataScreen.headers["last-modified"]),
      });
    } catch (e) {
      const errorData = JSON.parse(await e.response.data.text());
      const errorMessage = errorData.message;
      setIsError(true);
      setErrorMsg(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleCloseAlert = () => {
    setIsError(false);
  };

  return (
    <>
      {size === "md" && (
        <Tooltip
          title={
            !schedulePermission.create ? t("item.screenshotPermissionTip") : ""
          }
        >
          <Box>
            <Button
              onClick={() => {
                handleScreenshot();
              }}
              sx={{ ml: 2, boxShadow: 3 }}
              variant="contained"
              startIcon={<ScreenshotIcon />}
              disabled={!schedulePermission.create || disabled ? true : false}
            >
              {t("item.screenshotBtn")}
            </Button>
          </Box>
        </Tooltip>
      )}

      {size === "sm" && (
        <Tooltip title={t("item.screenshotTip")} sx={{ mr: "16px" }}>
          <Box>
            <IconButton
              onClick={() => {
                handleScreenshot();
              }}
              sx={{
                color: "white",
                backgroundColor: colorStyling.primary,
                "&:hover": {
                  backgroundColor: colorStyling.blue.deep,
                  color: "white",
                },
              }}
              variant="contained"
              disabled={disabled}
            >
              <ScreenshotIcon />
            </IconButton>
          </Box>
        </Tooltip>
      )}

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          {isError && (
            <Alert
              severity="error"
              variant="outlined"
              onClose={handleCloseAlert}
            >
              {errorMsg}
            </Alert>
          )}

          {loading ? (
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              sx={{ py: 1 }}
            >
              <CircularProgress
                size={20}
                thickness={3}
                sx={{ color: colorStyling.primary }}
              />
              <TypographyNormal
                sx={{
                  color: colorStyling.primary,
                  marginTop: "15px",
                  fontWeight: 300,
                }}
              >
                {t("item.screenshotLoading")}
              </TypographyNormal>
            </Box>
          ) : (
            screenshotData.image && (
              <>
                <img
                  alt="screenshot"
                  style={{ width: "100%", height: "auto" }}
                  src={URL.createObjectURL(screenshotData.image)}
                />
                {/* <TypographyNormal sx={{ marginTop: "15px", fontWeight: 700 }}>
                  Screenshot Date : {screenshotData.lastModified}
                </TypographyNormal> */}
              </>
            )
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" fullWidth onClick={handleClickClose}>
            {tCommon("closeBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ViewerScreenshot;
