// Enum: "REVEAL_FROM_TOP"
export const RevealFromTop = {
  id: "revealFromTop",
  label: "Reveal From Top",
  speed: 100,
  effect: {
    prev: {
      translate: [0, 0, -1],
    },
    next: {
      translate: [0, 0, 0],
    },
  },
  coverBox: {
    direction: "up",
    speed: 2200,
  },
};
