import React, { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { initiateAXIOS } from "../../../../config/axios";
import { initiateViewerAPI } from "../../../../api";
import {
  TypographySmall,
  TypographyNormal,
  TypographyLarge,
} from "../../../customComponent";
import { ViewerBlueIcon } from "../../../../icons";
import { colorStyling } from "../../../../helpers/color";
import { getViewerStatus } from "../../../../helpers/viewerHelper";
import { logger } from "../../../../helpers/logger";

const tableStyling = {
  padding: "12px 12px",
};

const ContentViewer = ({ open, handleClose, content }) => {
  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const DATA_PER_PAGE = 10;
  const [fetchOffset] = useState(0); // TODO: Infinite scrolling
  const [connectedViewers, setConnectedViewers] = useState([]);
  const [loading, setLoading] = useState(false);

  const getViewerByContent = useCallback(
    async (contentID) => {
      const access_token = sessionStorage.getItem("access_token");
      let getURL = `${initiateViewerAPI}?limit=${DATA_PER_PAGE}`;
      getURL += `&offset=${fetchOffset}`;
      getURL += `&contentId=${contentID}`;

      try {
        const { data } = await initiateAXIOS.get(getURL, {
          headers: { authorization: `Bearer ${access_token}` },
        });

        const viewers = [];
        data.items?.map((item) => viewers.push(item));
        setConnectedViewers(viewers);
        setLoading(false);
      } catch (error) {
        logger.error(`Error found while [getViewerByContent]:`, error);
      }
    },

    [fetchOffset]
  );

  useEffect(() => {
    setLoading(true);
    if (open) {
      getViewerByContent(content.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={() => handleClose()}>
      <DialogTitle display={"flex"} justifyContent={"space-between"}>
        {t("detail.viewerTitle")} [{content.name}]
        <IconButton onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        {loading && (
          <Box
            sx={{ height: "20vh" }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <CircularProgress
              size={20}
              thickness={3}
              sx={{ color: colorStyling.primary }}
            />
            <TypographyNormal
              sx={{ color: colorStyling.primary, mt: 2, fontWeight: 400 }}
            >
              {tCommon("loadingDataList")}
            </TypographyNormal>
          </Box>
        )}

        {!loading && (
          <Box
            sx={{
              border: `1px solid ${colorStyling.white.border}`,
              borderRadius: "4px",
            }}
          >
            <TableContainer
              component={Paper}
              sx={{ overflowY: "auto", maxHeight: 444, boxShadow: 3 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ ...tableStyling, paddingLeft: 2 }}>
                      {t("detail.viewerNameLabel")}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ ...tableStyling, width: 111 }}
                    >
                      {t("detail.viewerIDLabel")}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ ...tableStyling, width: 222 }}
                    >
                      {t("detail.viewerStatusLabel")}
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {connectedViewers.length !== 0 &&
                    connectedViewers.map((viewer) => {
                      const viewerIsActive = getViewerStatus(viewer.activeAt);
                      return (
                        <TableRow key={viewer.id}>
                          <TableCell>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <ViewerBlueIcon />
                              <TypographyNormal sx={{ ml: 1 }}>
                                {viewer.name}
                              </TypographyNormal>
                            </Box>
                          </TableCell>
                          <TableCell align="center">{viewer.id}</TableCell>
                          <TableCell align="center">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <TypographySmall
                                sx={{
                                  alignItems: "center",
                                  borderRadius: "15px",
                                  color: viewerIsActive
                                    ? colorStyling.green
                                    : colorStyling.black,
                                  backgroundColor: viewerIsActive
                                    ? colorStyling.white.active
                                    : colorStyling.white.inactive,
                                  p: 1,
                                  mr: 1,
                                  fontWeight: 600,
                                }}
                              >
                                <FiberManualRecordIcon
                                  sx={{
                                    color: viewerIsActive
                                      ? colorStyling.green
                                      : colorStyling.gray.normal,
                                    fontSize: 8,
                                    mr: 1,
                                  }}
                                />

                                {viewerIsActive ? "Online" : "Offline"}
                              </TypographySmall>

                              <TypographySmall
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  borderRadius: "15px",
                                  color:
                                    viewer?.displayStatus === "ON" &&
                                    viewerIsActive
                                      ? colorStyling.green
                                      : colorStyling.black,
                                  backgroundColor:
                                    viewer?.displayStatus === "ON" &&
                                    viewerIsActive
                                      ? colorStyling.white.active
                                      : colorStyling.white.inactive,
                                  p: 1,
                                }}
                              >
                                <FiberManualRecordIcon
                                  sx={{
                                    color:
                                      viewer?.displayStatus === "ON" &&
                                      viewerIsActive
                                        ? colorStyling.green
                                        : colorStyling.gray.normal,
                                    fontSize: 8,
                                    mr: 1,
                                  }}
                                />
                                {t("detail.viewerDisplayLabel")}
                                <span
                                  style={{
                                    marginLeft: "2px",
                                    marginRight: "2px",
                                    fontWeight: 600,
                                  }}
                                >
                                  {" "}
                                  {viewer?.displayStatus === "ON" &&
                                  viewerIsActive
                                    ? t("detail.viewerDisplayOn")
                                    : viewer?.displayStatus === "OFF" &&
                                      viewerIsActive
                                    ? t("detail.viewerDisplayOff")
                                    : t("detail.viewerDisplayNoData")}
                                </span>
                              </TypographySmall>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>

            {connectedViewers.length === 0 && (
              <Paper
                bgcolor={colorStyling.cms.white}
                sx={{
                  p: 5,
                  boxShadow: 3,
                  backgroundColor: colorStyling.cms.gray,
                }}
              >
                <TypographyLarge textAlign={"center"}>
                  {t("detail.viewerNoData")}
                </TypographyLarge>
              </Paper>
            )}
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ mx: 2, pb: 2 }}>
        <Button variant="outlined" onClick={() => handleClose()}>
          {tCommon("closeBtn")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContentViewer;
