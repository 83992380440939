import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Tooltip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { deleteSchedule } from "../../../store/actions/scheduleAction";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";

const DeleteSchedule = ({ schedule }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation("schedule");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteSchedule(id));

    handleClickClose();
    navigate(
      schedule.type === "ADVERTISEMENT" ? "/rotating-schedules" : "/schedules"
    );
  };

  return (
    <>
      <Tooltip
        title={schedule.isApproved ? t("item.deleteDisabledTip") : ""}
        placement="top"
      >
        <Box>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon fontSize="small" />}
            sx={{ boxShadow: 3, ml: 2 }}
            disabled={schedule.isApproved ? true : false}
            {...(!schedule.isApproved && { onClick: () => setOpen(true) })}
          >
            {t("item.deleteBtn")}
          </Button>
        </Box>
      </Tooltip>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            {parser(
              parseVariables(t("item.deleteConfirmationText"), {
                name: schedule.name,
              })
            )}
          </DialogContentText>

          <Box>
            <Alert severity="warning" variant="outlined">
              <strong>{tCommon("permanentActionHint")}</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            color="error"
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={() => handleDelete(schedule.id)}
          >
            {tCommon("confirmBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteSchedule;
