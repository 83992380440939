// Enum: "SLIDE_RIGHT"
export const SlideInFromLeft = {
  id: "slideInFromLeft",
  label: "Slide In From Left",
  speed: 2000,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "left center",
      translate: ["-100%", 0, 10],
    },
  },
};
