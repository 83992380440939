import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  InputLabel,
  Radio,
  TextField,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { dateFormatYYYYMMDD } from "../../../helpers/dateFormat";
import { CstTypo14W300 } from "../../customComponent";
import { colorStyling } from "../../../helpers/color";
import { instanceContentReportAPI } from "../../../api";
import { initiateAXIOS } from "../../../config/axios";
import { calculateDuration } from "../../../helpers/scheduleHelper";
import { ascending } from "../../../helpers/sorting";
import { cleanReportData } from "../../../store/actions/reportingAction";
import AssetSelector from "./AssetSelector";
import { CSVLink } from "react-csv";
import dayjs from "dayjs";

const initialHeaders = [
  { labelID: "AssetName", key: "assetName", order: 1 },
  { labelID: "CampaignName", key: "assetCampaignName", order: 2 },
  { labelID: "Type", key: "assetType", order: 3 },
  { labelID: "TotalPlayTime", key: "duration", order: 4 },
];

const ExportContentReport = () => {
  const { t } = useTranslation("contentreport");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorHeader, setErrorHeader] = useState("");

  const initialDate = {
    start: dayjs().subtract(7, "d").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
  };

  const [startDate, setStartDate] = useState(initialDate.start);
  const [endDate, setEndDate] = useState(initialDate.end);

  const [selectType, setSelectType] = useState("TOTAL");
  const [selectData, setSelectData] = useState("ALL");
  const [selectedData, setselectedData] = useState([]);

  const [loading, setLoading] = useState(false);

  const csvRef = useRef();
  const [reportData, setReportData] = useState([]);
  const [reportHeaders, setReportHeaders] = useState([{}]);
  const [csvHeaders, setCsvHeaders] = useState([{}]);

  const handleSelectListData = (arrayId) => {
    setselectedData(arrayId);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    resetData();
    setOpen(false);
  };

  const handleCloseErr = () => {
    setIsError(false);
  };

  const setupReportHeaders = (headers) => {
    const updated = [];
    headers.forEach((header) => {
      updated.push({
        label: t(`table.header${header.labelID}`),
        labelID: header.labelID,
        key: header.key,
        order: header.order,
      });
    });
    setReportHeaders(updated);
  };

  useEffect(() => {
    if (open) {
      setupReportHeaders(initialHeaders);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const resetData = () => {
    setStartDate(initialDate.start);
    setEndDate(initialDate.end);
    setSelectType("TOTAL");
    setSelectData("ALL");
    setselectedData([]);
    setReportData([]);
    setupReportHeaders(initialHeaders);
  };

  const handleTypeChange = (e) => {
    setSelectType(e.target.value);
  };

  const handleSelectDataChange = (e) => {
    setSelectData(e.target.value);
  };

  const handleStartDate = (e) => {
    const value = e.target.value;
    setStartDate(value);

    if (dayjs(endDate)?.diff(dayjs(value), "day") < 0 || endDate === "") {
      setEndDate(value);
    }
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleChangeHeader = (e) => {
    let asc;
    if (e.target.checked) {
      const payload = initialHeaders.find(
        (item) => item.key === e.target.value
      );
      asc = ascending([...reportHeaders, payload], "order");
    } else {
      const filtered = reportHeaders.filter(
        (item) => item.key !== e.target.value
      );
      asc = ascending(filtered, "order");
    }
    setupReportHeaders(asc);
  };

  useEffect(() => {
    if (reportData.length !== 0) {
      csvRef.current.link.click();
      handleClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  const fetchReport = async (query) => {
    const access_token = sessionStorage.getItem("access_token");
    let newQuery = query;
    if (selectType === "DAILY") newQuery += "&isDaily=true";

    switch (selectData) {
      case "ASSETS":
        const arrStrAssets = "&id=" + selectedData.join("&id=");
        newQuery += arrStrAssets;
        break;

      case "CAMPAIGN":
        const arrStrCampaign =
          "&campaignName=" + selectedData.join("&campaignName=");
        newQuery += arrStrCampaign;
        break;

      default:
        break;
    }

    try {
      const { data } = await initiateAXIOS.get(
        instanceContentReportAPI + newQuery,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const filtered = cleanReportData(data.items);

      return { totalItem: data.totalItem, items: filtered };
    } catch (e) {
      setIsError(true);
      setErrorMessage(`[${e?.response?.status}] ${e?.response?.data?.message}`);
      setErrorHeader(t("export.errorHeaderFetchReport"));
      setTimeout(handleCloseErr, 5000);
      setLoading(false);
    }
  };

  /**
   * generate csv function
   */
  const generateCsv = async () => {
    let csvData = [];

    // Cancel function and show error if start date empty
    if (!startDate || !endDate) {
      setIsError(true);
      setErrorHeader(t("export.errorHeaderPeriod"));
      setErrorMessage(t("export.errorMsgPeriod"));
      setTimeout(handleCloseErr, 5000);
      return;
    }

    if (selectData !== "ALL" && selectedData.length < 1) {
      setIsError(true);
      setErrorHeader(t("export.errorHeaderSelectData"));
      setErrorMessage(t("export.errorMsgSelectData"));
      setTimeout(handleCloseErr, 5000);
      return;
    }

    let updatedHeaders;
    switch (selectType) {
      default:
      case "TOTAL":
        updatedHeaders = [...reportHeaders];
        break;
      case "DAILY":
        const dateHeader = { label: t("table.headerDate"), key: "date" };
        updatedHeaders = [dateHeader, ...reportHeaders];
        break;
    }

    setCsvHeaders(updatedHeaders);
    setLoading(true);

    // Start Date in ISO format for filtering date at 00:00
    const startDateISO = dayjs(startDate).format("YYYY-MM-DD");

    // End Date in ISO format for filtering date.
    // using Start Date at 23:59 if empty
    const endDateISO = dayjs(endDate).format("YYYY-MM-DD");

    // api call query param
    const query = `?createdAtGte=${startDateISO}&createdAtLte=${endDateISO}`;

    // initial fetch for total item
    const initialData = await fetchReport(query);
    csvData = csvData.concat(initialData.items);
    setLoading(false);

    if (csvData.length === 0) {
      setIsError(true);
      setErrorHeader(tCommon("noData"));
      setErrorMessage(
        `${t("export.noAssetsPlayingHint")} [${
          startDateISO !== endDateISO
            ? startDateISO + ` ${tCommon("to")} ` + endDateISO
            : startDateISO + ""
        }]`
      );
      setTimeout(handleCloseErr, 5000);
      return;
    }

    generateData(csvData);
  };

  const generateData = (payload) => {
    setReportData(
      payload.map(
        ({ assetName, assetCampaignName, assetType, assetDuration, date }) => ({
          ...(selectType === "DAILY" && { date }),
          assetName,
          assetCampaignName,
          assetType,
          duration: calculateDuration(assetDuration),
        })
      )
    );
  };

  return (
    <>
      <Button variant="contained" onClick={handleOpen}>
        {t("export.exportBtn")}
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} disableEscapeKeyDown={true}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {t("export.csvOption")}
          <IconButton onClick={handleClose} disabled={loading}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          {isError && (
            <Alert
              sx={{ my: 2 }}
              severity="error"
              variant="filled"
              onClose={handleCloseErr}
            >
              <AlertTitle>{errorHeader}</AlertTitle>
              {errorMessage}
            </Alert>
          )}

          {loading ? (
            <Box
              sx={{ height: "20vh" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <CircularProgress
                size={22}
                thickness={3}
                sx={{ color: colorStyling.primary }}
              />
              <CstTypo14W300
                sx={{ color: colorStyling.primary, marginTop: "15px" }}
              >
                {tCommon("loadingDataList")}
              </CstTypo14W300>
            </Box>
          ) : (
            <>
              {/* Select Played Time Type (Daily or Total) */}
              <Box>
                <InputLabel>{t("export.selectPlayTimeTypeLabel")}</InputLabel>
                <Box
                  sx={{
                    mb: 1,
                    mt: 0.25,
                    px: 1,
                    borderRadius: "4px",
                    border: `1px solid ${colorStyling.gray.transparent}`,
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <FormControlLabel
                    disableTypography
                    checked={selectType === "TOTAL"}
                    value="TOTAL"
                    onChange={handleTypeChange}
                    control={<Radio size="small" />}
                    label={
                      <>
                        <Box sx={{ mr: 0.5 }}>
                          {t("export.totalPlayTimeLabel")}
                        </Box>
                        <Tooltip
                          placement="top"
                          leaveDelay={400}
                          title={t("export.totalPlayTimeTip")}
                        >
                          <InfoOutlinedIcon
                            fontSize="small"
                            sx={{ opacity: 0.7 }}
                          />
                        </Tooltip>
                      </>
                    }
                  />
                  <FormControlLabel
                    disableTypography
                    checked={selectType === "DAILY"}
                    value="DAILY"
                    onChange={handleTypeChange}
                    control={<Radio size="small" />}
                    label={
                      <>
                        <Box sx={{ mr: 0.5 }}>
                          {t("export.dailyPlayTimeLabel")}
                        </Box>
                        <Tooltip
                          placement="top"
                          leaveDelay={400}
                          title={t("export.dailyPlayTimeTip")}
                        >
                          <InfoOutlinedIcon
                            fontSize="small"
                            sx={{ opacity: 0.7 }}
                          />
                        </Tooltip>
                      </>
                    }
                  />
                </Box>
              </Box>

              {/* Select Assets (ALL or Checkbox Selected Asset or Checkbox  Selected Asset Group) */}
              <Box>
                <InputLabel>{t("export.selectIncludedDataLabel")}</InputLabel>
                <Box
                  sx={{
                    mb: 1,
                    mt: 0.25,
                    px: 1,
                    borderRadius: "4px",
                    border: `1px solid ${colorStyling.gray.transparent}`,
                  }}
                  display={"flex"}
                  flexDirection={"column"}
                >
                  <FormControlLabel
                    disableTypography
                    checked={selectData === "ALL"}
                    value="ALL"
                    onChange={handleSelectDataChange}
                    control={<Radio size="small" />}
                    label={
                      <Box sx={{ mr: 0.5 }}>
                        {t("export.radioSelectAllAssets")}
                      </Box>
                    }
                  />
                  <FormControlLabel
                    disableTypography
                    checked={selectData === "ASSETS"}
                    value="ASSETS"
                    onChange={handleSelectDataChange}
                    control={<Radio size="small" />}
                    label={
                      <Box sx={{ mr: 0.5 }}>
                        {t("export.radioSelectAssets")}
                      </Box>
                    }
                  />
                  {/* TODO when adding campaign */}
                  <FormControlLabel
                    disableTypography
                    checked={selectData === "CAMPAIGN"}
                    value="CAMPAIGN"
                    onChange={handleSelectDataChange}
                    control={<Radio size="small" />}
                    label={
                      <Box sx={{ mr: 0.5 }}>
                        {t("export.radioSelectCampaigns")}
                      </Box>
                    }
                  />

                  {selectData === "ASSETS" && (
                    <Box sx={{ my: 1 }}>
                      <AssetSelector
                        handleSelectListData={handleSelectListData}
                        type={"asset"}
                      />
                    </Box>
                  )}

                  {selectData === "CAMPAIGN" && (
                    <Box sx={{ my: 1 }}>
                      <AssetSelector
                        handleSelectListData={handleSelectListData}
                        type={"campaign"}
                      />
                    </Box>
                  )}
                </Box>
              </Box>

              {/* Select Included Field (Select ALL or Checkbox each field) */}
              <Box>
                <InputLabel>{t("export.selectReportedFieldLabel")}</InputLabel>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  sx={{
                    mb: 1,
                    mt: 0.25,
                    px: 1,
                    borderRadius: "4px",
                    border: `1px solid ${colorStyling.gray.transparent}`,
                  }}
                >
                  {initialHeaders.map((header) => (
                    <FormControlLabel
                      disableTypography
                      key={header.key}
                      value={header.key}
                      checked={
                        reportHeaders.find((obj) => obj.key === header.key)
                          ? true
                          : false
                      }
                      onChange={handleChangeHeader}
                      control={<Checkbox size="small" />}
                      label={
                        <Box sx={{ mr: 0.5 }}>
                          {t(`table.header${header.labelID}`)}
                        </Box>
                      }
                    />
                  ))}
                </Box>
              </Box>

              <Box>
                <InputLabel>{t("export.selectDateRangeLabel")}</InputLabel>

                <Box sx={{ display: "flex", alignItems: "end", mb: 1, mt: 1 }}>
                  <Box sx={{ width: "100%", mb: 0 }}>
                    <InputLabel>{t("export.startDateLabel")}</InputLabel>

                    <TextField
                      size="small"
                      fullWidth
                      value={startDate}
                      onChange={handleStartDate}
                      required
                      margin="dense"
                      type="date"
                      variant="outlined"
                      InputProps={{
                        inputProps: {
                          max: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
                        },
                      }}
                    />
                  </Box>

                  <Box
                    sx={{
                      ml: 2,
                      width: "100%",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <InputLabel>{t("export.endDateLabel")}</InputLabel>

                      <TextField
                        size="small"
                        fullWidth
                        value={endDate}
                        onChange={handleEndDate}
                        margin="dense"
                        type="date"
                        variant="outlined"
                        InputProps={{
                          inputProps: {
                            min: dayjs(startDate).format("YYYY-MM-DD"),
                            max: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
                          },
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            fullWidth
            disabled={loading}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            variant="contained"
            fullWidth
            onClick={generateCsv}
            disabled={loading}
          >
            {tCommon("exportBtn")}
          </Button>
        </DialogActions>

        <CSVLink
          data={reportData}
          headers={csvHeaders}
          filename={`content_report-${dateFormatYYYYMMDD(startDate)}${
            startDate !== endDate ? "-" + dateFormatYYYYMMDD(endDate) : ""
          }.csv`}
          ref={csvRef}
        ></CSVLink>
      </Dialog>
    </>
  );
};

export default ExportContentReport;
