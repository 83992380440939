// Enum: "EXPAND_IN_FROM_TOP"
export const ExpandInFromTop = {
  id: "expandInFromTop",
  label: "Expand In From Top",
  speed: 2500,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "top center",
      scale: 5,
      translate: [0, 0, 0],
    },
  },
};
