import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  SET_ASSET_FILTER,
  SET_ASSET_SORTING,
} from "../../../store/actionTypes/assetActionType";
import { colorStyling } from "../../../helpers/color";

const initFilter = {
  searchBy: "name",
  searchType: "",
  searchInput: "",
};

const AssetsManagerFilter = () => {
  const dispatch = useDispatch();
  const { assetFilter } = useSelector((state) => state.asset);

  const { t } = useTranslation("assetmanager");
  const { t: tCommon } = useTranslation();

  const [searchBy, setSearchBy] = useState("name");
  const [searchInput, setSearchInput] = useState("");
  const [searchType, setSearchType] = useState("");

  useEffect(() => {
    setSearchBy(assetFilter.searchBy);
    setSearchType(assetFilter.searchType);
    setSearchInput(assetFilter.searchInput);
  }, [assetFilter]);

  const handleDispatchFilter = (payload) => {
    dispatch({
      type: SET_ASSET_FILTER,
      payload,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let filterPayload = { ...assetFilter };
    filterPayload["searchType"] = searchType;
    filterPayload["type"] = searchType;

    filterPayload["searchBy"] = searchBy;
    filterPayload["searchInput"] = searchInput;

    switch (searchBy) {
      default:
      case "name":
        filterPayload["nameLike"] = searchInput;
        delete filterPayload["campaignNameLike"];
        break;

      case "campaignName":
        filterPayload["campaignNameLike"] = searchInput;
        delete filterPayload["nameLike"];
        break;
    }

    delete filterPayload["sortBy"];
    dispatch({
      type: SET_ASSET_SORTING,
      payload: {
        reset: true,
      },
    });

    handleDispatchFilter({ ...filterPayload });
  };

  const handleRefresh = () => {
    handleDispatchFilter(initFilter);
    setSearchBy("name");
    setSearchInput("");
    setSearchType("");
    dispatch({
      type: SET_ASSET_SORTING,
      payload: {
        reset: true,
      },
    });
  };

  const handleSearchByChange = (event) => {
    setSearchBy(event.target.value);
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        my: 0.5,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box>
          <TextField
            size="small"
            label={t("filter.searchByLabel")}
            select
            SelectProps={{ native: true }}
            value={searchBy}
            onChange={handleSearchByChange}
            sx={{ width: "150px" }}
          >
            <option key="name" value="name">
              {t("filter.searchByName")}
            </option>
            <option key="campaignName" value="campaignName">
              {t("filter.searchByCampaignName")}
            </option>
          </TextField>
        </Box>
      </Box>

      <Box sx={{ mx: 2, width: "100%" }}>
        <TextField
          fullWidth
          size="small"
          placeholder={t("filter.searchInputPlaceholder")}
          type="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </Box>

      <TextField
        size="small"
        fullWidth
        select
        SelectProps={{ native: true }}
        value={searchType}
        onChange={(e) => setSearchType(e.target.value)}
        sx={{ mr: 2, maxWidth: "200px" }}
      >
        <option key="default" value="" defaultValue>
          {t("filter.searchTypeAny")}
        </option>
        <option key="IMAGE" value="IMAGE">
          {t("filter.searchTypeImage")}
        </option>
        <option key="VIDEO" value="VIDEO">
          {t("filter.searchTypeVideo")}
        </option>
      </TextField>

      <Button type="submit" variant="contained">
        {tCommon("searchBtn")}
      </Button>

      <IconButton
        onClick={handleRefresh}
        sx={{ color: colorStyling.black, ml: 1 }}
      >
        <Tooltip title={tCommon("resetFilterTip")}>
          <RestartAltIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </Box>
  );
};

export default AssetsManagerFilter;
