import { isEmpty } from "lodash";
import {
  SET_DELETE_SCHEDULE,
  SET_EDIT_SCHEDULE,
  SET_LOADING_SCHEDULE,
  SET_NEW_SCHEDULE,
  SET_SCHEDULE,
  SET_SCHEDULE_DETAIL,
  SET_SUCCESS_MESSAGE_SCHEDULE_PAGE,
  SET_SUCCESS_PROMPT_SCHEDULE_PAGE,
  SET_ERROR_PROMPT_SCHEDULE_PAGE,
  SET_ERROR_MESSAGE_SCHEDULE_PAGE,
  SET_SCHEDULE_FILTER,
  SET_ERROR_PROMPT_SCHEDULE_DIALOG,
  SET_ERROR_MESSAGE_SCHEDULE_DIALOG,
  SET_PREFETCH_SCHEDULE,
  SET_SCHEDULE_PAGE,
  SET_SCHEDULE_ROWS_PER_PAGE,
  SET_ADVERTISEMENT_SCHEDULE,
  SET_ADVERTISEMENT_SCHEDULE_CONTENT,
  SET_PREFETCH_ADV_SCHEDULE,
  SET_ADV_SCHEDULE_FILTER,
  SET_LOADING_ADV_SCHEDULE,
  SET_NEW_ADV_SCHEDULE,
  SET_SCHEDULE_ASSIGNED_GROUP,
  SET_SCHEDULE_ASSIGNED_VIEWER,
} from "../actionTypes/scheduleActionType";

const initialState = {
  schedules: { items: [], totalItem: 0 },
  advertisementSchedules: { items: [], totalItem: 0 },
  scheduleFilters: { sortBy: "idDesc" },
  advertisementScheduleFilters: { sortBy: "idDesc" },
  schedule: { type: "" },
  page: 0,
  rowsPerPage: 5,
  loadingSchedule: false,
  loadingAdvertisementSchedule: false,
  isSuccessPromptSchedulePage: false,
  isErrorPromptSchedulePage: false,
  successMessageSchedulePage: "",
  errorMessageSchedulePage: "",
  isErrorPromptScheduleDialog: false,
  errorMessageScheduleDialog: "",
};

export const scheduleReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_SCHEDULE:
      let newSchedule = [...payload.items];
      newSchedule.forEach((s, i) => {
        const oldSchedule = state.schedules.items.find(
          (obj) => obj.id === s.id
        );
        if (!isEmpty(oldSchedule)) {
          newSchedule[i]["screens"] = oldSchedule.screens;
          newSchedule[i]["screenGroups"] = oldSchedule.screenGroups;
          newSchedule[i]["content"] = oldSchedule.content;
        }
      });

      return {
        ...state,
        schedules: { items: newSchedule, totalItem: payload.totalItem },
      };

    case SET_ADVERTISEMENT_SCHEDULE:
      let newAdvSchedule = [...payload.items];
      newAdvSchedule.forEach((s, i) => {
        const oldSchedule = state.advertisementSchedules.items.find(
          (obj) => obj.id === s.id
        );
        if (!isEmpty(oldSchedule)) {
          newAdvSchedule[i]["screens"] = oldSchedule.screens;
          newAdvSchedule[i]["screenGroups"] = oldSchedule.screenGroups;
          newAdvSchedule[i]["content"] = oldSchedule.content;
        }
      });
      return {
        ...state,
        advertisementSchedules: {
          items: newAdvSchedule,
          totalItem: payload.totalItem,
        },
      };

    case SET_ADVERTISEMENT_SCHEDULE_CONTENT:
      const contentAddingAdvData = state.advertisementSchedules.items.map(
        (obj) => {
          return obj.contentId === payload.id
            ? { ...obj, content: payload.content }
            : obj;
        }
      );

      const contentAddingData = state.schedules.items.map((obj) => {
        return obj.contentId === payload.id
          ? { ...obj, content: payload.content }
          : obj;
      });

      return {
        ...state,
        advertisementSchedules: {
          totalItem: state.advertisementSchedules.totalItem,
          items: [...contentAddingAdvData],
        },
        schedules: {
          totalItem: state.schedules.totalItem,
          items: [...contentAddingData],
        },
      };

    case SET_SCHEDULE_FILTER:
      return { ...state, scheduleFilters: payload };

    case SET_ADV_SCHEDULE_FILTER:
      return { ...state, advertisementScheduleFilters: payload };

    case SET_SCHEDULE_PAGE:
      return { ...state, page: payload };

    case SET_SCHEDULE_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: payload };

    case SET_LOADING_SCHEDULE:
      return { ...state, loadingSchedule: payload };

    case SET_LOADING_ADV_SCHEDULE:
      return { ...state, loadingAdvertisementSchedule: payload };

    case SET_NEW_SCHEDULE:
      const listOfSchedules =
        state.page === 0
          ? [payload, ...state.schedules.items]
          : [
              ...state.schedules.items.slice(0, state.page * state.rowsPerPage),
              payload,
              ...state.schedules.items.slice(state.page * state.rowsPerPage),
            ];
      return {
        ...state,
        schedules: {
          items: listOfSchedules,
          totalItem: state.schedules.totalItem + 1,
        },
      };

    case SET_NEW_ADV_SCHEDULE:
      const listOfAdvSchedules =
        state.page === 0
          ? [payload, ...state.advertisementSchedules.items]
          : [
              ...state.advertisementSchedules.items.slice(
                0,
                state.page * state.rowsPerPage
              ),
              payload,
              ...state.advertisementSchedules.items.slice(
                state.page * state.rowsPerPage
              ),
            ];
      return {
        ...state,
        advertisementSchedules: {
          items: listOfAdvSchedules,
          totalItem: state.advertisementSchedules.totalItem + 1,
        },
      };

    case SET_PREFETCH_SCHEDULE:
      let addedPrefetchScheduless = [
        ...state.schedules.items,
        ...payload.items,
      ];
      return {
        ...state,
        schedules: {
          items: addedPrefetchScheduless,
          totalItem: payload.totalItem,
        },
      };

    case SET_PREFETCH_ADV_SCHEDULE:
      let addedPrefetchAdvSchedules = [
        ...state.advertisementSchedules.items,
        ...payload.items,
      ];
      return {
        ...state,
        advertisementSchedules: {
          items: addedPrefetchAdvSchedules,
          totalItem: payload.totalItem,
        },
      };

    case SET_SCHEDULE_DETAIL:
      const sd = state.schedules.items.find((obj) => obj.id === payload.id);
      const asd = state.advertisementSchedules.items.find(
        (obj) => obj.id === payload.id
      );
      if (!isEmpty(sd)) {
        sd["screens"] = payload.screens;
        sd["screenGroups"] = payload.screenGroups;
        sd["content"] = payload.content;
      }

      if (!isEmpty(asd)) {
        asd["screens"] = payload.screens;
        asd["screenGroups"] = payload.screenGroups;
        asd["content"] = payload.content;
      }

      return {
        ...state,
        schedule: {
          ...state.schedule,
          [payload.id]: { ...(state.schedule[payload.id] ?? {}), ...payload },
        },
      };

    case SET_DELETE_SCHEDULE:
      const schedulesState = state.schedules.items;
      const filtered = schedulesState.filter(
        (schedule) => schedule.id.toString() !== payload.toString()
      );
      return {
        ...state,
        schedules: {
          items: filtered,
          totalItem: state.schedules.totalItem - 1,
        },
      };

    case SET_EDIT_SCHEDULE:
      const data = state.schedules;
      const objIndex = data.findIndex((obj) => obj.id === payload.id);
      data[objIndex] = payload;
      return {
        ...state,
        schedules: [...data],
      };

    case SET_SCHEDULE_ASSIGNED_GROUP:
      const assignedGroupData = state?.schedules?.items;
      const objIndexG = assignedGroupData?.findIndex(
        (obj) => obj.id === payload.id
      );
      if (objIndexG !== -1)
        assignedGroupData[objIndexG].screenGroups = payload?.screenGroups;
      return {
        ...state,
        schedules: {
          totalItem: state.schedules.totalItem,
          items: [...assignedGroupData],
        },
        ...(state.schedule.id === payload.id && {
          schedule: { ...state.schedule, screenGroups: payload.screenGroups },
        }),
      };

    case SET_SCHEDULE_ASSIGNED_VIEWER:
      const assignedViewerData = state.schedules.items;
      const objIndexV = assignedViewerData.findIndex(
        (obj) => obj.id === payload.id
      );
      if (objIndexV !== -1)
        assignedViewerData[objIndexV].screens = payload.screens;
      return {
        ...state,
        schedules: {
          totalItem: state.schedules.totalItem,
          items: [...assignedViewerData],
        },
        ...(state.schedule.id === payload.id && {
          schedule: { ...state.schedule, screens: payload.screens },
        }),
      };

    case SET_SUCCESS_PROMPT_SCHEDULE_PAGE:
      return { ...state, isSuccessPromptSchedulePage: payload };

    case SET_SUCCESS_MESSAGE_SCHEDULE_PAGE:
      return { ...state, successMessageSchedulePage: payload };

    case SET_ERROR_PROMPT_SCHEDULE_PAGE:
      return { ...state, isErrorPromptSchedulePage: payload };

    case SET_ERROR_MESSAGE_SCHEDULE_PAGE:
      return { ...state, errorMessageSchedulePage: payload };

    case SET_ERROR_PROMPT_SCHEDULE_DIALOG:
      return { ...state, isErrorPromptScheduleDialog: payload };

    case SET_ERROR_MESSAGE_SCHEDULE_DIALOG:
      return { ...state, errorMessageScheduleDialog: payload };

    default:
      return state;
  }
};
