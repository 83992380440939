import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Sidebar from "../../sidebar/Sidebar";
import { Box, Button, Container, CssBaseline, Toolbar } from "@mui/material";
import { TypographyXLarge } from "../../customComponent";
import ScheduleBaseCalendar from "./ScheduleBaseCalendar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const ScheduleCalendar = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("schedule");

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ pb: 2 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("calendar.title")}
            </TypographyXLarge>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              startIcon={<ChevronLeftIcon />}
              onClick={() => navigate("/schedules")}
            >
              {t("calendar.backBtn")}
            </Button>
          </Box>

          <ScheduleBaseCalendar />
        </Container>
      </Box>
    </div>
  );
};

export default ScheduleCalendar;
