const fontFamily = {
  "ARIAL": {
    label: "Arial",
    value: "\"Arial\"",
  },
  "HELVETICA": {
    label: "Helvetica",
    value: "\"Helvetica\"",
  },
  "NEW_YORK": {
    label: "New York",
    value: "\"New York\"",
  },
  "ROBOTO": {
    label: "Roboto",
    value: "\"Roboto\"",
  },
  "TIMES_NEW_ROMAN": {
    label: "Times New Roman",
    value: "\"Times New Roman\"",
  },
  // Uncomment when needed
  // "SANS_SERIF": {
  //   label: "Sans Serif",
  //   value: "sans-serif",
  // },
};

export default fontFamily;
