// Enum: "EXPAND_FROM_TOP_LEFT"
export const ExpandFromTopLeft = {
  id: "expandFromTopLeft",
  label: "Expand From Top Left",
  speed: 2200,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "top left",
      scale: 0.1,
      translate: ["-10%", "-10%", 0],
    },
  },
};
