// Enum: "SLIDE_FROM_TOP_RIGHT"
export const SlideInFromTopRight = {
  id: "slideInFromTopRight",
  label: "Slide In From Top Right",
  speed: 2000,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "top right",
      translate: ["100%", "-100%", 10],
    },
  },
};
