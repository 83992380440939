import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { deleteViewer } from "../../store/actions/viewerAction";
import { parseVariables } from "../../helpers/localize";
import parser from "html-react-parser";

const DialogViewerDelete = ({ open, handleClose, viewer }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("viewer");
  const { t: tCommon } = useTranslation();

  const handleDelete = (id) => {
    dispatch(deleteViewer(id));
    handleClose();
  };

  return (
    <>
      <Dialog maxWidth="sm" open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText sx={{ textAlign: "center", mb: 2 }}>
            {t("item.displayOn")}
            {parser(
              parseVariables(t("item.deleteConfirmationText"), {
                name: viewer.name,
              })
            )}
          </DialogContentText>

          <Box sx={{ textAlign: "center" }}>
            <Alert severity="warning" variant="outlined">
              <strong>{tCommon("permanentActionHint")}</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClose}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            color="error"
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={() => {
              handleDelete(viewer.id);
            }}
          >
            {tCommon("confirmBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogViewerDelete;
