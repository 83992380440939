import React from "react";
import { Box, Tooltip } from "@mui/material";
import { colorStyling } from "../../../helpers/color";
import { TypographySmall } from "../../customComponent";

const AssetSelectorItem = ({ data }) => {
  return (
    <Box display={"flex"} flexDirection={"row"} flexWrap={"nowrap"}>
      <Box
        sx={{ width: "90px", height: "70px", mr: 1 }}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Box
          sx={{
            border: `1px solid ${colorStyling.gray.normal}`,
            borderRadius: "4px",
            width: "max-content",
            height: "max-content",
          }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {/* TODO Add overlay for opening assets */}
          {/* <ContentReportViewAsset asset={data} /> */}
          <img
            style={{ maxWidth: "90px", maxHeight: "70px" }}
            src={data.thumbnailUrl}
            alt={data.name}
          />
        </Box>
      </Box>
      <Box display={"flex"} flexDirection={"column"} justifyContent={"center"}>
        <Box
          sx={{
            backgroundColor: data.type === "IMAGE" ? "sandybrown" : "skyblue",
            width: "50px",
            borderRadius: "4px",
          }}
          display={"flex"}
          justifyContent={"center"}
        >
          <TypographySmall>{data.type}</TypographySmall>
        </Box>
        <Tooltip title={data.name} placement="top">
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              textWrap: "nowrap",
              maxWidth: "200px",
            }}
          >
            {data.name}
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default AssetSelectorItem;
