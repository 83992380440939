export const SET_SCHEDULE = "SET_SCHEDULE";
export const SET_ADVERTISEMENT_SCHEDULE = "SET_ADVERTISEMENT_SCHEDULE";
export const SET_ADVERTISEMENT_SCHEDULE_CONTENT =
  "SET_ADVERTISEMENT_SCHEDULE_CONTENT";
export const SET_PREFETCH_ADV_SCHEDULE = "SET_PREFETCH_ADV_SCHEDULE";
export const SET_SCHEDULE_FILTER = "SET_SCHEDULE_FILTER";
export const SET_ADV_SCHEDULE_FILTER = "SET_ADV_SCHEDULE_FILTER";
export const SET_LOADING_SCHEDULE = "SET_LOADING_SCHEDULE";
export const SET_LOADING_ADV_SCHEDULE = "SET_LOADING_ADV_SCHEDULE";
export const SET_NEW_SCHEDULE = "SET_NEW_SCHEDULE";
export const SET_NEW_ADV_SCHEDULE = "SET_NEW_ADV_SCHEDULE";
export const SET_PREFETCH_SCHEDULE = "SET_PREFETCH_SCHEDULE";
export const SET_SCHEDULE_PAGE = "SET_SCHEDULE_PAGE";
export const SET_SCHEDULE_ROWS_PER_PAGE = "SET_SCHEDULE_ROWS_PER_PAGE";
export const SET_SCHEDULE_DETAIL = "SET_SCHEDULE_DETAIL";
export const SET_DELETE_SCHEDULE = "SET_DELETE_SCHEDULE";
export const SET_EDIT_SCHEDULE = "SET_EDIT_SCHEDULE";
export const SET_SCHEDULE_ASSIGNED_GROUP = "SET_SCHEDULE_ASSIGNED_GROUP";
export const SET_SCHEDULE_ASSIGNED_VIEWER = "SET_SCHEDULE_ASSIGNED_VIEWER";
export const SET_SUCCESS_PROMPT_SCHEDULE_PAGE =
  "SET_SUCCESS_PROMPT_SCHEDULE_PAGE";
export const SET_ERROR_PROMPT_SCHEDULE_PAGE = "SET_ERROR_PROMPT_SCHEDULE_PAGE";
export const SET_SUCCESS_MESSAGE_SCHEDULE_PAGE =
  "SET_SUCCESS_MESSAGE_SCHEDULE_PAGE";
export const SET_ERROR_MESSAGE_SCHEDULE_PAGE =
  "SET_ERROR_MESSAGE_SCHEDULE_PAGE";
export const SET_ERROR_PROMPT_SCHEDULE_DIALOG =
  "SET_ERROR_PROMPT_SCHEDULE_DIALOG";
export const SET_ERROR_MESSAGE_SCHEDULE_DIALOG =
  "SET_ERROR_MESSAGE_SCHEDULE_DIALOG";
