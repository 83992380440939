import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress, Box, Grid, Tooltip } from "@mui/material";
import {
  Sort as PriorityIcon,
  FiberManualRecord as FiberManualRecordIcon,
} from "@mui/icons-material";
import BackupTableTwoToneIcon from "@mui/icons-material/BackupTableTwoTone";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
  TypographyXLarge,
} from "../../customComponent";
import {
  AnnouncementPurpleIcon,
  TerminalIcon,
  ScheduleGreyIcon,
} from "../../../icons";
import { colorStyling } from "../../../helpers/color";
import {
  switchBackgroundColorBasedOnStatus,
  switchColorOnScheduleStatus,
} from "../../../helpers/switchColor";
import ContentDetails from "../../cms/contentTemplate/detail/ContentDetails";

const AnnouncementInfo = ({ announcement, loading }) => {
  const { t } = useTranslation("announcement");
  const { t: tCommon } = useTranslation();

  const [openContentDetail, setOpenContentDetail] = useState(false);
  const [hoveredContent, setHoveredContent] = useState(false);

  const handleOpenContentDetail = () => {
    setOpenContentDetail(true);
  };

  const handleCloseContentDetail = () => {
    setOpenContentDetail(false);
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress
          size={20}
          thickness={3}
          sx={{ color: colorStyling.primary }}
        />
        <TypographyNormal
          sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
        >
          {tCommon("loadingDataList")}
        </TypographyNormal>
      </Box>
    );

  return (
    <>
      <Box sx={{ px: 2, pb: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mt: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AnnouncementPurpleIcon />
            <Box display={"flex"} flexDirection={"column"} sx={{ ml: 2 }}>
              <Box sx={{ width: "fit-content" }}>
                <TypographySmall
                  sx={{
                    borderRadius: "15px",
                    color: switchColorOnScheduleStatus(
                      announcement.isApproved ? "APPROVED" : "NOT_APPROVED"
                    ),
                    backgroundColor: switchBackgroundColorBasedOnStatus(
                      announcement.isApproved ? "APPROVED" : "NOT_APPROVED"
                    ),
                    fontWeight: 600,
                    px: 1,
                    py: 0.2,
                  }}
                >
                  {announcement.isApproved
                    ? t("item.approvedLabel")
                    : t("item.notApprovedLabel")}
                </TypographySmall>
              </Box>
              <Tooltip
                title={
                  announcement?.name?.length > 30 ? announcement?.name : ""
                }
                placement="top-start"
              >
                <TypographyXLarge sx={{ fontWeight: 700 }}>
                  {announcement?.name?.length > 30
                    ? announcement?.name.substring(0, 27).trim() + "..."
                    : announcement?.name}
                </TypographyXLarge>
              </Tooltip>
            </Box>
          </Box>

          <Box sx={{ px: 2, display: "flex", alignItems: "center" }}>
            <TypographyNormal
              sx={{
                display: "flex",
                alignItems: "center",
                borderRadius: "15px",
                color: announcement.isActive
                  ? colorStyling.green
                  : colorStyling.black,
                backgroundColor: announcement.isActive
                  ? colorStyling.white.active
                  : colorStyling.white.inactive,
                p: 1,
                fontWeight: 600,
              }}
            >
              <FiberManualRecordIcon
                sx={{
                  color: announcement.isActive
                    ? colorStyling.green
                    : colorStyling.gray.normal,
                  fontSize: 8,
                  mr: 1,
                }}
              />
              {announcement.isActive
                ? t("item.statusONLabel")
                : t("item.statusOFFLabel")}
            </TypographyNormal>
          </Box>
        </Box>

        <Box sx={{ pl: 2, pr: 1 }}>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ScheduleGreyIcon />

                <Box sx={{ ml: 2 }}>
                  <TypographyNormal sx={{ fontWeight: 300 }}>
                    {t("item.typeLabel")}
                  </TypographyNormal>
                  <TypographyLarge sx={{ fontWeight: 600 }}>
                    {announcement.type}
                  </TypographyLarge>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <TerminalIcon />

                <Box sx={{ ml: 2 }}>
                  <TypographyNormal sx={{ fontWeight: 300 }}>
                    {t("item.commandLabel")}
                  </TypographyNormal>
                  <TypographyLarge sx={{ fontWeight: 600 }}>
                    {announcement.command}
                  </TypographyLarge>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <PriorityIcon sx={{ color: colorStyling.gray.normal }} />

                <Box sx={{ ml: 2 }}>
                  <TypographyNormal sx={{ fontWeight: 300 }}>
                    {t("item.priorityLabel")}
                  </TypographyNormal>
                  <TypographyLarge sx={{ fontWeight: 600 }}>
                    {announcement.priority?.name}
                  </TypographyLarge>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={8}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <BackupTableTwoToneIcon
                  sx={{ color: colorStyling.gray.normal }}
                />

                <Box sx={{ ml: 2 }}>
                  <TypographyNormal sx={{ fontWeight: 300 }}>
                    {t("item.contentNameLabel")}
                  </TypographyNormal>
                  <Tooltip
                    arrow
                    disableInteractive
                    placement="top-start"
                    title={t("item.viewContentDetailsTip")}
                  >
                    <TypographyLarge
                      onClick={handleOpenContentDetail}
                      onMouseEnter={() => setHoveredContent(true)}
                      onMouseLeave={() => setHoveredContent(false)}
                      sx={{
                        ...(hoveredContent && {
                          color: colorStyling.primary,
                          textDecoration: "underline",
                        }),
                        fontWeight: 600,
                        cursor: "pointer",
                      }}
                    >
                      {announcement?.content?.name}
                      <LaunchIcon
                        fontSize="xs"
                        sx={{
                          color: hoveredContent
                            ? colorStyling.primary
                            : colorStyling.gray.label,
                        }}
                      />
                    </TypographyLarge>
                  </Tooltip>
                </Box>
              </Box>
              <ContentDetails
                open={openContentDetail}
                handleClose={handleCloseContentDetail}
                contentID={announcement?.contentId}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default AnnouncementInfo;
