import CryptoJS from "crypto-js";
import { isEmpty } from "lodash";

const parseString = (string) =>
  string &&
  string.length > 2 &&
  string[0] === '"' &&
  string[string.length - 1] === '"'
    ? string.slice(1, -1)
    : string;
const encryptionKey = parseString(process.env.REACT_APP_ENCRYPT_PRIVATE_KEY);

const loginEncrypt = (data) => {
  const encrypted = CryptoJS.AES.encrypt(data, encryptionKey).toString();

  return encrypted;
};

const encrypt = (data) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    encryptionKey
  ).toString();

  return encrypted;
};

const decrypt = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, encryptionKey);
  const decrypted = data ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : "";

  return decrypted;
};

const decryptUser = (data) => {
  if (data === "" || isEmpty(data)) {
    window.location.href = "/login";
    return {};
  }
  return decrypt(data);
};

export { encrypt, decrypt, loginEncrypt, decryptUser };
