import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  CircularProgress,
  Paper,
  TablePagination,
} from "@mui/material";
import { TypographyNormal } from "../customComponent";
import GroupItem from "./GroupItem";
import { getGroups } from "../../store/actions/groupAction";
import { colorStyling } from "../../helpers/color";
import { filterObjtoString } from "../../helpers/filterHelper";
import {
  SET_GROUP_PAGE,
  SET_GROUP_ROWS_PER_PAGE,
  SET_LOADING_GROUP,
} from "../../store/actionTypes/groupActionType";
import { parseVariables } from "../../helpers/localize";

const GroupList = () => {
  const dispatch = useDispatch();
  const { groups, loadingGroup, groupFilters, page, rowsPerPage } = useSelector(
    (state) => state.group
  );

  const { t: tCommon } = useTranslation();

  const scrollRef = useRef(null);

  // TODO for
  // how many prefetched data page rows
  // step 1 = 5 data (if rows per page = 5)
  // const step = 1;

  const handleLoading = (value) => {
    dispatch({ type: SET_LOADING_GROUP, payload: value });
  };

  const handleChangePagination = (e, page) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    dispatch({ type: SET_GROUP_PAGE, payload: page });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: SET_GROUP_ROWS_PER_PAGE,
      payload: parseInt(event.target.value, 10),
    });
    handleChangePagination(null, 0);
  };

  const fetchData = async (filterObj) => {
    let isPrefetch = false;
    let limit = rowsPerPage;
    let offset = page * rowsPerPage;
    if (offset === 0) {
      limit = limit * 2; //fetch 2 page worth of data
      isPrefetch = false;
    } else {
      offset = (page + 1) * rowsPerPage;
      isPrefetch = true;
    }
    let filterString = filterObjtoString(filterObj, limit, offset);

    const groupsLength = groups?.items?.length;
    if (groupsLength === 0) handleLoading(true);
    if (groupsLength !== groups.totalItem || groupsLength === 0 || offset === 0)
      dispatch(getGroups(filterString, isPrefetch, handleLoading));
  };

  useEffect(() => {
    fetchData(groupFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (page === 0) {
      fetchData(groupFilters);
    } else handleChangePagination(null, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupFilters]);

  const displayedRowsLabel = ({ from, to, count }) => {
    return parseVariables(tCommon("table.displayedRowsLabel"), {
      prefix: `${from}–${to}`,
      suffix:
        count !== -1
          ? count
          : `${tCommon("table.displayedRowsMoreThan")} ${to}`,
    });
  };

  return (
    <>
      <Box>
        {loadingGroup ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ height: "20vh" }}
          >
            <CircularProgress
              size={20}
              thickness={3}
              sx={{ color: colorStyling.primary }}
            />
            <TypographyNormal
              sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
            >
              {tCommon("loadingDataList")}
            </TypographyNormal>
          </Box>
        ) : (
          <>
            {!groups?.items?.length ? (
              <Paper elevation={2} sx={{ borderRadius: "6px", p: 2 }}>
                <Alert severity="info" variant="outlined">
                  <strong>{tCommon("noData")}</strong>
                </Alert>
              </Paper>
            ) : (
              <Paper
                component="section"
                elevation={2}
                sx={{ p: 2, my: 2, borderRadius: "6px" }}
              >
                <Box ref={scrollRef} sx={{ overflowY: "auto", maxHeight: 500 }}>
                  {groups?.items
                    ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                    ?.map((group) => (
                      <GroupItem
                        key={group.id}
                        group={group}
                        dispatch={dispatch}
                      />
                    ))}
                </Box>
              </Paper>
            )}
          </>
        )}
      </Box>

      {(!loadingGroup || groups?.totalItem > 0) && (
        <Box
          sx={{
            pt: 2,
            pb: 1,
            pr: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Paper component="div" elevation={1} sx={{ px: 1 }}>
            <TablePagination
              component="div"
              count={groups.totalItem}
              page={page}
              onPageChange={handleChangePagination}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={displayedRowsLabel}
              labelRowsPerPage={tCommon("table.rowsPerPageLabel")}
            />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default GroupList;
