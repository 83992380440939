// Enum: "REVEAL_FROM_LEFT"
export const RevealFromLeft = {
  id: "revealFromLeft",
  label: "Reveal From Left",
  speed: 100,
  effect: {
    prev: {
      translate: [0, 0, -1],
    },
    next: {
      translate: [0, 0, 0],
    },
  },
  coverBox: {
    direction: "left",
    speed: 2200,
  },
};
