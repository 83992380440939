import { initiateAXIOS } from "../../config/axios";
import { handleError } from "../handleError";
import User from "../../models/UserModel";
import {
  SET_DELETE_USER,
  SET_DELETE_USER_GROUP,
  SET_EDIT_USER,
  SET_EDIT_USER_GROUP,
  SET_ERROR_MESSAGE_USER_DIALOG,
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_PROMPT_USER_DIALOG,
  SET_ERROR_PROMPT_USER_PAGE,
  SET_LOADING_USER,
  SET_NEW_USER,
  SET_NEW_USER_GROUP,
  SET_PREFETCH_USER,
  SET_PREFETCH_USER_GROUP,
  SET_SUCCESS_MESSAGE_USER_PAGE,
  SET_SUCCESS_PROMPT_USER_PAGE,
  SET_USER,
  SET_USER_GROUP,
  SET_USER_HISTORY,
} from "../actionTypes/userActionType";
import Role from "../../helpers/roles";
import { decryptUser } from "../../helpers/crypto";
import {
  API_LIMIT,
  initiateAuthAPI,
  initiateOrganizationAPI,
  initiateUserAPI,
  initiateUserGroupAPI,
  instanceAuditLogAPI,
} from "../../api";
import {
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../actionTypes/globalActionType";
import { logger } from "../../helpers/logger";
import { handleTimeoutPrompt } from "../handlePrompt";

import i18n from "i18next";
const t = i18n.getFixedT(null, "user");

export const getUsers = (query, isPrefetch, loading) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const userRole = localStorage.getItem("permissionRole");
      const decryptedUserRole = decryptUser(userRole);

      const userPermission = localStorage.getItem("permissionList");
      const decryptedPermission = decryptUser(userPermission);
      const userGroupPermission = decryptedPermission.userGroup;

      let newQuery = query ?? "";
      if (decryptedUserRole === Role.Admin || decryptedUserRole === Role.User) {
        newQuery === ""
          ? (newQuery = "?type=BASIC")
          : (newQuery += "&type=BASIC");
      }

      const { data } = await initiateAXIOS.get(initiateUserAPI + newQuery, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const listUsers = data.items;

      let users = [];
      for (let i = 0; i < listUsers.length; i++) {
        let org;
        let userGroup;
        if (decryptedUserRole === Role.Eins) {
          org = await initiateAXIOS.get(
            initiateOrganizationAPI + listUsers[i].organizationId,
            {
              headers: { authorization: `Bearer ${access_token}` },
            }
          );
        }

        if (userGroupPermission.read) {
          userGroup = await initiateAXIOS.get(
            initiateUserGroupAPI + listUsers[i].userGroupId,
            {
              headers: { authorization: `Bearer ${access_token}` },
            }
          );
        }

        users.push({
          ...listUsers[i],
          ...(org?.data?.name && { organizationName: org.data.name }),
          ...(userGroup?.data?.name && {
            userGroupName: userGroup.data.name,
          }),
        });
      }

      dispatch({
        type: isPrefetch ? SET_PREFETCH_USER : SET_USER,
        payload: { items: users, totalItem: data.totalItem },
      });
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      if (loading) loading(false);
    }
  };
};

export const getUserGroups = (query, isPrefetch, loading) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    try {
      const userRole = localStorage.getItem("permissionRole");
      const decryptedUserRole = decryptUser(userRole);

      const { data } = await initiateAXIOS.get(
        initiateUserGroupAPI + (query ?? ""),
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      const listUserGroup = data.items;
      let userGroups = [];
      for (let i = 0; i < listUserGroup.length; i++) {
        let org;
        if (decryptedUserRole === Role.Eins) {
          org = await initiateAXIOS.get(
            initiateOrganizationAPI + listUserGroup[i].organizationId,
            {
              headers: { authorization: `Bearer ${access_token}` },
            }
          );
        }

        userGroups.push({
          ...listUserGroup[i],
          ...(org?.data?.name && { organizationName: org.data.name }),
        });
      }

      dispatch({
        type: isPrefetch ? SET_PREFETCH_USER_GROUP : SET_USER_GROUP,
        payload: { items: userGroups, totalItem: data.totalItem },
      });
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      if (loading) loading(false);
    }
  };
};

export const filterUserGroups = (query) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });
    try {
      const { data } = await initiateAXIOS.get(
        initiateUserGroupAPI + `?limit=${API_LIMIT}${query}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({ type: SET_USER_GROUP, payload: data.items });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const createUserGroup = (payload, callback) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    try {
      const { data } = await initiateAXIOS.post(initiateUserGroupAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({
        type: SET_NEW_USER_GROUP,
        payload: data,
      });

      dispatch({
        type: SET_SUCCESS_PROMPT_USER_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: t("api.createGroupMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_USER_PAGE);
      callback("success");
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_DIALOG, payload: message });
      callback("error");

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_DIALOG);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    }
  };
};

export const editUserGroup = ({ id, payload }, callback) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    try {
      const { data } = await initiateAXIOS.patch(
        initiateUserGroupAPI + id,
        payload,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      dispatch({ type: SET_EDIT_USER_GROUP, payload: data });
      dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: t("api.updateGroupMsg"),
      });

      callback("success");
      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_USER_PAGE);
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_DIALOG, payload: message });

      callback("error");
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_DIALOG);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    }
  };
};

export const filterUser = (query) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });
    try {
      const { data } = await initiateAXIOS.get(
        initiateUserAPI + `?limit=${API_LIMIT}` + query,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const listUsers = data.items;

      const userRole = localStorage.getItem("permissionRole");
      const decryptedUserRole = decryptUser(userRole);

      const userPermission = localStorage.getItem("permissionList");
      const decryptedPermission = decryptUser(userPermission);
      const userGroupPermission = decryptedPermission.userGroup;

      let users = [];

      await Promise.all(
        listUsers.map(async (item) => {
          let org;
          let userGroup;
          if (decryptedUserRole === Role.Eins) {
            org = await initiateAXIOS.get(
              initiateOrganizationAPI + item.organizationId,
              {
                headers: { authorization: `Bearer ${access_token}` },
              }
            );
          }

          if (userGroupPermission.read) {
            userGroup = await initiateAXIOS.get(
              initiateUserGroupAPI + item.userGroupId,
              {
                headers: { authorization: `Bearer ${access_token}` },
              }
            );
          }

          users.push({
            ...item,
            ...(org?.data?.name && { organizationName: org.data.name }),
            ...(userGroup?.data?.name && {
              userGroupName: userGroup.data.name,
            }),
          });
        })
      );

      if (decryptedUserRole === Role.Admin) {
        const filtered = users.filter((user) => user.type === Role.User);
        users = filtered;
      }
      dispatch({ type: SET_USER, payload: users });
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const createUser = (payload, callback) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    try {
      const { data } = await initiateAXIOS.post(initiateUserAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const userRole = localStorage.getItem("permissionRole");
      const decryptedUserRole = decryptUser(userRole);

      const userPermission = localStorage.getItem("permissionList");
      const decryptedPermission = decryptUser(userPermission);
      const userGroupPermission = decryptedPermission.userGroup;

      let org;
      let userGroup;
      if (decryptedUserRole === Role.Eins) {
        org = await initiateAXIOS.get(
          initiateOrganizationAPI + data.organizationId,
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );
      }

      if (userGroupPermission.read) {
        userGroup = await initiateAXIOS.get(
          initiateUserGroupAPI + data.userGroupId,
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );
      }

      const newData = {
        ...data,
        ...(org?.data?.name && { organizationName: org.data.name }),
        ...(userGroup?.data?.name && {
          userGroupName: userGroup.data.name,
        }),
      };

      dispatch({ type: SET_NEW_USER, payload: newData });
      dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: t("api.createMsg"),
      });

      callback("success");
      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_USER_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_DIALOG, payload: message });

      callback("error");
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_DIALOG, 8000);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    try {
      await initiateAXIOS.delete(initiateUserAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: t("api.deleteMsg"),
      });
      dispatch({ type: SET_DELETE_USER, payload: id });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_USER_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const deleteUsergroup = (id) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    try {
      await initiateAXIOS.delete(initiateUserGroupAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_DELETE_USER_GROUP, payload: id });
      dispatch({
        type: SET_SUCCESS_PROMPT_USER_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: t("api.deleteGroupMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_USER_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({
        type: SET_ERROR_PROMPT_USER_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_USER_PAGE,
        payload: message,
      });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const editUser = ({ id, user }, callback) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    try {
      const { password, currentPassword, ...userPayload } = user;
      const { data } = await initiateAXIOS.patch(
        initiateUserAPI + id,
        userPayload,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      if (password) {
        await initiateAXIOS.patch(
          initiateUserAPI + id + "/password",
          { currentPassword, password },
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );
      }

      const userRole = localStorage.getItem("permissionRole");
      const decryptedUserRole = decryptUser(userRole);

      const userPermission = localStorage.getItem("permissionList");
      const decryptedPermission = decryptUser(userPermission);
      const userGroupPermission = decryptedPermission.userGroup;

      let org;
      let userGroup;
      if (decryptedUserRole === Role.Eins) {
        org = await initiateAXIOS.get(
          initiateOrganizationAPI + data.organizationId,
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );
      }

      if (userGroupPermission.read) {
        userGroup = await initiateAXIOS.get(
          initiateUserGroupAPI + data.userGroup.id,
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );
      }

      const newData = {
        ...data,
        ...(org?.data?.name && { organizationName: org.data.name }),
        ...(userGroup?.data?.name && {
          userGroupName: userGroup.data.name,
          userGroupId: userGroup.data.id,
        }),
      };

      dispatch({ type: SET_EDIT_USER, payload: newData });
      dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: t("api.updateMsg"),
      });

      callback("success");
      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_USER_PAGE);
    } catch (e) {
      logger.log(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_DIALOG, payload: message });

      callback("error");
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_DIALOG, 8000);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const unlockUser = (user) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });

    if (user.isLocked) {
      try {
        const { data } = await initiateAXIOS.patch(
          initiateUserAPI + user.id,
          {
            user: { isLocked: false },
          },
          {
            headers: {
              authorization: `Bearer ${access_token}`,
            },
          }
        );

        const OOP = new User(data);
        dispatch({ type: SET_EDIT_USER, payload: OOP });
        dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
        dispatch({
          type: SET_SUCCESS_MESSAGE_USER_PAGE,
          payload: t("api.userLockedMsg"),
        });

        handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_USER_PAGE);
      } catch (e) {
        const message = handleError(e);
        dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
        dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

        handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_PAGE);
        if (e.response?.status >= 500) {
          throw new Error(JSON.stringify(e.response?.data) || message);
        }
      } finally {
        dispatch({ type: SET_LOADING_USER, payload: false });
      }
    } else {
      try {
        const { data } = await initiateAXIOS.post(
          initiateUserAPI + user.id + "/unrestrict",
          {},
          {
            headers: {
              authorization: `Bearer ${access_token}`,
            },
          }
        );

        const OOP = new User(data);
        dispatch({ type: SET_EDIT_USER, payload: OOP });
        dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
        dispatch({
          type: SET_SUCCESS_MESSAGE_USER_PAGE,
          payload: t("api.unlockMsg"),
        });

        handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_USER_PAGE);
      } catch (e) {
        const message = handleError(e);
        dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
        dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

        handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_PAGE);
        if (e.response?.status >= 500) {
          throw new Error(JSON.stringify(e.response?.data) || message);
        }
      } finally {
        dispatch({ type: SET_LOADING_USER, payload: false });
      }
    }
  };
};

export const lockUser = (userId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_USER, payload: true });
    const access_token = sessionStorage.getItem("access_token");

    try {
      const { data } = await initiateAXIOS.patch(
        initiateUserAPI + userId,
        {
          user: { isLocked: true },
        },
        {
          headers: {
            authorization: `Bearer ${access_token}`,
          },
        }
      );

      const OOP = new User(data);
      dispatch({ type: SET_EDIT_USER, payload: OOP });
      dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: t("api.lockMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_USER_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
    }
  };
};

export const forceLogoutUser = (userId) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    try {
      await initiateAXIOS.post(
        initiateUserAPI + userId + "/logout/",
        {},
        {
          headers: {
            authorization: `Bearer ${access_token}`,
          },
        }
      );
      dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_USER_PAGE,
        payload: t("api.forceLogoutMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_USER_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    }
  };
};

export const getUserHistory = (
  { payload, cache, append, filter },
  callback
) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    logger.log(`getUserHistory:`, { payload, cache, append, filter });
    if (filter || cache?.items?.length === 0) {
      dispatch({ type: SET_LOADING_USER, payload: true });
    }

    const {
      limit,
      offset,
      userType,
      userNameLike,
      createdAtGte,
      createdAtLte,
      sortBy,
    } = payload;
    let getURL = `${instanceAuditLogAPI}?limit=${limit || 10}`;
    if (userType?.length > 0) {
      getURL += `&userType=${userType}`;
    }
    if (userNameLike?.length > 0) {
      getURL += `&userNameLike=${userNameLike}`;
    }
    if (createdAtGte?.length > 0) {
      getURL += `&createdAtGte=${createdAtGte}`;
    }
    if (createdAtLte?.length > 0) {
      getURL += `&createdAtLte=${createdAtLte}`;
    }
    if (sortBy?.length > 0) {
      getURL += `&sortBy=${sortBy}`;
    } else {
      getURL += `&sortBy=idAsc`;
    }

    // parsing payload to query
    getURL += `&offset=${offset || 0}`;

    try {
      const { data } = await initiateAXIOS.get(getURL, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      let fetchedData = [];
      const items = data.items;
      for (let i = 0; i < items.length; i++) {
        fetchedData.push(items[i]);
      }

      let updated = cache?.items;
      if (append && cache?.items?.length > 0) {
        let appendedLength = cache.items.length + limit;
        if (appendedLength <= offset) {
          const paddingLength = offset - appendedLength + limit;
          const paddingArray = new Array(paddingLength)
            .fill({})
            .map((_, index) => {
              return {
                id: index + 1,
                name: "..........",
                campaignName: ".....",
              };
            });
          updated = updated.concat(paddingArray);
        }

        updated = updated.concat(fetchedData);
        dispatch({
          type: SET_USER_HISTORY,
          payload: {
            items: updated,
            totalItem: data.totalItem,
          },
        });
      } else {
        if (cache?.items?.length > 0 && !filter) {
          updated.splice(offset, limit, ...fetchedData);
          dispatch({
            type: SET_USER_HISTORY,
            payload: {
              items: updated,
              totalItem: data.totalItem,
            },
          });
        } else {
          dispatch({
            type: SET_USER_HISTORY,
            payload: {
              items: fetchedData,
              totalItem: data.totalItem,
            },
          });
        }
      }
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
      if (callback) callback();
    }
  };
};

export const changeSelfPassword = (payload, changeSelfPasswordCallback) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_USER, payload: true });

    let resData;
    let error;
    try {
      await initiateAXIOS.patch(initiateAuthAPI + "password", payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      resData = true;
      dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
      dispatch({
        type: SET_GLOBAL_SUCCESS_MESSAGE,
        payload: t("api.updatePasswordMsg"),
      });
    } catch (e) {
      logger.log(e);
      const message = handleError(e, "user");
      dispatch({ type: SET_ERROR_PROMPT_USER_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_DIALOG, payload: message });

      error = e;
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_USER_DIALOG, 8000);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_USER, payload: false });
      if (changeSelfPasswordCallback)
        changeSelfPasswordCallback(resData, error);
    }
  };
};

export const getOrganizationFeatures = async (orgId) => {
  const access_token = sessionStorage.getItem("access_token");

  try {
    const { data } = await initiateAXIOS.get(initiateOrganizationAPI + orgId, {
      headers: {
        authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });

    return data;
  } catch (e) {
    const message = handleError(e);
    logger.error(message);
    throw new Error(JSON.stringify(e.response?.data) || message);
  }
};
