// Enum: "SLIDE_FROM_TOP_LEFT"
export const SlideInFromTopLeft = {
  id: "slideInFromTopLeft",
  label: "Slide In From Top Left",
  speed: 2000,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "top left",
      translate: ["-100%", "-100%", 10],
    },
  },
};
