import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { TypographyNormal } from "../../customComponent";
import UserHistoryItem from "./UserHistoryItem";
import { getUserHistory } from "../../../store/actions/userAction";
import { colorStyling } from "../../../helpers/color";
import Role from "../../../helpers/roles";
import { logger } from "../../../helpers/logger";
import { parseVariables } from "../../../helpers/localize";
import {
  SET_USER_HISTORY_FIRST_LOAD,
  SET_USER_HISTORY_ROWS_PER_PAGE,
} from "../../../store/actionTypes/userActionType";
import { useDebounce } from "../../hooks";

const DATA_FETCH_LIMIT = 100;

const UserHistoryList = ({ role }) => {
  const dispatch = useDispatch();
  const {
    loadingUser: loading,
    userHistory,
    userHistoryFirstLoad: firstLoad,
    userHistoryFilter,
    userHistoryRowsPerPage: rowsPerPage,
  } = useSelector((state) => state.user);

  const { t } = useTranslation("auditlog");
  const { t: tCommon } = useTranslation();

  const [currentPage, setCurrentPage] = useState(0);
  const [dataFrom, setDataFrom] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [appendLoading, setAppendLoading] = useState(false);
  const tableDiv = useRef(null);

  const fetchContents = (payload) => {
    const { offset, append, filterUsed } = payload;

    const getPayload = {
      payload: {
        ...userHistoryFilter,
        limit: DATA_FETCH_LIMIT,
        offset: offset ?? dataOffset,
      },
      cache: userHistory,
      append: append,
      filter: filterUsed,
    };

    setDataOffset(offset);
    dispatch(
      getUserHistory(getPayload, () => {
        setAppendLoading(false);
      })
    );
  };

  const updateDataList = (page, filterUsed) => {
    const nextSlice = page * rowsPerPage;
    setDataFrom(nextSlice);

    let appendData = false;
    if (userHistory.items.length > 0 && nextSlice >= userHistory.items.length) {
      appendData = true;
    }

    const nextOffset =
      Math.floor(nextSlice / DATA_FETCH_LIMIT) * DATA_FETCH_LIMIT;
    const payload = { offset: nextOffset, append: appendData, filterUsed };
    fetchContents(payload);
  };

  const debouncedUpdate = useDebounce(currentPage, 1111);
  useEffect(() => {
    if (updateFlag === true) {
      updateDataList(currentPage);
      setUpdateFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUpdate]);

  const updateDataSlice = ({ page, rows }) => {
    const nextPage = page >= 0 ? page : currentPage;
    const nextSlice = nextPage * (rows ?? rowsPerPage);
    if (nextSlice >= userHistory.items.length) {
      setAppendLoading(true);
    }

    setDataFrom(nextSlice);
    setCurrentPage(nextPage);
    setUpdateFlag(true);

    if (tableDiv.current) {
      tableDiv.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleChangePagination = (e, page) => {
    updateDataSlice({ page });
  };

  const handleChangeRowsPerPage = (event) => {
    const updatedRows = parseInt(event.target.value, 10);
    dispatch({
      type: SET_USER_HISTORY_ROWS_PER_PAGE,
      payload: updatedRows,
    });
    updateDataSlice({ page: 0, rows: updatedRows });
  };

  const displayedRowsLabel = ({ from, to, count }) => {
    return parseVariables(tCommon("table.displayedRowsLabel"), {
      prefix: `${from}–${to}`,
      suffix:
        count !== -1
          ? count
          : `${tCommon("table.displayedRowsMoreThan")} ${to}`,
    });
  };

  useEffect(() => {
    updateDataList(0);
    return () => {
      dispatch({ type: SET_USER_HISTORY_FIRST_LOAD, payload: false });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setCurrentPage(0);
      updateDataList(0, true);
    } else {
      dispatch({ type: SET_USER_HISTORY_FIRST_LOAD, payload: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHistoryFilter]);

  const dataSliced = useMemo(() => {
    const lastIndex = dataFrom + rowsPerPage;
    logger.log(`[${dataFrom}, ${lastIndex}]`);
    return userHistory.items.slice(dataFrom, lastIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userHistory, dataFrom, rowsPerPage]);

  return (
    <>
      <Box sx={{ maxHeight: 500 }}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ height: "20vh" }}
          >
            <CircularProgress
              size={20}
              thickness={3}
              sx={{ color: colorStyling.primary }}
            />
            <TypographyNormal
              sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
            >
              {tCommon("loadingDataList")}
            </TypographyNormal>
          </Box>
        ) : (
          <>
            {userHistory.items.length === 0 ? (
              <Paper elevation={2} sx={{ borderRadius: "6px", p: 2 }}>
                <Alert severity="info" variant="outlined">
                  <strong>{tCommon("noData")}</strong>
                </Alert>
              </Paper>
            ) : (
              <TableContainer
                ref={tableDiv}
                component={Paper}
                sx={{ overflowY: "auto", maxHeight: 500, boxShadow: 3 }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" width={"100px"}>
                        {t("header.id")}
                      </TableCell>
                      <TableCell align="center" width={"120px"}>
                        {t("header.userType")}
                      </TableCell>
                      <TableCell align="center" width={"135px"}>
                        {t("header.userName")}
                      </TableCell>
                      <TableCell align="center" width={"140px"}>
                        {t("header.action")}
                      </TableCell>
                      <TableCell sx={{ pl: 7 }}>{t("header.object")}</TableCell>
                      {role === Role.Eins && (
                        <TableCell align="center" width={"55px"}>
                          {t("header.organization")}
                        </TableCell>
                      )}
                      <TableCell align="center" width={"225px"}>
                        {t("header.time")}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {dataSliced.length !== 0 ? (
                      dataSliced.map((data, index) => (
                        <UserHistoryItem
                          key={data.id}
                          data={data}
                          index={index}
                          role={role}
                        />
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={role === Role.Eins ? 7 : 6}>
                          <Box
                            sx={{ height: "20vh" }}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                          >
                            <CircularProgress
                              size={20}
                              thickness={3}
                              sx={{ color: colorStyling.primary }}
                            />
                            <TypographyNormal
                              sx={{
                                color: colorStyling.primary,
                                mt: 2,
                                fontWeight: 400,
                              }}
                            >
                              {tCommon("loadingDataList")}
                            </TypographyNormal>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Box>

      {(!loading || userHistory?.totalItem > 0) && (
        <Box
          sx={{
            pt: 2,
            pb: 1,
            pr: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Paper component="div" elevation={1} sx={{ px: 1 }}>
            <TablePagination
              component="div"
              count={userHistory.totalItem}
              page={currentPage}
              onPageChange={handleChangePagination}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={displayedRowsLabel}
              labelRowsPerPage={tCommon("table.rowsPerPageLabel")}
              disabled={appendLoading}
            />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default UserHistoryList;
