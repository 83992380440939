// Enum: "EXPAND_FROM_TOP_RIGHT"
export const ExpandFromTopRight = {
  id: "expandFromTopRight",
  label: "Expand From Top Right",
  speed: 2200,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "top right",
      scale: 0.1,
      translate: ["50%", "-10%", 0],
    },
  },
};
