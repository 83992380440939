import Group from "../../models/GroupModel";
import {
  SET_DELETE_GROUP,
  SET_DETAIL_GROUP,
  SET_EDIT_GROUP,
  SET_ERROR_MESSAGE_GROUP_DIALOG,
  SET_ERROR_MESSAGE_GROUP_PAGE,
  SET_ERROR_PROMPT_GROUP_DIALOG,
  SET_ERROR_PROMPT_GROUP_PAGE,
  SET_GROUP,
  SET_GROUP_FILTER,
  SET_GROUP_PAGE,
  SET_GROUP_ROWS_PER_PAGE,
  SET_GROUP_SCHEDULE,
  SET_GROUP_VIEWER,
  SET_LOADING_GROUP,
  SET_NEW_GROUP,
  SET_PREFETCH_GROUP,
  SET_SUCCESS_MESSAGE_GROUP_PAGE,
  SET_SUCCESS_PROMPT_GROUP_PAGE,
} from "../actionTypes/groupActionType";

const initialState = {
  groups: { items: [], totalItem: 0 },
  group: {},
  initialFetch: true,
  groupFilters: { sortBy: "idDesc" },
  page: 0,
  rowsPerPage: 5,
  loadingGroup: false,
  isSuccessPromptGroupPage: false,
  successMessageGroupPage: "",
  isErrorPromptGroupPage: false,
  errorMessageGroupPage: "",
  isErrorPromptGroupDialog: false,
  errorMessageGroupDialog: "",
};

export const groupReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_GROUP:
      const initialGroupState = state.groups;
      let newGroupData = [];
      payload.items.forEach((g) => {
        const foundGroup = initialGroupState?.items?.find(
          (ig) => ig.id === g.id
        );
        newGroupData.push({
          ...g,
          screens: foundGroup?.screens ?? g.screens,
          screensCount: foundGroup?.screensCount ?? g.screensCount,
          schedules: foundGroup?.schedules ?? g.schedules,
          schedulesCount: foundGroup?.schedulesCount ?? g.schedulesCount,
        });
      });
      return {
        ...state,
        groups: { totalItem: payload.totalItem, items: newGroupData },
        initialFetch: false,
      };

    case SET_GROUP_FILTER:
      return { ...state, groupFilters: payload };

    case SET_GROUP_PAGE:
      return { ...state, page: payload };

    case SET_GROUP_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: payload };

    case SET_LOADING_GROUP:
      return { ...state, loadingGroup: payload };

    case SET_NEW_GROUP:
      const listOfGroups =
        state.page === 0
          ? [payload, ...state.groups.items]
          : [
              ...state.groups.items.slice(0, state.page * state.rowsPerPage),
              payload,
              ...state.groups.items.slice(state.page * state.rowsPerPage),
            ];

      return {
        ...state,
        groups: { items: listOfGroups, totalItem: state.groups.totalItem + 1 },
      };

    case SET_PREFETCH_GROUP:
      let addedPrefetchGroups = [...state.groups.items, ...payload.items];
      return {
        ...state,
        groups: {
          items: addedPrefetchGroups,
          totalItem: payload.totalItem,
        },
      };

    case SET_DELETE_GROUP:
      const groupsState = state.groups.items;
      const filtered = groupsState.filter(
        (group) => group.id.toString() !== payload.toString()
      );
      return {
        ...state,
        groups: { items: filtered, totalItem: state.groups.totalItem - 1 },
      };

    case SET_EDIT_GROUP:
      const data = state.groups;
      const objIndex = data.findIndex((obj) => obj.id === payload.id);
      data[objIndex] = payload;
      return { ...state, groups: [...data] };

    case SET_GROUP_VIEWER:
      const viewerAddingData = state.groups.items;
      const viewerAddingObbjIndex = viewerAddingData?.findIndex(
        (obj) => obj.id === payload.id
      );
      viewerAddingData[viewerAddingObbjIndex]["screens"] = payload?.screens;
      viewerAddingData[viewerAddingObbjIndex] = new Group(
        viewerAddingData[viewerAddingObbjIndex]
      );
      return {
        ...state,
        groups: {
          totalItem: state.groups.totalItem,
          items: [...viewerAddingData],
        },
      };

    case SET_GROUP_SCHEDULE:
      const scheduleAddingData = state.groups.items;
      const scheduleAddingObbjIndex = scheduleAddingData?.findIndex(
        (obj) => obj.id === payload.id
      );
      scheduleAddingData[scheduleAddingObbjIndex]["schedules"] =
        payload?.schedules;

      scheduleAddingData[scheduleAddingObbjIndex] = new Group(
        scheduleAddingData[scheduleAddingObbjIndex]
      );
      return {
        ...state,
        groups: {
          totalItem: state.groups.totalItem,
          items: [...scheduleAddingData],
        },
      };

    case SET_DETAIL_GROUP:
      return {
        ...state,
        group: {
          ...state.group,
          [payload.id]: { ...(state.group[payload.id] ?? {}), ...payload },
        },
      };

    case SET_SUCCESS_PROMPT_GROUP_PAGE:
      return { ...state, isSuccessPromptGroupPage: payload };

    case SET_SUCCESS_MESSAGE_GROUP_PAGE:
      return { ...state, successMessageGroupPage: payload };

    case SET_ERROR_PROMPT_GROUP_PAGE:
      return { ...state, isErrorPromptGroupPage: payload };

    case SET_ERROR_MESSAGE_GROUP_PAGE:
      return { ...state, errorMessageGroupPage: payload };

    case SET_ERROR_PROMPT_GROUP_DIALOG:
      return { ...state, isErrorPromptGroupDialog: payload };

    case SET_ERROR_MESSAGE_GROUP_DIALOG:
      return { ...state, errorMessageGroupDialog: payload };

    default:
      return state;
  }
};
