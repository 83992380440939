// Enum: "EXPAND_FROM_BOTTOM"
export const ExpandFromBottom = {
  id: "expandFromBottom",
  label: "Expand From Bottom",
  speed: 2000,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "bottom center",
      scale: 0.1,
      translate: [0, "50%", 0],
    },
  },
};
