import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Toolbar,
} from "@mui/material";

import { getViewer } from "../../../store/actions/viewerAction";
import Sidebar from "../../sidebar/Sidebar";
import ViewerBreadCrumb from "./ViewerBreadCrumb";
import ViewerSuccessPrompt from "../ViewerSuccessPrompt";
import ViewerErrorPrompt from "../ViewerErrorPrompt";
import ViewerInfo from "./ViewerInfo";
import DeleteViewer from "./DeleteViewer";
import { decryptUser } from "../../../helpers/crypto";
import AssignedComponentList from "../../assign/AssignedComponentList";
import AssignComponent from "../../assign/AssignComponent";
import EditViewer from "./EditViewer";
import CreateInstantSchedule from "../../schedule/CreateInstantSchedule";
import ViewerScreenshot from "./ViewerScreenshot";
import { getViewerStatus } from "../../../helpers/viewerHelper";
import CoreDetailPageLoader from "../../skeletonLoader/CoreDetailPageLoader";
import { isEmpty } from "lodash";
// TODO: When working on the feature
// import BlockViewer from "./BlockViewer";

const ViewerDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getViewer(id));
  }, [dispatch, id]);

  const {
    viewer: { [id]: viewer },
    loadingViewerDetail: loading,
  } = useSelector((state) => state.viewer);

  const [viewerIsActive, setViewerIsActive] = useState(false);

  useEffect(() => {
    setViewerIsActive(getViewerStatus(viewer?.activeAt));
  }, [viewer]);

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);
  const userRole = decryptedLoggedUser.type;
  const permission = decryptedLoggedUser?.permission;
  const schedulePermission = permission.schedule;
  const groupPermission = permission.screenGroup;
  const viewerPermission = permission.screen;

  let isLoading = !loading || +viewer?.id === +id;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />
        {isEmpty(viewer) ? (
          <CoreDetailPageLoader infoLine={1} />
        ) : (
          <Container maxWidth="xl">
            <Box sx={{ mb: 2 }}>
              <ViewerBreadCrumb viewer={viewer} />
            </Box>

            <ViewerSuccessPrompt />
            <ViewerErrorPrompt />

            {isLoading && (
              <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                {schedulePermission.create && (
                  <CreateInstantSchedule data={viewer} type={"viewer"} />
                )}

                <ViewerScreenshot
                  viewer={viewer}
                  size="md"
                  disabled={
                    !viewerIsActive || viewer.status?.displayStatus === "OFF"
                  }
                />

                {viewerPermission.update && <EditViewer viewer={viewer} />}

                {viewerPermission.delete && <DeleteViewer viewer={viewer} />}

                {/* TODO: When working on the feature */}
                {/* <BlockViewer viewer={viewer} size="md" /> */}
              </Box>
            )}

            <Grid container spacing={2} direction="column">
              <Grid item xs={4}>
                <Paper elevation={2} sx={{ p: 2, borderRadius: "6px" }}>
                  <ViewerInfo
                    viewer={viewer}
                    loading={!isLoading}
                    userRole={userRole}
                  />
                </Paper>
              </Grid>

              {isLoading && viewerPermission.update ? (
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  {/* Assign Group */}
                  {groupPermission.read && (
                    <AssignComponent
                      getName={"group"}
                      typeName="viewer"
                      typeData={viewer}
                    />
                  )}

                  {/* Assign Schedule */}
                  {schedulePermission.read && (
                    <AssignComponent
                      getName={"schedule"}
                      typeName="viewer"
                      typeData={viewer}
                    />
                  )}
                </Box>
              ) : null}

              {/* Group List */}
              {groupPermission.read && (
                <Grid item xs={4}>
                  <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                    <AssignedComponentList
                      key={"group"}
                      type={"group"}
                      listData={viewer.screenGroups}
                      sourceData={{
                        id: viewer.id,
                        name: viewer.name,
                        type: "viewer",
                        editPermission: viewerPermission.update,
                      }}
                      loading={!isLoading}
                    />
                  </Paper>
                </Grid>
              )}

              {/* Schedule List */}
              {schedulePermission.read && (
                <Grid item xs={4}>
                  <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                    <AssignedComponentList
                      key={"schedule"}
                      type={"schedule"}
                      listData={viewer.schedules}
                      sourceData={{
                        id: viewer.id,
                        name: viewer.name,
                        type: "viewer",
                        editPermission: viewerPermission.update,
                      }}
                      loading={!isLoading}
                    />
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Container>
        )}
      </Box>
    </div>
  );
};

export default ViewerDetail;
