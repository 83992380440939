import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { colorStyling } from "../../../helpers/color";
import { TypographyLarge, TypographyNormal } from "../../customComponent";
import { assetCMSAPILimit, campaignCMSAPILimit } from "../../../api";
import { initiateAXIOS } from "../../../config/axios";
import { useDebounce } from "../../hooks";
import AssetSelectorItem from "./AssetSelectorItem";
import CampaignSelectorItem from "./CampaignSelectorItem";
import { parseVariables } from "../../../helpers/localize";

const InputSearch = styled("input")(() => ({
  outline: "none",
  border: "none",
  fontSize: "16px",
  lineHeight: "26px",
  fontWeight: 400,
  width: "100%",
  marginLeft: "10px",
  fontFamily: "Inter, sans-serif",
}));

const BoxInput = styled(Box)(({ theme }) => ({
  border: `1px solid ${colorStyling.white.border}`,
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(1.875),
  paddingRight: theme.spacing(1.875),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  borderRadius: "6px",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const AssetSelector = ({ handleSelectListData, type }) => {
  const { t } = useTranslation("contentreport");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Please assign at least one assets"
  );

  const [search, setSearch] = useState("");
  const [assetsData, setAssetsData] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  const isAsset = type === "asset";
  const isCampaign = type === "campaign";

  const parseTypeText = useMemo(() => {
    switch (type) {
      default:
      case "asset":
        return t("export.assetText");
      case "campaign":
        return t("export.campaignText");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  /**
   * 1 Second delay after input value change to do API fetch
   */
  const debouncedSearchTerm = useDebounce(search, 1000);
  const resetState = () => {
    setAssetsData([]);
    setSearch("");
  };

  const handleOpenConfirmation = () => {
    setOpenConfirmation(true);
  };
  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClickConfirm = () => {
    let arrId;
    if (isAsset) arrId = selectedItem.map((item) => item.id);
    if (isCampaign) arrId = selectedItem.map((item) => item.name);
    handleSelectListData(arrId);
    resetState();
    setOpen(false);
  };

  const handleClickClose = () => {
    setOpenConfirmation(false);
    resetState();
    setSelectedItem([]);
    handleSelectListData([]);
    setOpen(false);
  };

  const handleCloseAlert = () => {
    setIsError(false);
  };

  const handleRemoveSelected = (value) => {
    const filtered = selectedItem.filter((item) =>
      isAsset ? item.id !== +value : item.name !== value
    );
    setSelectedItem(filtered);
  };

  const handleChangeCheckbox = (event) => {
    if (event.target.checked) {
      const payload = assetsData.find((item) =>
        isAsset
          ? item.id === +event.target.value
          : item.name === event.target.value
      );
      setSelectedItem([...selectedItem, payload]);
    } else {
      handleRemoveSelected(event.target.value);
    }
  };

  const fetchAssetData = async (name) => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);
    try {
      let qname = name ? "&nameLike=" + name : "";
      const query = "&sortBy=idAsc&type=IMAGE&type=VIDEO";
      const { data } = await initiateAXIOS.get(
        assetCMSAPILimit + query + qname,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setAssetsData(data.items);
    } catch (e) {
      const errorMessage = e.response.data.message;
      setIsError(true);
      setErrorMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaignData = async (name) => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);
    try {
      let qname = name ? "&nameLike=" + name : "";
      const query = "&sortBy=nameAsc";
      const { data } = await initiateAXIOS.get(
        campaignCMSAPILimit + query + qname,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setAssetsData(data.items);
    } catch (e) {
      const errorMessage = e.response.data.message;
      setIsError(true);
      setErrorMessage(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      if (isAsset) fetchAssetData(search);
      if (isCampaign) fetchCampaignData(search);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  /**
   * Run api call after set value (1 second) when search box value change
   */
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        if (isAsset) fetchAssetData(debouncedSearchTerm);
        if (isCampaign) fetchCampaignData(debouncedSearchTerm);
      } else if (debouncedSearchTerm === "" && open) {
        if (isAsset) fetchAssetData();
        if (isCampaign) fetchCampaignData();
      } else {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  return (
    <>
      <Button
        sx={{ textTransform: "capitalize" }}
        variant={selectedItem.length ? "outlined" : "contained"}
        onClick={handleOpen}
        fullWidth
      >
        {selectedItem.length
          ? parseVariables(t("export.editSelectedBtn"), {
              count: selectedItem.length,
              type: parseTypeText,
            })
          : parseVariables(t("export.selectBtn"), { type: parseTypeText })}
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose} fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textTransform: "capitalize",
          }}
        >
          {parseVariables(t("export.selectBtn"), { type: parseTypeText })}
          <IconButton onClick={handleClickClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider />

        <DialogContent>
          <BoxInput>
            <SearchIcon
              fontSize="medium"
              sx={{ color: colorStyling.gray.normal }}
            />

            <InputSearch
              type="search"
              value={search}
              placeholder={parseVariables(t("export.searchPlaceholder"), {
                type: parseTypeText,
              })}
              onChange={(e) => setSearch(e.target.value)}
            />
          </BoxInput>

          {isError && (
            <Alert
              severity="error"
              variant="outlined"
              onClose={handleCloseAlert}
            >
              {errorMessage}
            </Alert>
          )}

          <Accordion disableGutters>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: colorStyling.black }} />}
            >
              <TypographyLarge
                sx={{ fontWeight: 600, textTransform: "capitalize" }}
              >
                {selectedItem?.length > 0
                  ? parseVariables(t("export.selectedTrueHint"), {
                      type: parseTypeText,
                      count: selectedItem?.length,
                    })
                  : parseVariables(t("export.selectedZeroHint"), {
                      type: parseTypeText,
                    })}
              </TypographyLarge>
            </AccordionSummary>
            <Box sx={{ px: 2, pb: 2 }}>
              <AccordionDetails
                sx={{
                  py: 1,
                  px: 2,
                  maxHeight: "200px",
                  overflowY: "auto",
                  borderRadius: "6px",
                  backgroundColor: colorStyling.white.full,
                  border: `1px solid ${colorStyling.white.border}`,
                }}
              >
                {selectedItem.length === 0 && (
                  <Alert severity="info" variant="outlined">
                    <strong>
                      {parseVariables(t("export.noneSelectedHint"), {
                        type: parseTypeText,
                      })}
                    </strong>
                  </Alert>
                )}
                {selectedItem?.map((item) => (
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    key={item.id ?? item.name}
                    sx={{
                      py: "4px",
                      "&:hover": {
                        backgroundColor: colorStyling.white.hover,
                      },
                    }}
                  >
                    {isAsset ? (
                      <AssetSelectorItem data={item} />
                    ) : (
                      <CampaignSelectorItem data={item} />
                    )}

                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          handleRemoveSelected(item.id ?? item.name);
                        }}
                      >
                        {t("export.removeSelectedBtn")}
                      </Button>
                    </Box>
                  </Box>
                ))}
              </AccordionDetails>
            </Box>
          </Accordion>

          <Box
            sx={{
              p: 1,
              mt: 1,
              maxHeight: 300,
              overflowY: "auto",
              borderRadius: "6px",
              border: `1px solid ${colorStyling.white.border}`,
            }}
          >
            {!loading && !assetsData.length ? (
              <Alert severity="info" variant="outlined">
                <strong>
                  {parseVariables(t("export.noneFoundHint"), {
                    type: parseTypeText,
                  })}
                </strong>{" "}
                {parseVariables(t("export.noneFoundHintExplain"), {
                  type: parseTypeText,
                })}
              </Alert>
            ) : null}

            {loading && (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                sx={{ py: 1 }}
              >
                <CircularProgress
                  size={20}
                  thickness={3}
                  sx={{ color: colorStyling.primary }}
                />
                <TypographyNormal
                  sx={{
                    color: colorStyling.primary,
                    marginTop: "15px",
                    fontWeight: 300,
                  }}
                >
                  {tCommon("loadingDataList")}
                </TypographyNormal>
              </Box>
            )}

            {!loading &&
              assetsData?.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    py: 1,
                    pl: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: colorStyling.white.hover,
                    },
                  }}
                >
                  {isAsset ? (
                    <AssetSelectorItem data={item} />
                  ) : (
                    <CampaignSelectorItem data={item} />
                  )}

                  <Checkbox
                    value={item.id ?? item.name}
                    onChange={handleChangeCheckbox}
                    checked={
                      selectedItem.find((obj) =>
                        obj.id ? obj.id === item.id : obj.name === item.name
                      )
                        ? true
                        : false
                    }
                  />
                </Box>
              ))}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            fullWidth
            onClick={
              selectedItem.length > 0
                ? handleOpenConfirmation
                : handleClickClose
            }
          >
            {tCommon("cancelBtn")}
          </Button>
          <CloseConfirmation
            open={openConfirmation}
            handleClose={handleClickClose}
            onClose={handleCloseConfirmation}
            type={parseTypeText}
          />

          <Button onClick={handleClickConfirm} variant="contained" fullWidth>
            {tCommon("confirmBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const CloseConfirmation = ({ handleClose, open, onClose, type }) => {
  const { t } = useTranslation("contentreport");
  const { t: tCommon } = useTranslation();

  const handleCallbackClose = (event) => {
    event.preventDefault();

    handleClose();
  };
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText sx={{ textAlign: "center", mb: 1 }}>
          {parseVariables(t("export.closeConfirmationTitle"), { type })}
        </DialogContentText>
        <Alert severity="warning">
          {parseVariables(t("export.closeConfirmationWarning"), { type })}
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button
          startIcon={<ClearIcon />}
          fullWidth
          variant="outlined"
          onClick={onClose}
        >
          {tCommon("noBtn")}
        </Button>

        <Button
          onClick={handleCallbackClose}
          startIcon={<DoneIcon />}
          fullWidth
          variant="contained"
        >
          {tCommon("yesBtn")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssetSelector;
