import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Container, CssBaseline, Toolbar, Paper } from "@mui/material";
import { TypographyNormal, TypographyXLarge } from "../customComponent";
import Sidebar from "../sidebar/Sidebar";
import ViewerSuccessPrompt from "./ViewerSuccessPrompt";
import ViewerErrorPrompt from "./ViewerErrorPrompt";
import FilterViewer from "./FilterViewer";
import ViewerList from "./ViewerList";
import CreateViewer from "./CreateViewer";
import { decryptUser } from "../../helpers/crypto";
import Role from "../../helpers/roles";

const Viewer = () => {
  const { t } = useTranslation("viewer");

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);
  const userRole = decryptedLoggedUser.type;
  const viewerPermission = decryptedLoggedUser?.permission.screen;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              {t("titleExplain")}
            </TypographyNormal>
          </Box>

          <ViewerSuccessPrompt />
          <ViewerErrorPrompt />

          {(userRole === Role.Eins || viewerPermission.create) && (
            <Box display={"flex"} justifyContent={"flex-end"}>
              <CreateViewer />
            </Box>
          )}

          <Paper
            component="section"
            elevation={2}
            sx={{ p: 2, my: 2, borderRadius: "6px" }}
          >
            <FilterViewer />
          </Paper>

          <ViewerList />
        </Container>
      </Box>
    </div>
  );
};

export default Viewer;
