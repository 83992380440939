import React, { Fragment, useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Box,
  InputLabel,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  CustomTextField,
  TypographyLarge,
} from "../../../../components/customComponent";

import Line from "../../../../models/LineModel";
import Station from "../../../../models/StationModel";
import { disruptionAXIOS } from "../../../../config/axios";
import { instanceDisruptionLineAPI as lineAPI } from "../../../../api";
import { useDispatch } from "react-redux";
import { createDisruption } from "../../../store/actions/smrt/disruptionAction";
import dayjs from "dayjs";
import PreviewDisruption from "./preview/PreviewDisruption";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const CreateTrainDisruption = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [form, setForm] = useState({
    type: "DISRUPTION",
    dir: "",
    delayMinutes: 10,
    resumeTime: "",
    lineId: "",
    fromId: "",
    toId: "",
    isBothDirection: false,
    isMessageHidden: false,
  });

  const [affectedStations, setAffectedStations] = useState([]);

  const [lines, setLines] = useState([]);
  const [stations, setStations] = useState([]);

  const [previewDisabled, setPreviewDisabled] = useState(true);

  const delayList = [10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

  const filterAffectedStation = (fromId, toId, stationsData) => {
    const fromOrder = stationsData.filter(
      (item) => item.id === parseInt(fromId)
    )[0].order;
    const toOrder = stationsData.filter((item) => item.id === parseInt(toId))[0]
      .order;

    let filterCondition;
    fromOrder <= toOrder
      ? (filterCondition = (obj) =>
          fromOrder <= obj.order && obj.order <= toOrder)
      : (filterCondition = (obj) =>
          fromOrder >= obj.order && obj.order >= toOrder);

    const filteredList = stationsData.filter(filterCondition);
    return filteredList;
  };

  const setResumeTime = () => {
    const newFormat = dayjs().format("YYYY-MM-DDTHH:mm");
    setForm({ ...form, resumeTime: newFormat });
  };

  const handleClose = () => {
    setForm({
      type: "DISRUPTION",
      dir: "",
      delayMinutes: 10,
      resumeTime: "",
      lineId: "",
      fromId: "",
      toId: "",
      isBothDirection: false,
      isMessageHidden: false,
    });

    setOpen(false);
  };

  const getStationsByLineId = async (lineId) => {
    let station = [];

    const listStation = lines
      .find((line) => line.id === lineId)
      .stations.sort((a, b) => a.order - b.order);

    listStation.map((item) => station.push(new Station(item)));
    setStations(station);
  };

  const handleLineChange = (event) => {
    setForm({
      ...form,
      [event.target.name]: event.target.value,
      dir: event.target.options[event.target.selectedIndex].text.split(
        "==> "
      )[1],
      toId: "",
      fromId: "",
    });
    setPreviewDisabled(true);
    getStationsByLineId(parseInt(event.target.value.substring(1)));
  };

  const handleChange = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
    if (event.target.name === "fromId" || event.target.name === "toId") {
      setAffectedStations([]);

      if (form.fromId || form.toId) setPreviewDisabled(false);
    }
  };

  const handleChangeStationCheckbox = (event, isChecked) => {
    const value = parseInt(event.target.value);
    const stationObject = stations.find((station) => station.id === value);
    if (isChecked) {
      setAffectedStations([...affectedStations, { id: stationObject.id }]);
    }
    if (!isChecked) {
      setAffectedStations(
        affectedStations.filter((item) => item.id !== stationObject.id)
      );
    }
  };

  const handleChangeDirectionCheckbox = (event, isChecked) => {
    setForm({ ...form, isBothDirection: isChecked });
  };

  const handleChangeHideMessageCheckbox = (event, isChecked) => {
    setForm({ ...form, isMessageHidden: isChecked });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newDateFormat = new Date(form.resumeTime).toISOString();

    let direction = {};
    const fromOrder = stations.find(
      (station) => station.id === parseInt(form.fromId)
    ).order;
    const toOrder = stations.find(
      (station) => station.id === parseInt(form.toId)
    ).order;

    if (form.lineId[0] === "l") {
      fromOrder < toOrder
        ? (direction = { from: form.fromId, to: form.toId })
        : (direction = { from: form.toId, to: form.fromId });
    } else {
      fromOrder > toOrder
        ? (direction = { from: form.fromId, to: form.toId })
        : (direction = { from: form.toId, to: form.fromId });
    }

    const payload = {
      type: form.type,
      duration: form.delayMinutes,
      resumeAt: newDateFormat,
      lineId: parseInt(form.lineId.substring(1)),
      fromStationId: parseInt(direction.from),
      toStationId: parseInt(direction.to),
      isBothDirection: form.isBothDirection,
      isMessageHidden: form.isMessageHidden,
      stations: affectedStations,
    };

    dispatch(createDisruption(payload));
    resetForm();
    setOpen(false);
  };

  const resetForm = () => {
    setForm({
      type: "DISRUPTION",
      dir: "",
      delayMinutes: 10,
      resumeTime: "",
      lineId: "",
      fromId: "",
      toId: "",
      isBothDirection: false,
      isMessageHidden: false,
    });
    setAffectedStations([]);
  };

  const getPreviewData = () => {
    const newDateFormat = new Date(form.resumeTime).toISOString();

    let direction = {};
    const fromOrder = stations.find(
      (station) => station.id === parseInt(form.fromId)
    )?.order;
    const toOrder = stations.find(
      (station) => station.id === parseInt(form.toId)
    )?.order;

    fromOrder < toOrder
      ? (direction = { from: form.fromId, to: form.toId })
      : (direction = { from: form.toId, to: form.fromId });

    let newAffected = [];
    affectedStations.forEach((station) => {
      newAffected.push(stations.filter((obj) => obj.id === station.id)[0]);
    });

    let previewData = [
      {
        type: form.type,
        direction: {
          name: form.isBothDirection
            ? "BOTH"
            : form.dir === stations.sort((a, b) => a.order - b.order)[0]?.abbr
            ? "ASC"
            : "DESC",
        },
        delayTime: form.delayMinutes,
        resumeTime: newDateFormat,
        line: lines.filter(
          (obj) => obj.id === parseInt(form.lineId.substring(1))
        )[0],
        betweenFrom: stations.filter(
          (obj) => obj.id === parseInt(direction.from)
        )[0],
        betweenTo: stations.filter(
          (obj) => obj.id === parseInt(direction.to)
        )[0],
        isMessageHidden: form.isMessageHidden,
        affectedStations: newAffected,
      },
    ];

    return previewData;
  };

  useEffect(() => {
    const getLines = async () => {
      const access_token = sessionStorage.getItem("access_token");
      try {
        const { data } = await disruptionAXIOS.get(
          lineAPI + "?includeStations=true",
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );

        const listLine = data;

        let line = [];
        listLine.map((item) => line.push(new Line(item)));
        setLines(line);
      } catch (error) {
        console.log(error);
      }
    };

    if (open) {
      getLines();
      setResumeTime();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<AddIcon fontSize="small" />}
        variant="contained"
        fullWidth
        sx={{ boxShadow: 3 }}
      >
        Create Disruption
      </Button>

      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        onClose={() => setOpen(false)}
      >
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Create New Disruption
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize="small" color="inherit" />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box>
              <StyledBox>
                <InputLabel>Message Type</InputLabel>
                <CustomTextField
                  autoFocus
                  required
                  select
                  SelectProps={{ native: true }}
                  fullWidth
                  margin="dense"
                  name="type"
                  value={form.type}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Choose Message Type
                  </option>
                  <option value="DISRUPTION">Disruption</option>
                  <option value="DELAY">Delay</option>
                </CustomTextField>
              </StyledBox>

              <StyledBox>
                <InputLabel>Line</InputLabel>
                <CustomTextField
                  select
                  required
                  SelectProps={{ native: true }}
                  fullWidth
                  margin="dense"
                  name="lineId"
                  value={form.lineId}
                  onChange={handleLineChange}
                >
                  <option value="" disabled>
                    Choose Line
                  </option>
                  {lines.map((line) => (
                    <Fragment key={line.id}>
                      <option value={"l" + line.id} key={"l" + line.id}>
                        {line.name +
                          " ==> " +
                          line.stations?.sort((a, b) => a.order - b.order)[0]
                            ?.abbr}
                      </option>
                      {line.stations?.length > 1 ? (
                        <option value={"r" + line.id} key={"r" + line.id}>
                          {line.name +
                            " ==> " +
                            line.stations?.sort((a, b) => b.order - a.order)[0]
                              ?.abbr}
                        </option>
                      ) : null}
                    </Fragment>
                  ))}
                </CustomTextField>
              </StyledBox>

              <StyledBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={"BOTH"}
                      name="direction"
                      onChange={handleChangeDirectionCheckbox}
                    />
                  }
                  label="Both Direction"
                />
              </StyledBox>

              <StyledBox>
                <InputLabel>From</InputLabel>
                <CustomTextField
                  select
                  required
                  SelectProps={{ native: true }}
                  fullWidth
                  margin="dense"
                  name="fromId"
                  value={form.fromId}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Choose From
                  </option>
                  {stations.map((station) => (
                    <option value={station.id} key={"from" + station.id}>
                      {station.abbr + " - " + station.name}
                    </option>
                  ))}
                </CustomTextField>
              </StyledBox>

              <StyledBox>
                <InputLabel>To</InputLabel>
                <CustomTextField
                  select
                  required
                  SelectProps={{ native: true }}
                  fullWidth
                  margin="dense"
                  name="toId"
                  value={form.toId}
                  onChange={handleChange}
                >
                  <option value="" disabled>
                    Choose To
                  </option>
                  {stations.map((station) => (
                    <option value={station.id} key={"to" + station.id}>
                      {station.abbr + " - " + station.name}
                    </option>
                  ))}
                </CustomTextField>
              </StyledBox>

              {form.fromId !== "" && form.toId !== "" && (
                <StyledBox>
                  <InputLabel>Affected Stations</InputLabel>
                  <Box
                    sx={{ border: "1px solid #e7e7e7", borderRadius: "4px" }}
                  >
                    <Grid
                      container
                      sx={{ p: 1, maxHeight: 150, overflow: "auto" }}
                    >
                      {filterAffectedStation(
                        form.fromId,
                        form.toId,
                        stations
                      ).map((station) => (
                        <Grid item key={station.id} xs={3} md={4}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={station.id}
                                key={station.id}
                                onChange={handleChangeStationCheckbox}
                              />
                            }
                            label={station.abbr + " - " + station.name}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </StyledBox>
              )}

              {form.type !== "DELAY" && (
                <StyledBox>
                  <InputLabel>Resume Time</InputLabel>
                  <CustomTextField
                    required
                    variant="outlined"
                    name="resumeTime"
                    margin="dense"
                    type="datetime-local"
                    value={form.resumeTime}
                    onChange={handleChange}
                  />
                </StyledBox>
              )}

              {form.type === "DELAY" && (
                <StyledBox>
                  <InputLabel>Delay Time</InputLabel>
                  <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
                    <Select
                      size="small"
                      value={form.delayMinutes}
                      name="delayMinutes"
                      onChange={handleChange}
                    >
                      {delayList.map((item) => (
                        <MenuItem value={item} key={item}>
                          {item.toString()}
                        </MenuItem>
                      ))}
                    </Select>
                    <TypographyLarge sx={{ ml: 1 }}>mins</TypographyLarge>
                  </Box>
                </StyledBox>
              )}

              <StyledBox>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="hideMessage"
                      onChange={handleChangeHideMessageCheckbox}
                    />
                  }
                  label="Hide Message"
                />
              </StyledBox>

              <StyledBox>
                <PreviewDisruption
                  fullWidth={false}
                  previewType={"CREATE"}
                  disabled={previewDisabled}
                  createData={getPreviewData}
                />
              </StyledBox>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button fullWidth variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button fullWidth variant="contained" type="submit">
              Create Disruption
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateTrainDisruption;
