import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Link,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import AssetsManagerZoom from "./AssetsManagerZoom";
import { TypographyNormal, TypographySmall } from "../../customComponent";
import { colorStyling } from "../../../helpers/color";
import parser from "html-react-parser";

const AssetDeleteConfirmation = ({
  asset,
  assetType,
  open,
  closeDialog,
  handleConfirm,
  loading,
}) => {
  const { t } = useTranslation("assetmanager");
  const { t: tCommon } = useTranslation();

  const [viewAsset, setViewAsset] = useState(false);
  const [confirmCheckbox, setConfirmCheckbox] = useState(false);

  const handleCallback = (event) => {
    event.preventDefault();
    handleConfirm();
  };

  const handleClose = (event) => {
    event.preventDefault();
    closeDialog();
    setConfirmCheckbox(false);
  };

  return (
    <Dialog maxWidth="md" open={open}>
      <DialogContent>
        <AssetsManagerZoom
          open={viewAsset}
          closeDialog={() => setViewAsset(false)}
          asset={asset}
          assetType={assetType}
        />

        <Box display={"flex"} sx={{ px: 1, py: 2 }}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Link
              href="#"
              underline="none"
              component={"div"}
              onClick={() => setViewAsset(true)}
              sx={{
                width: "333px",
                position: "relative",
                cursor: "pointer",
                ":hover": {
                  opacity: 0.9,
                },
              }}
            >
              {asset && (
                <img
                  alt={asset.name}
                  style={{
                    width: "100%",
                    height: "222px",
                  }}
                  src={asset?.thumbnailUrl ?? asset?.url}
                />
              )}
              {assetType.isVideo && (
                <PlayCircleIcon
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: colorStyling.white.full,
                    fontSize: "77px",
                  }}
                />
              )}
            </Link>
            <Box display={"flex"} justifyContent={"center"}>
              <Box
                display={"flex"}
                alignItems={"center"}
                onClick={() => setViewAsset(true)}
                sx={{
                  ":hover": {
                    cursor: "pointer",
                    textDecoration: "underline",
                  },
                }}
              >
                <ZoomInIcon fontSize="15px" sx={{ mt: "4px" }} />
                <TypographySmall
                  width={"fit-content"}
                  textAlign={"center"}
                  sx={{ pt: "4px" }}
                >
                  {t("asset.zoomHint")}
                </TypographySmall>
              </Box>
            </Box>
          </Box>

          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            sx={{ pl: 2 }}
          >
            <Box display={"flex"} flexDirection={"column"}>
              <DialogContentText
                sx={{ mb: 1, textAlign: "center", fontWeight: 600 }}
              >
                {t("asset.deleteItemText")}
              </DialogContentText>
              <Alert severity="warning" sx={{ alignItems: "center" }}>
                {parser(t("asset.deleteItemWarning"))}
              </Alert>

              <Box
                sx={{
                  p: 2,
                  marginTop: 2,
                  border: "1px solid black",
                }}
              >
                <Box display={"flex"} flexDirection={"column"}>
                  <TypographyNormal>
                    <b>{t("asset.fileNameLabel")}</b> {asset.name}
                  </TypographyNormal>
                  <TypographyNormal>
                    <b>{t("asset.campaignNameLabel")}</b>{" "}
                    {asset.campaignName ?? "—"}
                  </TypographyNormal>
                  <TypographyNormal>
                    <b>{t("asset.fileSizeLabel")}</b>
                    {` ${(asset.size / (1024 * 1024)).toFixed(2)} MB`}
                  </TypographyNormal>
                  <TypographyNormal textAlign={"center"} sx={{ mt: 2 }}>
                    {parser(t("asset.itemUsageHint"))}
                  </TypographyNormal>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <Box
        sx={{ width: "100%", height: 0 }}
        display={"flex"}
        justifyContent={"end"}
        alignItems={"center"}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={confirmCheckbox}
              onClick={(e) => {
                setConfirmCheckbox(e.target.checked);
              }}
            />
          }
          label={
            <TypographySmall sx={{ color: "red" }}>
              {parser(t("asset.confirmCheckbox"))}
            </TypographySmall>
          }
          disabled={loading}
          sx={{ m: 0, width: "384px", marginTop: "-22px" }}
        />
      </Box>

      <DialogActions>
        <Button
          startIcon={<ClearIcon />}
          fullWidth
          variant="outlined"
          disabled={loading}
          onClick={handleClose}
          sx={{ height: "38px" }}
        >
          {tCommon("cancelBtn")}
        </Button>

        <Button
          startIcon={!loading && <DoneIcon />}
          fullWidth
          variant="contained"
          disabled={!confirmCheckbox || loading}
          onClick={handleCallback}
          sx={{ height: "38px" }}
        >
          {!loading ? (
            tCommon("yesBtn")
          ) : (
            <CircularProgress size={"22px"} color="inherit" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssetDeleteConfirmation;
