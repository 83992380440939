import React, { useState } from "react";

import { Box, CircularProgress, Grid, Tooltip } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Sort as PriorityIcon,
  Timelapse as DurationIcon,
} from "@mui/icons-material";
import BackupTableTwoToneIcon from "@mui/icons-material/BackupTableTwoTone";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import LaunchIcon from "@mui/icons-material/Launch";
import ContentDetails from "../../../../components/cms/contentTemplate/detail/ContentDetails";
import { colorStyling } from "../../../../helpers/color";
import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
  TypographyXLarge,
} from "../../../../components/customComponent";
import {
  switchBackgroundColorBasedOnStatus,
  switchColorOnScheduleStatus,
} from "../../../../helpers/switchColor";
import { AdsScheduleGreenIcon, ScheduleGreyIcon } from "../../../../icons";
import { dateOnlyFormatDDMMYYYY } from "../../../../helpers/dateFormat";
import { calculateDuration } from "../../../../helpers/scheduleHelper";
import { getTimeFromCron } from "../../../../helpers/cronHelper";
import { useTranslation } from "react-i18next";

const AdvertisementScheduleInfo = ({ schedule, loading }) => {
  const { t } = useTranslation("advertschedule");
  const { t: tSchedule } = useTranslation("schedule");
  const { t: tCommon } = useTranslation();

  const [openContentDetail, setOpenContentDetail] = useState(false);
  const [hoveredContent, setHoveredContent] = useState(false);

  const handleOpenContentDetail = () => {
    setOpenContentDetail(true);
  };

  const handleCloseContentDetail = () => {
    setOpenContentDetail(false);
  };

  if (loading)
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress
          size={20}
          thickness={3}
          sx={{ color: colorStyling.primary }}
        />
        <TypographyNormal
          sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
        >
          {tCommon("loadingDataList")}
        </TypographyNormal>
      </Box>
    );

  return (
    <Box sx={{ px: 2, pb: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AdsScheduleGreenIcon />
          <Box display={"flex"} flexDirection={"column"} sx={{ ml: 2 }}>
            <Box sx={{ width: "fit-content" }}>
              <TypographySmall
                sx={{
                  borderRadius: "15px",
                  color: switchColorOnScheduleStatus(
                    schedule?.isApproved ? "APPROVED" : "NOT_APPROVED"
                  ),
                  backgroundColor: switchBackgroundColorBasedOnStatus(
                    schedule?.isApproved ? "APPROVED" : "NOT_APPROVED"
                  ),
                  fontWeight: 600,
                  px: 1,
                  py: 0.2,
                }}
              >
                {schedule?.isApproved
                  ? tSchedule("item.approvedLabel")
                  : tSchedule("item.notApprovedLabel")}
              </TypographySmall>
            </Box>

            <Tooltip
              title={schedule?.name?.length > 30 ? schedule?.name : ""}
              placement="top-start"
            >
              <TypographyXLarge sx={{ fontWeight: 700 }}>
                {schedule?.name?.length > 30
                  ? schedule?.name?.substring(0, 27).trim() + "..."
                  : schedule?.name}
              </TypographyXLarge>
            </Tooltip>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TypographySmall
            sx={{
              borderRadius: "15px",
              p: 1,
              color: switchColorOnScheduleStatus(
                schedule?.isActive ? "ACTIVE" : "PENDING"
              ),
              backgroundColor: switchBackgroundColorBasedOnStatus(
                schedule?.isActive ? "ACTIVE" : "PENDING"
              ),
              fontWeight: 600,
              mr: 4,
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color: switchColorOnScheduleStatus(
                  schedule?.isActive ? "ACTIVE" : "PENDING"
                ),
                fontSize: 8,
                mr: "5px",
              }}
            />
            {schedule?.isActive
              ? tSchedule("item.activeLabel")
              : tSchedule("item.inactiveLabel")}
          </TypographySmall>
        </Box>
      </Box>

      <Box sx={{ pl: 2, pr: 1 }}>
        <Grid container spacing={2} sx={{ my: 1 }}>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ScheduleGreyIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  {t("startDateLabel")}
                </TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {dateOnlyFormatDDMMYYYY(schedule.startAt)}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ScheduleGreyIcon />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  {t("endDateLabel")}
                </TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {schedule.endAt
                    ? dateOnlyFormatDDMMYYYY(schedule.endAt)
                    : tSchedule("item.endTimeIndefinitely")}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <WatchLaterIcon sx={{ color: colorStyling.gray.normal }} />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  {t("displayTimeLabel")}
                </TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {getTimeFromCron(schedule.cron, schedule.cronTimeZone) +
                    " - " +
                    getTimeFromCron(
                      schedule.cron,
                      schedule.cronTimeZone,
                      schedule.duration
                    )}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Tooltip title={t("contentDetailHint")} placement="top-start">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={handleOpenContentDetail}
                onMouseEnter={() => setHoveredContent(true)}
                onMouseLeave={() => setHoveredContent(false)}
              >
                <BackupTableTwoToneIcon
                  sx={{ color: colorStyling.gray.normal }}
                />
                <Box sx={{ ml: 2 }}>
                  <TypographyNormal sx={{ fontWeight: 300 }}>
                    {t("contentLabel")}
                  </TypographyNormal>
                  <TypographyLarge
                    sx={{
                      ...(hoveredContent && {
                        color: colorStyling.primary,
                        textDecoration: "underline",
                      }),
                      fontWeight: 600,
                    }}
                  >
                    <Tooltip
                      title={
                        schedule?.content?.name?.length > 30
                          ? schedule?.content?.name
                          : ""
                      }
                      placement="top-start"
                    >
                      {schedule?.content?.name?.length > 30
                        ? schedule?.content?.name?.substring(0, 27).trim() +
                          "..."
                        : schedule?.content?.name}
                    </Tooltip>
                    <LaunchIcon
                      fontSize="xs"
                      sx={{
                        color: hoveredContent
                          ? colorStyling.primary
                          : colorStyling.gray.label,
                      }}
                    />
                  </TypographyLarge>
                </Box>
              </Box>
            </Tooltip>
            <ContentDetails
              open={openContentDetail}
              handleClose={handleCloseContentDetail}
              contentID={schedule?.contentId}
            />
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <DurationIcon sx={{ color: colorStyling.gray.normal }} />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  {t("slotLabel")}
                </TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {calculateDuration(+schedule.advertDuration)}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PriorityIcon sx={{ color: colorStyling.gray.normal }} />

              <Box sx={{ ml: 2 }}>
                <TypographyNormal sx={{ fontWeight: 300 }}>
                  {t("priorityLabel")}
                </TypographyNormal>
                <TypographyLarge sx={{ fontWeight: 600 }}>
                  {schedule?.priority?.name}
                </TypographyLarge>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdvertisementScheduleInfo;
