import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@mui/material";
import { SET_ERROR_PROMPT_ASSET_PAGE } from "../../../store/actionTypes/assetActionType";

const AssetsManagerErrorPrompt = () => {
  const dispatch = useDispatch();
  const { t: tCommon } = useTranslation();

  const {
    isErrorPromptAssetPage: isError,
    errorMessageAssetPage: errorMessage,
  } = useSelector((state) => state.asset);

  const handleClose = (e, reason) => {
    dispatch({ type: SET_ERROR_PROMPT_ASSET_PAGE, payload: false });
  };

  return (
    isError && (
      <Alert
        sx={{ my: 2 }}
        severity="error"
        variant="filled"
        onClose={handleClose}
      >
        <AlertTitle>{tCommon("errorPromptTitle")}</AlertTitle>
        {errorMessage}
      </Alert>
    )
  );
};

export default AssetsManagerErrorPrompt;
