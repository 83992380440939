export const SET_SMP_DISRUPTION = "SET_SMP_DISRUPTION";
export const SET_SMP_STATION = "SET_SMP_STATION";
export const SET_LOADING_SMP_DISRUPTION = "SET_LOADING_SMP_DISRUPTION";
export const SET_LOADING_ANNOUNCEMENT_SMP_DISRUPTION =
  "SET_LOADING_ANNOUNCEMENT_SMP_DISRUPTION";
export const SET_LOADING_LINK_SMP_DISRUPTION =
  "SET_LOADING_LINK_SMP_DISRUPTION";
export const SET_ERROR_PROMPT_SMP_DISRUPTION =
  "SET_ERROR_PROMPT_SMP_DISRUPTION";
export const SET_ERROR_MESSAGE_SMP_DISRUPTION =
  "SET_ERROR_MESSAGE_SMP_DISRUPTION";
export const SET_ERROR_PROMPT_ANNOUNCEMENT_SMP_DISRUPTION =
  "SET_ERROR_PROMPT_ANNOUNCEMENT_SMP_DISRUPTION";
export const SET_ERROR_MESSAGE_ANNOUNCEMENT_SMP_DISRUPTION =
  "SET_ERROR_MESSAGE_ANNOUNCEMENT_SMP_DISRUPTION";
export const SET_SUCCESS_PROMPT_SMP_DISRUPTION =
  "SET_SUCCESS_PROMPT_SMP_DISRUPTION";
export const SET_SUCCESS_MESSAGE_SMP_DISRUPTION =
  "SET_SUCCESS_MESSAGE_SMP_DISRUPTION";
export const SET_EDIT_SMP_LINK_ANNOUNCEMENT = "SET_EDIT_SMP_LINK_ANNOUNCEMENT";
export const SET_UPDATE_STATION = "SET_UPDATE_STATION";
