import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UnpublishedIcon from "@mui/icons-material/Unpublished";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import {
  approveSchedule,
  unapproveSchedule,
} from "../../../store/actions/scheduleAction";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";

const ApproveSchedule = ({ schedule }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("schedule");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // TODO for approval
    // const data = {
    //   id: schedule.id,
    //   payload: {
    //     name: schedule.name,
    //     type: schedule.type,
    //     command: schedule.command,
    //     startAt: schedule.startAt,
    //     endAt: schedule.endAt,
    //     cron: schedule.cron,
    //     duration: schedule.duration,
    //     priority: schedule.priority.value,
    //     contentId: schedule.contentId,
    //     organizationId: schedule.organizationId,
    //   },
    // };

    dispatch(
      schedule.isApproved
        ? unapproveSchedule(schedule.id)
        : approveSchedule(schedule.id)
    );
    setOpen(false);
  };
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        color={schedule.isApproved ? "warning" : "success"}
        variant="contained"
        startIcon={
          schedule.isApproved ? (
            <UnpublishedIcon fontSize="small" />
          ) : (
            <CheckCircleIcon fontSize="small" />
          )
        }
        sx={{ mr: 2, boxShadow: 3 }}
      >
        {schedule.isApproved ? t("item.suspendBtn") : t("item.approveBtn")}
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            {schedule.isApproved
              ? parser(
                  parseVariables(t("item.suspendConfirmationText"), {
                    name: schedule.name,
                  })
                )
              : parser(
                  parseVariables(t("item.approveConfirmationText"), {
                    name: schedule.name,
                  })
                )}
          </DialogContentText>

          <Box>
            <Alert severity="warning" variant="outlined">
              <strong>
                {schedule.isApproved
                  ? t("item.suspendWarningHint")
                  : t("item.approveWarningHint")}
              </strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            color={schedule.isApproved ? "warning" : "primary"}
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            onClick={handleSubmit}
          >
            {tCommon("continueBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ApproveSchedule;
