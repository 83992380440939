import { isEmpty } from "lodash";
import {
  SET_ANNOUNCEMENT,
  SET_ANNOUNCEMENT_DETAIL,
  SET_DELETE_ANNOUNCEMENT,
  SET_LOADING_ANNOUNCEMENT,
  SET_NEW_ANNOUNCEMENT,
  SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE,
  SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE,
  SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE,
  SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE,
  SET_ANNOUNCEMENT_FILTER,
  SET_ANNOUNCEMENT_PAGE,
  SET_ANNOUNCEMENT_ROWS_PER_PAGE,
  SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG,
  SET_ERROR_MESSAGE_ANNOUNCEMENT_DIALOG,
  SET_PREFETCH_ANNOUNCEMENT,
  SET_EDIT_ANNOUNCEMENT,
  SET_ANNOUNCEMENT_CONTENT,
  SET_ANNOUNCEMENT_ASSIGNED_GROUP,
  SET_ANNOUNCEMENT_ASSIGNED_VIEWER,
} from "../actionTypes/announcementActionType";

const initialState = {
  announcements: { items: [], totalItem: 0 },
  announcementFilters: { sortBy: "isActiveDesc" },
  loadingAnnouncement: false,
  announcement: {},
  page: 0,
  rowsPerPage: 5,
  isSuccessPromptAnnouncementPage: false,
  isErrorPromptAnnouncementPage: false,
  successMessageAnnouncementPage: "",
  errorMessageAnnouncementPage: "",
  isErrorPromptAnnouncementDialog: false,
  errorMessageAnnouncementDialog: "",
};

export const announcementReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_ANNOUNCEMENT:
      const initialAnnouncementState = state.announcements;
      let newAnnouncementData = [];
      payload.items.forEach((g) => {
        const foundAnnouncement = initialAnnouncementState?.items?.find(
          (ig) => ig.id === g.id
        );
        newAnnouncementData.push({
          ...g,
          content: foundAnnouncement?.content ?? g.content,
          screens: foundAnnouncement?.screens,
          screenGroups: foundAnnouncement?.screenGroups,
        });
      });
      return {
        ...state,
        announcements: {
          totalItem: payload.totalItem,
          items: newAnnouncementData,
        },
      };

    case SET_ANNOUNCEMENT_FILTER:
      return { ...state, announcementFilters: payload };

    case SET_ANNOUNCEMENT_PAGE:
      return { ...state, page: payload };

    case SET_ANNOUNCEMENT_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: payload };

    case SET_LOADING_ANNOUNCEMENT:
      return { ...state, loadingAnnouncement: payload };

    case SET_NEW_ANNOUNCEMENT:
      const listAnnouncement =
        state.page === 0
          ? [payload, ...state.announcements.items]
          : [
              ...state.announcements.items.slice(
                0,
                state.page * state.rowsPerPage
              ),
              payload,
              ...state.announcements.items.slice(
                state.page * state.rowsPerPage
              ),
            ];
      return {
        ...state,
        announcements: {
          items: listAnnouncement,
          totalItem: state.announcements.totalItem + 1,
        },
      };

    case SET_PREFETCH_ANNOUNCEMENT:
      let addedPrefetchAnnouncements = [
        ...state.announcements.items,
        ...payload.items,
      ];
      return {
        ...state,
        announcements: {
          items: addedPrefetchAnnouncements,
          totalItem: payload.totalItem,
        },
      };

    case SET_ANNOUNCEMENT_DETAIL:
      const ad = state.announcements.items.find((obj) => obj.id === payload.id);
      if (!isEmpty(ad)) {
        ad["screenGroups"] = payload.screenGroups;
        ad["screens"] = payload.screens;
      }
      return {
        ...state,
        announcement: {
          ...state.announcement,
          [payload.id]: {
            ...(state.announcement[payload.id] ?? {}),
            ...payload,
          },
        },
      };

    case SET_EDIT_ANNOUNCEMENT:
      const data = state.announcements;
      const objIndex = data.findIndex((obj) => obj.id === payload.id);
      data[objIndex] = payload;
      return { ...state, announcements: [...data] };

    case SET_DELETE_ANNOUNCEMENT:
      const announcementsState = state.announcements.items;
      const filtered = announcementsState.filter(
        (announcement) => announcement.id.toString() !== payload.toString()
      );
      return {
        ...state,
        announcements: {
          items: filtered,
          totalItem: state.announcements.totalItem - 1,
        },
      };
    case SET_ANNOUNCEMENT_ASSIGNED_GROUP:
      const assignedGroupData = state?.announcements?.items;
      const objIndexG = assignedGroupData?.findIndex(
        (obj) => obj.id === payload.id
      );
      if (objIndexG !== -1)
        assignedGroupData[objIndexG].screenGroups = payload?.screenGroups;
      return {
        ...state,
        announcements: {
          totalItem: state.announcements.totalItem,
          items: [...assignedGroupData],
        },
        ...(state.announcement.id === payload.id && {
          announcement: {
            ...state.announcement,
            screenGroups: payload.screenGroups,
          },
        }),
      };

    case SET_ANNOUNCEMENT_ASSIGNED_VIEWER:
      const assignedViewerData = state.announcements.items;
      const objIndexV = assignedViewerData.findIndex(
        (obj) => obj.id === payload.id
      );
      if (objIndexV !== -1)
        assignedViewerData[objIndexV].screens = payload.screens;
      return {
        ...state,
        announcements: {
          totalItem: state.announcements.totalItem,
          items: [...assignedViewerData],
        },
        ...(state.announcement.id === payload.id && {
          announcement: { ...state.announcement, screens: payload.screens },
        }),
      };

    case SET_ANNOUNCEMENT_CONTENT:
      const contentAddingData = state.announcements.items.map((obj) => {
        return obj.contentId === payload.id
          ? { ...obj, content: payload.content }
          : obj;
      });
      return {
        ...state,
        announcements: {
          totalItem: state.announcements.totalItem,
          items: [...contentAddingData],
        },
      };

    case SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE:
      return { ...state, isSuccessPromptAnnouncementPage: payload };

    case SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE:
      return { ...state, successMessageAnnouncementPage: payload };

    case SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE:
      return { ...state, isErrorPromptAnnouncementPage: payload };

    case SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE:
      return { ...state, errorMessageAnnouncementPage: payload };

    case SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG:
      return { ...state, isErrorPromptAnnouncementDialog: payload };

    case SET_ERROR_MESSAGE_ANNOUNCEMENT_DIALOG:
      return { ...state, errorMessageAnnouncementDialog: payload };

    default:
      return state;
  }
};
