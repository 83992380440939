import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@mui/material";
import { SET_SUCCESS_PROMPT_VIEWER_PAGE } from "../../store/actionTypes/viewerActionTypes";

const ViewerSuccessPrompt = () => {
  const dispatch = useDispatch();
  const {
    isSuccessPromptViewerPage: isSuccess,
    successMessageViewerPage: successMessage,
  } = useSelector((state) => state.viewer);

  const { t: tCommon } = useTranslation();

  const handleClose = (event, reason) => {
    dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: false });
  };

  return (
    isSuccess && (
      <Alert
        sx={{ my: 2 }}
        severity="success"
        variant="filled"
        onClose={handleClose}
      >
        <AlertTitle>{tCommon("successPromptTitle")}</AlertTitle>
        {successMessage}
      </Alert>
    )
  );
};

export default ViewerSuccessPrompt;
