// Enum: "SLIDE_FROM_TOP"
export const SlideInFromTop = {
  id: "slideInFromTop",
  label: "Slide In From Top",
  speed: 2000,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "top center",
      translate: [0, "-100%", 10],
    },
  },
};
