import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { CustomTextField, TypographySmall } from "../../customComponent";
import { editGroup } from "../../../store/actions/groupAction";
import { colorStyling } from "../../../helpers/color";
import DispatchErrorPromptAlert from "../../DispatchErrorPromptAlert";
import { SET_ERROR_PROMPT_GROUP_DIALOG } from "../../../store/actionTypes/groupActionType";
import { parseVariables } from "../../../helpers/localize";

const EditGroup = ({ group }) => {
  const dispatch = useDispatch();
  const {
    isErrorPromptGroupDialog: isError,
    errorMessageGroupDialog: errorMessage,
  } = useSelector((state) => state.group);

  const { t } = useTranslation("group");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState(group.name);
  const [validation, setValidation] = useState("");

  const handleClickClose = () => {
    setOpen(false);
    setName("");
    setValidation("");
    dispatch({ type: SET_ERROR_PROMPT_GROUP_DIALOG, payload: false });
  };

  const handleInputName = (input) => {
    if (input.length > 40)
      setValidation(
        parseVariables(tCommon("validation.maxLengthChars"), {
          num: 40,
        })
      );
    else {
      setName(input);
      if (validation) setValidation("");
    }
  };

  const handleFinishSubmit = (stateSubmit) => {
    setLoading(false);
    if (stateSubmit === "success") {
      handleClickClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name.trim() === "") {
      setValidation(t("validation.nameEmpty"));
      return;
    }

    if (name !== group.name) {
      const payload = { id: group.id, data: { name: name.trim() }, group };

      setLoading(true);
      dispatch(editGroup(payload, handleFinishSubmit));
    } else {
      handleClickClose();
    }
  };

  useEffect(() => {
    if (open) setName(group.name);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Button
        startIcon={<EditIcon fontSize="small" />}
        variant="contained"
        sx={{ boxShadow: 3, mx: 2 }}
        onClick={() => setOpen(true)}
      >
        {t("item.editBtn")}
      </Button>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        keepMounted
        onClose={handleClickClose}
      >
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {t("form.editDialogTitle")}
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider />

          <DialogContent>
            <DispatchErrorPromptAlert
              isError={isError}
              errorMessage={errorMessage}
              closeActionType={SET_ERROR_PROMPT_GROUP_DIALOG}
            />

            <Box sx={{ mt: 1 }}>
              <CustomTextField
                fullWidth
                label={t("form.nameLabel")}
                margin="dense"
                name="name"
                value={name}
                onChange={(e) => handleInputName(e.target.value)}
                placeholder={t("form.namePlaceholder")}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                disabled={loading}
              />
              <TypographySmall sx={{ color: colorStyling.red }}>
                {validation}
              </TypographySmall>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button fullWidth variant="outlined" onClick={handleClickClose}>
              {tCommon("cancelBtn")}
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : tCommon("saveBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default EditGroup;
