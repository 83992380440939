import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import UploadField, { dropzoneStyle } from "../../mediaPicker/UploadField";
import { colorStyling } from "../../../../helpers/color";
import { v4 as uuidv4 } from "uuid";
import parser from "html-react-parser";

const DirectUpload = ({
  resType,
  acceptedFiles,
  isDragActive,
  uploadBoxActive,
  setUploadBoxActive,
  loadingFile,
  setLoadingFile,
}) => {
  const { t } = useTranslation("cms");

  const [container, setContainer] = useState({});
  const [contents, setContents] = useState([]);
  const [uploadedURLs, setUploadedURLs] = useState([]);

  useEffect(() => {
    if (uploadBoxActive) {
      setUploadedURLs([]);
      setContainer({ id: uuidv4() });
    } else {
      setContainer({});
    }
  }, [uploadBoxActive]);

  const closeUploadUI = () => {
    setUploadBoxActive(false);
  };

  const addUploadedURLs = (urlArray, reset = false) => {
    if (reset) {
      setUploadedURLs([]);
      return;
    }

    const updated = [...uploadedURLs, ...urlArray];
    setUploadedURLs(updated);
  };

  if (resType.length === 0) {
    return <></>;
  }

  return (
    <>
      {uploadBoxActive && (
        <Dialog open={true} maxWidth="lg" fullWidth disableEscapeKeyDown={true}>
          <DialogTitle
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {t("designer.directUploadTitle")}
          </DialogTitle>

          <DialogContent sx={{ minHeight: "55vh" }}>
            <UploadField
              setOnPage={() => {
                return;
              }}
              closeMediaPicker={closeUploadUI}
              closeUploadUI={closeUploadUI}
              container={container}
              contentState={[contents, setContents]}
              setLoadingFile={setLoadingFile}
              uploadedURLs={uploadedURLs}
              addUploadedURLs={addUploadedURLs}
              directFlag={true}
              directFiles={acceptedFiles}
            />
          </DialogContent>
        </Dialog>
      )}

      {isDragActive && (
        <Box display={"flex"} sx={{}}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ ...dropzoneStyle, minHeight: "700px" }}>
              <img
                style={{ width: "200px", height: "200px" }}
                src="/assets/multimedia-icon.png"
                alt={t("designer.uploadZoneAlt")}
              />
              {parser(t("designer.uploadZoneTexts"))}
            </Box>
          </Box>
        </Box>
      )}

      <Backdrop
        sx={{
          color: colorStyling.white.inactive,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loadingFile}
        onClick={null}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default DirectUpload;
