import axios, { initiateAXIOS } from "../../config/axios";
import { handleError } from "../handleError";
import {
  SET_DELETE_ORGANIZATION,
  SET_ORGANIZATION,
  SET_EDIT_ORGANIZATION,
  SET_ERROR_MESSAGE_ORGANIZATION_PAGE,
  SET_ERROR_PROMPT_ORGANIZATION_PAGE,
  SET_LOADING_ORGANIZATION,
  SET_NEW_ORGANIZATION,
  SET_SUCCESS_MESSAGE_ORGANIZATION_PAGE,
  SET_SUCCESS_PROMPT_ORGANIZATION_PAGE,
} from "../actionTypes/organizationActionType";
import { API_LIMIT, initiateOrganizationAPI } from "../../api";

const handleTimeoutSuccessPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_SUCCESS_PROMPT_ORGANIZATION_PAGE, payload: false });
  }, 3000);
};

const handleTimeoutErrorPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_ERROR_PROMPT_ORGANIZATION_PAGE, payload: false });
  }, 5000);
};

export const getOrganizations = () => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    dispatch({ type: SET_LOADING_ORGANIZATION, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateOrganizationAPI + `?limit=${API_LIMIT}&sortBy=idAsc`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      const listOfOrganizations = data.items;

      let organizations = [];

      listOfOrganizations.map((item) => organizations.push(item));
      dispatch({ type: SET_ORGANIZATION, payload: organizations });
    } catch (e) {
      const errorMessage = handleError(e);
      dispatch({
        type: SET_ERROR_PROMPT_ORGANIZATION_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_ORGANIZATION_PAGE,
        payload: errorMessage,
      });
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || errorMessage);
      }
    } finally {
      dispatch({ type: SET_LOADING_ORGANIZATION, payload: false });
    }
  };
};

export const filterOrganizations = (query) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    dispatch({ type: SET_LOADING_ORGANIZATION, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateOrganizationAPI + `?limit=${API_LIMIT}` + query,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      const listOfOrganizations = data.items;

      let organizations = [];

      listOfOrganizations.map((item) => organizations.push(item));
      dispatch({ type: SET_ORGANIZATION, payload: organizations });
    } catch (e) {
      const errorMessage = handleError(e);
      dispatch({
        type: SET_ERROR_PROMPT_ORGANIZATION_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_ORGANIZATION_PAGE,
        payload: errorMessage,
      });
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || errorMessage);
      }
    } finally {
      dispatch({ type: SET_LOADING_ORGANIZATION, payload: false });
    }
  };
};

export const createOrganization = (payload) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    dispatch({ type: SET_LOADING_ORGANIZATION, payload: true });

    try {
      const { data } = await initiateAXIOS.post(
        initiateOrganizationAPI,
        { ...payload, featuresBlacklist: [] },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_NEW_ORGANIZATION,
        payload: data,
      });

      dispatch({
        type: SET_SUCCESS_PROMPT_ORGANIZATION_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ORGANIZATION_PAGE,
        payload: "A new organization has been created successfully.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({
        type: SET_ERROR_PROMPT_ORGANIZATION_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_ORGANIZATION_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ORGANIZATION, payload: false });
    }
  };
};

export const editOrganization = ({ id, payload }) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    dispatch({ type: SET_LOADING_ORGANIZATION, payload: true });

    try {
      const { data } = await initiateAXIOS.patch(
        initiateOrganizationAPI + id,
        payload,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      const OOP = data;
      dispatch({ type: SET_EDIT_ORGANIZATION, payload: OOP });
      dispatch({
        type: SET_SUCCESS_PROMPT_ORGANIZATION_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ORGANIZATION_PAGE,
        payload: "The data is updated.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({
        type: SET_ERROR_PROMPT_ORGANIZATION_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_ORGANIZATION_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ORGANIZATION, payload: false });
    }
  };
};

export const deleteOrganization = (id) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    dispatch({ type: SET_LOADING_ORGANIZATION, payload: true });

    try {
      await axios({
        method: "DELETE",
        url: initiateOrganizationAPI + id,
        headers: { authorization: `Bearer ${access_token}` },
      });
      dispatch({ type: SET_DELETE_ORGANIZATION, payload: id });
      dispatch({
        type: SET_SUCCESS_PROMPT_ORGANIZATION_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_SUCCESS_MESSAGE_ORGANIZATION_PAGE,
        payload: "The organization is deleted.",
      });

      handleTimeoutSuccessPrompt(dispatch);
    } catch (e) {
      const message = handleError(e);
      dispatch({
        type: SET_ERROR_PROMPT_ORGANIZATION_PAGE,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_ORGANIZATION_PAGE,
        payload: message,
      });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_ORGANIZATION, payload: false });
    }
  };
};
