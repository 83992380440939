// Enum: "EXPAND_FROM_BOTTOM_LEFT"
export const ExpandFromBottomLeft = {
  id: "expandFromBottomLeft",
  label: "Expand From Bottom Left",
  speed: 2200,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "bottom left",
      scale: 0.1,
      translate: ["-10%", "50%", 0],
    },
  },
};
