export const SET_LOADING_GROUP = "SET_LOADING_GROUP";
export const SET_GROUP = "SET_GROUP";
export const SET_GROUP_FILTER = "SET_GROUP_FILTER";
export const SET_NEW_GROUP = "SET_NEW_GROUP";
export const SET_DELETE_GROUP = "SET_DELETE_GROUP";
export const SET_EDIT_GROUP = "SET_EDIT_GROUP";
export const SET_DETAIL_GROUP = "SET_DETAIL_GROUP";
export const SET_SUCCESS_PROMPT_GROUP_PAGE = "SET_SUCCESS_PROMPT_GROUP_PAGE";
export const SET_ERROR_PROMPT_GROUP_PAGE = "SET_ERROR_PROMPT_GROUP_PAGE";
export const SET_SUCCESS_MESSAGE_GROUP_PAGE = "SET_SUCCESS_MESSAGE_GROUP_PAGE";
export const SET_ERROR_MESSAGE_GROUP_PAGE = "SET_ERROR_MESSAGE_GROUP_PAGE";
export const SET_ERROR_PROMPT_GROUP_DIALOG = "SET_ERROR_PROMPT_GROUP_DIALOG";
export const SET_ERROR_MESSAGE_GROUP_DIALOG = "SET_ERROR_MESSAGE_GROUP_DIALOG";
export const SET_GROUP_PAGE = "SET_GROUP_PAGE";
export const SET_GROUP_ROWS_PER_PAGE = "SET_GROUP_ROWS_PER_PAGE";
export const SET_GROUP_SCHEDULE = "SET_GROUP_SCHEDULE";
export const SET_GROUP_VIEWER = "SET_GROUP_VIEWER";
export const SET_PREFETCH_GROUP = "SET_PREFETCH_GROUP";
