import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { Clear, Done } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveCircleOutlined from "@mui/icons-material/RemoveCircleOutlineOutlined";
import ViewDetailViewer from "../viewDetails/viewer/ViewDetailViewer";
import { red } from "@mui/material/colors";
import {
  removeScheduleFromGroup,
  removeViewerFromGroup,
} from "../../store/actions/groupAction";
import {
  removeGroupFromSchedule,
  removeViewerFromSchedule,
} from "../../store/actions/scheduleAction";
import {
  removeGroupFromAnnouncement,
  removeViewerFromAnnouncement,
} from "../../store/actions/announcementAction";
import {
  removeGroupFromViewer,
  removeScheduleFromViewer,
} from "../../store/actions/viewerAction";
import { colorStyling } from "../../helpers/color";
import { parseVariables } from "../../helpers/localize";
import parser from "html-react-parser";

const AssignedComponentActionMenu = ({ type, item, sourceData }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClickOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClickClose = (e) => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={{ color: colorStyling.black }} onClick={handleClickOpen}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClickClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <DetailsMenu item={item} type={type} />
        {!sourceData.isApproved === true && sourceData.editPermission && (
          <RemoveMenu item={item} type={type} sourceData={sourceData} />
        )}
      </Menu>
    </>
  );
};

export default AssignedComponentActionMenu;

const DetailsMenu = ({ type, item }) => {
  const navigate = useNavigate();
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    switch (type) {
      case "group":
        navigate("/group/detail/" + item.id);
        break;

      case "schedule":
        switch (item.type) {
          case "ANNOUNCEMENT":
            navigate("/announcement/detail/" + item.id);
            break;
          case "ADVERTISEMENT":
            navigate("/rotating-schedule/detail/" + item.id);
            break;

          default:
            navigate("/schedule/detail/" + item.id);
            break;
        }
        break;

      default:
        setOpen(true);
        break;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <MenuItem dense onClick={handleOpen}>
        <InfoOutlinedIcon fontSize="small" sx={{ marginRight: "5px" }} />
        {tCommon("assign.viewBtn")}
      </MenuItem>

      {type === "viewer" && (
        <ViewDetailViewer
          openDetail={open}
          viewer={item}
          onClose={handleClose}
        />
      )}
    </>
  );
};

const RemoveMenu = ({ type, item, sourceData }) => {
  const dispatch = useDispatch();
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    const payload = { id: sourceData.id };
    let apiCall;

    switch (type) {
      case "viewer":
        switch (sourceData.type) {
          case "group":
            apiCall = removeViewerFromGroup;
            break;

          case "schedule":
            apiCall = removeViewerFromSchedule;
            break;

          case "announcement":
            apiCall = removeViewerFromAnnouncement;
            break;

          default:
            break;
        }
        break;

      case "schedule":
        switch (sourceData.type) {
          case "group":
            apiCall = removeScheduleFromGroup;
            break;
          case "viewer":
            apiCall = removeScheduleFromViewer;
            break;

          default:
            break;
        }
        break;

      case "group":
        switch (sourceData.type) {
          case "schedule":
            apiCall = removeGroupFromSchedule;
            break;
          case "announcement":
            apiCall = removeGroupFromAnnouncement;
            break;

          case "viewer":
            apiCall = removeGroupFromViewer;
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
    payload["remove"] = [{ id: +item.id }];
    dispatch(apiCall(payload));
    handleClose();
  };

  return (
    <>
      <MenuItem sx={{ color: red[600] }} dense onClick={handleOpen}>
        <RemoveCircleOutlined fontSize="small" sx={{ marginRight: "5px" }} />
        {tCommon("removeBtn")}
      </MenuItem>

      <Dialog maxWidth="sm" open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText
            gutterBottom
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            {parser(
              parseVariables(tCommon("assign.removeDialogText"), {
                type: type,
                name: item.name,
                source: sourceData.name,
                sourceType: sourceData.type,
              })
            )}
          </DialogContentText>

          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Alert severity="warning" variant="outlined">
              <strong>{tCommon("permanentActionHint")}</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            startIcon={<Clear />}
            onClick={handleClose}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            variant="contained"
            color="error"
            fullWidth
            startIcon={<Done />}
            onClick={handleRemove}
          >
            {tCommon("yesRemoveBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
