import { getFrequency, setPriorityName } from "../helpers/scheduleHelper";

export default class Schedule {
  constructor(payload) {
    this.id = payload.id;
    this.isActive = payload.isActive;
    this.isApproved = payload.isApproved;
    this.name = payload.name;
    this.type = payload.type ?? "";
    this.command = payload.command;
    this.createdAt = payload.createdAt;
    this.startAt = payload.startAt;
    this.endAt = payload.endAt;
    this.frequency = getFrequency(payload.repetition, payload.cron);
    this.cron = payload.cron;
    this.cronTimeZone = payload.cronTimeZone;
    this.duration = payload.duration;
    this.advertDuration = payload.advertDuration;
    this.priority = {
      value: payload.priority,
      name: setPriorityName(+payload.priority),
    };
    this.content = payload.content;
    this.contentId = payload.contentId;
    this.screenGroups = payload.screenGroups;
    this.screens = payload.screens;
    this.organizationId = payload.organizationId;
  }
}
