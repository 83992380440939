import { Box, Container, CssBaseline, Paper, Toolbar } from "@mui/material";
import { TypographyLarge, TypographyXLarge } from "../customComponent";

import Sidebar from "../sidebar/Sidebar";
import { useSelector } from "react-redux";
import { decryptUser } from "../../helpers/crypto";
import Role from "../../helpers/roles";
import OrganizationList from "./OrganizationList";
import CreateOrganization from "./CreateOrganization";
import OrganizationErrorPrompt from "./OrganizationErrorPrompt";
import OrganizationSuccessPrompt from "./OrganizationSuccessPrompt";
import OrganizationFilter from "./OrganizationFilter";
import { useTranslation } from "react-i18next";

const Organization = () => {
  const { loggedUser } = useSelector((state) => state.auth);

  const decryptedLoggedUser = decryptUser(loggedUser);
  const userRole = decryptedLoggedUser.type;

  const { t } = useTranslation("organization");

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
            <TypographyLarge sx={{ fontWeight: 400 }}>
              {t("titleExplain")}
            </TypographyLarge>
          </Box>

          <OrganizationErrorPrompt />
          <OrganizationSuccessPrompt />

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <CreateOrganization />
          </Box>

          {userRole === Role.Eins && (
            <Paper
              component="section"
              elevation={2}
              sx={{ p: 2, my: 2, borderRadius: "6px" }}
            >
              <OrganizationFilter />
            </Paper>
          )}

          <Box
            component="section"
            elevation={2}
            sx={{ borderRadius: "6px", my: 2 }}
          >
            <OrganizationList />
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default Organization;
