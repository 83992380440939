import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import AssetsManagerZoom from "./AssetsManagerZoom";
import { colorStyling } from "../../../helpers/color";
import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
} from "../../customComponent";
import { splitFileName } from "../../../helpers/fileProcessing";
import parser from "html-react-parser";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const AssetsManagerViewAsset = ({
  asset,
  assetType,
  updatePermission,
  handleUpdate,
}) => {
  const { loadingUpdate } = useSelector((state) => state.asset);

  const { t } = useTranslation("assetmanager");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const [viewAsset, setViewAsset] = useState(false);
  const [newName, setNewName] = useState("");
  const [newCampaignName, setNewCampaignName] = useState("");
  const [editName, setEditName] = useState(false);
  const [editCampaignName, setEditCampaignName] = useState(false);
  const [currentlyEditing, setCurrentlyEditing] = useState(false);

  const assetName = useMemo(() => {
    const split = splitFileName(asset.name);
    return split;
  }, [asset.name]);

  const handleCallback = (event) => {
    event.preventDefault();
    const payload = { id: asset.id };
    if (editName && newName.length > 0) {
      payload["name"] = `${newName}.${assetName.extension}`;
    }
    if (editCampaignName && newCampaignName.length > 0) {
      payload["campaignName"] = newCampaignName;
    }
    handleUpdate(payload, handleClose);
  };

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setViewAsset(false);
    setNewName("");
    setNewCampaignName("");
    setEditName(false);
    setEditCampaignName(false);
    setCurrentlyEditing(false);
  };

  const handleChangeName = (event) => {
    const value = event.target.value;
    setNewName(value);
    if (value === "" && !editCampaignName) {
      setCurrentlyEditing(false);
    } else {
      setCurrentlyEditing(true);
    }
  };

  const handleChangeCampaignName = (event) => {
    const value = event.target.value;
    setNewCampaignName(value);
    if (value === "" && !editName) {
      setCurrentlyEditing(false);
    } else {
      setCurrentlyEditing(true);
    }
  };

  const handleEditToggle = (which, value) => {
    let nameFlag;
    switch (which) {
      case "name":
        nameFlag = true;
        break;

      case "campaignName":
        nameFlag = false;
        break;

      default:
        break;
    }

    if (!value) {
      if (nameFlag) {
        if (newCampaignName.length === 0) {
          setCurrentlyEditing(false);
        }
      } else {
        if (newName.length === 0) {
          setCurrentlyEditing(false);
        }
      }
    } else {
      if (nameFlag) {
        if (newName !== assetName.name && newName.length > 0) {
          setCurrentlyEditing(true);
        }
      } else {
        if (
          newCampaignName !== (asset.campaignName ?? "") &&
          newCampaignName.length > 0
        ) {
          setCurrentlyEditing(true);
        }
      }
    }

    if (nameFlag) {
      setEditName(value);
    } else {
      setEditCampaignName(value);
    }
  };

  return (
    <>
      <Tooltip
        arrow
        disableInteractive
        title={
          <TypographySmall sx={{ color: "white", textAlign: "center" }}>
            {t("asset.viewBtn")}
          </TypographySmall>
        }
      >
        <Box>
          <IconButton
            variant="contained"
            disabled={!(assetType.isImage || assetType.isVideo)}
            onClick={() => {
              handleOpen();
            }}
            sx={{
              color: colorStyling.primary,
            }}
          >
            <PreviewIcon />
          </IconButton>
        </Box>
      </Tooltip>

      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={() => (!loadingUpdate ? handleClose() : null)}
      >
        <Box component="form" onSubmit={handleCallback}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tooltip
              title={assetName.name.length > 20 ? assetName.name : ""}
              placement="top-end"
            >
              <Box>
                {`${t("asset.previewAssetPrefix")} [${
                  assetName.name?.length > 20
                    ? assetName.name.substring(0, 19) +
                      `...${assetName.extension}`
                    : asset.name
                }]`}
              </Box>
            </Tooltip>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <AssetsManagerZoom
              open={viewAsset}
              closeDialog={() => setViewAsset(false)}
              asset={asset}
              assetType={assetType}
            />

            <Box display={"flex"} sx={{ px: 1, py: 2 }}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
              >
                <Link
                  href="#"
                  underline="none"
                  component={"div"}
                  onClick={() => setViewAsset(true)}
                  sx={{
                    width: "333px",
                    position: "relative",
                    cursor: "pointer",
                    ":hover": {
                      opacity: 0.9,
                    },
                  }}
                >
                  {asset && (
                    <img
                      alt={asset.name}
                      style={{
                        width: "100%",
                        height: "222px",
                      }}
                      src={asset?.thumbnailUrl ?? asset?.url}
                    />
                  )}
                  {assetType.isVideo && (
                    <PlayCircleIcon
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: colorStyling.white.full,
                        fontSize: "77px",
                      }}
                    />
                  )}
                </Link>
                <Box display={"flex"} justifyContent={"center"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    onClick={() => setViewAsset(true)}
                    sx={{
                      ":hover": {
                        cursor: "pointer",
                        textDecoration: "underline",
                      },
                    }}
                  >
                    <ZoomInIcon fontSize="15px" sx={{ mt: "4px" }} />
                    <TypographySmall
                      width={"fit-content"}
                      textAlign={"center"}
                      sx={{ pt: "4px" }}
                    >
                      {t("asset.zoomHint")}
                    </TypographySmall>
                  </Box>
                </Box>
              </Box>

              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                sx={{ pl: 2 }}
              >
                <Box display={"flex"} flexDirection={"column"}>
                  <DialogContentText
                    sx={{
                      mb: 1,
                      textAlign: "center",
                      fontWeight: 600,
                      textDecoration: "underline",
                    }}
                  >
                    {t("asset.assetDetailsLabel")}
                  </DialogContentText>

                  <Box
                    sx={{
                      p: 2,
                    }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      sx={{
                        width: "333px",
                      }}
                    >
                      <StyledBox>
                        <InputLabel>{t("asset.fileNameFullLabel")}</InputLabel>

                        <TextField
                          required
                          margin="dense"
                          fullWidth
                          size="small"
                          variant="outlined"
                          placeholder={t("asset.newFileNamePlaceholder")}
                          value={editName ? newName : assetName.name}
                          disabled={!editName || loadingUpdate}
                          onChange={(e) =>
                            editName ? handleChangeName(e) : null
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{ ml: "10px", fontWeight: 600 }}
                              >
                                .{assetName.extension}
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: colorStyling.black,
                              backgroundColor: colorStyling.white.inactive,
                            },
                          }}
                        />

                        {updatePermission && !loadingUpdate && (
                          <Link
                            href="#"
                            underline="none"
                            component={"div"}
                            onClick={() => handleEditToggle("name", !editName)}
                            sx={{
                              ml: 1,
                              cursor: "pointer",
                            }}
                          >
                            <TypographySmall
                              sx={{
                                fontWeight: 600,
                                color: colorStyling.blue.light,
                              }}
                            >
                              {!editName
                                ? t("asset.clickEditBtn")
                                : t("asset.cancelEditBtn")}
                            </TypographySmall>
                          </Link>
                        )}
                      </StyledBox>

                      <StyledBox>
                        <InputLabel>{t("asset.campaignNameLabel")}</InputLabel>

                        <TextField
                          required
                          margin="dense"
                          fullWidth
                          size="small"
                          variant="outlined"
                          placeholder={t("asset.newCampaignNamePlaceholder")}
                          value={
                            editCampaignName
                              ? newCampaignName
                              : asset.campaignName ?? "—"
                          }
                          disabled={!editCampaignName || loadingUpdate}
                          onChange={(e) =>
                            editCampaignName
                              ? handleChangeCampaignName(e)
                              : null
                          }
                          sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                              WebkitTextFillColor: colorStyling.black,
                              backgroundColor: colorStyling.white.inactive,
                            },
                          }}
                        />

                        {updatePermission && !loadingUpdate && (
                          <Link
                            href="#"
                            underline="none"
                            component={"div"}
                            onClick={() =>
                              handleEditToggle(
                                "campaignName",
                                !editCampaignName
                              )
                            }
                            sx={{
                              ml: 1,
                              cursor: "pointer",
                            }}
                          >
                            <TypographySmall
                              sx={{
                                fontWeight: 600,
                                color: colorStyling.blue.light,
                              }}
                            >
                              {!editCampaignName
                                ? t("asset.clickEditBtn")
                                : t("asset.cancelEditBtn")}
                            </TypographySmall>
                          </Link>
                        )}
                      </StyledBox>

                      <StyledBox display={"flex"} alignItems={"baseline"}>
                        <InputLabel>{t("asset.fileSizeLabel")}</InputLabel>
                        <TypographyLarge sx={{ ml: 2 }}>
                          {(asset.size / (1024 * 1024)).toFixed(2)} MB
                        </TypographyLarge>
                      </StyledBox>

                      {asset.canDelete ? (
                        <TypographyNormal textAlign={"center"} sx={{ mt: 2 }}>
                          {parser(t("asset.inUseHint"))}
                        </TypographyNormal>
                      ) : (
                        <TypographyNormal textAlign={"center"} sx={{ mt: 2 }}>
                          {parser(t("asset.notUsedHint"))}
                        </TypographyNormal>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button
              startIcon={<ClearIcon />}
              fullWidth
              variant="outlined"
              disabled={loadingUpdate}
              onClick={handleClose}
              sx={{ height: "38px" }}
            >
              {tCommon("closeBtn")}
            </Button>

            {(editName || editCampaignName) && (
              <Button
                type="submit"
                startIcon={!loadingUpdate && <DoneIcon />}
                fullWidth
                variant="contained"
                disabled={!currentlyEditing || loadingUpdate}
                sx={{ height: "38px" }}
              >
                {!loadingUpdate ? (
                  tCommon("submitBtn")
                ) : (
                  <CircularProgress size={"22px"} color="inherit" />
                )}
              </Button>
            )}
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default AssetsManagerViewAsset;
