import React from "react";
import SessionTimeout from "./components/SessionTimeout";
import UploadingWindow from "./components/contentUpload/UploadingWindow";
import RouterProvider from "./router/RouterProvider";
import { logger } from "./helpers/logger";
import { useTranslation } from "react-i18next";

const App = () => {
  const { i18n } = useTranslation();
  logger.log("dev version");
  logger.warn("staging version");
  logger.log("process.env:", process.env);
  logger.log("initiate localization!", {
    init: i18n.language,
    languages: i18n.languages,
  });

  return (
    <>
      <UploadingWindow />
      <RouterProvider />
      <SessionTimeout />
    </>
  );
};

export default App;
