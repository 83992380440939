// Enum: "EXPAND_FROM_BOTTOM_RIGHT"
export const ExpandFromBottomRight = {
  id: "expandFromBottomRight",
  label: "Expand From Bottom Right",
  speed: 2200,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "bottom right",
      scale: 0.1,
      translate: ["50%", "50%", 0],
    },
  },
};
