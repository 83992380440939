// Enum: "EXPAND_IN_FROM_BOTTOM"
export const ExpandInFromBottom = {
  id: "expandInFromBottom",
  label: "Expand In From Bottom",
  speed: 2500,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "bottom center",
      scale: 5,
      translate: [0, 0, 0],
    },
  },
};
