import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { bplrtAXIOS } from "../../../../config/axios";
import { instanceEquipmentBreakdownAPI, instanceSMPAPI } from "../../../../api";
import { useDispatch } from "react-redux";
import { editEquipment } from "../../../store/actions/bplrt/equipmentAction";
import { logger } from "../../../../helpers/logger";
import { colorStyling } from "../../../../helpers/color";
import { CstTypo14W300 } from "../../../../components/customComponent";
import { useTranslation } from "react-i18next";
import parser from "html-react-parser";

const InputSearch = styled("input")(() => ({
  outline: "none",
  border: "none",
  fontSize: "16px",
  lineHeight: "26px",
  fontWeight: 400,
  width: "100%",
  marginLeft: "10px",
  fontFamily: "Inter, sans-serif",
}));

const BoxInput = styled(Box)(({ theme }) => ({
  border: "1px solid #DCDEE3",
  display: "flex",
  alignItems: "center",
  paddingLeft: theme.spacing(1.875),
  paddingRight: theme.spacing(1.875),
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  borderRadius: "6px",
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const AddBreakdown = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("bplrtEquipment");

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [data, setData] = useState([]);
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setSelectedEquipment([]);
  };

  const handleOpenConfirmation = () => {
    if (selectedEquipment.length > 0) setOpenConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleSelectEquipment = (event, isChecked) => {
    const value = parseInt(event.target.value);
    if (isChecked) {
      setSelectedEquipment([...selectedEquipment, value]);
    }
    if (!isChecked) {
      setSelectedEquipment(selectedEquipment.filter((item) => item !== value));
    }
  };

  const handleSubmit = () => {
    let payload = [];
    selectedEquipment.map((id) => payload.push({ id, isDisrupted: true }));

    dispatch(editEquipment({ equipments: payload }));
    handleCloseConfirmation();
    handleClose();
  };

  const searchFunction = (items) => {
    return items.filter((item) => {
      return (
        item["name"]
          .toString()
          .toLowerCase()
          .indexOf(searchQuery.toLowerCase()) > -1 ||
        item.station.code
          .toString()
          .toLowerCase()
          .indexOf(searchQuery.toLowerCase()) > -1
      );
    });
  };

  useEffect(() => {
    const getEquipments = async () => {
      const access_token = sessionStorage.getItem("access_token");
      setLoading(true);
      try {
        const { data } = await bplrtAXIOS.get(
          instanceEquipmentBreakdownAPI + "?isDisrupted=false&limit=100",
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );

        const stationData = await bplrtAXIOS.get(
          instanceSMPAPI + "station?limit=100",
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );
        const stationItem = stationData.data.items;

        const filteredData = data.items.filter(
          (eq) => !eq.name.includes("Offpeak")
        );

        const combinedData = filteredData.map((data) => {
          const findStation = stationItem.find(
            (station) => station.id === data.stationId
          );

          return {
            ...data,
            station: {
              code: findStation ? findStation.code : null,
              name: findStation ? findStation.name : null,
            },
          };
        });

        setData(combinedData);
      } catch (error) {
        logger.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      getEquipments();
    }
  }, [open]);

  return (
    <>
      <Button
        onClick={handleOpen}
        startIcon={<AddIcon fontSize="small" />}
        variant="contained"
        fullWidth
        sx={{ boxShadow: 3 }}
      >
        {t("addBreakdownBtn")}
      </Button>

      <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {t("addBreakdownDialogTitle")}
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="small" color="inherit" />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box alignContent="flex-start" flexDirection="column">
            <Box sx={{ mb: 2 }}>
              {`(`}
              <span style={{ color: "#1C4ED8" }}>
                {selectedEquipment?.length}
              </span>
              {`) ${t("nEquipmentSelectedText")}`}
            </Box>

            <BoxInput>
              <SearchIcon fontSize="small" sx={{ color: "#9CA3AF" }} />

              <InputSearch
                type="search"
                placeholder={t("searchBreakdownPlaceholder")}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </BoxInput>
            <Box>
              <Box sx={{ border: "1px solid #e7e7e7", borderRadius: "4px" }}>
                {loading ? (
                  <Box
                    sx={{ height: 300 }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <CircularProgress
                      size={22}
                      thickness={3}
                      sx={{ color: colorStyling.primary }}
                    />
                    <CstTypo14W300
                      sx={{ color: colorStyling.primary, marginTop: "15px" }}
                    >
                      {t("loadingData")}
                    </CstTypo14W300>
                  </Box>
                ) : (
                  <Grid
                    container
                    sx={{ p: 1, maxHeight: 300, overflow: "auto" }}
                  >
                    {searchFunction(data)?.map((equip) => (
                      <Grid item key={equip.id} xs={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={equip.id}
                              key={equip.id}
                              onChange={handleSelectEquipment}
                            />
                          }
                          label={equip.station.code + " - " + equip.name}
                        />
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button fullWidth variant="outlined" onClick={handleClose}>
            {t("cancelBtn")}
          </Button>

          <Button
            fullWidth
            variant="contained"
            onClick={handleOpenConfirmation}
            disabled={selectedEquipment.length <= 0}
          >
            {t("addBreakdownBtn")}
          </Button>

          <Confirmation
            data={data.filter((item) => selectedEquipment.includes(item.id))}
            open={openConfirmation}
            onClose={handleCloseConfirmation}
            handleSubmit={handleSubmit}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddBreakdown;

const Confirmation = ({ data, open, onClose, handleSubmit }) => {
  const { t } = useTranslation("bplrtEquipment");

  const handleCallbackSubmit = (event) => {
    event.preventDefault();

    handleSubmit();
  };
  return (
    <Dialog maxWidth="sm" open={open} onClose={onClose}>
      <DialogContent>
        <DialogContentText sx={{ my: 2 }}>
          {parser(
            t("selectedEquipment", {
              count: data.length,
            })
          )}
        </DialogContentText>
        <Box
          sx={{
            border: "1px solid #a7a7a7",
            borderRadius: "4px",
            mb: 2,
            mx: 2,
          }}
        >
          <Grid container sx={{ p: 1, maxHeight: 100, overflow: "auto" }}>
            {data?.map((equip) => (
              <Grid
                item
                key={equip.id}
                xs={6}
                md={4}
                sx={{ fontWeight: 500, textAlign: "center", my: 0.3 }}
              >
                {equip.station.code + " - " + equip.name}
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box>
          <DialogContentText sx={{ mb: 2 }}>
            {parser(t("addBreakdownConfirmHint"))}
          </DialogContentText>
          <Alert severity="warning" variant="outlined">
            <strong>{t("addBreakdownConfirmAlert")}</strong>
          </Alert>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          startIcon={<ClearIcon />}
          fullWidth
          variant="outlined"
          onClick={onClose}
        >
          {t("addBreakdownConfirmNoBtn")}
        </Button>

        <Button
          onClick={handleCallbackSubmit}
          startIcon={<DoneIcon />}
          fullWidth
          variant="contained"
        >
          {t("addBreakdownConfirmYesBtn")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
