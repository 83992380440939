import i18n from "i18next";
import { colorStyling } from "../../helpers/color";
import {
  AdminIcon,
  AnnouncementIcon,
  CompanyIcon,
  DashboardIcon,
  GroupIcon,
  ScheduleIcon,
  UserManualIcon,
  ViewerIcon,
} from "../../icons";
import GroupsIcon from "@mui/icons-material/Groups";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Role from "../../helpers/roles";
import { stellarFlag } from "../../externalFeatures/componentsFlag";
import ClockMediaIcon from "../../icons/ClockMediaIcon";
const tCommon = i18n.getFixedT(null, "common");

export const sidebarMenuConfigTop = [
  {
    path: "/dashboard",
    icon: <DashboardIcon />,
    iconProps: {},
    label: tCommon("sidebar.Dashboard"),
    condition: (_, userRole) => userRole !== Role.Eins,
  },
  {
    flag: "SCREEN_GROUP",
    path: "/groups",
    pathInclude: "/group",
    icon: <GroupIcon />,
    iconProps: {},
    label: tCommon("sidebar.Group"),
    condition: (userPermission, userRole) =>
      userPermission?.screenGroup?.read && userRole !== Role.Eins,
  },
  {
    flag: "SCREEN",
    path: "/viewers",
    pathInclude: "/viewer",
    icon: <ViewerIcon />,
    iconProps: {},
    label: tCommon("sidebar.Viewer"),
    condition: (userPermission, userRole) =>
      userPermission?.screen?.read || userRole === Role.Eins,
  },
  {
    flag: "SCHEDULE",
    path: "/schedules",
    pathInclude: "/schedule",
    icon: (
      <ScheduleIcon
        colorI={colorStyling.orange}
        colorII={colorStyling.yellow}
      />
    ),
    iconProps: {},
    label: tCommon(
      stellarFlag ? "sidebar.StandardSchedule" : "sidebar.Schedule"
    ),
    condition: (userPermission, userRole) =>
      userPermission?.schedule?.read && userRole !== Role.Eins,
  },
  ...(stellarFlag
    ? [
        {
          flag: "ADVERTISEMENT",
          path: "/rotating-schedules",
          pathInclude: "/rotating-schedule",
          icon: <ClockMediaIcon color={colorStyling.orange} />,
          label: tCommon("sidebar.RotatingSchedule"),
          condition: (userPermission, userRole) =>
            userPermission?.schedule?.read && userRole !== Role.Eins,
        },
      ]
    : []),
  {
    flag: "ANNOUNCEMENT",
    path: "/announcements",
    pathInclude: "/announcement",
    icon: <AnnouncementIcon />,
    iconProps: {},
    label: tCommon("sidebar.Announcement"),
    condition: (userPermission, userRole) =>
      userPermission?.schedule?.read && userRole !== Role.Eins,
  },
  {
    flag: "ASSET",
    path: "/cms/assets",
    icon: (
      <PermMediaIcon fontSize="medium" sx={{ color: colorStyling.orange }} />
    ),
    iconProps: {},
    label: tCommon("sidebar.AssetsManager"),
    condition: (userPermission, userRole) =>
      userPermission?.content?.read && userRole !== Role.Eins,
  },
  {
    flag: "CONTENT",
    path: "/cms/content-template",
    pathInclude: "/cms/content-template",
    icon: (
      <BackupTableIcon fontSize="medium" sx={{ color: colorStyling.orange }} />
    ),
    iconProps: {},
    label: tCommon("sidebar.ContentTemplate"),
    condition: (userPermission, userRole) =>
      userPermission?.content?.read && userRole !== Role.Eins,
  },
  {
    flag: "LOG.CONTENT",
    path: "/report/content",
    icon: (
      <AssessmentIcon fontSize="medium" sx={{ color: colorStyling.orange }} />
    ),
    iconProps: {},
    label: tCommon("sidebar.ContentReport"),
    condition: (userPermission, userRole) =>
      userPermission?.assetLog?.read && userRole !== Role.Eins,
  },
];

export const sidebarMenuConfigBottom = [
  {
    flag: "USER",
    path: "/users",
    icon: <AdminIcon />,
    iconProps: {},
    label: tCommon("sidebar.User"),
    condition: (userPermission, userRole) =>
      userPermission?.user?.read || userRole !== Role.User,
  },
  {
    flag: "USER_GROUP",
    path: "/user/groups",
    icon: <GroupsIcon sx={{ color: colorStyling.orange }} />,
    iconProps: {},
    label: tCommon("sidebar.UserGroup"),
    condition: (userPermission, userRole) =>
      userPermission?.userGroup?.read || userRole !== Role.User,
  },
  {
    flag: "LOG.AUDIT",
    path: "/audit-log",
    icon: <TextSnippetIcon sx={{ color: colorStyling.orange }} />,
    iconProps: {},
    label: tCommon("sidebar.AuditLog"),
    condition: (userPermission, userRole) =>
      userPermission?.auditLog?.read || userRole === Role.Eins,
  },
  {
    path: "/organizations",
    icon: <CompanyIcon />,
    iconProps: {},
    label: tCommon("sidebar.Organization"),
    condition: (_, userRole) => userRole === Role.Eins,
  },
  {
    flag: "MANUAL",
    path: "/manual",
    icon: <UserManualIcon />,
    iconProps: {},
    label: tCommon("sidebar.UserManual"),
    condition: (_, userRole) => userRole !== Role.Eins,
  },
];
