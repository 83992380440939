import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Container, CssBaseline, Toolbar, Paper } from "@mui/material";
import Sidebar from "../../../../components/sidebar/Sidebar";
import {
  TypographyNormal,
  TypographyXLarge,
} from "../../../../components/customComponent";
import { logger } from "../../../../helpers/logger";
import { decryptUser } from "../../../../helpers/crypto";
import Role from "../../../../helpers/roles";

const getTrakoUrl = () => {
  const env = process.env.REACT_APP_ENV;
  switch (env) {
    default:
    case "dev":
      return "https://portal-embed.trakomatic.com";
    case "staging":
    case "prod":
      return "https://insights-embed.trakomatic.com";
  }
};

const trakoUrl = getTrakoUrl();
const trakoToken = process.env.REACT_APP_TRAKO_TOKEN;

const TrakoDashboard = () => {
  const navigate = useNavigate();
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);
  const userRole = decryptedLoggedUser.type;
  const userOrganization = decryptedLoggedUser.organization;

  const iframeRef = useRef(null);

  useEffect(() => {
    if (userRole !== Role.Admin && userOrganization?.id !== 1) {
      navigate("/");
      return;
    }

    if (trakoToken) {
      const interval = setTimeout(() => {
        logger.warn("postMessage POSTED!");
        iframeRef.current.contentWindow.postMessage(
          { code: "AUTH", token: trakoToken },
          trakoUrl
        );
        clearTimeout(interval);
      }, 9 * 1000); // fixed 9sec, waiting url to load
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              Video Analytics
            </TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              Access media analytics from Trakomatic.
            </TypographyNormal>
          </Box>

          <Box sx={{ my: 2 }}>
            <Paper
              component="section"
              elevation={2}
              sx={{
                p: 2,
                my: 2,
                borderRadius: "6px",
              }}
            >
              {!trakoToken && (
                <Box sx={{ mb: 1 }}>
                  <TypographyNormal
                    sx={{ color: "red", textAlign: "center", fontWeight: 400 }}
                  >
                    An <b>issue has been detected</b> while accessing
                    Trakomatic.
                    <br />
                    Please notify the <b>administrator</b> as soon as possible
                    so that we can resolve the issue and ensure uninterrupted
                    service.
                  </TypographyNormal>
                </Box>
              )}

              <iframe
                ref={(iframe) => {
                  if (iframe) {
                    iframeRef.current = iframe;
                  }
                }}
                title="Trako"
                src={`${trakoUrl}/login?auth=token`}
                width="100%"
                height="700"
              />
            </Paper>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default TrakoDashboard;
