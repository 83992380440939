import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, InputLabel, Checkbox } from "@mui/material";
import PreviewItem from "./PreviewItem";
import { TypographyNormal } from "../../../customComponent";
import { TypographySmall } from "../../../customComponent";
import { logger } from "../../../../helpers/logger";
import update from "immutability-helper";
import parser from "html-react-parser";

const PreviewBox = () => {
  const {
    layerMedias,
    contentLayers,
    canvasRes,
    contentRes,
    widthRatio,
    heightRatio,
    textLayers,
  } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");

  const [layers, setLayers] = useState({});
  const [pauseViewer, setPauseViewer] = useState(false);

  // FOR DEV debugging purposes
  // eslint-disable-next-line no-unused-vars
  const consoleLayers = () => {
    logger.log(`layerMedias:`, layerMedias);
    logger.log(`contentLayers:`, contentLayers);
    logger.log(`textLayers:`, textLayers);
    logger.log(`contentRes:`, contentRes);
    logger.log(`canvasRes:`, canvasRes);
    logger.log(`ratio:`, { widthRatio, heightRatio });
  };

  useEffect(() => {
    const layerKeys = Object.keys(contentLayers);
    const updatedLayers = {};
    if (widthRatio === 0 || heightRatio === 0) {
      return;
    }

    for (let i = 0; i < layerKeys.length; i++) {
      const layer = Object.assign({}, contentLayers[layerKeys[i]]);
      const layerDim = layer.dimensions;

      const ratiodWidth = Math.round(layerDim.width / widthRatio);
      const ratiodHeight = Math.round(layerDim.height / heightRatio);
      layerDim.ratiodWidth = ratiodWidth;
      layerDim.ratiodHeight = ratiodHeight;

      const parsedLayer = { ...layerDim };
      parsedLayer["active"] = layer.active;
      parsedLayer["type"] = layer.type;
      parsedLayer["bgColor"] = layer.bgColor;
      parsedLayer["zIndex"] = layerDim.zIndex;
      if (layer.type === "text") {
        parsedLayer["text"] = layer["text"];
      }

      updatedLayers[layer.id] = parsedLayer;
    }

    const finalUpdates = update(layers, { $set: { ...updatedLayers } });
    setLayers(finalUpdates);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentLayers, canvasRes, contentRes, widthRatio, heightRatio]);

  const checkInvalidLayers = useMemo(() => {
    const layerKeys = Object.keys(contentLayers);
    let invalidFlag = false;
    layerKeys.forEach((layerID) => {
      if (invalidFlag) {
        return;
      }

      const layerDim = contentLayers[layerID].dimensions;
      if (
        layerDim.width > contentRes.width ||
        layerDim.height > contentRes.height
      ) {
        invalidFlag = true;
      }
    });
    return invalidFlag;
  }, [contentLayers, contentRes]);

  const previewResLabel = useMemo(() => {
    if (contentRes.width > 0 && contentRes.height > 0) {
      return (
        <InputLabel>
          ({contentRes.width} x {contentRes.height})
        </InputLabel>
      );
    }

    return;
  }, [contentRes]);

  const boxHeightDynamic = useMemo(() => {
    if (canvasRes.height > canvasRes.width || canvasRes.height > 600) {
      return "690px";
    } else {
      return `${canvasRes.height + 90}px`;
    }
  }, [canvasRes]);

  const layerCount = useMemo(() => {
    return Object.keys(contentLayers).length;
  }, [contentLayers]);

  return (
    <Box sx={{ width: "50%", height: "fit-content" }}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={3}
        sx={{
          pt: 2,
          py: 2,
          pb: checkInvalidLayers || pauseViewer ? 1 : 2,
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          width={360}
        >
          <Box display={"flex"} flexDirection={"column"} sx={{ py: "4px" }}>
            <InputLabel>{t("designer.previewBoxLabel")}</InputLabel>
            {previewResLabel}
          </Box>
          {/* // For Development */}
          {false && (
            <Button
              variant="contained"
              sx={{ boxShadow: 3 }}
              onClick={() => consoleLayers()}
            >
              {t("designer.debugInfoBtn")}
            </Button>
          )}

          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              width: "fit-content",
              height: "fit-content",
              placeSelf: "end",
            }}
          >
            {layerCount > 0 && (
              <>
                <InputLabel
                  sx={{ cursor: "pointer" }}
                  onClick={() => setPauseViewer(!pauseViewer)}
                >
                  <TypographyNormal>
                    {t("designer.pauseScreenCheckbox")}
                  </TypographyNormal>
                </InputLabel>

                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Checkbox
                    value={pauseViewer}
                    checked={pauseViewer}
                    onClick={() => setPauseViewer(!pauseViewer)}
                    sx={{ padding: "4px" }}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Box>

      {pauseViewer && (
        <Box sx={{ width: 360 }}>
          <TypographySmall
            sx={{
              ml: 3,
              fontWeight: 600,
              fontSize: "12px",
              lineHeight: "14px",
              textAlign: "center",
            }}
          >
            {t("designer.pauseScreenHint")}
          </TypographySmall>
        </Box>
      )}

      {checkInvalidLayers && (
        <Box sx={{ mb: "6px", width: 360 }}>
          <TypographySmall
            sx={{
              ml: 3,
              color: "red",
              fontWeight: 500,
              fontSize: "11px",
              lineHeight: "14px",
            }}
          >
            {parser(t("designer.invalidLayerWarning"))}
          </TypographySmall>
        </Box>
      )}
      <Box
        sx={{
          paddingLeft: 1,
          paddingRight: 4,
          width: "auto",
          overflowX: "scroll",
          overflowY: "scroll",
          height: boxHeightDynamic,
        }}
      >
        <Box
          sx={{
            marginY: 2,
            width: canvasRes.width,
            height: canvasRes.height,
            position: "relative",
            backgroundColor: "white",
            border: "1px solid rgba(28, 78, 216, 0.5)",
          }}
        >
          {Object.keys(layers).map((key, index) => {
            return (
              <PreviewItem
                id={key}
                key={key}
                index={index + 1}
                layer={layers[key]}
                pauseViewer={pauseViewer}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default PreviewBox;
