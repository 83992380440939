export const filterObjtoString = (filterObject, limit, offset) => {
  let filterString = "?limit=" + limit + "&offset=" + offset;
  if (filterObject)
    Object.keys(filterObject).forEach((item) => {
      if (Array.isArray(filterObject[item]))
        filterObject[item].forEach(
          (itemArr) => (filterString += `&${item}=${itemArr}`)
        );
      else filterString += `&${item}=${filterObject[item]}`;
    });

  return filterString;
};

export const filterObjOnly = (filterObj) => {
  let filterString = "";
  if (filterObj)
    Object.keys(filterObj).forEach((item) => {
      if (Array.isArray(filterObj[item]))
        filterObj[item].forEach(
          (itemArr) => (filterString += `&${item}=${itemArr}`)
        );
      else filterString += `&${item}=${filterObj[item]}`;
    });

  return filterString;
};

export const filterDuplicateData = (arr1, arr2) => {
  let res = [];
  res = arr1.filter((el) => {
    return !arr2.find((element) => {
      return element.id === el.id;
    });
  });
  return res;
};

export const filterBySearch = (array, value) => {
  return array.filter(
    (data) =>
      JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
};
