import React, { useState } from "react";
import {
  alpha,
  CircularProgress,
  FormControlLabel,
  Paper,
  styled,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { instanceBPLRTDisruptionAPI } from "../../../../api";
import { bplrtAXIOS } from "../../../../config/axios";
import { TypographyLarge } from "../../../../components/customComponent";
import {
  SET_EQUIPMENT_PEAK_HOUR,
  SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN,
  SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN,
  SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN,
  SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN,
} from "../../../store/actionTypes/bplrt/equipmentActionType";
import { logger } from "../../../../helpers/logger";
import { useTranslation } from "react-i18next";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#10e02f",
    "&:hover": {
      backgroundColor: alpha("#10e02f", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#10e02f",
  },
}));

const TogglePeakHour = () => {
  const dispatch = useDispatch();
  const { isPeakHour } = useSelector((state) => state.bplrtEquip);

  const { t } = useTranslation("bplrtEquipment");

  const [loading, setLoading] = useState(false);
  const handleToggle = (e) => {
    const payload = { isPeakHour: e.target.checked };
    updatePeakData(payload);
  };

  const updatePeakData = async (payload) => {
    setLoading(true);
    const access_token = sessionStorage.getItem("access_token");
    try {
      const { data } = await bplrtAXIOS.patch(
        instanceBPLRTDisruptionAPI + "equipment",
        payload,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({ type: SET_EQUIPMENT_PEAK_HOUR, payload: data });
      dispatch({
        type: SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN,
        payload: true,
      });
      dispatch({
        type: SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN,
        payload: data.isPeakHour
          ? t("peakSuccessMessage")
          : t("offpeakSuccessMessage"),
      });

      setTimeout(() => {
        dispatch({
          type: SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN,
          payload: false,
        });
      }, 5000);
    } catch (error) {
      logger.log(error);

      dispatch({
        type: SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN,
        payload: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Paper sx={{ pl: 2, minWidth: "145px" }}>
      <FormControlLabel
        control={
          <CustomSwitch
            checked={isPeakHour}
            onChange={handleToggle}
            disabled={loading}
          />
        }
        label={
          loading ? (
            <CircularProgress size={20} />
          ) : isPeakHour ? (
            <TypographyLarge sx={{ fontWeight: "500", color: "green" }}>
              {t("peakLabel")}
            </TypographyLarge>
          ) : (
            <TypographyLarge sx={{ fontWeight: "500", color: "gray" }}>
              {t("offpeakLabel")}
            </TypographyLarge>
          )
        }
      />
    </Paper>
  );
};

export default TogglePeakHour;
