import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconButton, Menu, MenuItem, TableCell } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { red } from "@mui/material/colors";
import DeleteUser from "./DeleteUser";
import EditUser from "./EditUser";
import { StyledTableRow } from "../customComponent";
import {
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_PROMPT_USER_PAGE,
} from "../../store/actionTypes/userActionType";
import ViewUser from "./ViewUser";
import ForceLogoutUser from "./ForceLogoutUser";
import Role from "../../helpers/roles";
import { colorStyling } from "../../helpers/color";

const UserItem = ({ user, index, userRole, userGroupPermission }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("user");

  const [anchorEl, setAnchorEl] = useState(null);

  const [openView, setOpenView] = useState(false);

  const [openForceLogout, setOpenForceLogout] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);

  const open = Boolean(anchorEl);

  const handleClickOpenView = () => setOpenView(true);

  const handleClickCloseView = () => setOpenView(false);

  const handleClickOpenForceLogout = () => setOpenForceLogout(true);

  const handleClickCloseForceLogout = () => setOpenForceLogout(false);

  const handleClickOpenEdit = () => {
    if (userRole === Role.Admin && user.type === Role.Admin) {
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_USER_PAGE,
        payload: t("item.errNoPermission"),
      });
    } else setOpenEdit(true);
  };

  const handleClickCloseEdit = () => setOpenEdit(false);

  const handleClickOpenDelete = () => setOpenDelete(true);

  const handleClickCloseDelete = () => setOpenDelete(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <StyledTableRow>
      <TableCell align="center">{index + 1}</TableCell>
      <TableCell align="left">{user.name}</TableCell>
      <TableCell align="center">{user.type}</TableCell>
      {userGroupPermission.read && (
        <TableCell align="center">{user.userGroupName}</TableCell>
      )}

      {userRole === Role.Eins && (
        <TableCell align="center">{user.organizationName ?? "-"}</TableCell>
      )}
      <TableCell align="right">
        <IconButton
          sx={{ color: colorStyling.black }}
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreHorizIcon fontSize="small" />
        </IconButton>

        {userRole === Role.Admin && user.type === Role.Admin ? (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClickOpenView} dense>
              <VisibilityOutlinedIcon fontSize="small" sx={{ mr: "5px" }} />
              {t("item.viewUserBtn")}
            </MenuItem>
            <ViewUser
              open={openView}
              onClose={handleClickCloseView}
              user={user}
            />
          </Menu>
        ) : (
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={handleClickOpenEdit} dense>
              <EditOutlinedIcon fontSize="small" sx={{ mr: "5px" }} />
              {t("item.editUserBtn")}
            </MenuItem>
            <EditUser
              open={openEdit}
              onClose={handleClickCloseEdit}
              user={user}
            />

            <MenuItem
              sx={{ color: red[600] }}
              onClick={handleClickOpenForceLogout}
              dense
            >
              <LogoutOutlinedIcon fontSize="small" sx={{ mr: "5px" }} />
              {t("item.forceLogoutBtn")}
            </MenuItem>

            <ForceLogoutUser
              user={user}
              open={openForceLogout}
              onClose={handleClickCloseForceLogout}
            />

            <MenuItem
              sx={{ color: red[600] }}
              onClick={handleClickOpenDelete}
              dense
            >
              <DeleteOutlinedIcon fontSize="small" sx={{ mr: "5px" }} />
              {t("item.removeUserBtn")}
            </MenuItem>
            <DeleteUser
              open={openDelete}
              onClose={handleClickCloseDelete}
              user={user}
            />
          </Menu>
        )}
      </TableCell>
    </StyledTableRow>
  );
};

export default UserItem;
