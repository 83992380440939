import * as yup from "yup";
import i18n from 'i18next';
import { parseVariables } from "../helpers/localize";

export const editPasswordAdminSchema = yup.object().shape({
  "Admin Password": yup.string().notRequired(),
  "New Password": yup.string().min(8, ({ min }) => parseVariables(i18n.t("validation.minLengthChars"), { num: min })).max(30, ({ max }) => parseVariables(i18n.t("validation.maxLengthChars"), { num: max })).nullable().notRequired(),
  "Confirm Password": yup.string().oneOf([yup.ref("New Password"), null]),
});

export const editPasswordUserSchema = yup.object().shape({
  "New Password": yup.string().min(8, ({ min }) => parseVariables(i18n.t("validation.minLengthChars"), { num: min })).max(30, ({ max }) => parseVariables(i18n.t("validation.maxLengthChars"), { num: max })).nullable().notRequired(),
  "Confirm Password": yup.string().oneOf([yup.ref("New Password"), null]),
});
