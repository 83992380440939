// Enum: "SLIDE_FROM_BOTTOM_LEFT"
export const SlideInFromBottomLeft = {
  id: "slideInFromBottomLeft",
  label: "Slide In From Bottom Left",
  speed: 2000,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "bottom left",
      translate: ["-100%", "100%", 10],
    },
  },
};
