import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Link, Menu, MenuItem, Tooltip } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { TypographyNormal } from "../customComponent";
import { ViewerBlueIcon } from "../../icons";
import { formattedDate } from "../../helpers/dateFormat";
import { colorStyling } from "../../helpers/color";
// import ViewerScreenshot from "./id/ViewerScreenshot";
import { decryptUser } from "../../helpers/crypto";
import Role from "../../helpers/roles";
import { red } from "@mui/material/colors";
import DialogViewerDelete from "./DialogViewerDelete";
import { SET_VIEWER_DETAIL } from "../../store/actionTypes/viewerActionTypes";

import ViewerStatusIndicator from "./ViewerStatusIndicator";

const ViewerItem = ({ viewer, dispatch }) => {
  const navigate = useNavigate();
  const { viewer: detail } = useSelector((state) => state.viewer);

  const { t } = useTranslation("viewer");

  const userRole = localStorage.getItem("permissionRole");
  const decryptedUserRole = decryptUser(userRole);

  const redirectToViewerDetailPage = (id) => {
    const newDetail = {
      ...viewer,
      ...(detail.id === viewer.id && {
        screenGroups: detail.screenGroups,
        schedules: detail.schedules,
      }),
    };
    dispatch({ type: SET_VIEWER_DETAIL, payload: newDetail });
    navigate("/viewer/detail/" + id);
  };

  const viewerNameComponent = (
    <Tooltip
      title={viewer?.name?.length > 30 ? viewer?.name : ""}
      placement="top-start"
    >
      <TypographyNormal
        sx={
          decryptedUserRole === Role.Eins
            ? { fontWeight: 600 }
            : {
                fontWeight: 600,
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                  color: colorStyling.primary,
                },
              }
        }
      >
        {viewer?.name?.length > 30
          ? viewer?.name?.substring(0, 27).trim() + "..."
          : viewer?.name}
      </TypographyNormal>
    </Tooltip>
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        p: 2,
        "&:hover": {
          backgroundColor: colorStyling.white.hover,
          cursor: "pointer",
        },
      }}
      onClick={() => redirectToViewerDetailPage(viewer.id)}
    >
      <Box display="flex" alignItems="center">
        <ViewerBlueIcon />

        <Box sx={{ ml: 2, width: 250 }}>
          {decryptedUserRole === Role.Eins ? (
            viewerNameComponent
          ) : (
            <Link style={{ textDecoration: "none" }}>
              {viewerNameComponent}
            </Link>
          )}
        </Box>

        {/* TODO: Will work on it when working on the feature */}
        {/* <ViewerScreenshot viewer={viewer} size="sm" /> */}

        {/* {decryptedUserRole !== Role.User && (
          <BlockViewer viewer={viewer} size="sm" />
        )} */}
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", mx: 1 }}>
        <ViewerStatusIndicator size={22} viewer={viewer} />
      </Box>

      <Box minWidth={150}>
        <TypographyNormal sx={{ fontWeight: 300 }}>
          {t("item.lastActiveTimeLabel")}
        </TypographyNormal>
        <TypographyNormal sx={{ fontWeight: 600 }}>
          {viewer.activeAt ? formattedDate(viewer.activeAt) : "-"}
        </TypographyNormal>
      </Box>

      {decryptedUserRole !== Role.Superadmin &&
        decryptedUserRole !== Role.Eins && (
          <Link>
            <IconButton size="small" sx={{ color: colorStyling.black }}>
              <NavigateNextIcon />
            </IconButton>
          </Link>
        )}

      {decryptedUserRole === Role.Eins && <ViewerItemMenu viewer={viewer} />}
    </Box>
  );
};

const ViewerItemMenu = ({ viewer }) => {
  const { t } = useTranslation("viewer");

  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteViewer, setOpenDeleteViewer] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const handleOpenDeleteViewer = () => setOpenDeleteViewer(true);
  const handleCloseDeleteViewer = () => setOpenDeleteViewer(false);

  return (
    <>
      <IconButton
        sx={{ color: colorStyling.black }}
        aria-controls={openMenu ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          sx={{ color: red[600] }}
          onClick={handleOpenDeleteViewer}
          dense
        >
          <DeleteOutlinedIcon fontSize="small" sx={{ mr: "5px" }} />
          {t("item.deleteBtn")}
        </MenuItem>
        <DialogViewerDelete
          open={openDeleteViewer}
          handleClose={handleCloseDeleteViewer}
          viewer={viewer}
        />
      </Menu>
    </>
  );
};

export default ViewerItem;
