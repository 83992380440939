import React from "react";
import { useTranslation } from "react-i18next";
import { colorStyling } from "../../helpers/color";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  CircularProgress,
} from "@mui/material";
import { TypographyLarge, TypographyNormal } from "../customComponent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AssignedComponentItem from "./AssignedComponentItem";
import { parseVariables } from "../../helpers/localize";

const AssignedComponentList = ({ type, listData, loading, sourceData }) => {
  const { t: tCommon } = useTranslation();

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ p: 2 }}
        >
          <CircularProgress
            size={20}
            thickness={3}
            sx={{ color: colorStyling.primary }}
          />
          <TypographyNormal
            sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
          >
            {tCommon("loadingDataList")}
          </TypographyNormal>
        </Box>
      ) : (
        <Box>
          {!listData || listData?.length < 1 ? (
            <Alert severity="info" variant="filled" sx={{ mt: 1 }}>
              {parseVariables(tCommon("assign.noAssignedHint"), {
                source: sourceData.type,
                type: type,
              })}
            </Alert>
          ) : (
            <Accordion
              disableGutters
              sx={{ backgroundColor: "transparent" }}
              defaultExpanded
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ color: colorStyling.black }} />
                }
              >
                <TypographyLarge
                  sx={{ fontWeight: 600, textTransform: "capitalize" }}
                >
                  {parseVariables(
                    tCommon("assign.title", { count: listData?.length ?? 0 }),
                    {
                      type: type,
                    }
                  )}
                </TypographyLarge>
              </AccordionSummary>
              <Box sx={{ px: 2, pb: 2 }}>
                <AccordionDetails
                  sx={{
                    maxHeight: "30vh",
                    overflowY: "auto",
                    backgroundColor: colorStyling.white.full,
                    border: `1px solid ${colorStyling.white.border}`,
                    borderRadius: "6px",
                    p: 2,
                  }}
                >
                  {listData?.map((item) => (
                    <AssignedComponentItem
                      key={type + item.id}
                      type={type}
                      item={item}
                      sourceData={sourceData}
                    />
                  ))}
                </AccordionDetails>
              </Box>
            </Accordion>
          )}
        </Box>
      )}
    </>
  );
};

export default AssignedComponentList;
