import React, { useState, forwardRef } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Dialog,
  AppBar,
  Slide,
  IconButton,
  Toolbar,
  Typography,
  Paper,
  Box,
  Pagination,
} from "@mui/material";
import { CustomTableHead, TertiaryButton } from "../customComponent";
import {
  LoginImg,
  SidebarImg,
  DashboardImg,
  GroupImg,
  CreateAGroupImg,
  SubgroupsImg,
  InsideGroupsOneImg,
  InsideGroupsTwoImg,
  ViewerOneImg,
  ViewerTwoImg,
  ScheduleOneImg,
  ScheduleTwoImg,
  CreateAScheduleImg,
  AssigningAScheduleOneImg,
  AssigningAScheduleTwoImg,
  JobHistoryImg,
  AnnouncementImg,
  CreateAnAnnouncementImg,
} from "./images";
import { Close } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { usePagination } from "../hooks";
import { colorStyling } from "../../helpers/color";

const data = [
  {
    title: "Logging in",
    description: "Enter your username and password in the fields provided.",
    img: LoginImg,
  },
  {
    title: "Sidebar",
    description: "Navigate between pages using the sidebar.",
    img: SidebarImg,
  },
  {
    title: "Dashboard",
    description:
      "This page provides an overview of all current active Viewers and Schedules, along with a brief summary of your Viewers, Schedules, and Groups.",
    img: DashboardImg,
  },
  {
    title: "Group",
    description: "Access your current Group and add new Groups here.",
    img: GroupImg,
  },
  {
    title: "Creating a Group",
    description:
      "To create a group, click the “+ Create New Group” button. A pop-up will appear, similar to the image on the right. Enter your group name in the field provided, then click the “Create Group” button.",
    img: CreateAGroupImg,
  },
  {
    title: "Subgroups",
    description:
      "Click the down arrow to view all Subgroups within a Group. The arrow will change to an up arrow, showing the Subgroups. Use the search bar and filters to find specific Groups.",
    img: SubgroupsImg,
  },
  {
    title: "Inside Groups",
    description:
      "Selecting a group takes you to a page like this, where you can view its Subgroups, Viewers, and Schedules. ",
    img: InsideGroupsOneImg,
  },
  {
    title: "",
    description:
      "Click the three dots next to the Group's name to open a pop-up where you can change or delete the Group's name.",
    img: InsideGroupsTwoImg,
  },
  {
    title: "Viewer",
    description:
      "Monitor your current Viewers and check their individual statuses. Use the search bars and filters to find specific Viewers.",
    img: ViewerOneImg,
  },
  {
    title: "",
    description:
      "Clicking on a Viewer opens a pop-up displaying the Viewer’s details.",
    img: ViewerTwoImg,
  },
  {
    title: "Schedule",
    description:
      "View all schedules and their details here. Use the search bar and filters to find a specific schedule.",
    img: ScheduleOneImg,
  },
  {
    title: "",
    description: "Click on a schedule to view its details.",
    img: ScheduleTwoImg,
  },
  {
    title: "Creating a Schedule",
    description:
      "Click the “+ Create New Schedule” button. A pop-up similar to the image on the right will appear. Enter the schedule name, frequency, type, command, and start and end dates. Once completed, click the “Create Schedule” button. Frequency refers to how often the schedule will be executed. Type indicates the device you want to control. Command specifies the action you want the device to perform.",
    img: CreateAScheduleImg,
  },
  {
    title: "Assigning a Schedule",
    description:
      "Click the “Assign New Schedule” button. Search for the schedule(s) to assign to your viewer. ",
    img: AssigningAScheduleOneImg,
  },
  {
    title: "",
    description:
      "Select the checkboxes on the right side of the pop-up to choose which Viewers or Groups will receive the Schedule. Click the “Assign” button to assign the Schedule as specified.",
    img: AssigningAScheduleTwoImg,
  },
  {
    title: "Job History",
    description:
      "View the history of assigned schedules here. Use the search bar and filters to find a specific schedule. Click on a previous job to view its details.",
    img: JobHistoryImg,
  },
  {
    title: "Announcements",
    description:
      "Here, you can view all announcements. Use the search bar and filters to find a specific announcement. Click on an announcement to see its details.",
    img: AnnouncementImg,
  },
  {
    title: "Creating an Announcement",
    description:
      "Click the “+ Create New Announcement” button. A pop-up similar to the image on the right will appear. Enter your announcement name and any relevant link. Once completed, click the “Create Announcement” button.",
    img: CreateAnAnnouncementImg,
  },
];

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShowImage = ({ open, onClose, item }) => {
  const handleClickClose = () => {
    onClose(false);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClickClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{ position: "relative", backgroundColor: colorStyling.primary }}
      >
        <Toolbar>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {item.title}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClickClose}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </Toolbar>
      </AppBar>
      <img src={item.img} alt="img content" />
    </Dialog>
  );
};

const RenderData = ({ index, item }) => {
  const [showImg, setShowImg] = useState(false);

  const handleOpenImg = () => {
    setShowImg(true);
  };

  const handleCloseSImg = () => {
    setShowImg(false);
  };

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: colorStyling.white.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <StyledTableRow key={index}>
        <TableCell>{item.title}</TableCell>
        <TableCell>{item.description}</TableCell>
        <TableCell>
          <TertiaryButton onClick={handleOpenImg}>View</TertiaryButton>
        </TableCell>
        <ShowImage open={showImg} onClose={handleCloseSImg} item={item} />
      </StyledTableRow>
    </>
  );
};

const TableComponent = () => {
  const [page, setPage] = useState(1);

  const DATA_PER_PAGE = 7;

  const count = Math.ceil(data.length / DATA_PER_PAGE);

  const _DATA = usePagination(data, DATA_PER_PAGE);

  const handleChangePagination = (e, page) => {
    setPage(page);
    _DATA.jump(page);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ overflowY: "auto", maxHeight: 700 }}
      >
        <Table aria-label="global table">
          <TableHead>
            <TableRow>
              <CustomTableHead>Title</CustomTableHead>
              <CustomTableHead>Description</CustomTableHead>
              <CustomTableHead>Content</CustomTableHead>
            </TableRow>
          </TableHead>

          <TableBody>
            {_DATA.currentData().map((item, index) => (
              <RenderData key={index} item={item} index={index} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 4,
            pb: 2,
            pr: 2,
          }}
        >
          <Pagination
            count={count}
            page={page}
            shape="rounded"
            color="primary"
            onChange={handleChangePagination}
          />
        </Box>
      ) : null}
    </>
  );
};

export default TableComponent;
