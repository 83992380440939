import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Paper, Box, CircularProgress } from "@mui/material";
import { Typography2XLarge, TypographyLarge } from "../customComponent";
import { colorStyling } from "../../helpers/color";

const ViewerCard = () => {
  const { loadingViewersData, viewersData } = useSelector(
    (state) => state.home
  );

  const { t } = useTranslation("home");

  if (loadingViewersData && viewersData === "")
    return (
      <Paper
        elevation={2}
        sx={{ display: "flex", flexDirection: "column", borderRadius: "6px" }}
      >
        <Box
          p={2}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <CircularProgress
            size={22}
            thickness={3}
            sx={{ color: colorStyling.primary }}
          />
        </Box>
      </Paper>
    );

  return (
    <Paper
      elevation={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "6px",
      }}
    >
      <Box display="flex" p={2}>
        <Box p={1} alignSelf="center">
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M5.4001 5.3999C4.40598 5.3999 3.6001 6.20579 3.6001 7.1999C3.6001 8.19401 4.40598 8.9999 5.4001 8.9999V23.3999C5.4001 25.3881 7.01187 26.9999 9.0001 26.9999H13.6545L11.3273 29.3271C10.6244 30.03 10.6244 31.1697 11.3273 31.8727C12.0302 32.5756 13.1699 32.5756 13.8729 31.8727L18.0001 27.7455L22.1273 31.8727C22.8302 32.5756 23.9699 32.5756 24.6729 31.8727C25.3758 31.1697 25.3758 30.03 24.6729 29.3271L22.3457 26.9999H27.0001C28.9883 26.9999 30.6001 25.3881 30.6001 23.3999V8.9999C31.5942 8.9999 32.4001 8.19401 32.4001 7.1999C32.4001 6.20579 31.5942 5.3999 30.6001 5.3999H5.4001ZM26.4729 13.8727C27.1758 13.1697 27.1758 12.0301 26.4729 11.3271C25.7699 10.6242 24.6302 10.6242 23.9273 11.3271L18.0001 17.2543L15.6729 14.9271C14.9699 14.2242 13.8302 14.2242 13.1273 14.9271L9.5273 18.5271C8.82436 19.2301 8.82436 20.3697 9.5273 21.0727C10.2302 21.7756 11.3699 21.7756 12.0729 21.0727L14.4001 18.7455L16.7273 21.0727C17.4302 21.7756 18.5699 21.7756 19.2729 21.0727L26.4729 13.8727Z"
              fill={colorStyling.primary}
            />
          </svg>
        </Box>

        <Box ml={3}>
          <Typography2XLarge>
            {!viewersData ? 0 : viewersData}
          </Typography2XLarge>
          <TypographyLarge sx={{ fontWeight: 400 }}>
            {t("totalViewerLabel")}
          </TypographyLarge>
        </Box>
      </Box>
    </Paper>
  );
};

export default ViewerCard;
