import dayjs from "dayjs";

/**
 * get viewer status based on lastActiveTime
 * @param {Date} lastAt
 */
const minuteDifference = 1;

export const getViewerStatusMinuteDiff = () => {
  return minuteDifference;
};

export const getViewerStatus = (lastAt) => {
  const beforeDate = dayjs().add(minuteDifference, "m");
  const compareDate = beforeDate.diff(lastAt, "m", true);

  let isActive = false;
  if (compareDate <= minuteDifference * 2 && lastAt) isActive = true;

  return isActive;
};

export const sortViewerActiveAt = (viewers) => {
  return viewers.sort(function compare(a, b) {
    if (new Date(a.activeAt) > new Date(b.activeAt)) {
      return -1;
    }
    if (new Date(a.activeAt) < new Date(b.activeAt)) {
      return 1;
    }
    return 0;
  });
};
