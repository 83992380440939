import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Toolbar,
} from "@mui/material";
import Sidebar from "../../sidebar/Sidebar";
import AnnouncementBreadCrumb from "./AnnouncementBreadCrumb";
import AnnouncementInfo from "./AnnouncementInfo";
import AnnouncementSuccessPrompt from "../AnnouncementSuccessPrompt";
import AnnouncementErrorPrompt from "../AnnouncementErrorPrompt";
import AnnouncementUpdateStatus from "./AnnouncementUpdateStatus";
import EditAnnouncement from "./EditAnnouncement";
import DeleteAnnouncement from "./DeleteAnnouncement";
import { getAnnouncement } from "../../../store/actions/announcementAction";
import { decryptUser } from "../../../helpers/crypto";
import AssignComponent from "../../assign/AssignComponent";
import AssignedComponentList from "../../assign/AssignedComponentList";
import ApproveAnnouncement from "./ApproveAnnouncement";
import { isEmpty } from "lodash";
import CoreDetailPageLoader from "../../skeletonLoader/CoreDetailPageLoader";

const AnnouncementDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    announcement: { [id]: announcement },
    loadingAnnouncement: loading,
  } = useSelector((state) => state.announcement);

  useEffect(() => {
    dispatch(getAnnouncement(id));
  }, [dispatch, id]);

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);
  const permission = decryptedLoggedUser?.permission;
  const schedulePermission = permission.schedule;
  const groupPermission = permission.screenGroup;
  const viewerPermission = permission.screen;

  let isLoading = !loading || +announcement?.id === +id;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 2 }}>
        <Toolbar />
        <CssBaseline />

        {isEmpty(announcement) ? (
          <CoreDetailPageLoader infoLine={2} />
        ) : (
          <Container maxWidth="xl">
            <Box sx={{ mb: 2 }}>
              <AnnouncementBreadCrumb announcement={announcement} />
            </Box>

            <AnnouncementSuccessPrompt />
            <AnnouncementErrorPrompt />
            {isLoading && (
              <Box
                sx={{ mb: 2 }}
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"nowrap"}
                justifyContent={"space-between"}
              >
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  {schedulePermission.approve && (
                    <ApproveAnnouncement announcement={announcement} />
                  )}

                  {schedulePermission.activate && (
                    <AnnouncementUpdateStatus announcement={announcement} />
                  )}
                </Box>

                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {schedulePermission.update && (
                    <EditAnnouncement announcement={announcement} />
                  )}

                  {schedulePermission.delete && (
                    <DeleteAnnouncement announcement={announcement} />
                  )}
                </Box>
              </Box>
            )}
            <Grid container spacing={2} direction="column">
              <Grid item xs={4}>
                <Paper elevation={2} sx={{ p: 2, borderRadius: "6px", mb: 2 }}>
                  <AnnouncementInfo
                    announcement={announcement}
                    loading={!isLoading}
                  />
                </Paper>
              </Grid>

              {isLoading && schedulePermission.update ? (
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {/* Assign Group */}
                  {groupPermission.read && (
                    <AssignComponent
                      getName={"group"}
                      typeName="announcement"
                      typeData={announcement}
                    />
                  )}

                  {/* Assign Viewer */}
                  {viewerPermission.read && (
                    <AssignComponent
                      getName={"viewer"}
                      typeName="announcement"
                      typeData={announcement}
                    />
                  )}
                </Box>
              ) : null}

              {/* Assigned Group List */}
              {groupPermission.read && (
                <Grid item xs={4}>
                  <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                    <AssignedComponentList
                      key={"group"}
                      type={"group"}
                      listData={announcement.screenGroups}
                      sourceData={{
                        id: announcement.id,
                        name: announcement.name,
                        type: "announcement",
                        isApproved: announcement.isApproved,
                        editPermission: schedulePermission.update,
                      }}
                      loading={!isLoading}
                    />
                  </Paper>
                </Grid>
              )}

              {/* Assigned Viewer List */}
              {viewerPermission.read && (
                <Grid item xs={4}>
                  <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                    <AssignedComponentList
                      key={"viewer"}
                      type={"viewer"}
                      listData={announcement.screens}
                      sourceData={{
                        id: announcement.id,
                        name: announcement.name,
                        type: "announcement",
                        isApproved: announcement.isApproved,
                        editPermission: schedulePermission.update,
                      }}
                      loading={!isLoading}
                    />
                  </Paper>
                </Grid>
              )}
            </Grid>
          </Container>
        )}
      </Box>
    </div>
  );
};

export default AnnouncementDetail;
