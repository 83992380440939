import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Tooltip } from "@mui/material";
import {
  AdsScheduleGreenIcon,
  AnnouncementPurpleIcon,
  ClockIcon,
  GroupBlueIcon,
  ScheduleYellowIcon,
  ViewerBlueIcon,
} from "../../icons";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TypographyNormal, TypographySmall } from "../customComponent";
import AssignedComponentActionMenu from "./AssignedComponentActionMenu";
import { purple } from "@mui/material/colors";
// import ViewerScreenshot from "../viewer/id/ViewerScreenshot";

const AssignedComponentItem = ({ type, item, sourceData }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: 2,
        ":last-child": {
          mb: 0,
        },
      }}
    >
      {type === "viewer" && <ViewerItem item={item} />}
      {type === "schedule" && <ScheduleItem item={item} />}
      {type === "group" && <GroupItem item={item} />}

      <Box sx={{ display: "flex", flexDirection: "row" }}>
        {/* TODO when implementing screenshot */}
        {/* {type === "viewer" && <ViewerScreenshot viewer={item} size="sm" />} */}

        {/* Hide menu for Instant Schedule */}
        {item?.type !== "INSTANT" && (
          <AssignedComponentActionMenu
            type={type}
            item={item}
            sourceData={sourceData}
          />
        )}
      </Box>
    </Box>
  );
};

export default AssignedComponentItem;

const ViewerItem = ({ item }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <ViewerBlueIcon />

      <Box sx={{ ml: 2 }}>
        <TypographyNormal sx={{ fontWeight: 600 }}>
          {item.name}
        </TypographyNormal>
      </Box>
    </Box>
  );
};

const ScheduleItem = ({ item }) => {
  const { t } = useTranslation("schedule");

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {item.type === "ANNOUNCEMENT" ? (
        <AnnouncementPurpleIcon />
      ) : item.type === "ADVERTISEMENT" ? (
        <AdsScheduleGreenIcon />
      ) : (
        <ScheduleYellowIcon />
      )}

      <Box sx={{ ml: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TypographyNormal
            sx={{
              fontWeight: 600,
            }}
          >
            {item.type !== "INSTANT" ? item.name : item.type}
          </TypographyNormal>

          {item.type === "INSTANT" && (
            <>
              <Box sx={{ mx: 0.5 }}> {" - "}</Box>
              <TypographyNormal
                sx={{
                  fontWeight: 600,
                  color: purple[600],
                }}
              >
                {t(`command.${item.command}`, { defaultValue: null }) ??
                  item.command}
              </TypographyNormal>
            </>
          )}
        </Box>

        {item.type.includes("SCHEDULE") && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <TypographySmall
              sx={{
                fontWeight: 600,
                color: purple[600],
                textTransform: "capitalize",
                mr: 1,
              }}
            >
              {item.command.replace(/_/g, " ").toLowerCase()}
            </TypographySmall>
            <ClockIcon />
            <TypographySmall
              display="flex"
              alignItems="center"
              sx={{ ml: 0.5, fontWeight: 600, textTransform: "uppercase" }}
            >
              {item.frequency === "OFF" ? (
                <>
                  {item.type.includes("CONTENT") ? (
                    <>
                      {t("frequency.LOOP")}
                      <Tooltip
                        arrow
                        disableInteractive
                        placement="top"
                        title={t("item.explainLOOP")}
                      >
                        <InfoOutlinedIcon
                          fontSize="1rem"
                          sx={{ marginLeft: "3px" }}
                        />
                      </Tooltip>
                    </>
                  ) : (
                    t("frequency.ONCE")
                  )}
                </>
              ) : (
                t(`frequency.${item.frequency}`, {
                  defaultValue: null,
                }) ?? item.frequency
              )}
            </TypographySmall>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const GroupItem = ({ item }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <GroupBlueIcon />

      <TypographyNormal sx={{ fontWeight: 600, ml: 2 }}>
        {item.name}
      </TypographyNormal>
    </Box>
  );
};
