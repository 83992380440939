import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  Box,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { forceLogoutUser } from "../../store/actions/userAction";
import { parseVariables } from "../../helpers/localize";
import parser from "html-react-parser";

const ForceLogoutUser = ({ user, open, onClose }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("user");
  const { t: tCommon } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    dispatch(forceLogoutUser(+user.id));

    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Box component="form" onSubmit={handleSubmit}>
        <DialogContent>
          <DialogContentText sx={{ mb: 2, textAlign: "center" }}>
            {parser(
              parseVariables(t("item.forceLogoutConfirmationText"), {
                name: user.name,
              })
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClose}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            variant="contained"
            fullWidth
            startIcon={<DoneIcon />}
            type="submit"
          >
            {tCommon("confirmBtn")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default ForceLogoutUser;
