import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  InputLabel,
  TextField,
  InputAdornment,
  Tooltip,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import CheckIcon from "@mui/icons-material/Check";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { styled } from "@mui/material/styles";
import { setLayerArray, uploadMedia } from "../../../store/actions/cmsAction";
import { getImageSource, splitFileName } from "../../../helpers/fileProcessing";
import { TypographyLarge, TypographyXLarge } from "../../customComponent";
import {
  setLayerMedias,
  setContentLayers,
  setUploadingMedia,
} from "../../../store/actions/cmsAction";
import { logger } from "../../../helpers/logger";
import { colorStyling } from "../../../helpers/color";
import update from "immutability-helper";
import { v4 as uuidv4 } from "uuid";
import "./styles.min.css";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

export const dropzoneStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  borderWidth: "2px",
  borderRadius: "2px",
  borderColor: "rgba(28, 78, 216, 0.8)",
  borderStyle: "dashed",
  backgroundColor: "rgba(28, 78, 216, 0.1)",
  height: "100%",
  ":hover": {
    backgroundColor: "rgba(28, 78, 216, 0.2)",
    borderColor: "rgba(28, 78, 216, 1)",
  },
};

const UploadField = ({
  setOnPage,
  closeMediaPicker,
  closeUploadUI,
  container,
  setLoadingFile,
  uploadedURLs,
  addUploadedURLs,
  contentState,
  directFlag,
  directFiles,
}) => {
  const dispatch = useDispatch();
  const {
    uploadingMedia,
    contentLayers,
    textLayers,
    layerArray,
    layerMedias,
    contentRes,
    widthRatio,
    heightRatio,
  } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [parsedFiles, setParsedFiles] = useState([]);
  const [tempFiles, setTempFiles] = useState([]);
  const [fileFocus, setFileFocus] = useState(null);
  const [fileNameArray, setFileNameArray] = useState([]);
  const [campaignNameArray, setCampaignNameArray] = useState([]);
  const [confirmCheckArray, setConfirmCheckArray] = useState([]);
  const [onHoverArray, setOnHoverArray] = useState([]);
  const [successCount, setSuccessCount] = useState(0);

  const [confirmationDialogue, setConfirmationDialogue] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [finishUpload, setFinishUpload] = useState(false);

  const [contents, setContents] = contentState;
  const thumbnailContainer = useRef(null);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    open: openSelectFile,
  } = useDropzone({
    accept: {
      "image/*": [],
      "video/*": [],
    },
  });

  // FOR DEV debugging purposes
  // eslint-disable-next-line no-unused-vars
  const debugInfo = () => {
    logger.log(`uploadingMedia:`, uploadingMedia);
    logger.log(`parsedFiles:`, parsedFiles);
    logger.log(`fileNameArray:`, fileNameArray);
    logger.log(`campaignNameArray:`, campaignNameArray);
    dispatch(setUploadingMedia({}));
  };

  useEffect(() => {
    dispatch(setUploadingMedia({}));
    return () => {
      setOnPage("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeAfterSelect = () => {
    logger.log(`revoking urls!`);
    uploadedURLs.forEach((url) => {
      URL.revokeObjectURL(url);
    });
    closeUploadUI();
    addUploadedURLs(null, true);
  };

  const parseSubmittedFiles = (files) => {
    const fileNames = fileNameArray;
    const campaignNames = campaignNameArray;
    const confirmChecks = confirmCheckArray;
    const onHovers = onHoverArray;
    const localURLs = [];
    const parsed = [];
    const errs = [];

    const parseImage = async (file, index) => {
      await getImageSource({ input: file }).then(
        ({ type, url, dimension, videoURL, localURL, resized, duration }) => {
          const resFile = {
            id: uuidv4(),
            index: index,
            file: file,
            fileSrc: url,
            videoSrc: videoURL,
            type: type,
            name: file.name,
            dimension: dimension,
            duration: duration,
            size: file.size,
          };
          if (resized) {
            resFile.file = resized;
            resFile.name = resized.name;
            resFile.size = resized.size;
          }
          parsed.push(resFile);

          const { name, extension } = splitFileName(file.name);
          fileNames.push({ name, ext: extension });
          campaignNames.push("");
          confirmChecks.push(true);
          onHovers.push(false);
          localURLs.push(localURL);

          if (parsed.length === files.length) {
            const updatedTempFiles = [...tempFiles, ...parsed];
            const updatedParsedFiles = [...parsedFiles, ...parsed];
            setParsedFiles(updatedParsedFiles);
            setTempFiles(updatedTempFiles);

            setFileNameArray(fileNames);
            setCampaignNameArray(campaignNames);
            setConfirmCheckArray(confirmChecks);
            setOnHoverArray(onHovers);
            addUploadedURLs(localURLs);

            if (parsedFiles.length === 0) {
              setFileFocus({ ...parsed[0], index: 0 });
              setConfirmationDialogue(true);
            } else {
              const thumbnail = thumbnailContainer.current;
              thumbnail.scrollLeft = thumbnail.scrollWidth;
              const lastIndex = updatedParsedFiles.length - 1;
              setFileFocus({
                ...updatedParsedFiles[lastIndex],
                index: lastIndex,
              });
            }

            setLoadingFile(false);
          }
        },
        ({ file, message }) => {
          // WIP
          errs.push(file);
        }
      );
    };

    for (let i = 0; i < files.length; i++) {
      parseImage(files[i], i + tempFiles.length + successCount);
    }
  };

  useEffect(() => {
    const files = directFiles;
    if (files?.length > 0) {
      setLoadingFile(true);
      parseSubmittedFiles(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [directFiles]);

  useEffect(() => {
    const files = acceptedFiles;
    if (files?.length > 0) {
      logger.log(`UploadField-acceptedFiles:`, acceptedFiles);
      setLoadingFile(true);
      setOnPage("confirm");
      parseSubmittedFiles(files);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [acceptedFiles]);

  const previewFileFocus = useMemo(() => {
    if (!fileFocus) {
      return null;
    }

    switch (fileFocus.type) {
      case "video":
        return (
          <video
            is="x-muted"
            draggable={false}
            src={fileFocus.videoSrc}
            alt={fileFocus.name}
            controls={true}
            onClick={(e) => e.preventDefault()}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        );

      case "image":
      default:
        return (
          <img
            draggable={false}
            src={fileFocus.fileSrc}
            alt={t("contentImageAltText")}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileFocus]);

  const handleFileFocus = (index) => {
    setFileFocus({ ...parsedFiles[index], index });
  };

  const changeFocusPrevious = () => {
    const currentIndex = fileFocus.index;
    if (currentIndex === 0) {
      return;
    }

    const newIndex = currentIndex - 1;
    setFileFocus({ ...parsedFiles[newIndex], index: newIndex });
  };

  const changeFocusNext = () => {
    const currentIndex = fileFocus.index;
    if (currentIndex === parsedFiles.length - 1) {
      return;
    }

    const newIndex = currentIndex + 1;
    setFileFocus({ ...parsedFiles[newIndex], index: newIndex });
  };

  const handleChangeFileName = (event, index) => {
    const value = event.target?.value ?? "";
    if (value.length === 0) {
      return;
    }

    const target = fileNameArray[index];
    const updated = { name: value, ext: target.ext };
    setFileNameArray(update(fileNameArray, { $merge: { [index]: updated } }));
  };

  const handleChangeCampaignName = (event, index) => {
    const value = event.target?.value;
    setCampaignNameArray(
      update(campaignNameArray, { $merge: { [index]: value } })
    );
  };

  const handleThumbnailHover = (index, value) => {
    setOnHoverArray(update(onHoverArray, { $merge: { [index]: value } }));
  };

  const removeMediaFromUploading = (index) => {
    const removeByIndex = (files, index) => {
      const updated = [];
      files.forEach((f, i) => {
        if (i < index) {
          updated.push(f);
          return;
        }
        if (i > index) {
          f.index = f.index - 1;
          updated.push(f);
        }
      });
      return updated;
    };

    const findIndex = (obj, i) => index !== i;
    setFileNameArray(fileNameArray.filter(findIndex));
    setCampaignNameArray(campaignNameArray.filter(findIndex));
    setConfirmCheckArray(confirmCheckArray.filter(findIndex));
    setOnHoverArray(onHoverArray.filter(findIndex));

    const updatedParsedFiles = removeByIndex(parsedFiles, index);
    setParsedFiles(updatedParsedFiles);

    const updatedTempFiles = removeByIndex(tempFiles, index - successCount);
    setTempFiles(updatedTempFiles);
    if (updatedTempFiles.length === 0) {
      setFinishUpload(true);
    }

    if (updatedParsedFiles[index]) {
      setFileFocus({ ...updatedParsedFiles[index], index: index });
    } else {
      let newIndex = index - 1;
      if (newIndex < 0) newIndex = 0;
      if (updatedParsedFiles[newIndex]) {
        setFileFocus({ ...updatedParsedFiles[newIndex], index: newIndex });
      } else {
        if (updatedParsedFiles.length === 0) {
          closeUploadUI();
        }
      }
    }
  };

  const uploadFiles = async () => {
    const payload = [];
    for (let i = 0; i < tempFiles.length; i++) {
      const index = tempFiles[i].index;
      const updatedName = `${fileNameArray[index].name}.${fileNameArray[index].ext}`;
      const file = {
        ...tempFiles[i],
        name: updatedName,
        campaignName: campaignNameArray[i],
      };
      payload.push(file);
    }

    setUploadLoading(true);
    logger.log(`uploadFiles:`, { tempFiles, fileNameArray });
    logger.log(`uploadMedia-payload:`, payload);

    dispatch(
      uploadMedia(payload, (mediaRes) => {
        let count = successCount;
        const updatedTempFiles = [];
        const updatedParsedFiles = [...parsedFiles];
        mediaRes.forEach((m) => {
          if (m.error) {
            const file = { ...updatedParsedFiles[m.index] };
            file.error = true;
            updatedTempFiles.push(file);
          } else {
            updatedParsedFiles[m.index].success = true;
            count++;
          }
        });

        setTempFiles(updatedTempFiles);
        setParsedFiles(updatedParsedFiles);
        setSuccessCount(count);

        if (updatedTempFiles.length === 0) {
          setFinishUpload(true);
        }

        setUploadLoading(false);
      })
    );
  };

  const addContents = () => {
    let newLayer = null;
    let updatedMedias = layerMedias[container.id];
    if (!updatedMedias) {
      updatedMedias = [];
      if (!contentLayers[container.id]) {
        logger.log(`new layer!`);
        const layerCount = Object.keys(contentLayers).length;
        newLayer = {
          id: container.id,
          name: "",
          type: "media",
          active: false,
          dragFlag: false,
          transition: "NONE",
          bgColor: colorStyling.cms.black,
          dimensions: {
            left: 0,
            top: 0,
            lockRatio: true,
            zIndex: layerCount + 1,
          },
        };
      }
    }

    const updatedContents = [];
    const uploadedIDs = Object.keys(uploadingMedia);
    for (let i = 0; i < uploadedIDs.length; i++) {
      const mediaData = uploadingMedia[uploadedIDs[i]];
      logger.log(`uploadingMedia [${i}]:`, mediaData);
      if (mediaData.error) {
        continue;
      }

      const parsedData = {
        index: mediaData.index,
        src: mediaData.src,
        vidSrc: mediaData.vidSrc,
        name: mediaData.name,
        campaignName: mediaData.campaignName,
        type: mediaData.type,
        id: mediaData.response?.id,
        size: mediaData.response?.size,
        organizationId: mediaData.response?.organizationId,
        textAsset: mediaData.response?.textAsset ?? null,
        unapprovedTextAsset: mediaData.response?.unapprovedTextAsset ?? null,
        interval: mediaData.duration || 5,
        assetFit: "FIT",
      };

      if (i === 0 && newLayer) {
        let fixedWidth = mediaData["dimension"].width;
        if (fixedWidth > contentRes.width) fixedWidth = contentRes.width;
        let fixedHeight = mediaData["dimension"].height;
        if (fixedHeight > contentRes.height) fixedHeight = contentRes.height;

        newLayer["dimensions"]["width"] = fixedWidth;
        newLayer["dimensions"]["height"] = fixedHeight;
      }

      parsedData["assetID"] = parsedData.id;
      updatedMedias.push(parsedData);
      updatedContents.push(parsedData);
    }

    setLoadingFile(true);
    dispatch(
      setLayerMedias({ layerID: container.id, layerMedias: updatedMedias })
    );

    let processedContentLayers = { ...contentLayers };
    let processedLayerArray = [...layerArray];
    if (newLayer) {
      processedContentLayers = update(processedContentLayers, {
        $merge: { [newLayer.id]: newLayer },
      });
      processedLayerArray.push(newLayer.id);
      dispatch(setLayerArray(processedLayerArray));
    }

    let adjustedDim;
    if (!contents || contents.length === 0) {
      const layerKeys = processedLayerArray.filter(
        (id) => !Object.keys(textLayers).includes(id)
      );

      let countIt = 0;
      let countAdjusted = 0;
      const draggedLayer = [];
      layerKeys.forEach((layerID) => {
        const layer = processedContentLayers[layerID];
        if (layer.dragFlag) {
          switch (contentRes.orientation) {
            default:
            case "P":
              if (layer.dimensions.top === 0) {
                countIt++;
              }
              break;

            case "L":
              if (layer.dimensions.left === 0) {
                countIt++;
              }
              break;
          }

          draggedLayer.push(layerID);
          return;
        }

        adjustedDim = {};
        let splitDimNum = 1 / layerKeys.length;
        switch (contentRes.orientation) {
          default:
          case "P":
            splitDimNum *= contentRes.height;
            adjustedDim.top = countIt++ * splitDimNum * (1 / heightRatio);
            adjustedDim.left = 0;
            adjustedDim.width = contentRes.width;
            adjustedDim.height = splitDimNum;
            break;

          case "L":
            splitDimNum *= contentRes.width;
            adjustedDim.top = 0;
            adjustedDim.left = countIt++ * splitDimNum * (1 / widthRatio);
            adjustedDim.width = splitDimNum;
            adjustedDim.height = contentRes.height;
            break;
        }

        adjustedDim.zIndex = ++countAdjusted;
        processedContentLayers = update(processedContentLayers, {
          [layerID]: {
            dimensions: {
              $merge: adjustedDim,
            },
          },
        });
      });

      draggedLayer.forEach((layerID) => {
        adjustedDim = {};
        const layerDim = processedContentLayers[layerID].dimensions;
        adjustedDim.zIndex = layerDim.zIndex + countAdjusted;
        processedContentLayers = update(processedContentLayers, {
          [layerID]: {
            dimensions: {
              $merge: adjustedDim,
            },
          },
        });
      });
    }

    dispatch(setContentLayers({ ...processedContentLayers }));
    setContents([...contents, ...updatedContents]);
    setFinishUpload(false);
    closeMediaPicker();
    setTimeout(() => {
      dispatch(setUploadingMedia({}));
      closeUploadUI();
      setLoadingFile(false);
    }, 1111);
  };

  const parseFileStatus = (status) => {
    switch (status) {
      default:
        return t("upload.statusPending");
      case "uploading":
        return t("upload.statusUploading");
      case "queued":
        return t("upload.statusQueue");
      case "completed":
        return t("upload.statusComplete");
      case "error":
        return t("upload.statusFailed");
    }
  };

  const disableInputCond = (index) => {
    return uploadLoading || finishUpload || parsedFiles[index].success;
  };

  if (confirmationDialogue) {
    return (
      <Box display={"flex"} flexDirection={"column"} sx={{ mb: 3 }}>
        <Box display={"flex"} alignItems={"center"}>
          <TypographyLarge width={"100%"} textAlign={"center"}>
            {t("upload.confirmationHint")}
          </TypographyLarge>
          {/* // For internal testing */}
          {/* <Button onClick={() => debugInfo()}>
            <CloseIcon />
          </Button> */}
        </Box>

        <Box
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Button
            variant="outlined"
            sx={{ height: "320px", minWidth: "fit-content", padding: 0 }}
            disabled={fileFocus.index === 0}
            onClick={() => changeFocusPrevious()}
          >
            <NavigateBeforeIcon color="inherit" sx={{ fontSize: "33px" }} />
          </Button>

          <Box width={"100%"} display={"flex"}>
            <Box width={"50%"} sx={{ mx: 1 }}>
              <TypographyXLarge sx={{ ml: 2 }}>
                {t("upload.fileLabel")} #{fileFocus.index + 1}
              </TypographyXLarge>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={"100%"}
                height={"400px"}
                sx={{
                  my: 2,
                  backgroundColor:
                    fileFocus.type === "video" ? "dimgrey" : null,
                }}
              >
                {previewFileFocus}
              </Box>

              <TypographyLarge sx={{ ml: 2, textDecoration: "underline" }}>
                {t("upload.selectedFilesLabel")}
              </TypographyLarge>

              <Box
                ref={thumbnailContainer}
                display={"flex"}
                className="thumbnails-container"
                height={"90px"}
                minWidth={"100%"}
                maxWidth={"100px"}
                sx={{ pt: 1, overflowX: "auto", paddingBottom: "4px" }}
              >
                {parsedFiles.map((file, i) => {
                  return (
                    <Box
                      key={i}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ px: 2 }}
                    >
                      <Box width={60} height={60}>
                        <img
                          draggable={false}
                          src={file.fileSrc}
                          alt={t("upload.imgThumbnailsAlt")}
                          width={60}
                          height={60}
                          onClick={() => handleFileFocus(i)}
                          onMouseEnter={() => handleThumbnailHover(i, true)}
                          onMouseLeave={() => handleThumbnailHover(i, false)}
                          style={{
                            cursor: "pointer",
                            objectFit: "fill",
                            border:
                              fileFocus.index === i
                                ? "3px solid lightseagreen"
                                : onHoverArray[i]
                                ? "2px solid limegreen"
                                : "",
                          }}
                        />
                      </Box>

                      {uploadingMedia[file.id] && (
                        <Box
                          width={60}
                          minHeight={5}
                          sx={{
                            mt: "2px",
                            position: "relative",
                          }}
                        >
                          {uploadingMedia[file.id].status === "queued" ? (
                            <LinearProgress
                              sx={{
                                height: 5,
                                borderRadius: "11px",
                              }}
                              variant="query"
                            />
                          ) : (
                            <LinearProgress
                              sx={{
                                height: 5,
                                borderRadius: "11px",
                              }}
                              variant="determinate"
                              value={uploadingMedia[file.id].progress}
                            />
                          )}

                          {uploadingMedia[file.id].status === "completed" && (
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{
                                p: "1px",
                                top: "-16px",
                                right: "-11px",
                                position: "absolute",
                                borderRadius: "999px",
                                backgroundColor: "powderblue",
                              }}
                            >
                              <CheckIcon fontSize="small" />
                            </Box>
                          )}

                          {uploadingMedia[file.id].status === "error" && (
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              sx={{
                                p: "1px",
                                top: "-16px",
                                right: "-11px",
                                position: "absolute",
                                borderRadius: "999px",
                                backgroundColor: "orangered",
                              }}
                            >
                              <CloseIcon fontSize="small" />
                            </Box>
                          )}
                        </Box>
                      )}
                    </Box>
                  );
                })}
              </Box>
            </Box>

            <Box
              width={"50%"}
              display="flex"
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Box
                height={"440px"}
                display="flex"
                flexDirection={"column"}
                justifyContent={
                  disableInputCond(fileFocus.index) ? "start" : "space-between"
                }
              >
                <Box
                  display="flex"
                  flexDirection={"column"}
                  sx={{ px: 3, pt: 6 }}
                >
                  <StyledBox>
                    <InputLabel>{t("upload.fileNameLabel")}</InputLabel>
                    <TextField
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      placeholder={t("upload.fileNamePlaceholder")}
                      size="small"
                      value={fileNameArray[fileFocus.index].name}
                      disabled={disableInputCond(fileFocus.index)}
                      onChange={(e) => handleChangeFileName(e, fileFocus.index)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ ml: "10px", fontWeight: 600 }}
                          >
                            .{fileNameArray[fileFocus.index].ext}
                          </InputAdornment>
                        ),
                      }}
                      sx={{ pl: 2 }}
                    />
                  </StyledBox>

                  <StyledBox>
                    <InputLabel>{t("upload.campaignNameLabel")}</InputLabel>
                    <TextField
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      placeholder={t("upload.campaignNamePlaceholder")}
                      size="small"
                      value={campaignNameArray[fileFocus.index]}
                      disabled={disableInputCond(fileFocus.index)}
                      onChange={(e) =>
                        handleChangeCampaignName(e, fileFocus.index)
                      }
                      sx={{ pl: 2 }}
                    />
                  </StyledBox>

                  <StyledBox>
                    <InputLabel>{t("upload.fileSizeLabel")}</InputLabel>
                    <TypographyLarge sx={{ pl: 2 }}>
                      {(fileFocus.size / (1024 * 1024)).toFixed(2)} MB
                    </TypographyLarge>
                  </StyledBox>

                  <Box display="flex" flexDirection={"column"} sx={{ pt: 1 }}>
                    <StyledBox>
                      <InputLabel>{t("upload.statusLabel")}</InputLabel>
                      <TypographyLarge sx={{ pl: 2, lineHeight: 1.5 }}>
                        {parseFileStatus(uploadingMedia[fileFocus.id]?.status)}
                      </TypographyLarge>
                    </StyledBox>
                  </Box>
                </Box>

                {!disableInputCond(fileFocus.index) ? (
                  <StyledBox sx={{ px: 3 }}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => removeMediaFromUploading(fileFocus.index)}
                    >
                      {tCommon("removeBtn")}
                    </Button>
                  </StyledBox>
                ) : (
                  <></>
                )}
              </Box>

              {!finishUpload || tempFiles.length > 0 ? (
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  sx={{ mb: "-11px" }}
                >
                  <Box display={"flex"} sx={{ mt: "10px", ml: "20px" }}>
                    <Tooltip
                      arrow
                      disableInteractive
                      title={t("upload.addMoreTip")}
                    >
                      <Button
                        sx={{
                          width: "30px",
                          minWidth: "30px",
                          height: "50px",
                          borderRadius: "16px",
                          boxShadow: "0px 10px 10px -10px rgba(0,0,0,0.4)",
                        }}
                        variant="contained"
                        disabled={uploadLoading}
                        onClick={() => openSelectFile()}
                      >
                        <AddIcon fontSize="small" />
                      </Button>
                    </Tooltip>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{ mr: "-22px" }}
                  >
                    <Button
                      sx={{
                        borderRadius: "16px",
                        boxShadow: "0px 10px 10px -10px rgba(0,0,0,0.4)",
                      }}
                      variant="contained"
                      startIcon={<UploadIcon fontSize="small" />}
                      disabled={uploadLoading}
                      onClick={() => uploadFiles()}
                    >
                      {t("upload.confirmUploadBtn")}
                    </Button>

                    <Button
                      sx={{
                        mt: 2,
                        borderRadius: "16px",
                        boxShadow: "0px 10px 10px -10px rgba(0,0,0,0.4)",
                        backgroundColor: "white",
                      }}
                      variant="outlined"
                      startIcon={<CloseIcon fontSize="small" />}
                      disabled={uploadLoading}
                      onClick={() => closeAfterSelect()}
                    >
                      {t("upload.cancelUploadBtn")}
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  justifyContent={"right"}
                  sx={{ mb: "-11px" }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{ mr: "-22px" }}
                  >
                    <Button
                      sx={{
                        borderRadius: "16px",
                        boxShadow: "0px 10px 10px -10px rgba(0,0,0,0.4)",
                      }}
                      variant="contained"
                      startIcon={<NavigateNextIcon fontSize="medium" />}
                      onClick={() => addContents()}
                    >
                      {t("upload.returnBtn")}
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Button
            variant="outlined"
            sx={{ height: "320px", minWidth: "fit-content", padding: 0 }}
            disabled={fileFocus.index === parsedFiles.length - 1}
            onClick={() => changeFocusNext()}
          >
            <NavigateNextIcon color="inherit" sx={{ fontSize: "33px" }} />
          </Button>
        </Box>
      </Box>
    );
  }

  if (directFlag) {
    return (
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        sx={{ height: "50vh" }}
      >
        <CircularProgress size={"77px"} color="inherit" />
      </Box>
    );
  }

  return (
    <Box>
      <Box sx={{ ...dropzoneStyle, minHeight: "500px" }} {...getRootProps()}>
        <input {...getInputProps()} />
        <img
          style={{ width: "200px", height: "200px" }}
          src="/assets/multimedia-icon.png"
          alt={t("upload.uploadZoneAlt")}
          draggable={false}
        />
        <p>{t("upload.uploadZoneTexts")}</p>
      </Box>

      <Box display={"flex"} justifyContent={"end"} sx={{ mt: 2 }}>
        <Button
          sx={{
            mr: 2,
            borderRadius: "16px",
            boxShadow: "0px 10px 10px -10px rgba(0,0,0,0.4)",
            backgroundColor: "white",
          }}
          variant="outlined"
          startIcon={<CloseIcon fontSize="small" />}
          onClick={() => closeUploadUI()}
        >
          {t("upload.cancelUploadBtn")}
        </Button>
      </Box>
    </Box>
  );
};

export default UploadField;
