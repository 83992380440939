import React, { useEffect, useState } from "react";
import { Close as CloseIcon } from "@mui/icons-material";
import Marquee from "react-fast-marquee";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
  InputLabel,
  TextField,
  Alert,
  AlertTitle,
} from "@mui/material";
import { styled } from "@mui/styles";
import { instanceTickerAPI } from "../../../../api";
import { bplrtAXIOS } from "../../../../config/axios";
import { handleError } from "../../../../store/handleError";
import { logger } from "../../../../helpers/logger";
import { useTranslation } from "react-i18next";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));
const TickertapeButton = () => {
  const { t } = useTranslation("bplrtSmp");

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const getTickerTape = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);
    try {
      const { data } = await bplrtAXIOS.get(instanceTickerAPI, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setText(data.text);
    } catch (error) {
      logger.error(error);
      const errorMsg = handleError(error);
      setErrorMessage(errorMsg);
      handleErrorPrompt();
    } finally {
      setLoading(false);
    }
  };

  const updateTickerTape = async (payload) => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);
    try {
      await bplrtAXIOS.patch(instanceTickerAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });
      handleSuccessPrompt();
    } catch (error) {
      logger.error(error);
      const errorMsg = handleError(error);
      setErrorMessage(errorMsg);
      handleErrorPrompt();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getTickerTape();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseErrorPrompt = () => {
    setIsError(false);
  };

  const handleCloseSuccessPrompt = () => {
    setIsSuccess(false);
  };

  const handleErrorPrompt = () => {
    setIsError(true);

    setTimeout(() => {
      handleCloseErrorPrompt();
    }, 5000);
  };

  const handleSuccessPrompt = () => {
    setIsSuccess(true);

    setTimeout(() => {
      handleCloseSuccessPrompt();
    }, 5000);
  };

  const handleSubmit = () => {
    const payload = {};
    payload["text"] = text;
    updateTickerTape(payload);
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
        fullWidth
        sx={{ boxShadow: 3 }}
      >
        {t("tickerBtn")}
      </Button>

      <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
        <Box>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {t("tickerDialogTitle")}
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {isError && (
              <Alert
                sx={{ my: 2 }}
                severity="error"
                variant="filled"
                onClose={handleCloseErrorPrompt}
              >
                <AlertTitle>{errorMessage}</AlertTitle>
              </Alert>
            )}
            {isSuccess && (
              <Alert
                sx={{ my: 2 }}
                severity="success"
                variant="filled"
                onClose={handleCloseSuccessPrompt}
              >
                <AlertTitle>{t("message.tickerSuccess")}</AlertTitle>
              </Alert>
            )}
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress size={24} sx={{ color: "#1C4ED8" }} />
              </Box>
            ) : (
              <Box sx={{ mb: "20px" }}>
                <StyledBox>
                  <InputLabel>{t("tickerTextLabel")}</InputLabel>
                  <TextField
                    fullWidth
                    autoFocus
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                  ></TextField>
                </StyledBox>
                <StyledBox>
                  <InputLabel>{t("tickerPreviewLabel")}</InputLabel>
                  <Box height={"39px"}>
                    <Marquee
                      style={{
                        backgroundColor: "black",
                        color: "yellow",
                        fontSize: "26px",
                        height: "39px",
                      }}
                    >
                      <pre
                        style={{
                          paddingRight: "80px",
                        }}
                      >
                        {text}
                      </pre>
                    </Marquee>
                  </Box>
                </StyledBox>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button fullWidth variant="outlined" onClick={handleClose}>
              {t("cancelBtn")}
            </Button>
            <Button fullWidth variant="contained" onClick={handleSubmit}>
              {t("tickerSubmitBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default TickertapeButton;
