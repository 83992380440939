import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { Clear, Done } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteGroup } from "../../../store/actions/groupAction";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";

const DeleteGroup = ({ group }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation("group");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    let payload;

    payload = { id: id };
    dispatch(deleteGroup(payload, () => navigate("/groups")));

    handleClickClose();
  };

  return (
    <>
      <Button
        startIcon={<DeleteIcon fontSize="small" />}
        color="error"
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ boxShadow: 3 }}
      >
        {t("item.deleteBtn")}
      </Button>

      <Dialog maxWidth="sm" open={open} onClose={handleClickClose}>
        <DialogContent>
          <DialogContentText sx={{ textAlign: "center", mb: 2 }}>
            {parser(
              parseVariables(t("item.deleteConfirmationText"), {
                name: group.name,
              })
            )}
          </DialogContentText>

          <Box sx={{ textAlign: "center" }}>
            <Alert severity="warning" variant="outlined">
              {parser(t("item.deleteConfirmationHint"))}
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            color="error"
            variant="outlined"
            fullWidth
            startIcon={<Clear />}
            onClick={handleClickClose}
          >
            {tCommon("cancelBtn")}
          </Button>

          <Button
            color="error"
            variant="contained"
            fullWidth
            startIcon={<Done />}
            onClick={() => handleDelete(group.id)}
          >
            {tCommon("confirmBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteGroup;
