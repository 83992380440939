import { isEmpty } from "lodash";
import {
  SET_DELETE_VIEWER,
  SET_LOADING_VIEWER,
  SET_LOADING_VIEWER_DETAIL,
  SET_VIEWER,
  SET_VIEWER_DETAIL,
  SET_SUCCESS_MESSAGE_VIEWER_PAGE,
  SET_SUCCESS_PROMPT_VIEWER_PAGE,
  SET_ERROR_PROMPT_VIEWER_PAGE,
  SET_ERROR_MESSAGE_VIEWER_PAGE,
  SET_CREATE_VIEWER,
  SET_VIEWER_FILTER,
  SET_VIEWER_PAGE,
  SET_VIEWER_ROWS_PER_PAGE,
  SET_PREFETCH_VIEWER,
  SET_ERROR_PROMPT_VIEWER_DIALOG,
  SET_ERROR_MESSAGE_VIEWER_DIALOG,
  SET_VIEWER_ASSIGNED_GROUP,
  SET_VIEWER_ASSIGNED_SCHEDULE,
} from "../actionTypes/viewerActionTypes";

const initialState = {
  viewers: { items: [], totalItem: 0 },
  viewerFilters: { sortBy: "activeAtDesc" },
  loadingViewer: false,
  viewer: {},
  page: 0,
  rowsPerPage: 5,
  loadingViewerDetail: false,
  isSuccessPromptViewerPage: false,
  isErrorPromptViewerPage: false,
  successMessageViewerPage: "",
  errorMessageViewerPage: "",
  isErrorPromptViewerDialog: false,
  errorMessageViewerDialog: "",
};
export const viewerReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_VIEWER:
      let newViewers = [...payload.items];
      newViewers.forEach((v, i) => {
        const oldViewer = state.viewers.items.find((obj) => obj.id === v.id);
        if (!isEmpty(oldViewer)) {
          newViewers[i]["schedules"] = oldViewer.schedules;
          newViewers[i]["screenGroups"] = oldViewer.screenGroups;
        }
      });
      return {
        ...state,
        viewers: { items: newViewers, totalItem: payload.totalItem },
      };

    case SET_VIEWER_FILTER:
      return {
        ...state,
        viewerFilters: payload,
      };

    case SET_CREATE_VIEWER:
      const listViewer =
        state.page === 0
          ? [payload, ...state.viewers.items]
          : [
              ...state.viewers.items.slice(0, state.page * state.rowsPerPage),
              payload,
              ...state.viewers.items.slice(state.page * state.rowsPerPage),
            ];

      return {
        ...state,
        viewers: { items: listViewer, totalItem: state.viewers.totalItem + 1 },
      };

    case SET_LOADING_VIEWER:
      return { ...state, loadingViewer: payload };

    case SET_VIEWER_DETAIL:
      const vd = state.viewers.items.find((obj) => obj.id === payload.id);
      if (!isEmpty(vd)) {
        vd["schedules"] = payload.schedules;
        vd["screenGroups"] = payload.screenGroups;
        vd["content"] = payload.content;
      }
      return {
        ...state,
        viewer: {
          ...state.viewer,
          [payload.id]: { ...(state.viewer[payload.id] ?? {}), ...payload },
        },
      };

    case SET_LOADING_VIEWER_DETAIL:
      return { ...state, loadingViewerDetail: payload };

    case SET_VIEWER_PAGE:
      return { ...state, page: payload };

    case SET_VIEWER_ROWS_PER_PAGE:
      return { ...state, rowsPerPage: payload };

    case SET_PREFETCH_VIEWER:
      let addedPrefetchViewers = [...state.viewers.items, ...payload.items];
      return {
        ...state,
        viewers: {
          items: addedPrefetchViewers,
          totalItem: payload.totalItem,
        },
      };

    case SET_VIEWER_ASSIGNED_GROUP:
      const assignedGroupData = state?.viewers?.items;
      const objIndexG = assignedGroupData?.findIndex(
        (obj) => obj.id === payload.id
      );
      if (objIndexG !== -1)
        assignedGroupData[objIndexG].screenGroups = payload?.screenGroups;
      return {
        ...state,
        viewers: {
          totalItem: state.viewers.totalItem,
          items: [...assignedGroupData],
        },
        ...(state.viewer.id === payload.id && {
          viewer: { ...state.viewer, screenGroups: payload.screenGroups },
        }),
      };

    case SET_VIEWER_ASSIGNED_SCHEDULE:
      const assignedScheduleData = state.viewers.items;
      const objIndexS = assignedScheduleData.findIndex(
        (obj) => obj.id === payload.id
      );
      if (objIndexS !== -1)
        assignedScheduleData[objIndexS]["schedules"] = payload.schedules;
      return {
        ...state,
        viewers: {
          totalItem: state.viewers.totalItem,
          items: [...assignedScheduleData],
        },
        ...(state.viewer.id === payload.id && {
          viewer: { ...state.viewer, schedules: payload.schedules },
        }),
      };

    case SET_DELETE_VIEWER:
      const viewersState = state.viewers.items;
      const filtered = viewersState.filter(
        (viewer) => viewer.id.toString() !== payload.toString()
      );
      return {
        ...state,
        viewers: { items: filtered, totalItem: state.viewers.totalItem - 1 },
      };

    case SET_SUCCESS_PROMPT_VIEWER_PAGE:
      return { ...state, isSuccessPromptViewerPage: payload };

    case SET_SUCCESS_MESSAGE_VIEWER_PAGE:
      return { ...state, successMessageViewerPage: payload };

    case SET_ERROR_PROMPT_VIEWER_PAGE:
      return { ...state, isErrorPromptViewerPage: payload };

    case SET_ERROR_MESSAGE_VIEWER_PAGE:
      return { ...state, errorMessageViewerPage: payload };
    case SET_ERROR_PROMPT_VIEWER_DIALOG:
      return { ...state, isErrorPromptViewerDialog: payload };

    case SET_ERROR_MESSAGE_VIEWER_DIALOG:
      return { ...state, errorMessageViewerDialog: payload };

    default:
      return state;
  }
};
