// Enum: "REVEAL_FROM_BOTTOM"
export const RevealFromBottom = {
  id: "revealFromBottom",
  label: "Reveal From Bottom",
  speed: 100,
  effect: {
    prev: {
      translate: [0, 0, -1],
    },
    next: {
      translate: [0, 0, 0],
    },
  },
  coverBox: {
    direction: "down",
    speed: 2200,
  },
};
