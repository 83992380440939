import React from "react";

const CompanyIcon = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="7" y="5" width="5" height="5" fill="#00D4FF" />
        <rect x="12" y="5" width="5" height="5" fill="#FFFF" />
        <rect x="7" y="10" width="5" height="5" fill="#F55858" />
        <rect x="12" y="10" width="5" height="5" fill="#244EBA" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.8001 4.80002C4.8001 3.47454 5.87461 2.40002 7.2001 2.40002H16.8001C18.1256 2.40002 19.2001 3.47454 19.2001 4.80002V19.2C19.8628 19.2 20.4001 19.7373 20.4001 20.4C20.4001 21.0628 19.8628 21.6 19.2001 21.6H15.4001C14.8478 21.6 14.4001 21.1523 14.4001 20.6V18C14.4001 17.3373 13.8628 16.8 13.2001 16.8H10.8001C10.1374 16.8 9.6001 17.3373 9.6001 18V20.6C9.6001 21.1523 9.15238 21.6 8.6001 21.6H4.8001C4.13736 21.6 3.6001 21.0628 3.6001 20.4C3.6001 19.7373 4.13736 19.2 4.8001 19.2V4.80002ZM8.4001 6.00002H10.8001V8.40002H8.4001V6.00002ZM10.8001 10.8H8.4001V13.2H10.8001V10.8ZM13.2001 6.00002H15.6001V8.40002H13.2001V6.00002ZM15.6001 10.8H13.2001V13.2H15.6001V10.8Z"
          fill="#FFBD80"
        />
      </svg>
    </>
  );
};

export default CompanyIcon;
