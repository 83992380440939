import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Paper,
  Box,
  IconButton,
  Collapse,
  Card,
  CircularProgress,
  Button,
} from "@mui/material";
import {
  Circle as CircleIcon,
  NavigateNext as NavigateNextIcon,
  KeyboardArrowDown as ArrowDownIcon,
  KeyboardArrowUp as ArrowUpIcon,
} from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { TypographyLarge, TypographyNormal } from "../customComponent";
import { AnnouncementPurpleIcon } from "../../icons";
import { getActiveAnnouncements } from "../../store/actions/homeAction";
import { colorStyling } from "../../helpers/color";

const useStyles = makeStyles(() => ({
  hoverPanel: {
    "&:hover": { backgroundColor: colorStyling.panel, cursor: "pointer" },
  },
  hoverItem: {
    "&:hover": { backgroundColor: colorStyling.hover, cursor: "pointer" },
  },
}));

const ActiveAnnouncementCard = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    activeAnnouncements: announcements,
    loadingActiveAnnouncementsData: loading,
  } = useSelector((state) => state.home);

  const { t } = useTranslation("home");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    dispatch(getActiveAnnouncements());
  }, [dispatch]);

  if (loading && announcements.totalItem === "")
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height={150}
      >
        <CircularProgress
          size={22}
          thickness={3}
          sx={{ color: colorStyling.primary }}
        />
        <TypographyNormal
          sx={{
            color: colorStyling.primary,
            marginTop: "15px",
            fontWeight: 300,
          }}
        >
          {tCommon("loadingDataList")}
        </TypographyNormal>
      </Box>
    );

  return (
    <>
      <Card
        className={classes.hoverPanel}
        elevation={2}
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onClick={() => {
          announcements?.totalItem > 0
            ? setOpen(!open)
            : navigate("/announcements");
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AnnouncementPurpleIcon />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CircleIcon sx={{ color: colorStyling.red, mr: "6px" }} />
          <TypographyLarge sx={{ fontWeight: 600 }}>
            {`${t("activeAnnouncementLabel")} `}
            {announcements.totalItem}
          </TypographyLarge>
        </Box>

        <IconButton sx={{ color: colorStyling.black }}>
          {announcements?.totalItem === 0 ? (
            <NavigateNextIcon />
          ) : open ? (
            <ArrowUpIcon />
          ) : (
            <ArrowDownIcon />
          )}
        </IconButton>
      </Card>
      <Collapse in={open} sx={{ mt: "6px" }}>
        <Box elevation={2}>
          <Box
            sx={{
              maxHeight: 325,
              overflowY: "auto",
            }}
          >
            {announcements?.items?.map((announcement) => (
              <ActiveAnnouncementItem
                announcement={announcement}
                key={announcement.id}
              />
            ))}
          </Box>

          <Box display={"flex"} justifyContent={"center"}>
            <Button
              variant="contained"
              onClick={() => navigate("/announcements")}
              fullWidth
            >
              {t("viewAllAnnouncementBtn")}
            </Button>
          </Box>
        </Box>
      </Collapse>
    </>
  );
};

const ActiveAnnouncementItem = ({ announcement }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <>
      <Paper
        onClick={() => navigate("/announcement/detail/" + announcement.id)}
        className={classes.hoverPanel}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 2,
          mb: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AnnouncementPurpleIcon />
          <TypographyNormal sx={{ ml: 2, fontWeight: 600 }}>
            {announcement.name}
          </TypographyNormal>
        </Box>

        <TypographyNormal sx={{ fontWeight: 600 }}>
          {announcement.content.name === "" ? "-" : announcement.content.name}
        </TypographyNormal>

        <IconButton sx={{ color: colorStyling.black }}>
          <NavigateNextIcon />
        </IconButton>
      </Paper>
    </>
  );
};

export default ActiveAnnouncementCard;
