import { initiateAXIOS } from "../../config/axios";
import { handleError } from "../handleError";
import Viewer from "../../models/ViewerModel";
import {
  SET_DELETE_VIEWER,
  SET_ERROR_MESSAGE_VIEWER_PAGE,
  SET_ERROR_PROMPT_VIEWER_PAGE,
  SET_LOADING_VIEWER,
  SET_LOADING_VIEWER_DETAIL,
  SET_SUCCESS_MESSAGE_VIEWER_PAGE,
  SET_SUCCESS_PROMPT_VIEWER_PAGE,
  SET_VIEWER,
  SET_VIEWER_DETAIL,
  SET_CREATE_VIEWER,
  SET_PREFETCH_VIEWER,
  SET_ERROR_PROMPT_VIEWER_DIALOG,
  SET_ERROR_MESSAGE_VIEWER_DIALOG,
  SET_VIEWER_ASSIGNED_SCHEDULE,
  SET_VIEWER_ASSIGNED_GROUP,
} from "../actionTypes/viewerActionTypes";
import {
  groupAPILimit,
  initiateViewerAPI,
  scheduleAPILimit,
  viewerAPILimit,
} from "../../api";
import Schedule from "../../models/ScheduleModel";
import Group from "../../models/GroupModel";
import { logger } from "../../helpers/logger";
import { handleTimeoutPrompt } from "../handlePrompt";

import i18n from "i18next";
const t = i18n.getFixedT(null, "viewer");

const fetchViewerById = async (id, access_token) => {
  try {
    const { data } = await initiateAXIOS.get(initiateViewerAPI + id, {
      headers: { authorization: `Bearer ${access_token}` },
    });

    const scheduleData = await fetchSchedulesData(id);

    const screenGroupData = await fetchScreenGroupData(id);

    let schedules = [];
    // Check if screens exist
    if (scheduleData)
      scheduleData.map((item) => schedules.push(new Schedule(item)));

    let screenGroups = [];
    // Check if screen groups exist
    if (screenGroupData)
      screenGroupData.map((item) => screenGroups.push(new Group(item)));

    return { ...data, schedules, screenGroups };
  } catch (e) {
    logger.error(e);
    if (e.response?.status >= 500) {
      throw new Error(JSON.stringify(e.response?.data));
    }
  }
};

const fetchSchedulesData = async (id, dispatch = null) => {
  const access_token = sessionStorage.getItem("access_token");

  try {
    const scheduleData = await initiateAXIOS.get(
      scheduleAPILimit + "&screenId=" + id,
      {
        headers: { authorization: `Bearer ${access_token}` },
      }
    );

    let schedules = [];
    // Check if screen groups exist
    if (scheduleData.data.items) {
      const initSchedules = scheduleData.data.items;
      const filteredSchedules = initSchedules.filter((schedule) => {
        return (
          schedule.type !== "INSTANT" || schedule.command !== "REFRESH_DISPLAY"
        );
      });
      filteredSchedules.map((item) => schedules.push(new Schedule(item)));
    }

    if (dispatch)
      dispatch({
        type: SET_VIEWER_ASSIGNED_SCHEDULE,
        payload: { id, schedules },
      });
    else return schedules;
  } catch (e) {
    logger.error(e);
    if (e.response?.status >= 500) {
      throw new Error(JSON.stringify(e.response?.data));
    }
  }
};

const fetchScreenGroupData = async (id, dispatch = null) => {
  const access_token = sessionStorage.getItem("access_token");

  try {
    const groupData = await initiateAXIOS.get(
      groupAPILimit + "&screenId=" + id,
      {
        headers: { authorization: `Bearer ${access_token}` },
      }
    );

    let groups = [];
    // Check if screen groups exist
    if (groupData.data.items) {
      const initGroups = groupData.data.items;
      initGroups.map((item) => groups.push(new Group(item)));
    }

    if (dispatch)
      dispatch({
        type: SET_VIEWER_ASSIGNED_GROUP,
        payload: { id, screenGroups: groups },
      });
    else return groups;
  } catch (e) {
    logger.error(e);
    if (e.response?.status >= 500) {
      throw new Error(JSON.stringify(e.response?.data));
    }
  }
};

export const getViewers = (query, isPrefetch, loading) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    try {
      const { data } = await initiateAXIOS.get(
        initiateViewerAPI + (query ?? ""),
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let viewers = [];
      const dataViewer = data.items;

      dataViewer.map((item) => viewers.push(new Viewer(item)));

      dispatch({
        type: isPrefetch ? SET_PREFETCH_VIEWER : SET_VIEWER,
        payload: {
          items: viewers,
          totalItem: data.totalItem,
        },
      });
      viewers.forEach((v) => {
        fetchSchedulesData(v.id, dispatch);
        fetchScreenGroupData(v.id, dispatch);
      });
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_VIEWER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      if (loading) loading(false);
    }
  };
};

export const getViewer = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      const { data } = await initiateAXIOS.get(initiateViewerAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const schedules = await fetchSchedulesData(id);
      const screenGroups = await fetchScreenGroupData(id);
      const dataViewer = {
        ...data,
        schedules: schedules,
        screenGroups: screenGroups,
      };

      const OOP = new Viewer(dataViewer);
      dispatch({ type: SET_VIEWER_DETAIL, payload: OOP });
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_VIEWER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const filterViewer = (query) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER, payload: true });

    try {
      const { data } = await initiateAXIOS.get(viewerAPILimit + query, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const dataViewer = data.items;
      let viewers = [];
      dataViewer.map((item) => viewers.push(new Viewer(item)));
      dispatch({
        type: SET_VIEWER,
        payload: { items: viewers, totalItem: data.totalItem },
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_VIEWER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_VIEWER, payload: false });
    }
  };
};

export const createViewer = (payload, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    try {
      const { data } = await initiateAXIOS.post(initiateViewerAPI, payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const viewerData = new Viewer(data);

      dispatch({ type: SET_CREATE_VIEWER, payload: viewerData });

      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: t("api.createMsg"),
      });

      callback("success");
      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_VIEWER_PAGE);
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_DIALOG, payload: message });
      callback("error");

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_VIEWER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    }
  };
};

export const createViewerImmediateJob = (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER, payload: true });
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      const { data } = await initiateAXIOS.post(
        initiateViewerAPI + payload.id + "/immediate",
        payload.schedule,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const OOP = new Viewer(data);

      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: t("api.createImmediateJobMsg"),
      });
      dispatch({ type: SET_VIEWER_DETAIL, payload: OOP });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_VIEWER_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_VIEWER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
      dispatch({ type: SET_LOADING_VIEWER, payload: false });
    }
  };
};

export const editViewer = ({ id, name, viewer }, callback) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      const { data } = await initiateAXIOS.patch(
        initiateViewerAPI + id,
        { name },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_VIEWER_DETAIL,
        payload: new Viewer({ ...viewer, ...data }),
      });

      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: t("api.updateMsg"),
      });

      callback("success");
      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_VIEWER_PAGE);
    } catch (e) {
      logger.error(e);
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_DIALOG, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_DIALOG, payload: message });

      callback("error");
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_VIEWER_DIALOG);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const deleteViewer = (id) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      await initiateAXIOS.delete(initiateViewerAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: t("api.deleteMsg"),
      });
      dispatch({ type: SET_DELETE_VIEWER, payload: id });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_VIEWER_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_VIEWER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const assignViewerToGroup = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateViewerAPI + id + "/screen-group",
        { add: assignData },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_VIEWER_DETAIL,
        payload: new Viewer(await fetchViewerById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: t("api.assignGroupMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_VIEWER_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_VIEWER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const removeGroupFromViewer = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateViewerAPI + id + "/screen-group",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_VIEWER_DETAIL,
        payload: new Viewer(await fetchViewerById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: t("api.removeGroupMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_VIEWER_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_VIEWER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const assignViewerToSchedule = ({ id, assignData }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateViewerAPI + id + "/schedule",
        { add: assignData },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_VIEWER_DETAIL,
        payload: new Viewer(await fetchViewerById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: t("api.assignScheduleMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_VIEWER_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_VIEWER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const removeScheduleFromViewer = ({ id, remove }) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: true });

    try {
      await initiateAXIOS.patch(
        initiateViewerAPI + id + "/schedule",
        { remove },
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch({
        type: SET_VIEWER_DETAIL,
        payload: new Viewer(await fetchViewerById(id, access_token)),
      });
      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: t("api.removeScheduleMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_VIEWER_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_VIEWER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_VIEWER_DETAIL, payload: false });
    }
  };
};

export const blockViewer = (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER, payload: true });

    try {
      await initiateAXIOS.post(initiateViewerAPI + "block", payload.data, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: t("api.blockMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_VIEWER_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_VIEWER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch(getViewers());
    }
  };
};

export const unblockViewer = (payload) => {
  const access_token = sessionStorage.getItem("access_token");

  return async (dispatch) => {
    dispatch({ type: SET_LOADING_VIEWER, payload: true });

    try {
      await initiateAXIOS.post(initiateViewerAPI + "unblock", payload, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_SUCCESS_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_VIEWER_PAGE,
        payload: t("api.unblockMsg"),
      });

      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_VIEWER_PAGE);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_VIEWER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_VIEWER_PAGE, payload: message });

      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_VIEWER_PAGE);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch(getViewers());
    }
  };
};
