// Enum: "SLIDE_FROM_BOTTOM"
export const SlideInFromBottom = {
  id: "slideInFromBottom",
  label: "Slide In From Bottom",
  speed: 2000,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "bottom center",
      translate: [0, "100%", 10],
    },
  },
};
