import React, { useState, useEffect } from "react";

import { DialogContent } from "@material-ui/core";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  InputLabel,
  Grid,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  TypographyLarge,
  CustomTextField,
} from "../../../../../components/customComponent";

import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { editSMPLinkAnnouncement } from "../../../../store/actions/bplrt/smpAction";
import { initiateAXIOS } from "../../../../../config/axios";
import { announcementAPILimit } from "../../../../../api";
import Announcement from "../../../../../models/AnnouncementModel";
import { ascending } from "../../../../../helpers/sorting";
import { logger } from "../../../../../helpers/logger";
import { parseVariables } from "../../../../../helpers/localize";
import { useTranslation } from "react-i18next";
import { colorStyling } from "../../../../../helpers/color";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const DetailLinkAnnouncement = ({ open, onClose, data, type }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("bplrtSmp");

  const initialState = {
    delayState: data.delayAnnouncementId ?? "",
    disruptionState: data.disruptionAnnouncementId ?? "",
    freeBusState: data.delayFreeBusAnnouncementId ?? "",
  };

  const { loadingLinkSMPDisruption, stationDisruptions } = useSelector(
    (state) => state.bplrtSmp
  );

  const [loading, setLoading] = useState(false);

  const [announcements, setAnnouncements] = useState([]);

  const [delaySelect, setDelaySelect] = useState(initialState.delayState);
  const [disruptionSelect, setDisruptionSelect] = useState(
    initialState.disruptionState
  );
  const [busSelect, setBusSelect] = useState(initialState.freeBusState);

  const filterAnnouncements = (announcements, id, type = null) => {
    const selectCase = (item) => {
      let filteredSelectType = true;
      switch (type) {
        case "Delay":
          filteredSelectType = +item.id !== +busSelect;
          break;
        case "FreeBus":
          filteredSelectType = +item.id !== +delaySelect;
          break;
        default:
          break;
      }
      return filteredSelectType;
    };

    const filtered = announcements.filter((item) => {
      return !stationDisruptions.some((fItem) => {
        return id !== "" && item.id === id
          ? false
          : item.id === fItem.delayAnnouncementId ||
              item.id === fItem.disruptionAnnouncementId ||
              item.id === fItem.delayFreeBusAnnouncementId;
      });
    });

    const filterSelect = filtered.filter((item) => selectCase(item));

    return ascending(filterSelect, "name");
  };

  const getAnnouncements = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);
    try {
      const { data } = await initiateAXIOS.get(
        announcementAPILimit + "&isApproved=true",
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const announcementList = data.items;

      let announcements = [];
      announcementList.map((item) =>
        announcements.push(new Announcement(item))
      );
      setAnnouncements(announcements);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) getAnnouncements();
  }, [open]);

  const handleClose = () => {
    onClose(false);
  };

  const handleLinkDelaySelect = (event) => {
    setDelaySelect(event.target.value);
  };

  const handleLinkDisruptionSelect = (event) => {
    setDisruptionSelect(event.target.value);
  };

  const handleLinkBusSelect = (event) => {
    setBusSelect(event.target.value);
  };

  const handleSubmit = (event) => {
    const payload = { id: data.id, type: "submit", announcement: {} };
    let changeExist = false;
    if (type === "Delay") {
      if (delaySelect !== "" && data.delayAnnouncementId !== delaySelect) {
        payload.announcement["delayAnnouncementId"] = +delaySelect;
        changeExist = true;
      }

      if (busSelect !== "" && data.delayFreeBusAnnouncementId !== busSelect) {
        payload.announcement["delayFreeBusAnnouncementId"] = +busSelect;
        changeExist = true;
      }
    }

    if (type === "Disruption") {
      if (
        disruptionSelect !== "" &&
        data.disruptionAnnouncementId !== disruptionSelect
      ) {
        payload.announcement["disruptionAnnouncementId"] = +disruptionSelect;
        changeExist = true;
      }
    }

    if (changeExist) {
      dispatch(editSMPLinkAnnouncement(payload));
      handleClose();
    }
  };

  const handleRemoveAnnouncement = (type) => {
    const payload = { id: data.id, type: "remove", announcement: {} };
    switch (type) {
      case "Delay":
        payload.announcement["delayAnnouncementId"] = null;
        setDelaySelect("");
        break;
      case "FreeBus":
        payload.announcement["delayFreeBusAnnouncementId"] = null;
        setBusSelect("");
        break;
      case "Disruption":
        payload.announcement["disruptionAnnouncementId"] = null;
        setDisruptionSelect("");
        break;
      default:
        return;
    }
    if (Object.keys(payload.announcement).length !== 0) {
      dispatch(editSMPLinkAnnouncement(payload));
    }
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <Box>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {parseVariables(t("linkAnnouncementDialogTitle"), { type })}
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {loadingLinkSMPDisruption || loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "290px",
            }}
          >
            <CircularProgress size={24} sx={{ color: colorStyling.primary }} />
          </Box>
        ) : (
          <DialogContent>
            <Box sx={{ mb: "20px" }}>
              <StyledBox>
                <InputLabel>{t("stationLabel")}</InputLabel>
                <TypographyLarge>
                  {data.code + " " + t("announcementLabel")}
                </TypographyLarge>
              </StyledBox>
            </Box>

            {type === "Delay" && (
              <>
                <Box sx={{ mb: "20px" }}>
                  <StyledBox>
                    <InputLabel>{t("delayAAnnouncementLabel")}</InputLabel>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <CustomTextField
                          select
                          required
                          SelectProps={{ native: true }}
                          fullWidth
                          margin="dense"
                          name="delayAnnouncementId"
                          value={delaySelect}
                          onChange={handleLinkDelaySelect}
                        >
                          <option value="" disabled>
                            {t("chooseAnnouncementLabel")}
                          </option>
                          {filterAnnouncements(
                            announcements,
                            delaySelect,
                            "Delay"
                          )?.map((announcement) => (
                            <option
                              value={announcement.id}
                              key={announcement.id}
                            >
                              {announcement.name}
                            </option>
                          ))}
                        </CustomTextField>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={t("removeDelayATooltip")}
                          placement="top"
                          disableInteractive
                        >
                          <Box>
                            <IconButton
                              sx={{ borderRadius: 2 }}
                              size="large"
                              color="error"
                              onClick={() => {
                                handleRemoveAnnouncement("Delay");
                              }}
                              disabled={
                                initialState.delayState === "" ? true : false
                              }
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </StyledBox>
                </Box>
                <Box sx={{ mb: "20px" }}>
                  <StyledBox>
                    <InputLabel>{t("delayBAnnouncementLabel")}</InputLabel>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <CustomTextField
                          select
                          required
                          SelectProps={{ native: true }}
                          fullWidth
                          margin="dense"
                          name="delayFreeBusAnnouncementId"
                          value={busSelect}
                          onChange={handleLinkBusSelect}
                        >
                          <option value="" disabled>
                            {t("chooseAnnouncementLabel")}
                          </option>
                          {filterAnnouncements(
                            announcements,
                            busSelect,
                            "FreeBus"
                          )?.map((announcement) => (
                            <option
                              value={announcement.id}
                              key={announcement.id}
                            >
                              {announcement.name}
                            </option>
                          ))}
                        </CustomTextField>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={t("removeDelayBTooltip")}
                          placement="top"
                          disableInteractive
                        >
                          <Box>
                            <IconButton
                              sx={{ borderRadius: 2 }}
                              size="large"
                              color="error"
                              onClick={() => {
                                handleRemoveAnnouncement("FreeBus");
                              }}
                              disabled={
                                initialState.freeBusState === "" ? true : false
                              }
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </StyledBox>
                </Box>
              </>
            )}

            {type === "Disruption" && (
              <>
                <Box sx={{ mb: "20px" }}>
                  <StyledBox>
                    <InputLabel>{t("disruptionAnnouncementLabel")}</InputLabel>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item xs={11}>
                        <CustomTextField
                          select
                          required
                          SelectProps={{ native: true }}
                          fullWidth
                          margin="dense"
                          name="DisruptionAnnouncementId"
                          value={disruptionSelect}
                          onChange={handleLinkDisruptionSelect}
                        >
                          <option value="" disabled>
                            {t("chooseAnnouncementLabel")}
                          </option>
                          {filterAnnouncements(
                            announcements,
                            disruptionSelect
                          )?.map((announcement) => (
                            <option
                              value={announcement.id}
                              key={announcement.id}
                            >
                              {announcement.name}
                            </option>
                          ))}
                        </CustomTextField>
                      </Grid>
                      <Grid item xs={1}>
                        <Tooltip
                          title={t("removeAnnouncementTooltip")}
                          placement="top"
                          disableInteractive
                        >
                          <Box>
                            <IconButton
                              sx={{ borderRadius: 2 }}
                              size="large"
                              color="error"
                              onClick={() => {
                                handleRemoveAnnouncement("Disruption");
                              }}
                              disabled={
                                initialState.disruptionState === ""
                                  ? true
                                  : false
                              }
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Box>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </StyledBox>
                </Box>
              </>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button fullWidth variant="outlined" onClick={handleClose}>
            {t("cancelBtn")}
          </Button>
          <Button fullWidth variant="contained" onClick={handleSubmit}>
            {t("linkAnnouncementBtn")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default DetailLinkAnnouncement;
