import React, { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, InputLabel, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import TitleIcon from "@mui/icons-material/Title";
import ContainerItem from "./ContainerItem";
import {
  setContentLayers,
  setLayerArray,
  setLayerMedias,
  setTextLayers,
} from "../../../../store/actions/cmsAction";
import { TypographyNormal } from "../../../customComponent";
import update from "immutability-helper";
import { v4 as uuidv4 } from "uuid";
import { colorStyling } from "../../../../helpers/color";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const ContainersList = ({ resType }) => {
  const dispatch = useDispatch();
  const {
    contentLayers,
    textLayers,
    layerMedias,
    layerArray,
    contentRes,
    widthRatio,
    heightRatio,
  } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const addContainer = useCallback(
    (type) => {
      const newLayer = {
        id: uuidv4(),
        name: "",
        type: type,
        active: false,
        dragFlag: false,
        transition: "NONE",
      };

      const layerCount = Object.keys(contentLayers).length;
      const initDim = {
        left: 0,
        top: 0,
        zIndex: layerCount + 1,
      };

      switch (type) {
        case "media":
          newLayer["bgColor"] = colorStyling.cms.black;
          initDim["width"] = Math.round(200 * widthRatio);
          initDim["height"] = Math.round(200 * heightRatio);
          if (initDim["width"] > contentRes.width) {
            initDim["width"] = contentRes.width;
          }
          if (initDim["height"] > contentRes.height) {
            initDim["height"] = contentRes.height;
          }
          initDim["lockRatio"] = false;
          break;

        case "text":
          newLayer["bgColor"] = colorStyling.cms.darkgray;
          initDim["width"] = contentRes.width;
          initDim["height"] = Math.round(50 * heightRatio);
          initDim["lockRatio"] = false;
          const initText = {
            text: t("designer.enterTextPlaceholder"),
            family: "ARIAL",
            size: "44",
            weight: "400",
            color: colorStyling.cms.black,
            hAlign: "LEFT",
            vAlign: "CENTER",
            scrolling: true,
            direction: "LEFT",
            speed: 1,
          };

          newLayer["text"] = initText;
          dispatch(setTextLayers({ add: { [newLayer.id]: { edit: false } } }));
          break;

        default:
          break;
      }

      newLayer["dimensions"] = initDim;
      const updatedLayers = update(contentLayers, {
        $merge: { [newLayer.id]: newLayer },
      });

      const updatedLayerArray = [...layerArray];
      updatedLayerArray.push(newLayer.id);
      dispatch(setLayerArray(updatedLayerArray));
      dispatch(setContentLayers({ ...updatedLayers }));
      handleCloseMenu();
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentLayers, contentRes, widthRatio, heightRatio]
  );

  const removeContainer = useCallback(
    (id, type) => {
      const updatedLayerArray = layerArray.filter((layerID) => layerID !== id);
      dispatch(setLayerArray(updatedLayerArray));

      const updatedLayers = update(contentLayers, { $unset: [id] });
      const updatedMedias = update(layerMedias, { $unset: [id] });
      dispatch(setContentLayers({ ...updatedLayers }));
      dispatch(setLayerMedias({ forceUpdate: { ...updatedMedias } }));
      if (type === "text") {
        const updatedTextLayers = update(textLayers, { $unset: [id] });
        dispatch(setTextLayers({ ...updatedTextLayers }));
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentLayers, layerMedias, textLayers]
  );

  return (
    <Box sx={{ mb: 8 }}>
      <StyledBox>
        <InputLabel>{t("designer.layerListLabel")}</InputLabel>

        <Box sx={{ mt: 2 }}>
          {Object.keys(contentLayers).map((key, index) => {
            const { id } = contentLayers[key];
            return (
              <ContainerItem
                key={id}
                index={index + 1}
                container={contentLayers[key]}
                removeContainer={removeContainer}
              />
            );
          })}
        </Box>

        <Box
          sx={{ width: "100%" }}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          {resType === "" && (
            <p style={{ color: "red" }}>{t("designer.selectResHint")}</p>
          )}

          <Button
            variant="contained"
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              boxShadow: 3,
              marginTop: resType === "" ? 2 : undefined,
            }}
            disabled={resType === ""}
            onClick={handleOpenMenu}
          >
            {t("designer.layerAddBtn")}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            elevation={0}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            PaperProps={{
              sx: {
                boxShadow: 3,
              },
            }}
            sx={{ marginTop: 1 }}
          >
            <MenuItem
              onClick={() => addContainer("media")}
              sx={{ display: "flex" }}
            >
              <PermMediaIcon sx={{ mx: 1 }} />
              <TypographyNormal sx={{ ml: 1 }}>
                {t("designer.layerAddMediaApps")}
              </TypographyNormal>
            </MenuItem>

            <MenuItem
              onClick={() => addContainer("text")}
              sx={{
                display: "flex",
              }}
            >
              <TitleIcon sx={{ mx: 1 }} />
              <TypographyNormal sx={{ ml: 1 }}>
                {t("designer.layerAddText")}
              </TypographyNormal>
            </MenuItem>
          </Menu>
        </Box>
      </StyledBox>
    </Box>
  );
};
export default ContainersList;
