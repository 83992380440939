import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  CircularProgress,
  Box,
  Alert,
  TablePagination,
  Paper,
} from "@mui/material";
import { TypographyNormal } from "../customComponent";
import AnnouncementItem from "./AnnouncementItem";
import { getAnnouncements } from "../../store/actions/announcementAction";
import { colorStyling } from "../../helpers/color";
import { filterObjtoString } from "../../helpers/filterHelper";
import {
  SET_ANNOUNCEMENT_PAGE,
  SET_ANNOUNCEMENT_ROWS_PER_PAGE,
  SET_LOADING_ANNOUNCEMENT,
} from "../../store/actionTypes/announcementActionType";
import ContentDetails from "../cms/contentTemplate/detail/ContentDetails";
import { parseVariables } from "../../helpers/localize";

const AnnouncementList = () => {
  const dispatch = useDispatch();
  const {
    announcements,
    announcementFilters,
    loadingAnnouncement: loading,
    page,
    rowsPerPage,
  } = useSelector((state) => state.announcement);

  const { t: tCommon } = useTranslation();

  const scrollRef = useRef(null);

  const [openContentDetail, setOpenContentDetail] = useState(false);
  const [contentDetailId, setContentDetailId] = useState(null);

  const handleOpenContentDetail = (id) => {
    setContentDetailId(id);
    setOpenContentDetail(true);
  };

  const handleCloseContentDetail = (e) => {
    setOpenContentDetail(false);
  };

  const handleLoading = (value) => {
    dispatch({ type: SET_LOADING_ANNOUNCEMENT, payload: value });
  };

  const handleChangePagination = (e, page) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    dispatch({ type: SET_ANNOUNCEMENT_PAGE, payload: page });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: SET_ANNOUNCEMENT_ROWS_PER_PAGE,
      payload: parseInt(event.target.value, 10),
    });
    handleChangePagination(null, 0);
  };

  const fetchData = async (filterObj) => {
    let isPrefetch = false;
    let limit = rowsPerPage;
    let offset = page * rowsPerPage;
    if (offset === 0) {
      limit = limit * 2; //fetch 2 page worth of data
      isPrefetch = false;
    } else {
      offset = (page + 1) * rowsPerPage;
      isPrefetch = true;
    }
    let filterString = filterObjtoString(filterObj, limit, offset);

    const announcementsLength = announcements?.items?.length;
    if (announcementsLength === 0) handleLoading(true);
    if (
      announcementsLength !== announcements.totalItem ||
      announcementsLength === 0 ||
      offset === 0
    )
      dispatch(getAnnouncements(filterString, isPrefetch, handleLoading));
  };

  useEffect(() => {
    fetchData(announcementFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (page === 0) {
      fetchData(announcementFilters);
    } else handleChangePagination(null, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementFilters]);

  const displayedRowsLabel = ({ from, to, count }) => {
    return parseVariables(tCommon("table.displayedRowsLabel"), {
      prefix: `${from}–${to}`,
      suffix:
        count !== -1
          ? count
          : `${tCommon("table.displayedRowsMoreThan")} ${to}`,
    });
  };

  return (
    <>
      <ContentDetails
        open={openContentDetail}
        handleClose={handleCloseContentDetail}
        contentID={contentDetailId}
      />
      <Box>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ height: "20vh" }}
          >
            <CircularProgress
              size={20}
              thickness={3}
              sx={{ color: colorStyling.primary }}
            />
            <TypographyNormal
              sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
            >
              {tCommon("loadingDataList")}
            </TypographyNormal>
          </Box>
        ) : (
          <>
            {!announcements.items.length ? (
              <Paper elevation={2} sx={{ borderRadius: "6px", p: 2 }}>
                <Alert severity="info" variant="outlined">
                  <strong>{tCommon("noData")}</strong>
                </Alert>
              </Paper>
            ) : (
              <Paper
                component="section"
                elevation={2}
                sx={{ p: 2, my: 2, borderRadius: "6px" }}
              >
                <Box ref={scrollRef} sx={{ overflowY: "auto", maxHeight: 500 }}>
                  {announcements?.items
                ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                ?.map((announcement) => (
                  <AnnouncementItem
                    key={announcement.id}
                    announcement={announcement}
                    dispatch={dispatch}
                    openContent={handleOpenContentDetail}
                  />
                    ))}
                </Box>
              </Paper>
            )}
          </>
        )}
      </Box>

      {(!loading || announcements?.totalItem > 0) && (
        <Box
          sx={{
            pt: 2,
            pb: 1,
            pr: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Paper component="div" elevation={1} sx={{ px: 1 }}>
            <TablePagination
              component="div"
              count={announcements.totalItem}
              page={page}
              onPageChange={handleChangePagination}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={displayedRowsLabel}
              labelRowsPerPage={tCommon("table.rowsPerPageLabel")}
            />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default AnnouncementList;
