import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import CloseIcon from "@mui/icons-material/Close";
import { initiateAXIOS } from "../../../config/axios";
import { assetCMSAPILimit } from "../../../api";
import { colorStyling } from "../../../helpers/color";
import { TypographyNormal } from "../../customComponent";
import AssetSelectorItem from "./AssetSelectorItem";
import { logger } from "../../../helpers/logger";
import { parseVariables } from "../../../helpers/localize";

const CampaignSelectorItem = ({ data }) => {
  const { t } = useTranslation("contentreport");
  const { t: tCommon } = useTranslation();

  const [openCampaignAssets, setOpenCampaignAssets] = useState(false);
  const [loadingCampaignAsset, setLoadingCampaignAsset] = useState(false);
  const [campaignAssetsData, setCampaignAssetsData] = useState([]);

  const fetchCampaignAssetData = async (name) => {
    const access_token = sessionStorage.getItem("access_token");
    setLoadingCampaignAsset(true);
    try {
      const query = "&sortBy=idAsc&type=IMAGE&type=VIDEO&campaignName=" + name;
      const { data } = await initiateAXIOS.get(assetCMSAPILimit + query, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      setCampaignAssetsData(data.items);
    } catch (e) {
      const errorMessage = e.response.data.message;
      logger.log(errorMessage);
    } finally {
      setLoadingCampaignAsset(false);
    }
  };

  const handleOpenCampaignAsset = () => {
    setOpenCampaignAssets(true);
    fetchCampaignAssetData(data.name);
  };
  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        flexWrap={"nowrap"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box sx={{ mr: 1.5 }}>
          <Tooltip title={t("export.viewCampaignAssetsTip")} placement="top">
            <IconButton
              sx={{
                color: "white",
                backgroundColor: colorStyling.primary,
                "&:hover": {
                  backgroundColor: colorStyling.blue.deep,
                },
              }}
              onClick={() => handleOpenCampaignAsset()}
            >
              <ImageSearchIcon sx={{ pl: 0.3 }} />
            </IconButton>
          </Tooltip>
        </Box>

        <Tooltip title={data.name} placement="top">
          <Box
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              textWrap: "nowrap",
              maxWidth: "200px",
            }}
          >
            {data.name}
          </Box>
        </Tooltip>
      </Box>

      <Dialog
        maxWidth="xs"
        open={openCampaignAssets}
        onClose={() => setOpenCampaignAssets(false)}
        fullWidth
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textTransform: "capitalize",
          }}
        >
          {parseVariables(t("export.campaignAssetsTitle"), {
            name: data.name,
          })}
          <IconButton onClick={() => setOpenCampaignAssets(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider />

        <DialogContent>
          <Box>
            {parseVariables(t("export.campaignAssetsTotalHint"), {
              count: campaignAssetsData.length,
            })}
          </Box>
          <Box
            sx={{
              maxHeight: 150,
              overflowY: "auto",
              p: 1,
              borderRadius: "6px",
              border: `1px solid ${colorStyling.white.border}`,
              mt: 1,
            }}
          >
            {!loadingCampaignAsset && !campaignAssetsData.length ? (
              <Alert severity="info" variant="outlined">
                <strong>{t("export.campaignAssetsNoneFound")}</strong>
              </Alert>
            ) : null}

            {loadingCampaignAsset && (
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                sx={{ py: 1 }}
              >
                <CircularProgress
                  size={20}
                  thickness={3}
                  sx={{ color: colorStyling.primary }}
                />
                <TypographyNormal
                  sx={{
                    color: colorStyling.primary,
                    marginTop: "15px",
                    fontWeight: 300,
                  }}
                >
                  {tCommon("loadingDataList")}
                </TypographyNormal>
              </Box>
            )}

            {!loadingCampaignAsset &&
              campaignAssetsData?.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    py: 1,
                    pl: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <AssetSelectorItem data={item} />
                </Box>
              ))}
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => setOpenCampaignAssets(false)}
          >
            {tCommon("closeBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CampaignSelectorItem;
