import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@mui/material";
import { SET_SUCCESS_PROMPT_ASSET_PAGE } from "../../../store/actionTypes/assetActionType";

const AssetsManagerSuccessPrompt = () => {
  const dispatch = useDispatch();
  const { t: tCommon } = useTranslation();

  const {
    isSuccessPromptAssetPage: isSuccess,
    successMessageAssetPage: successMessage,
  } = useSelector((state) => state.asset);

  const handleClose = (event, reason) => {
    dispatch({ type: SET_SUCCESS_PROMPT_ASSET_PAGE, payload: false });
  };

  return (
    isSuccess && (
      <Alert
        sx={{ my: 2 }}
        severity="success"
        variant="filled"
        onClose={handleClose}
      >
        <AlertTitle>{tCommon("successPromptTitle")}</AlertTitle>
        {successMessage}
      </Alert>
    )
  );
};

export default AssetsManagerSuccessPrompt;
