export const APP_ENV = process.env.REACT_APP_ENV;

/** Logger create outputs to the browser console */
export class ConsoleLogger {
  constructor(options) {
    const { level } = options || {};

    this.error = console.error.bind(console);
    this.warn = console.warn.bind(console);
    this.log = console.log.bind(console);

    switch (level) {
      case "prod":
        this.error = () => {};
        this.warn = () => {};
        this.log = () => {};
        return;

      case "staging":
        this.log = () => {};
        return;

      default:
      case "dev":
        return;
    }
  }
}

export const logger = new ConsoleLogger({ level: APP_ENV });
