import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { SET_CONTENT_REPORT_FILTER } from "../../../store/actionTypes/reportingActionType";
import { colorStyling } from "../../../helpers/color";
import dayjs from "dayjs";

const initialDate = {
  start: dayjs().subtract(1, "M").format("YYYY-MM-DD"),
  end: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
};

// TODO: Better reset filter
const initFilter = {
  name: "",
  type: "",
  recordedAtStart: initialDate.start,
  recordedAtEnd: initialDate.end,
  createdAtGte: initialDate.start,
  createdAtLte: initialDate.end,
  sortBy: "idDesc",
};

const ContentReportFilter = () => {
  const dispatch = useDispatch();
  const { contentReportFilters } = useSelector((state) => state.reporting);

  const { t } = useTranslation("contentreport");
  const { t: tCommon } = useTranslation();

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [recordedAtStart, setRecordedAtStart] = useState(initialDate.start);
  const [recordedAtEnd, setRecordedAtEnd] = useState(initialDate.end);

  useEffect(() => {
    setName(contentReportFilters.nameLike);
    setType(contentReportFilters.type);

    let start = contentReportFilters.recordedAtStart;
    if (start?.length === 0) {
      start = initialDate.start;
    }
    setRecordedAtStart(start);

    let end = contentReportFilters.recordedAtEnd;
    if (end?.length === 0) {
      end = initialDate.end;
    }
    setRecordedAtEnd(end);
  }, [contentReportFilters]);

  const handleChangeStartDate = (value) => {
    setRecordedAtStart(value);

    if (dayjs(recordedAtEnd).diff(dayjs(value), "day") < 0)
      setRecordedAtEnd(value);
  };

  const handleChangeEndDate = (value) => {
    setRecordedAtEnd(value);
  };

  const handleDispatchFilter = (payload) => {
    dispatch({
      type: SET_CONTENT_REPORT_FILTER,
      payload,
    });
  };

  const handleResetState = () => {
    setName("");
    setType("");
    setRecordedAtStart(initialDate.start);
    setRecordedAtEnd(initialDate.end);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let filterPayload = { ...contentReportFilters };
    delete filterPayload["type"];
    delete filterPayload["nameLike"];

    if (type) {
      filterPayload["type"] = type;
    }

    if (name) {
      filterPayload["nameLike"] = name;
    }

    delete filterPayload["recordedAtStart"];
    delete filterPayload["recordedAtEnd"];
    if (recordedAtStart) {
      filterPayload["recordedAtStart"] = recordedAtStart;
      filterPayload["recordedAtEnd"] = recordedAtEnd;
      const startDate = dayjs(recordedAtStart).format("YYYY-MM-DD");
      const endDate = dayjs(recordedAtEnd).format("YYYY-MM-DD");
      filterPayload = {
        ...filterPayload,
        createdAtGte: startDate,
        createdAtLte: endDate,
      };
    }

    handleDispatchFilter({ ...filterPayload });
  };

  const handleRefresh = () => {
    handleResetState();
    handleDispatchFilter(initFilter);
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        my: 0.5,
      }}
      onSubmit={handleSubmit}
    >
      <TextField
        fullWidth
        size="small"
        placeholder={t("filter.searchPlaceholder")}
        type="search"
        value={name}
        onChange={(e) => setName(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ mr: 2 }}
      />

      <TextField
        size="small"
        fullWidth
        select
        SelectProps={{ native: true }}
        value={type}
        onChange={(e) => setType(e.target.value)}
        sx={{ mr: 2, maxWidth: "200px" }}
      >
        <option defaultValue value="">
          {t("filter.searchTypeAny")}
        </option>
        <option value="IMAGE">{t("filter.searchTypeImage")}</option>
        <option value="VIDEO">{t("filter.searchTypeVideo")}</option>
      </TextField>

      <Box display={"flex"} alignItems={"center"} flexWrap={"nowrap"}>
        <Tooltip
          title={t("filter.startDateInputTip")}
          placement="top"
          arrow
          leaveDelay={200}
        >
          <TextField
            type="date"
            sx={{ mr: 1, maxWidth: 185 }}
            size="small"
            fullWidth
            value={recordedAtStart}
            onChange={(e) => {
              handleChangeStartDate(e.target.value);
            }}
            InputProps={{
              inputProps: {
                max: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
              },
            }}
          />
        </Tooltip>
        <Box sx={{ mr: 1 }}>{" - "}</Box>
        <Tooltip
          title={t("filter.endDateInputTip")}
          placement="top"
          arrow
          leaveDelay={200}
        >
          <TextField
            type="date"
            sx={{ mr: 2, maxWidth: 185 }}
            size="small"
            fullWidth
            value={recordedAtEnd}
            onChange={(e) => {
              handleChangeEndDate(e.target.value);
            }}
            InputProps={{
              inputProps: {
                min: dayjs(recordedAtStart).format("YYYY-MM-DD"),
                max: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
              },
            }}
          />
        </Tooltip>
      </Box>

      <Button type="submit" variant="contained">
        {tCommon("searchBtn")}
      </Button>

      <IconButton
        onClick={handleRefresh}
        sx={{ color: colorStyling.black, ml: 1 }}
      >
        <Tooltip title={tCommon("resetFilterTip")}>
          <RestartAltIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </Box>
  );
};

export default ContentReportFilter;
