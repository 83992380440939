import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { TypographyNormal } from "../customComponent";
import { usePagination } from "../hooks";
import { getOrganizations } from "../../store/actions/organizationAction";
import OrganizationItem from "./OrganizationItem";
import { colorStyling } from "../../helpers/color";

const OrganizationList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrganizations());
  }, [dispatch]);

  const { organizations, loadingOrganization } = useSelector(
    (state) => state.organization
  );

  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
    _DATA.jump(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations]);

  const DATA_PER_PAGE = 5;

  const count = Math.ceil(organizations.length / DATA_PER_PAGE);

  const _DATA = usePagination(organizations, DATA_PER_PAGE);

  const handleChangePagination = (event, page) => {
    setPage(page);
    _DATA.jump(page);
  };

  if (loadingOrganization)
    return (
      <Box
        sx={{ height: "20vh" }}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress
          size={20}
          thickness={3}
          sx={{ color: colorStyling.primary }}
        />
        <TypographyNormal sx={{ color: colorStyling.primary, mt: "15px" }}>
          Loading Data...
        </TypographyNormal>
      </Box>
    );

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: 3, borderRadius: "6px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {organizations &&
              _DATA
                .currentData()
                .map((organization) => (
                  <OrganizationItem
                    key={organization.id}
                    organization={organization}
                  />
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          pt: 4,
          pb: 2,
          pr: 2,
        }}
      >
        <Pagination
          count={count}
          page={page}
          shape="rounded"
          color="primary"
          onChange={handleChangePagination}
        />
      </Box>
    </>
  );
};

export default OrganizationList;
