import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { editUserGroup } from "../../../store/actions/userAction";
import { TypographyLarge } from "../../customComponent";
import {
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_PROMPT_USER_DIALOG,
  SET_ERROR_PROMPT_USER_PAGE,
} from "../../../store/actionTypes/userActionType";
import { initiateAXIOS } from "../../../config/axios";
import { initiatePermissionAPI, initiateUserGroupAPI } from "../../../api";
import { handleError } from "../../../store/handleError";
import { colorStyling } from "../../../helpers/color";
import { cloneArray } from "../../../helpers/arrayHelper";
import { isEmpty } from "lodash";
import DispatchErrorPromptAlert from "../../DispatchErrorPromptAlert";
import { checkUserGroupTooltipPermission } from "../../../helpers/localize";

const EditUserGroup = ({ group, open, onClose, editPermission }) => {
  const dispatch = useDispatch();
  const {
    isErrorPromptUserDialog: isError,
    errorMessageUserDialog: errorMessage,
  } = useSelector((state) => state.user);

  const { t } = useTranslation("usergroup");
  const { t: tCommon } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [groupName, setGroupName] = useState(group.name);
  const [initPermission, setInitPermission] = useState({});
  const [permission, setPermission] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const getInitPermission = (initPermission, permission) => {
    let initPerm = cloneArray(initPermission);
    Object.keys(initPerm).forEach((key) => {
      Object.keys(initPerm[key]).forEach((subKey) => {
        initPerm[key][subKey] = permission[key][subKey];
      });
    });

    return initPerm;
  };

  const handleToggleEdit = (value) => {
    if (!value) setPermission({ ...initPermission });
    setIsEditing(value);
  };

  const handleClickClose = () => {
    onClose(false);
    setGroupName(group.name);
    setIsEditing(false);
    handleChangeAll(false);
  };

  const handleGroupName = (e) => {
    setGroupName(e.target.value);
  };

  const handleFinishSubmit = (stateSubmit) => {
    setLoading(false);
    if (stateSubmit === "success") {
      handleClickClose();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      permission,
    };

    if (groupName !== group.name) payload["name"] = groupName;

    if (!isEmpty(payload.permission) || payload.name) {
      setLoading(true);
      dispatch(editUserGroup({ id: group.id, payload }, handleFinishSubmit));
    } else {
      handleClickClose();
    }
  };

  const handleChangePermission = (e, menu, perm) => {
    setPermission({
      ...permission,
      [menu]: { ...permission[menu], [perm]: e.target.checked },
    });
  };

  const handleChangeAll = (val) => {
    let newObj = {};
    Object.keys(permission).forEach((m) => {
      Object.keys(permission[m]).forEach((p) => {
        newObj = { ...newObj, [m]: { ...newObj[m], [p]: val } };
      });
    });
    setPermission(newObj);
  };

  const getUserGroups = async (id) => {
    const access_token = sessionStorage.getItem("access_token");
    if (isEmpty(initPermission)) setLoading(true);

    try {
      const { data } = await initiateAXIOS.get(initiateUserGroupAPI + id, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const permissionData = await initiateAXIOS.get(initiatePermissionAPI, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const permData = getInitPermission(permissionData.data, data.permission);
      setInitPermission(permData);
      setPermission(permData);
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_USER_PAGE, payload: message });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      getUserGroups(group.id);
      setGroupName(group.name);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={handleClickClose}>
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {t("item.detailsTitle")}
          <IconButton onClick={handleClickClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DispatchErrorPromptAlert
            isError={isError}
            errorMessage={errorMessage}
            closeActionType={SET_ERROR_PROMPT_USER_DIALOG}
          />

          {editPermission && (
            <FormControlLabel
              control={
                <Switch
                  checked={isEditing}
                  onChange={(event) => handleToggleEdit(event.target.checked)}
                  name={t("form.editSwitch")}
                  disabled={loading}
                />
              }
              label={t("form.editSwitch")}
            />
          )}

          <Box sx={{ mb: 2 }}>
            <InputLabel>{t("form.groupNameLabel")}</InputLabel>
            <TextField
              fullWidth
              size="small"
              margin="dense"
              name="groupName"
              value={groupName}
              onChange={handleGroupName}
              onKeyDown={(e) => e.stopPropagation()}
              placeholder={t("form.groupNamePlaceholder")}
              required
              disabled={!isEditing || loading}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: colorStyling.black,
                  backgroundColor: colorStyling.white.panel,
                },
              }}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            <Box
              sx={{ mb: 1 }}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <InputLabel sx={{ my: "5.5px" }}>
                {t("form.permissionLabel")}
              </InputLabel>
              {isEditing && (
                <Box>
                  <Button
                    sx={{ mr: "4px" }}
                    onClick={() => handleChangeAll(true)}
                    variant="contained"
                    size="small"
                    disabled={loading}
                  >
                    {t("form.checkAllBtn")}
                  </Button>
                  <Button
                    onClick={() => handleChangeAll(false)}
                    variant="contained"
                    size="small"
                    disabled={loading}
                  >
                    {t("form.uncheckAllBtn")}
                  </Button>
                </Box>
              )}
            </Box>

            {loading && isEmpty(initPermission) ? (
              <Box
                sx={{ width: "100%", height: 400 }}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Grid container maxHeight={500} sx={{ overflowY: "auto", px: 2 }}>
                {Object.keys(permission).map((key) => (
                  <Grid item md={3} sx={{ mb: 1 }} key={key}>
                    <TypographyLarge
                      sx={{
                        fontWeight: 600,
                        color: colorStyling.black,
                        textTransform: "capitalize",
                      }}
                    >
                      {key === "assetLog"
                        ? t("form.contentReportLabel")
                        : key.replace(/([A-Z])/g, " $1").trim()}
                    </TypographyLarge>
                    <Box display={"flex"} flexDirection={"column"}>
                      {Object.keys(permission[key]).map((perm) => (
                        <Tooltip
                          title={
                            checkUserGroupTooltipPermission[perm]
                              ? t(`tooltip.${perm}`)
                              : ""
                          }
                          placement="top-start"
                        >
                          <Box display={"flex"} sx={{ mr: 1 }}>
                            <FormControlLabel
                              key={key + perm}
                              sx={{
                                ...(!isEditing && { cursor: "default" }),
                                mr: 0.4,
                              }}
                              control={
                                <Checkbox
                                  size="small"
                                  sx={{
                                    ...(!isEditing && {
                                      cursor: "default",
                                      color: colorStyling.primary,
                                      backgroundColor: colorStyling.white.panel,
                                      width: 16,
                                      height: 16,
                                      m: "10px",
                                      borderRadius: 1,
                                      "&:hover": {
                                        backgroundColor:
                                          colorStyling.white.panel,
                                      },
                                      "&.Mui-checked": {
                                        color: colorStyling.white.panel,
                                        backgroundColor: colorStyling.primary,
                                      },
                                    }),
                                  }}
                                />
                              }
                              checked={permission[key][perm]}
                              {...(isEditing && {
                                onChange: (e) => {
                                  handleChangePermission(e, key, perm);
                                },
                              })}
                              label={tCommon(`permission.${perm}`)}
                              disabled={loading}
                            />

                            {perm === "alert" && (
                              <InfoOutlinedIcon fontSize="10" />
                            )}
                          </Box>
                        </Tooltip>
                      ))}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}
          </Box>
        </DialogContent>

        <DialogActions>
          {isEditing ? (
            <>
              <Button
                onClick={() => handleToggleEdit(false)}
                variant="outlined"
                fullWidth
                disabled={loading}
              >
                {tCommon("cancelBtn")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : tCommon("saveBtn")}
              </Button>
            </>
          ) : (
            <Button onClick={handleClickClose} variant="outlined" fullWidth>
              {tCommon("closeBtn")}
            </Button>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditUserGroup;
