import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PreviewIcon from "@mui/icons-material/Preview";
import { colorStyling } from "../../../helpers/color";
import { TypographyNormal } from "../../customComponent";
import { instanceCMSAssetAPI } from "../../../api";
import { initiateAXIOS } from "../../../config/axios";
import { parseVariables } from "../../../helpers/localize";

const ContentReportViewAsset = ({ asset }) => {
  const { t } = useTranslation("contentreport");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assetUrl, setAssetUrl] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleOpen = async () => {
    getMedia();
    setOpen(true);
  };

  const getMedia = async () => {
    const access_token = sessionStorage.getItem("access_token");
    setLoading(true);

    try {
      const { data } = await initiateAXIOS.get(
        instanceCMSAssetAPI + "/" + asset.assetId,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      setAssetUrl(data.url);
    } catch (e) {
      const errorData = JSON.parse(await e.response.data.text());
      const errorMessage = errorData.message;
      setIsError(true);
      setErrorMsg(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleCloseAlert = () => {
    setIsError(false);
  };

  return (
    <>
      <Box sx={{ ml: 2 }}>
        <Tooltip title={t("table.previewAssetTip")} placement="top-start">
          <Box>
            <IconButton
              variant="contained"
              disabled={
                !(asset.assetType === "IMAGE" || asset.assetType === "VIDEO")
              }
              onClick={handleOpen}
              sx={{
                color: colorStyling.primary,
              }}
            >
              <PreviewIcon />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tooltip
            title={asset.assetName?.length > 20 ? asset.assetName : ""}
            placement="top-end"
          >
            <Box>
              {`${t("table.previewAssetPrefix")} [${
                asset.assetName?.length > 20
                  ? asset.assetName.substring(0, 19) + "..."
                  : asset.assetName
              }]`}
            </Box>
          </Tooltip>

          <IconButton onClick={handleClickClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {isError && (
            <Alert
              severity="error"
              variant="outlined"
              onClose={handleCloseAlert}
            >
              {errorMsg}
            </Alert>
          )}

          {loading && assetUrl === "" ? (
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              sx={{ py: 1 }}
            >
              <CircularProgress
                size={20}
                thickness={3}
                sx={{ color: colorStyling.primary }}
              />
              <TypographyNormal
                sx={{
                  color: colorStyling.primary,
                  marginTop: "15px",
                  fontWeight: 300,
                }}
              >
                {parseVariables(t("loadingType"), { type: "Asset" })}
              </TypographyNormal>
            </Box>
          ) : (
            assetUrl && (
              <>
                {asset.assetType === "IMAGE" && (
                  <img
                    alt={asset.assetName}
                    style={{ width: "100%", height: "auto" }}
                    src={assetUrl}
                  />
                )}
                {asset.assetType === "VIDEO" && (
                  <video
                    alt={asset.assetName}
                    style={{ width: "100%", height: "auto" }}
                    src={assetUrl}
                    controls
                  />
                )}
              </>
            )
          )}
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" fullWidth onClick={handleClickClose}>
            {tCommon("closeBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContentReportViewAsset;
