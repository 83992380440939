import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  InputLabel,
  Popper,
  Tooltip,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slider,
  Menu,
  MenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import { AdsSlotIcon, BringToFrontIcon } from "../../../../icons";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeIcon from "@mui/icons-material/Mode";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import { ChromePicker } from "react-color";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
} from "../../../customComponent";
import ContentItem from "./ContentItem";
import MediaPicker from "../../mediaPicker/MediaPicker";
import AdContentSetting from "../../mediaPicker/AdContentSetting";
import MediaContentSetting from "../../mediaPicker/MediaContentSetting";
import { useDebounce } from "../../../hooks";
import { inputOnlyNumbers } from "../../../../helpers/inputProcessing";
import { colorStyling } from "../../../../helpers/color";
import { logger } from "../../../../helpers/logger";
import TRANSITION_LIST from "../../../../transitions";
import FONTS from "../../../../fonts";
import {
  setLayerMedias,
  setContentLayers,
  setErrorHandler,
  setTextLayers,
} from "../../../../store/actions/cmsAction";
import { stellarFlag } from "../../../../externalFeatures/componentsFlag";
import update from "immutability-helper";
import parser from "html-react-parser";

const TextLayerButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(grey[300]),
  backgroundColor: grey[300],
  "&:hover": {
    backgroundColor: grey[500],
  },
}));

const ContainerItem = ({ container, index, removeContainer }) => {
  const dispatch = useDispatch();
  const {
    contentLayers,
    textLayers,
    layerMedias,
    layerArray,
    contentRes,
    widthRatio,
    heightRatio,
  } = useSelector((state) => state.cms);

  const expireTime = +process.env.REACT_APP_FILE_PROCESSING_EXPIRY || 240000;
  const { features } = useSelector((state) => state.auth);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [openMediaPicker, setOpenMediaPicker] = useState(false);
  const [contents, setContents] = useState([]);

  const [tempName, setTempName] = useState("");
  const [layerName, setLayerName] = useState("");
  const [nameEditFlag, setNameEditFlag] = useState(false);
  const [nameHelperText, setNameHelperText] = useState("");

  const [widthInput, setWidthInput] = useState(0);
  const [heightInput, setHeightInput] = useState(0);
  const [lockRatio, setLockRatio] = useState(true);
  const [existingDialog, setExistingDialog] = useState(false);
  const [dimInputHelp, setDimInputHelp] = useState(false);
  const [dimMinMaxHelp, setDimMinMaxHelp] = useState(false);
  const [dimWidthError, setDimWidthError] = useState(false);
  const [dimHeightError, setDimHeightError] = useState(false);

  const [firstLoad, setFirstLoad] = useState(false);
  const [layerTransition, setLayerTransition] = useState("NONE");
  const [bgColor, setBgColor] = useState("");
  const [textInput, setTextInput] = useState("");
  const [textFamily, setTextFamily] = useState("ARIAL");
  const [textSize, setTextSize] = useState("");
  const [textWeight, setTextWeight] = useState("");
  const [textColor, setTextColor] = useState("");
  const [textHAlign, setTextHAlign] = useState("LEFT");
  const [textVAlign, setTextVAlign] = useState("CENTER");
  const [textScrolling, setTextScrolling] = useState(false);
  const [textDirection, setTextDirection] = useState("");
  const [textSpeed, setTextSpeed] = useState(1);
  const [textBold, setTextBold] = useState(false);

  const [openAdSetting, setOpenAdSetting] = useState(false);
  const [editAdContent, setEditAdContent] = useState(null);
  const [openMediaSetting, setOpenMediaSetting] = useState(false);
  const [editMediaContent, setEditMediaContent] = useState(null);
  const [loadingAddContent, setLoadingAddContent] = useState(false);

  const [anchorContentMenuEl, setAnchorContentMenuEl] = useState(null);
  const openContentMenu = Boolean(anchorContentMenuEl);
  const handleOpenContentMenu = (event) => {
    setAnchorContentMenuEl(event.currentTarget);
  };
  const handleCloseContentMenu = () => {
    setAnchorContentMenuEl(null);
  };

  const [anchorElBg, setAnchorElBg] = useState(null);
  const openColorPickerBg = Boolean(anchorElBg);
  const handleOpenColorBg = (event) => {
    if (openColorPickerBg) {
      setAnchorElBg(null);
      return;
    }
    setAnchorElBg(event.currentTarget);
  };
  const handleCloseColorBg = (event) => {
    setAnchorElBg(null);
  };

  const [anchorElText, setAnchorElText] = useState(null);
  const openColorPickerText = Boolean(anchorElText);
  const handleOpenColorText = (event) => {
    setAnchorElHAlign(null);
    setAnchorElVAlign(null);
    if (openColorPickerText) {
      setAnchorElText(null);
      return;
    }
    setAnchorElText(event.currentTarget);
  };
  const handleCloseColorText = (event) => {
    setAnchorElText(null);
  };

  const [anchorElHAlign, setAnchorElHAlign] = useState(null);
  const openMenuHAlign = Boolean(anchorElHAlign);
  const handleOpenMenuHAlign = (event) => {
    setAnchorElText(null);
    setAnchorElVAlign(null);
    if (openMenuHAlign) {
      setAnchorElHAlign(null);
      return;
    }
    setAnchorElHAlign(event.currentTarget);
  };
  const handleCloseMenuHAlign = (event) => {
    setAnchorElHAlign(null);
  };

  const [anchorElVAlign, setAnchorElVAlign] = useState(null);
  const openMenuVAlign = Boolean(anchorElVAlign);
  const handleOpenMenuVAlign = (event) => {
    setAnchorElText(null);
    setAnchorElHAlign(null);
    if (openMenuVAlign) {
      setAnchorElVAlign(null);
      return;
    }
    setAnchorElVAlign(event.currentTarget);
  };
  const handleCloseMenuVAlign = (event) => {
    setAnchorElVAlign(null);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // First load
  useEffect(() => {
    setLayerName(container.name ?? "");
    setWidthInput(container.dimensions?.width ?? 400);
    setHeightInput(container.dimensions?.height ?? 400);
    setLockRatio(container.dimensions?.lockRatio);
    setLayerTransition(container.transition ?? "NONE");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container]);

  useEffect(() => {
    setBgColor(container.bgColor ?? "red");
    setTextInput(container.text?.text ?? t("designer.enterTextPlaceholder"));
    setTextFamily(container.text?.family ?? "ARIAL");
    setTextSize(container.text?.size ?? "44");
    setTextWeight(container.text?.weight ?? "400");
    setTextColor(container.text?.color ?? "black");
    setTextHAlign(container.text?.hAlign ?? "LEFT");
    setTextVAlign(container.text?.vAlign ?? "CENTER");
    setTextScrolling(container.text?.scrolling ?? true);
    setTextDirection(container.text?.direction ?? "LEFT");
    setTextSpeed(container.text?.speed ?? 1);
    setTextBold(container.text?.weight > 500 ? true : false);
    setTimeout(() => {
      setFirstLoad(true);
    }, 777);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container]);

  useEffect(() => {
    const layerAssets = layerMedias[container.id];
    if (layerAssets) {
      logger.log(`updated:`, [...layerAssets]);
      setContents([...layerAssets]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerMedias]);

  const addContent = (data, order) => {
    if (order.length === 0) {
      dispatch(setErrorHandler({ selectedMedia: true }));
      return;
    }
    setLoadingAddContent(true);

    let oldLength;
    let updatedMedias;
    if (!layerMedias[container.id]) {
      updatedMedias = [];
      oldLength = 0;
    } else {
      updatedMedias = [...layerMedias[container.id]];
      oldLength = updatedMedias.length;

      let checkIfExists;
      if (order.length > 0) {
        checkIfExists = updatedMedias.filter((media) => data[media.id]);
        if (checkIfExists?.length > 0) {
          setExistingDialog(true);
          setLoadingAddContent(false);
          return;
        }
      }
    }

    let processedCount = 0;
    for (let i = 0; i < order.length; i++) {
      const addedContent = data[order[i]];
      switch (addedContent.appType) {
        case "ads":
          updatedMedias.push({ ...addedContent });
          processedCount += 1;
          break;

        default:
          const mediaContent = {
            ...addedContent,
            assetID: addedContent.id,
            interval: 5,
            assetFit: "FIT",
          };

          switch (addedContent.type) {
            case "VIDEO":
              const videoEl = document.createElement("video");
              videoEl.src = addedContent.url;

              // eslint-disable-next-line no-loop-func
              videoEl.onloadedmetadata = () => {
                updatedMedias[oldLength + i].interval = Math.floor(
                  videoEl.duration
                );
                processedCount += 1;
              };
              break;

            default:
            case "IMAGE":
              processedCount += 1;
              break;
          }

          updatedMedias.push(mediaContent);
          break;
      }
    }

    let breaker = 0;
    const dispatchSetLayerMedias = setInterval(async () => {
      if (processedCount === order.length) {
        clearInterval(dispatchSetLayerMedias);
        setLoadingAddContent(false);
        dispatch(
          setLayerMedias({ layerID: container.id, layerMedias: updatedMedias })
        );
      } else {
        breaker++;
        if (breaker === expireTime / 100) {
          clearInterval(dispatchSetLayerMedias);
          setLoadingAddContent(false);
        }
      }
    }, 100);

    let adjustedDim;
    if (!contents || contents.length === 0) {
      const layerKeys = layerArray.filter(
        (id) => !Object.keys(textLayers).includes(id)
      );

      let countIt = 0;
      let countAdjusted = 0;
      const draggedLayer = [];
      let updatedContentLayers = { ...contentLayers };
      layerKeys.forEach((layerID) => {
        const layer = contentLayers[layerID];
        if (layer.dragFlag) {
          switch (contentRes.orientation) {
            default:
            case "P":
              if (layer.dimensions.top === 0) {
                countIt++;
              }
              break;

            case "L":
              if (layer.dimensions.left === 0) {
                countIt++;
              }
              break;
          }

          draggedLayer.push(layerID);
          return;
        }

        adjustedDim = {};
        let splitDimNum = 1 / layerKeys.length;
        switch (contentRes.orientation) {
          default:
          case "P":
            splitDimNum *= contentRes.height;
            adjustedDim.top = countIt++ * splitDimNum * (1 / heightRatio);
            adjustedDim.left = 0;
            adjustedDim.width = contentRes.width;
            adjustedDim.height = splitDimNum;
            break;

          case "L":
            splitDimNum *= contentRes.width;
            adjustedDim.top = 0;
            adjustedDim.left = countIt++ * splitDimNum * (1 / widthRatio);
            adjustedDim.width = splitDimNum;
            adjustedDim.height = contentRes.height;
            break;
        }

        adjustedDim.zIndex = ++countAdjusted;
        updatedContentLayers = update(updatedContentLayers, {
          [layerID]: {
            dimensions: {
              $merge: adjustedDim,
            },
          },
        });
      });

      draggedLayer.forEach((layerID) => {
        adjustedDim = {};
        const layerDim = contentLayers[layerID].dimensions;
        adjustedDim.zIndex = layerDim.zIndex + countAdjusted;
        updatedContentLayers = update(updatedContentLayers, {
          [layerID]: {
            dimensions: {
              $merge: adjustedDim,
            },
          },
        });
      });

      dispatch(setContentLayers({ ...updatedContentLayers }));
    }

    handleCloseMediaPicker();
    handleCloseAdSetting();
  };

  const removeContent = (id) => {
    let updatedMedias = layerMedias[container.id];
    if (updatedMedias) {
      updatedMedias = updatedMedias.filter((media) => media.id !== id);
      dispatch(
        setLayerMedias({ layerID: container.id, layerMedias: updatedMedias })
      );
      setContents([...updatedMedias]);
    }
  };

  const handleOpenMediaPicker = () => {
    setOpenMediaPicker(true);
  };

  const handleCloseMediaPicker = () => {
    setOpenMediaPicker(false);
  };

  const handleOpenAdSetting = (data) => {
    if (data) {
      setEditAdContent(data);
    }
    setOpenAdSetting(true);
  };

  const handleCloseAdSetting = () => {
    setOpenAdSetting(false);
  };

  const handleOpenMediaSetting = (data) => {
    if (data) {
      setEditMediaContent(data);
    }
    setOpenMediaSetting(true);
  };

  const handleCloseMediaSetting = () => {
    setOpenMediaSetting(false);
  };

  const handleTempNameChange = (e) => {
    let value = e?.target?.value ?? "";
    if (value.length === 0) {
      setNameHelperText(t("error.layerNameEmpty"));
      setTempName(value);
      return;
    }
    if (value.length > 256) {
      setNameHelperText(t("error.layerNameMaxReached"));
      value = value.slice(0, 256);
      setTempName(value);
      return;
    }
    setTempName(value);
    setNameHelperText("");
  };

  const updateLayerName = () => {
    let value = tempName.trim();
    if (value.length === 0) {
      setTempName("");
      setNameHelperText(t("error.layerNameEmpty"));
      return;
    }

    setLayerName(value);
    const updated = update(contentLayers, {
      [container.id]: { $merge: { name: value } },
    });
    dispatch(setContentLayers({ ...updated }));
    setNameEditFlag(false);
    setNameHelperText("");
  };

  const handleChangeDim = useCallback(
    (which, event) => {
      setDimInputHelp(true);
      setDimMinMaxHelp(false);
      const originalWidth = container.dimensions?.width;
      const originalHeight = container.dimensions?.height;

      let value = event?.target?.value;
      const valueString = `${value}`;
      if (!value || valueString.length === 0) {
        switch (which) {
          case "width":
            setWidthInput(value);
            return;

          case "height":
            setHeightInput(value);
            return;

          default:
            return;
        }
      }

      let adjustedWidth;
      let adjustedHeight;
      value = parseInt(value);
      switch (which) {
        case "width":
          adjustedWidth = +value;
          adjustedHeight = +originalHeight;
          if (container.dimensions?.lockRatio) {
            adjustedHeight = Math.round(
              originalHeight * (value / originalWidth)
            );

            if (adjustedHeight <= 0) adjustedHeight = 1;
          }
          setWidthInput(adjustedWidth);
          break;

        case "height":
          adjustedHeight = +value;
          adjustedWidth = +originalWidth;
          if (container.dimensions?.lockRatio) {
            adjustedWidth = Math.round(
              originalWidth * (value / originalHeight)
            );

            if (adjustedWidth <= 0) adjustedWidth = 1;
          }
          setHeightInput(adjustedHeight);
          break;

        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [container, contentLayers, contentRes, widthRatio, heightRatio]
  );

  const applyDimensionChanges = () => {
    setDimInputHelp(false);
    let updatedWidth = widthInput;
    let updatedHeight = heightInput;

    let minMaxFlag = false;
    if (!(updatedWidth >= 50) || updatedWidth > contentRes.width) {
      minMaxFlag = true;
      setDimWidthError(true);
    }
    if (!(updatedHeight >= 50) || updatedHeight > contentRes.height) {
      minMaxFlag = true;
      setDimHeightError(true);
    }
    if (minMaxFlag) {
      setDimMinMaxHelp(true);
      return;
    }

    const updated = {
      top: 0,
      left: 0,
      width: updatedWidth,
      height: updatedHeight,
    };

    let updatedContentLayers = update(contentLayers, {
      [container.id]: {
        dimensions: {
          $merge: updated,
        },
      },
    });
    updatedContentLayers = update(updatedContentLayers, {
      [container.id]: { $merge: { dragFlag: true } },
    });
    dispatch(setContentLayers({ ...updatedContentLayers }));
  };

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setContents((contents) => {
        const oldIndex = contents.findIndex((i) => i.id === active.id);
        const newIndex = contents.findIndex((i) => i.id === over.id);
        const move = arrayMove(contents, oldIndex, newIndex);
        dispatch(setLayerMedias({ layerID: container.id, layerMedias: move }));
        return move;
      });
    }
  }

  function handleIntervalChange(index, value) {
    setContents((contents) => {
      const updated = update(contents, {
        [index]: { $merge: { interval: +value } },
      });
      dispatch(setLayerMedias({ layerID: container.id, layerMedias: updated }));
      return updated;
    });
  }

  function handleIsMutedChange(index, value) {
    setContents((contents) => {
      const updated = update(contents, {
        [index]: { $merge: { isMuted: value } },
      });
      dispatch(setLayerMedias({ layerID: container.id, layerMedias: updated }));
      return updated;
    });
  }

  function handleSaveMediaChanges(index, payload) {
    setContents((contents) => {
      const updated = update(contents, {
        [index]: { $merge: payload },
      });

      dispatch(setLayerMedias({ layerID: container.id, layerMedias: updated }));
      return updated;
    });
  }

  const handleTransitionChange = useCallback(
    (event) => {
      let value = event?.target?.value;
      setLayerTransition(value);
      const updated = update(contentLayers, {
        [container.id]: { $merge: { transition: value } },
      });
      dispatch(setContentLayers({ ...updated }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentLayers]
  );

  const handleLockRatioSwitch = useCallback(
    (event, value) => {
      event.stopPropagation();
      setLockRatio(value);
      const updated = update(contentLayers, {
        [container.id]: { dimensions: { $merge: { lockRatio: value } } },
      });
      dispatch(setContentLayers({ ...updated }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentLayers]
  );

  const handleChangeTextInput = useCallback(
    (event, which, fixedValue) => {
      let value = event?.target?.value;

      let updated;
      switch (which) {
        case "text":
          setTextInput(value);
          break;

        case "family":
          setTextFamily(value);
          updated = update(contentLayers, {
            [container.id]: { text: { $merge: { family: value } } },
          });
          dispatch(setContentLayers({ ...updated }));
          dispatch(setTextLayers({ edit: { id: container.id, value: true } }));
          break;

        case "size":
          if (!event) value = parseInt(textSize) + fixedValue;
          if (!value || parseInt(value) < 1) {
            value = "1";
          }
          if (parseInt(value) > 1000) {
            value = "1000";
          }
          setTextSize(value.toString());
          dispatch(setTextLayers({ edit: { id: container.id, value: true } }));
          break;

        case "weight":
          let updatedWeight;
          if (!textBold) {
            updatedWeight = 700;
            setTextBold(true);
          } else {
            updatedWeight = 400;
            setTextBold(false);
          }
          setTextWeight(updatedWeight.toString());
          dispatch(setTextLayers({ edit: { id: container.id, value: true } }));
          break;

        case "hAlign":
          setTextHAlign(fixedValue);
          updated = update(contentLayers, {
            [container.id]: { text: { $merge: { hAlign: fixedValue } } },
          });
          dispatch(setContentLayers({ ...updated }));
          dispatch(setTextLayers({ edit: { id: container.id, value: true } }));
          handleCloseMenuHAlign();
          break;

        case "vAlign":
          setTextVAlign(fixedValue);
          updated = update(contentLayers, {
            [container.id]: { text: { $merge: { vAlign: fixedValue } } },
          });
          dispatch(setContentLayers({ ...updated }));
          dispatch(setTextLayers({ edit: { id: container.id, value: true } }));
          handleCloseMenuVAlign();
          break;

        case "scrolling":
          setTextScrolling(fixedValue);
          updated = update(contentLayers, {
            [container.id]: { text: { $merge: { scrolling: fixedValue } } },
          });
          dispatch(setContentLayers({ ...updated }));
          dispatch(setTextLayers({ edit: { id: container.id, value: true } }));
          break;

        case "direction":
          setTextDirection(value);
          updated = update(contentLayers, {
            [container.id]: { text: { $merge: { direction: value } } },
          });
          dispatch(setContentLayers({ ...updated }));
          dispatch(setTextLayers({ edit: { id: container.id, value: true } }));
          break;

        case "speed":
          if (!value || parseInt(value) < 1) {
            value = "1";
          }
          if (parseInt(value) > 10) {
            value = "10";
          }
          setTextSpeed(value);
          updated = update(contentLayers, {
            [container.id]: { text: { $merge: { speed: value } } },
          });
          dispatch(setContentLayers({ ...updated }));
          dispatch(setTextLayers({ edit: { id: container.id, value: true } }));
          break;

        default:
          break;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentLayers, textSize, textBold]
  );

  const debouncedText = useDebounce(textInput, 300);
  useEffect(
    () => {
      if (firstLoad && container.type === "text") {
        const value = debouncedText;
        const updated = update(contentLayers, {
          [container.id]: { text: { $merge: { text: value } } },
        });
        dispatch(setContentLayers({ ...updated }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedText] // Only call effect if debounced interval changes
  );

  const debouncedSize = useDebounce(textSize, 300);
  useEffect(
    () => {
      if (firstLoad && container.type === "text") {
        const value = debouncedSize;
        const updated = update(contentLayers, {
          [container.id]: { text: { $merge: { size: value } } },
        });
        dispatch(setContentLayers({ ...updated }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSize] // Only call effect if debounced interval changes
  );

  const debouncedWeight = useDebounce(textWeight, 300);
  useEffect(
    () => {
      if (firstLoad && container.type === "text") {
        const value = debouncedWeight;
        const updated = update(contentLayers, {
          [container.id]: { text: { $merge: { weight: value } } },
        });
        dispatch(setContentLayers({ ...updated }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedWeight] // Only call effect if debounced interval changes
  );

  const fullColorToHex = function (color) {
    const hex = color.hex;
    const alphaHex = Math.round(color.rgb.a * 255).toString(16);
    return `${hex}${alphaHex}`;
  };

  const handleChangeColor = useCallback(
    (which, color) => {
      const value = fullColorToHex(color);

      let updated = null;
      switch (which) {
        case "bg":
          setBgColor(value);
          updated = update(contentLayers, {
            [container.id]: { bgColor: { $set: value } },
          });
          break;

        case "text":
        default:
          setTextColor(value);
          updated = update(contentLayers, {
            [container.id]: { text: { $merge: { color: value } } },
          });
          break;
      }
      dispatch(setContentLayers({ ...updated }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentLayers]
  );

  const bringLayerToFront = useCallback(
    (id) => {
      const copy = { ...contentLayers };
      const layerKeys = Object.keys(copy);
      const lastIndex = layerKeys.length;
      const fromIndex = copy[id].dimensions.zIndex;
      layerKeys.forEach((layerID) => {
        const currentZIndex = copy[layerID].dimensions.zIndex;
        if (currentZIndex < fromIndex) {
          return;
        }
        copy[layerID] = update(copy[layerID], {
          dimensions: { $merge: { zIndex: currentZIndex - 1 } },
        });
      });

      copy[id] = update(copy[id], {
        dimensions: { $merge: { zIndex: lastIndex } },
      });
      const updated = update(contentLayers, { $set: copy });
      dispatch(setContentLayers({ ...updated }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentLayers]
  );

  const applyTextToAll = () => {
    const { text: _, ...copySettings } = contentLayers[container.id].text;

    const targetLayers = { ...textLayers };
    delete targetLayers[container.id];
    const textLayerIDs = Object.keys(targetLayers);

    let updated = { ...contentLayers };
    let updatedTextLayers = { ...textLayers };
    for (let i = 0; i < textLayerIDs.length; i++) {
      const updatedLayers = update(updated, {
        [textLayerIDs[i]]: { text: { $merge: { ...copySettings } } },
      });
      updated = updatedLayers;
      updatedTextLayers = update(updatedTextLayers, {
        [textLayerIDs[i]]: { $merge: { edit: false } },
      });
    }

    updatedTextLayers = update(updatedTextLayers, {
      [container.id]: { $merge: { edit: false } },
    });

    dispatch(setContentLayers({ ...updated }));
    dispatch(setTextLayers({ ...updatedTextLayers }));
  };

  const parseHAlignIcon = useMemo(() => {
    switch (textHAlign) {
      default:
      case "LEFT":
        return <FormatAlignLeftIcon fontSize="small" />;
      case "CENTER":
        return <FormatAlignCenterIcon fontSize="small" />;
      case "RIGHT":
        return <FormatAlignRightIcon fontSize="small" />;
      case "JUSTIFY":
        return <FormatAlignJustifyIcon fontSize="small" />;
    }
  }, [textHAlign]);

  const parseVAlignIcon = useMemo(() => {
    switch (textVAlign) {
      default:
      case "TOP":
        return <VerticalAlignTopIcon fontSize="small" />;
      case "CENTER":
        return <VerticalAlignCenterIcon fontSize="small" />;
      case "BOTTOM":
        return <VerticalAlignBottomIcon fontSize="small" />;
    }
  }, [textVAlign]);

  const applyAllTextCondition = useMemo(() => {
    if (textLayers[container.id]?.edit && Object.keys(textLayers).length > 1) {
      return true;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentLayers]);

  const textLayer = useMemo(() => {
    return container.text;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentLayers]);

  const resApplyCondition = useMemo(() => {
    let flag = false;
    const currentDim = contentLayers[container.id].dimensions;
    if (widthInput !== currentDim.width) flag = true;
    if (heightInput !== currentDim.height) flag = true;

    if (!flag) {
      setDimInputHelp(false);
      setDimMinMaxHelp(false);
    }

    return flag;
  }, [container.id, widthInput, heightInput, contentLayers]);

  return (
    <Paper sx={{ p: 2, mb: 2, paddingTop: 0 }} elevation={2}>
      {(openColorPickerText ||
        openColorPickerBg ||
        openMenuHAlign ||
        openMenuVAlign) && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 200,
          }}
          onClick={() => {
            handleCloseColorText();
            handleCloseColorBg();
            handleCloseMenuHAlign();
            handleCloseMenuVAlign();
          }}
        ></Box>
      )}

      <Box display={"flex"} justifyContent={"space-between"}>
        {!nameEditFlag ? (
          <Tooltip
            arrow
            disableInteractive
            placement="top"
            title={t("designer.layerNameTip")}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{ ml: 1, mt: 2, pr: 3 }}
              onClick={() => {
                setTempName(
                  layerName.length > 0
                    ? layerName
                    : `${t("designer.layer")} ${index}`
                );
                setNameEditFlag(true);
              }}
            >
              <TypographyLarge
                sx={{
                  lineHeight: "1.4rem",
                  borderBottom: "1px dotted",
                }}
              >
                {layerName.length > 0
                  ? layerName
                  : `${t("designer.layer")} ${index}`}
              </TypographyLarge>
              <ModeIcon fontSize="1rem" sx={{ ml: 1, cursor: "pointer" }} />
            </Box>
          </Tooltip>
        ) : (
          <Box display="flex" alignItems="center" sx={{ ml: 1, mt: 2 }}>
            <TextField
              margin="dense"
              variant="standard"
              size="small"
              value={tempName}
              helperText={nameHelperText}
              error={nameHelperText.length > 0}
              onChange={(e) => handleTempNameChange(e)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  updateLayerName();
                }
              }}
              sx={{ width: tempName.length < 20 ? "200px" : "300px" }}
            />
            <CloseIcon
              fontSize="1rem"
              onClick={() => {
                setNameHelperText("");
                setNameEditFlag(false);
              }}
              sx={{ ml: 1, cursor: "pointer" }}
            />
            <CheckIcon
              fontSize="1rem"
              onClick={() => updateLayerName()}
              sx={{ ml: 1, cursor: "pointer" }}
            />
          </Box>
        )}

        <Box
          display={"flex"}
          gap={1}
          alignItems={"center"}
          sx={{ overflowY: "clip" }}
        >
          {container.type === "text" && (
            <Tooltip
              arrow
              disableInteractive
              title={openColorPickerBg ? "" : t("designer.bgColorTip")}
            >
              <Box
                sx={{
                  marginTop: "-22px",
                  width: "fit-content",
                  height: "fit-content",
                  borderRadius: "0 0 99px 99px",
                  border: "2px solid rgba(28, 78, 216, 0.5)",
                }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    padding: 0,
                    borderRadius: "0 0 99px 99px",
                    minWidth: "20px",
                    minHeight: "30px",
                    backgroundColor: container.bgColor,
                    ":hover": {
                      backgroundColor: container.bgColor,
                    },
                  }}
                  onClick={handleOpenColorBg}
                />
                <Popper
                  open={openColorPickerBg}
                  anchorEl={anchorElBg}
                  placement="bottom-start"
                  style={{ zIndex: 201 }}
                >
                  <Box sx={{ marginY: "4px" }}>
                    <ChromePicker
                      color={bgColor}
                      onChange={(color) => setBgColor(fullColorToHex(color))}
                      onChangeComplete={(color) =>
                        handleChangeColor(`bg`, color)
                      }
                      styles={{ "margin-top": 1 }}
                    />
                  </Box>
                </Popper>
              </Box>
            </Tooltip>
          )}

          <Tooltip arrow disableInteractive title={t("designer.btFrontTip")}>
            <Box sx={{ m: 1 }}>
              <TextLayerButton
                variant="contained"
                onClick={() => bringLayerToFront(container.id)}
                sx={{
                  paddingX: 1,
                  marginTop: 1,
                  minWidth: 25,
                }}
              >
                <BringToFrontIcon />
              </TextLayerButton>
            </Box>
          </Tooltip>

          <Tooltip
            arrow
            disableInteractive
            title={t("designer.deleteLayerTip")}
          >
            <IconButton
              color="error"
              onClick={() => removeContainer(container.id, container.type)}
              sx={{ marginTop: 1 }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <ExistingConfirmation
          open={existingDialog}
          closeDialog={() => setExistingDialog(false)}
        />
      </Box>

      <Divider sx={{ mt: 1 }} />
      <Accordion sx={{ mt: 1 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <TypographyLarge>{t("designer.dimensionsLabel")}</TypographyLarge>
            {!container.text && (
              <Tooltip
                arrow
                disableInteractive
                title={
                  lockRatio
                    ? t("designer.unlockARTip")
                    : t("designer.lockARTip")
                }
              >
                <IconButton
                  onClick={(e) =>
                    handleLockRatioSwitch(e, lockRatio ? false : true)
                  }
                  sx={{
                    padding: "4px",
                    marginRight: 1,
                    backgroundColor: colorStyling.cms.gray,
                    ":hover": {
                      backgroundColor: "lightgrey",
                    },
                  }}
                >
                  {lockRatio ? <LockIcon /> : <LockOpenIcon />}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </AccordionSummary>

        <AccordionDetails>
          <Box display={"flex"} gap={1} justifyContent={"left"} sx={{ px: 2 }}>
            <Box
              width={"100%"}
              display={"flex"}
              gap={1}
              justifyContent={"space-evenly"}
            >
              <Box display={"flex"} alignItems={"center"}>
                <TextField
                  size="small"
                  variant="standard"
                  placeholder="100"
                  value={widthInput}
                  onChange={(e) => handleChangeDim("width", e)}
                  onKeyDown={(e) => inputOnlyNumbers(e)}
                  InputProps={{
                    sx: { maxWidth: "130px", textAlignLast: "right" },
                    startAdornment: (
                      <InputAdornment position="start">
                        {t("designer.widthLabel")}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderBottom:
                        dimMinMaxHelp && dimWidthError
                          ? "1px solid red"
                          : undefined,
                    },
                  }}
                />
              </Box>

              <Box display={"flex"} alignItems={"center"}>
                <TextField
                  size="small"
                  variant="standard"
                  placeholder="100"
                  value={heightInput}
                  onChange={(e) => handleChangeDim("height", e)}
                  onKeyDown={(e) => inputOnlyNumbers(e)}
                  InputProps={{
                    sx: { maxWidth: "130px", textAlignLast: "right" },
                    startAdornment: (
                      <InputAdornment position="start">
                        {t("designer.heightLabel")}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">px</InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderBottom:
                        dimMinMaxHelp && dimHeightError
                          ? "1px solid red"
                          : undefined,
                    },
                  }}
                />
              </Box>
            </Box>

            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"end"}
              width={"100px"}
            >
              {resApplyCondition && (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={applyDimensionChanges}
                  sx={{
                    mb: "4px",
                    color: "#1976d2",
                    borderColor: "#1976d2",
                    backgroundColor: "white",
                    "&:hover": {
                      backgroundColor: "#e3f2fd",
                    },
                  }}
                >
                  {tCommon("applyBtn")}
                </Button>
              )}
            </Box>
          </Box>

          {dimInputHelp && (
            <TypographySmall sx={{ mt: 1, color: "black" }}>
              {parser(t("designer.dimInputHelp"))}
            </TypographySmall>
          )}

          {dimMinMaxHelp && (
            <TypographySmall sx={{ mt: 1, color: "red" }}>
              {parser(t("designer.dimMinMaxHelp"))}
            </TypographySmall>
          )}
        </AccordionDetails>
      </Accordion>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={contents.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          {contents.map((content, index) => (
            <ContentItem
              key={content.id}
              content={content}
              index={index}
              removeContent={removeContent}
              updateInterval={handleIntervalChange}
              updateIsMuted={handleIsMutedChange}
              openAdSetting={handleOpenAdSetting}
              openMediaSetting={handleOpenMediaSetting}
              assetCount={contents?.length ?? 0}
            />
          ))}
        </SortableContext>
      </DndContext>

      <MediaPicker
        open={openMediaPicker}
        handleClose={handleCloseMediaPicker}
        name={`[${t("designer.layer")} ${index}]`}
        addContent={addContent}
        container={container}
        contentState={[contents, setContents]}
      />

      <AdContentSetting
        open={openAdSetting}
        handleClose={handleCloseAdSetting}
        addContent={addContent}
        container={container}
        editContent={editAdContent}
        saveEditChanges={handleSaveMediaChanges}
        assetCount={contents?.length ?? 0}
      />

      <MediaContentSetting
        open={openMediaSetting}
        handleClose={handleCloseMediaSetting}
        container={container}
        editContent={editMediaContent}
        saveEditChanges={handleSaveMediaChanges}
        assetCount={contents?.length ?? 0}
      />

      {container.type === "media" && (
        <Box
          sx={{ mt: 2, width: "100%" }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={contents?.length >= 2 ? "space-around" : "center"}
        >
          {features?.TRANSITION_SELECTION && contents?.length >= 2 && (
            <Tooltip
              arrow
              disableInteractive
              title={
                <TypographySmall sx={{ color: "white" }}>
                  {t("designer.transitionTip")}
                </TypographySmall>
              }
            >
              <Box display={"flex"} alignItems={"center"}>
                <InputLabel sx={{ overflow: "visible" }}>
                  {t("designer.transitionLabel")}
                </InputLabel>
                <TextField
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  placeholder={t("designer.transitionSelect")}
                  size="small"
                  select
                  SelectProps={{ native: true }}
                  value={layerTransition}
                  onChange={(e) => handleTransitionChange(e)}
                  sx={{ ml: 1, mb: 1 }}
                >
                  {Object.keys(TRANSITION_LIST).map((ts) => {
                    const transition = TRANSITION_LIST[ts];
                    return (
                      <option value={ts} key={ts}>
                        {t(`transition.${transition.id}`)}
                      </option>
                    );
                  })}
                </TextField>
              </Box>
            </Tooltip>
          )}

          {features?.TRANSITION_SELECTION && contents?.length >= 2 ? (
            <Tooltip
              arrow
              disableInteractive
              title={
                <TypographySmall sx={{ color: "white" }}>
                  {t("designer.addContentTip")}
                </TypographySmall>
              }
            >
              <Button
                variant="contained"
                sx={{ boxShadow: 3 }}
                onClick={
                  stellarFlag ? handleOpenContentMenu : handleOpenMediaPicker
                }
              >
                <AddIcon fontSize="medium" />
              </Button>
            </Tooltip>
          ) : (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ boxShadow: 3 }}
              onClick={
                stellarFlag ? handleOpenContentMenu : handleOpenMediaPicker
              }
            >
              {t("designer.addContentBtn")}
            </Button>
          )}

          <Menu
            id="basic-menu"
            anchorEl={anchorContentMenuEl}
            open={openContentMenu}
            onClose={handleCloseContentMenu}
            elevation={1}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            PaperProps={{
              sx: {
                boxShadow: 1,
              },
            }}
            sx={{ marginTop: 1 }}
          >
            <MenuItem
              onClick={() => {
                handleOpenMediaPicker();
                handleCloseContentMenu();
              }}
              sx={{
                display: "flex",
              }}
            >
              <PermMediaIcon sx={{ mx: 1 }} />
              <TypographyNormal sx={{ ml: 1 }}>
                {t("designer.addContentMedia")}
              </TypographyNormal>
            </MenuItem>

            {stellarFlag && (
              <MenuItem
                onClick={() => {
                  setEditAdContent(null);
                  handleOpenAdSetting();
                  handleCloseContentMenu();
                }}
                sx={{
                  display: "flex",
                }}
              >
                <Box display={"flex"} sx={{ mx: 1 }}>
                  <AdsSlotIcon width={24} height={24} />
                </Box>
                <TypographyNormal sx={{ ml: 1 }}>
                  {t("designer.addContentAdvertisement")}
                </TypographyNormal>
              </MenuItem>
            )}
          </Menu>
        </Box>
      )}

      {container.type === "text" && (
        <Box sx={{ mt: 1, px: 1, width: "100%" }}>
          <Box sx={{ width: "100%" }} display={"flex"} alignItems={"center"}>
            <InputLabel sx={{ width: "60px" }}>
              <TypographyLarge>{t("designer.textLabel")}</TypographyLarge>
            </InputLabel>

            <TextField
              margin="dense"
              variant="outlined"
              placeholder={t("designer.enterTextPlaceholder")}
              size="small"
              value={textInput}
              onChange={(e) => handleChangeTextInput(e, "text")}
              sx={{ ml: 1, width: "inherit" }}
            />
          </Box>

          <Divider component="div" textAlign="left" sx={{ mt: "4px" }}>
            <TypographyNormal>
              {t("designer.fontSettingDivider")}
            </TypographyNormal>
          </Divider>

          <Paper
            elevation={0}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              overflowX: "auto",
            }}
          >
            <Box
              sx={{ width: "100%", paddingX: 1 }}
              display={"flex"}
              alignItems={"center"}
            >
              <Box
                display={"flex"}
                justifyContent={"left"}
                alignItems={"center"}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <Tooltip
                    arrow
                    disableInteractive
                    title={t("designer.fontFamilyTip")}
                  >
                    <TextField
                      margin="dense"
                      variant="outlined"
                      size="small"
                      select
                      SelectProps={{
                        native: true,
                        fullWidth: true,
                      }}
                      value={textFamily}
                      onChange={(e) => handleChangeTextInput(e, "family")}
                      sx={{ ml: 1, width: "163px" }}
                    >
                      {Object.keys(FONTS).map((font) => (
                        <option value={font} key={font}>
                          {FONTS[font].label}
                        </option>
                      ))}
                    </TextField>
                  </Tooltip>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{ marginX: "4px" }}
                >
                  <Tooltip
                    arrow
                    disableInteractive
                    title={t("designer.fontSizeDecTip")}
                  >
                    <TextLayerButton
                      variant="contained"
                      onClick={() => handleChangeTextInput(null, "size", -1)}
                      sx={{
                        padding: 0,
                        marginRight: "4px",
                        minWidth: 25,
                        height: 25,
                        fontWeight: 700,
                      }}
                    >
                      {" – "}
                    </TextLayerButton>
                  </Tooltip>

                  <Tooltip
                    arrow
                    disableInteractive
                    title={t("designer.fontSizeTip")}
                  >
                    <TextField
                      margin="dense"
                      variant="outlined"
                      placeholder={t("designer.fontSizePlaceholder")}
                      size="small"
                      value={textSize}
                      onChange={(e) => handleChangeTextInput(e, "size")}
                      onKeyDown={(e) => inputOnlyNumbers(e)}
                      sx={{ width: "80px" }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">px</InputAdornment>
                        ),
                      }}
                    />
                  </Tooltip>

                  <Tooltip
                    arrow
                    disableInteractive
                    title={t("designer.fontSizeIncTip")}
                  >
                    <TextLayerButton
                      variant="contained"
                      onClick={() => handleChangeTextInput(null, "size", +1)}
                      sx={{
                        padding: 0,
                        marginLeft: "4px",
                        minWidth: 25,
                        height: 25,
                        color: "black",
                        fontWeight: 700,
                      }}
                    >
                      {" + "}
                    </TextLayerButton>
                  </Tooltip>
                </Box>

                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{ marginX: "4px" }}
                >
                  <Tooltip
                    arrow
                    disableInteractive
                    title={t("designer.fontBoldTip")}
                  >
                    <Button
                      variant="contained"
                      onClick={() => handleChangeTextInput(null, "weight")}
                      sx={{
                        padding: "4px",
                        minWidth: 36,
                        height: 33,
                        color: "black",
                        backgroundColor: textBold ? grey[500] : grey[300],
                        "&:hover": {
                          color: textBold ? "white" : "black",
                          backgroundColor: textBold ? grey[700] : grey[500],
                        },
                      }}
                    >
                      <FormatBoldIcon />
                    </Button>
                  </Tooltip>
                </Box>

                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{ marginX: "4px" }}
                >
                  <Tooltip
                    arrow
                    disableInteractive
                    title={t("designer.fontColorTip")}
                  >
                    <TextLayerButton
                      variant="contained"
                      onClick={handleOpenColorText}
                      sx={{
                        padding: "4px",
                        minWidth: 36,
                        height: 33,
                        fontWeight: 700,
                      }}
                    >
                      <FormatColorTextIcon sx={{ color: textLayer?.color }} />
                    </TextLayerButton>
                  </Tooltip>
                  <Popper
                    open={openColorPickerText}
                    anchorEl={anchorElText}
                    placement="bottom-start"
                    style={{ zIndex: 200 }}
                  >
                    <Box sx={{ marginY: "4px" }}>
                      <ChromePicker
                        color={textColor}
                        onChange={(color) =>
                          setTextColor(fullColorToHex(color))
                        }
                        onChangeComplete={(color) =>
                          handleChangeColor(`text`, color)
                        }
                        styles={{ "margin-top": 1 }}
                      />
                    </Box>
                  </Popper>
                </Box>

                <Box
                  display={"flex"}
                  alignItems={"center"}
                  sx={{ marginX: "4px" }}
                >
                  <Box>
                    <Tooltip
                      arrow
                      disableInteractive
                      title={openMenuHAlign ? "" : t("designer.fontHAlignTip")}
                    >
                      <TextLayerButton
                        variant="contained"
                        onClick={handleOpenMenuHAlign}
                        sx={{
                          padding: "4px",
                          marginRight: "4px",
                          minWidth: 36,
                          height: 33,
                          color: "black",
                          fontWeight: 700,
                        }}
                      >
                        {parseHAlignIcon}
                      </TextLayerButton>
                    </Tooltip>
                    <Popper
                      open={openMenuHAlign}
                      anchorEl={anchorElHAlign}
                      placement="bottom"
                      style={{ zIndex: 200, backgroundColor: "white" }}
                    >
                      <Paper display={"flex"} sx={{ mt: 1, mb: "4px" }}>
                        <Tooltip
                          arrow
                          disableInteractive
                          title={t("designer.fontHAlignLeft")}
                        >
                          <Button
                            onClick={() =>
                              handleChangeTextInput(null, "hAlign", "LEFT")
                            }
                            sx={{
                              color: textHAlign === "LEFT" ? "blue" : "black",
                              padding: "4px",
                              marginRight: "4px",
                              minWidth: 36,
                              height: 33,
                            }}
                          >
                            <FormatAlignLeftIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          arrow
                          disableInteractive
                          title={t("designer.fontHAlignCenter")}
                        >
                          <Button
                            onClick={() =>
                              handleChangeTextInput(null, "hAlign", "CENTER")
                            }
                            sx={{
                              color: textHAlign === "CENTER" ? "blue" : "black",
                              padding: "4px",
                              marginRight: "4px",
                              minWidth: 36,
                              height: 33,
                            }}
                          >
                            <FormatAlignCenterIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          arrow
                          disableInteractive
                          title={t("designer.fontHAlignRight")}
                        >
                          <Button
                            onClick={() =>
                              handleChangeTextInput(null, "hAlign", "RIGHT")
                            }
                            sx={{
                              color: textHAlign === "RIGHT" ? "blue" : "black",
                              padding: "4px",
                              marginRight: "4px",
                              minWidth: 36,
                              height: 33,
                            }}
                          >
                            <FormatAlignRightIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          arrow
                          disableInteractive
                          title={t("designer.fontHAlignJustify")}
                        >
                          <Button
                            onClick={() =>
                              handleChangeTextInput(null, "hAlign", "JUSTIFY")
                            }
                            sx={{
                              color:
                                textHAlign === "JUSTIFY" ? "blue" : "black",
                              padding: "4px",
                              minWidth: 36,
                              height: 33,
                            }}
                          >
                            <FormatAlignJustifyIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                      </Paper>
                    </Popper>
                  </Box>

                  <Box>
                    <Tooltip
                      arrow
                      disableInteractive
                      title={openMenuVAlign ? "" : t("designer.fontVAlignTip")}
                    >
                      <TextLayerButton
                        variant="contained"
                        onClick={handleOpenMenuVAlign}
                        sx={{
                          padding: "4px",
                          marginRight: "4px",
                          minWidth: 36,
                          height: 33,
                          color: "black",
                          fontWeight: 700,
                        }}
                      >
                        {parseVAlignIcon}
                      </TextLayerButton>
                    </Tooltip>
                    <Popper
                      open={openMenuVAlign}
                      anchorEl={anchorElVAlign}
                      placement="bottom"
                      style={{ zIndex: 200, backgroundColor: "white" }}
                    >
                      <Paper display={"flex"} sx={{ mt: 1, mb: "4px" }}>
                        <Tooltip
                          arrow
                          disableInteractive
                          title={t("designer.fontVAlignTop")}
                        >
                          <Button
                            onClick={() =>
                              handleChangeTextInput(null, "vAlign", "TOP")
                            }
                            sx={{
                              color: textVAlign === "TOP" ? "blue" : "black",
                              padding: "4px",
                              marginRight: "4px",
                              minWidth: 36,
                              height: 33,
                            }}
                          >
                            <VerticalAlignTopIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          arrow
                          disableInteractive
                          title={t("designer.fontVAlignCenter")}
                        >
                          <Button
                            onClick={() =>
                              handleChangeTextInput(null, "vAlign", "CENTER")
                            }
                            sx={{
                              color: textVAlign === "CENTER" ? "blue" : "black",
                              padding: "4px",
                              marginRight: "4px",
                              minWidth: 36,
                              height: 33,
                            }}
                          >
                            <VerticalAlignCenterIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          arrow
                          disableInteractive
                          title={t("designer.fontVAlignBottom")}
                        >
                          <Button
                            onClick={() =>
                              handleChangeTextInput(null, "vAlign", "BOTTOM")
                            }
                            sx={{
                              color: textVAlign === "BOTTOM" ? "blue" : "black",
                              padding: "4px",
                              minWidth: 36,
                              height: 33,
                            }}
                          >
                            <VerticalAlignBottomIcon fontSize="small" />
                          </Button>
                        </Tooltip>
                      </Paper>
                    </Popper>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>

          <Divider component="div" sx={{ marginY: "4px" }} />

          <Box sx={{ width: "100%" }} display={"flex"} alignItems={"center"}>
            <InputLabel>
              <TypographyLarge>{t("designer.scrollTextLabel")}</TypographyLarge>
            </InputLabel>

            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ marginLeft: 0 }}
            >
              <Switch
                value={textScrolling}
                checked={textScrolling}
                onClick={() =>
                  handleChangeTextInput(null, "scrolling", !textScrolling)
                }
              />
            </Box>
          </Box>

          {textScrolling && (
            <Box
              sx={{ width: "100%" }}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box display={"flex"} alignItems={"center"}>
                <InputLabel sx={{ width: "80px" }}>
                  <TypographyLarge>
                    {t("designer.scrollDirectionLabel")}
                  </TypographyLarge>
                </InputLabel>

                <Tooltip
                  arrow
                  disableInteractive
                  title={t("designer.scrollDirectionTip")}
                >
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    size="small"
                    select
                    SelectProps={{
                      native: true,
                    }}
                    value={textDirection}
                    onChange={(e) => handleChangeTextInput(e, "direction")}
                    sx={{ ml: 1, width: "100px" }}
                  >
                    <option value={"RIGHT"} key={"right"}>
                      {t("designer.scrollDirectionRight")}
                    </option>
                    <option value={"LEFT"} key={"left"}>
                      {t("designer.scrollDirectionLeft")}
                    </option>
                  </TextField>
                </Tooltip>
              </Box>

              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                sx={{
                  width: "100%",
                  paddingLeft: 3,
                  paddingRight: 1,
                }}
              >
                <InputLabel>
                  <TypographyLarge>
                    {t("designer.scrollSpeedLabel")}
                  </TypographyLarge>
                </InputLabel>

                <Slider
                  aria-label={t("designer.scrollSpeedHint")}
                  value={textSpeed}
                  valueLabelDisplay="auto"
                  step={1}
                  marks={[
                    { value: 1, label: t("designer.scrollSpeedSlow") },
                    { value: 10, label: t("designer.scrollSpeedFast") },
                  ]}
                  min={1}
                  max={10}
                  onChange={(e) => handleChangeTextInput(e, "speed")}
                />
              </Box>
            </Box>
          )}

          {applyAllTextCondition && (
            <Box
              display={"flex"}
              justifyContent={"end"}
              sx={{ mt: 1, mr: "4px" }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  boxShadow: 3,
                  fontSize: "12px",
                  lineHeight: "20px",
                }}
                onClick={applyTextToAll}
              >
                {t("designer.applyAllTextBtn")}
              </Button>
            </Box>
          )}
        </Box>
      )}

      <Backdrop
        sx={{
          color: colorStyling.white.inactive,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loadingAddContent}
        onClick={null}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Paper>
  );
};

const ExistingConfirmation = ({ open, closeDialog }) => {
  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogContent>
        <DialogContentText sx={{ mb: 1, textAlign: "center", fontWeight: 500 }}>
          {t("designer.contentExistedHint")}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button fullWidth variant="contained" onClick={closeDialog}>
          {tCommon("okBtn")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContainerItem;
