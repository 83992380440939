import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  DialogTitle,
  IconButton,
  Divider,
  Button,
  InputLabel,
  TextField,
  CircularProgress,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { createGroup } from "../../store/actions/groupAction";
import { TypographySmall } from "../customComponent";
import { colorStyling } from "../../helpers/color";
import { SET_ERROR_PROMPT_GROUP_DIALOG } from "../../store/actionTypes/groupActionType";
import DispatchErrorPromptAlert from "../DispatchErrorPromptAlert";
import { parseVariables } from "../../helpers/localize";

const CreateGroup = () => {
  const dispatch = useDispatch();
  const {
    isErrorPromptGroupDialog: isError,
    errorMessageGroupDialog: errorMessage,
  } = useSelector((state) => state.group);

  const { t } = useTranslation("group");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [validation, setValidation] = useState("");
  const [loading, setLoading] = useState(false);

  const handleReset = () => {
    setName("");
    setValidation("");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setName("");
    setOpen(false);
    dispatch({ type: SET_ERROR_PROMPT_GROUP_DIALOG, payload: false });
  };

  const handleInputName = (input) => {
    if (input.length > 40)
      setValidation(
        parseVariables(tCommon("validation.maxLengthChars"), {
          num: 40,
        })
      );
    else {
      setName(input);
      if (validation) setValidation("");
    }
  };

  const handleFinishSubmit = (stateSubmit) => {
    setLoading(false);
    if (stateSubmit === "success") {
      handleReset();
      handleClickClose();
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (name.trim() === "") {
      setValidation(tCommon("validation.fieldMustNotEmpty"));
      return;
    }
    const payload = { name: name.trim() };

    setLoading(true);
    dispatch(createGroup(payload, handleFinishSubmit));
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddIcon fontSize="small" />}
        sx={{ boxShadow: 3 }}
      >
        {t("createBtn")}
      </Button>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {t("form.newDialogTitle")}
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider />

          <DialogContent>
            <DispatchErrorPromptAlert
              isError={isError}
              errorMessage={errorMessage}
              closeActionType={SET_ERROR_PROMPT_GROUP_DIALOG}
            />

            <Box sx={{ mt: 1 }}>
              <InputLabel>{t("form.nameLabel")}</InputLabel>

              <TextField
                autoFocus
                margin="dense"
                fullWidth
                name="groupName"
                placeholder={t("form.namePlaceholder")}
                value={name}
                onChange={(e) => handleInputName(e.target.value)}
                disabled={loading}
              />
              <TypographySmall sx={{ color: colorStyling.red }}>
                {validation}
              </TypographySmall>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button fullWidth variant="outlined" onClick={handleClickClose}>
              {tCommon("cancelBtn")}
            </Button>
            <Button
              fullWidth
              variant="contained"
              onClick={submitForm}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : tCommon("submitBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateGroup;
