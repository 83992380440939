import React from "react";

const AdsSlotIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ?? 128}
      height={height ?? 128}
      viewBox="0 0 512 512"
    >
      <path d="M157.5 272h37L176 218.8 157.5 272zM352 256c-13.2 0-24 10.8-24 24s10.8 24 24 24 24-10.8 24-24-10.8-24-24-24zM464 64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM250.6 352h-16.9c-6.8 0-12.9-4.3-15.1-10.8l-7.4-21.2h-70.3l-7.4 21.3a16 16 0 01-15.1 10.7h-16.9c-11 0-18.7-10.9-15.1-21.3L140 176.1a24 24 0 0122.7-16.1h26.7a24 24 0 0122.6 16.1l53.7 154.6c3.6 10.4-4.1 21.3-15.1 21.3zM424 336c0 8.8-7.2 16-16 16h-16c-4.9 0-9-2.3-12-5.7-8.6 3.7-18.1 5.7-28 5.7-39.7 0-72-32.3-72-72s32.3-72 72-72c8.5 0 16.5 1.7 24 4.4V176c0-8.8 7.2-16 16-16h16c8.8 0 16 7.2 16 16v160z"></path>
    </svg>
  );
};

export default AdsSlotIcon;
