import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  TextField,
  Tooltip,
} from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SearchIcon from "@mui/icons-material/Search";
import { colorStyling } from "../../../helpers/color";
import {
  SET_LOADING_CMS,
  SET_FORCE_LOAD,
} from "../../../store/actionTypes/cmsActionType";
import { initiateAXIOS } from "../../../config/axios";
import { initiateUserAPI } from "../../../api";
import { logger } from "../../../helpers/logger";

const ContentTemplateFilter = ({
  filter,
  setFilter,
  setSorting,
  resetFilter,
}) => {
  const dispatch = useDispatch();
  const { forceLoad } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [searchNameInput, setSearchNameInput] = useState("");
  const [searchByEditorInput, setSearchByEditorInput] = useState("");
  const [sortBy, setSortBy] = useState("idDesc");
  const [anchorEl, setAnchorEl] = useState(null);
  const openSortButton = Boolean(anchorEl);

  useEffect(() => {
    setSortBy(filter.sortBy);
    setSearchNameInput(filter.nameLike);
    setSearchByEditorInput(filter.searchByUser);
  }, [filter]);

  const getUserIDNameAlikes = async (nameInput, resolve) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_CMS, payload: true });
    dispatch({ type: SET_FORCE_LOAD, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateUserAPI + `?nameLike=${nameInput}`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let userIDs = [];
      data.items.forEach((user) => userIDs.push(user.id));
      resolve(userIDs);
    } catch (error) {
      logger.error(`error:`, error);
    }
  };

  const handleChangeSortBy = (value) => {
    setSortBy(value);
    setSorting(encodeURIComponent(value));
    setAnchorEl(null);
    dispatch({ type: SET_FORCE_LOAD, payload: true });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newFilter = {
      nameLike: searchNameInput,
      searchByUserIDs: [],
    };

    if (searchByEditorInput.length > 0) {
      getUserIDNameAlikes(searchByEditorInput, (userIDs) => {
        newFilter["searchByUserIDs"] = userIDs;
        newFilter["searchByUser"] = searchByEditorInput;
        setFilter(newFilter);
      });
      return;
    } else {
      newFilter["searchByUserIDs"] = [];
      newFilter["searchByUser"] = "";
    }

    dispatch({ type: SET_FORCE_LOAD, payload: true });
    setFilter(newFilter);
  };

  const resetState = () => {
    setSearchNameInput("");
    setSearchByEditorInput("");
    setSortBy("idDesc");
  };

  const handleRefresh = () => {
    dispatch({ type: SET_FORCE_LOAD, payload: true });
    resetState();
    resetFilter();
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box width={"50%"}>
          <TextField
            onChange={(e) => setSearchNameInput(e.target.value)}
            value={searchNameInput}
            fullWidth
            type="Search"
            placeholder={t("filter.searchPlaceholder")}
            size="small"
            disabled={forceLoad}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Box width={"27%"}>
          <TextField
            onChange={(e) => setSearchByEditorInput(e.target.value)}
            value={searchByEditorInput}
            fullWidth
            type="Search"
            placeholder={t("filter.searchByEditorPlaceholder")}
            size="small"
            disabled={forceLoad}
          />
        </Box>

        <Box display={"flex"} width={"177px"}>
          <Button variant="contained" type="submit" disabled={forceLoad}>
            {tCommon("searchBtn")}
          </Button>

          <IconButton
            onClick={handleClick}
            disabled={forceLoad}
            sx={{ ml: 2, color: colorStyling.black }}
          >
            <Tooltip title={tCommon("sortTip")}>
              <SortIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openSortButton}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => handleChangeSortBy("idDesc")}>
              <FormControlLabel
                disableTypography
                value="idDesc"
                control={<Radio size="small" checked={sortBy === "idDesc"} />}
                label={t("filter.sortByNewest")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>

            <MenuItem onClick={() => handleChangeSortBy("idAsc")}>
              <FormControlLabel
                disableTypography
                value="idAsc"
                control={<Radio size="small" checked={sortBy === "idAsc"} />}
                label={t("filter.sortByOldest")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>

            <MenuItem onClick={() => handleChangeSortBy("updatedAtDesc")}>
              <FormControlLabel
                disableTypography
                value="updatedAtDesc"
                control={
                  <Radio size="small" checked={sortBy === "updatedAtDesc"} />
                }
                label={t("filter.sortByRecentlyModified")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
          </Menu>

          <IconButton
            onClick={handleRefresh}
            disabled={forceLoad}
            sx={{ ml: 1, color: colorStyling.black }}
          >
            <Tooltip title={tCommon("resetFilterTip")}>
              <RestartAltIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default ContentTemplateFilter;
