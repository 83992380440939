import {
  SET_EQUIPMENT,
  SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN,
  SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN,
  SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN,
  SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN,
  SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN,
  SET_BREAKDOWN_ANNOUNCEMENT,
  SET_EDIT_BREAKDOWN_ANNOUNCEMENT,
  SET_EQUIPMENT_CONFIG,
  SET_EQUIPMENT_PEAK_HOUR,
  SET_ERROR_MESSAGE_REMARK_DIALOG,
  SET_ERROR_PROMPT_REMARK_DIALOG,
  SET_SUCCESS_MESSAGE_REMARK_DIALOG,
  SET_SUCCESS_PROMPT_REMARK_DIALOG,
} from "../../actionTypes/bplrt/equipmentActionType";

const initialState = {
  equipment: [],
  breakdownAnnouncement: {},
  isPeakHour: false,
  loadingAnnouncement: false,
  loadingLinkAnnouncement: false,
  isSuccessPromptEquipmentPage: false,
  successMessageEquipmentPage: "",
  isErrorPromptEquipmentPage: false,
  isErrorPromptEquipmentAnnouncementPage: false,
  errorMessageEquipmentPage: "",
  errorMessageEquipmentAnnouncementPage: "",
  isSuccessPromptRemarkDialog: false,
  successMessageRemarkDialog: "",
  isErrorPromptRemarkDialog: false,
  errorMessageRemarkDialog: "",
};

export const equipmentReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_EQUIPMENT:
      return {
        ...state,
        equipment: payload,
      };

    case SET_EQUIPMENT_CONFIG:
      return {
        ...state,
        breakdownAnnouncement: payload.announcement,
        isPeakHour: payload.isPeakHour,
      };

    case SET_EQUIPMENT_PEAK_HOUR:
      return {
        ...state,
        isPeakHour: payload.isPeakHour,
      };

    case SET_BREAKDOWN_ANNOUNCEMENT:
      return { ...state, breakdownAnnouncement: payload };

    case SET_EDIT_BREAKDOWN_ANNOUNCEMENT:
      let annData = state.breakdownAnnouncement;
      annData["isActive"] = payload.active;
      return { ...state, breakdownAnnouncement: { ...annData } };

    case SET_ERROR_MESSAGE_EQUIPMENT_BREAKDOWN:
      return { ...state, errorMessageEquipmentPage: payload };

    case SET_ERROR_PROMPT_EQUIPMENT_BREAKDOWN:
      return { ...state, isErrorPromptEquipmentPage: payload };

    case SET_SUCCESS_MESSAGE_EQUIPMENT_BREAKDOWN:
      return { ...state, successMessageEquipmentPage: payload };

    case SET_SUCCESS_PROMPT_EQUIPMENT_BREAKDOWN:
      return { ...state, isSuccessPromptEquipmentPage: payload };

    case SET_ERROR_MESSAGE_REMARK_DIALOG:
      return { ...state, errorMessageRemarkDialog: payload };

    case SET_ERROR_PROMPT_REMARK_DIALOG:
      return { ...state, isErrorPromptRemarkDialog: payload };

    case SET_SUCCESS_MESSAGE_REMARK_DIALOG:
      return { ...state, successMessageRemarkDialog: payload };

    case SET_SUCCESS_PROMPT_REMARK_DIALOG:
      return { ...state, isSuccessPromptRemarkDialog: payload };

    case SET_LOADING_ANNOUNCEMENT_EQUIPMENT_BREAKDOWN:
      return { ...state, loadingAnnouncement: payload };

    default:
      return state;
  }
};
