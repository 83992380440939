import { useTranslation } from "react-i18next";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import parser from "html-react-parser";

const CloseConfirmation = ({ open, handleConfirm, closeDialog }) => {
  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const handleCallback = (event) => {
    event.preventDefault();
    handleConfirm();
  };

  return (
    <Dialog maxWidth="sm" open={open}>
      <DialogContent>
        <DialogContentText sx={{ mb: 1, textAlign: "center", fontWeight: 600 }}>
          {t("setting.closeConfirmationTitle")}
        </DialogContentText>
        <Alert severity="warning" sx={{ alignItems: "center" }}>
          {parser(t("setting.closeConfirmationWarning"))}
        </Alert>
      </DialogContent>

      <DialogActions>
        <Button
          startIcon={<ClearIcon />}
          fullWidth
          variant="outlined"
          onClick={closeDialog}
        >
          {tCommon("noBtn")}
        </Button>

        <Button
          startIcon={<DoneIcon />}
          fullWidth
          variant="contained"
          onClick={handleCallback}
        >
          {tCommon("yesBtn")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloseConfirmation;
