import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@mui/material";
import { SET_ERROR_PROMPT_USER_PAGE } from "../../store/actionTypes/userActionType";

const UserErrorPrompt = () => {
  const dispatch = useDispatch();
  const { isUserPageError: isError, errorMessageUserPage: errorMessage } =
    useSelector((state) => state.user);

  const { t } = useTranslation("user");

  const handleClose = (e, reason) => {
    dispatch({ type: SET_ERROR_PROMPT_USER_PAGE, payload: false });
  };

  return (
    isError && (
      <Alert
        sx={{ my: 2 }}
        severity="error"
        variant="filled"
        onClose={handleClose}
      >
        <AlertTitle>{t("common.errorPromptTitle")}</AlertTitle>
        {errorMessage}
      </Alert>
    )
  );
};

export default UserErrorPrompt;
