import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Alert,
  Button,
  Box,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { deleteUsergroup } from "../../../store/actions/userAction";
import { colorStyling } from "../../../helpers/color";
import parser from "html-react-parser";
import { parseVariables } from "../../../helpers/localize";

const DeleteUserGroup = ({ open, onClose, group }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("usergroup");
  const { t: tCommon } = useTranslation();

  const handleClickClose = () => {
    onClose(false);
  };

  const handleDelete = (id) => {
    dispatch(deleteUsergroup(id));

    handleClickClose();
  };

  return (
    <>
      <Dialog maxWidth="sm" onClose={handleClickClose} open={open}>
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            {parser(
              parseVariables(t("item.deleteConfirmationText"), {
                name: group.name,
              })
            )}
          </DialogContentText>

          <Box
            sx={{
              fontFamily: "sans-serif",
              color: colorStyling.gray.darkblue,
              textAlign: "center",
              fontSize: 14,
            }}
            id="alert-dialog-slide-description"
          >
            <Alert severity="warning" variant="outlined">
              <strong>{t("item.deleteConfirmationHint")}</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            color="error"
            fullWidth
            startIcon={<ClearIcon />}
            onClick={handleClickClose}
          >
            {tCommon("cancelBtn")}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleDelete(group.id)}
            fullWidth
            startIcon={<DoneIcon />}
          >
            {tCommon("confirmBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteUserGroup;
