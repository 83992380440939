import { Box, Checkbox } from "@mui/material";
import { TypographyNormal } from "../components/customComponent";
import { parseExpression } from "cron-parser";
import { logger } from "./logger";
import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export const weeklyCheckbox = (dayCheckbox, setDayCheckbox) => {
  const dayData = [
    { text: "Monday", value: "1" },
    { text: "Tuesday", value: "2" },
    { text: "Wednesday", value: "3" },
    { text: "Thursday", value: "4" },
    { text: "Friday", value: "5" },
    { text: "Saturday", value: "6" },
    { text: "Sunday", value: "0" },
  ];

  const handleChangeDayCheckbox = (e, day) => {
    if (e.target.checked) setDayCheckbox([...dayCheckbox, day]);
    else setDayCheckbox(dayCheckbox.filter((d) => d !== day));
  };

  return dayData.map((day) => (
    <Box
      key={"check" + day.text}
      display={"flex"}
      flexDirection={"column"}
      flexWrap={"nowrap"}
      alignItems={"center"}
    >
      <TypographyNormal>{day.text}</TypographyNormal>
      <Checkbox
        checked={dayCheckbox.includes(day.value)}
        onChange={(e) => {
          handleChangeDayCheckbox(e, day.value);
        }}
      />
    </Box>
  ));
};

/**
 * Calculate cron according to frequency and recurrence option
 * @param {*} frequency
 * @param {*} date Form start date
 * @param {*} radioValue Value of radio button on Daily and Monthly
 * @param {*} dayCheckbox Weekly selected Day
 * @param {*} monthCheckbox Monthly selected Month
 * @param {*} selectMonthlyDate Monthly selected date
 * @returns cron string
 */
export const calculateCron = (
  frequency,
  date,
  radioValue,
  dayCheckbox,
  monthCheckbox,
  selectMonthlyDate,
  minuteInterval
) => {
  let cron;

  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();

  switch (frequency) {
    case "ONCE":
      cron = null;
      break;

    case "DAILY":
      let dailyDayofWeek;
      if (+radioValue === 0) dailyDayofWeek = "*";
      if (+radioValue === 1) dailyDayofWeek = "1-5";
      if (+radioValue === 2) dailyDayofWeek = "0,6";
      cron = `${minute} ${hour} * * ${dailyDayofWeek}`;
      break;

    case "WEEKLY":
      cron = `${minute} ${hour} * * ${dayCheckbox.toString()}`;
      break;

    case "MONTHLY":
      let monthlyDateSelect;
      let monthlyDaySelect;

      switch (+radioValue) {
        case 0:
          monthlyDaySelect = "*";
          monthlyDateSelect = "*";
          break;

        case 1:
          monthlyDaySelect = "1-5";
          monthlyDateSelect = "*";
          break;

        case 2:
          monthlyDaySelect = "0,6";
          monthlyDateSelect = "*";
          break;

        case 3:
          monthlyDaySelect = "*";
          monthlyDateSelect = selectMonthlyDate.start;
          break;

        case 4:
          monthlyDaySelect = "*";
          monthlyDateSelect =
            selectMonthlyDate.start + "-" + selectMonthlyDate.end;
          break;

        default:
          break;
      }
      cron = `${minute} ${hour} ${monthlyDateSelect} ${monthCheckbox} ${monthlyDaySelect}`;
      break;

    default:
      return frequency;
  }

  if (minuteInterval && cron) {
    let cronSplit = cron.split(" ");
    cronSplit[0] = "*/" + minuteInterval;
    cronSplit[1] = "*";
    const newCron = cronSplit.join(" ");
    cron = newCron;
  }

  logger.log(cron);
  return cron;
};

export const cronToFormData = (frequency, cron) => {
  if (!cron || cron.length === 0) {
    return null;
  }

  const cronSplit = cron.split(" ");
  /**
   * Cron day included in daily frequency
   * respectively "Everyday", "Weekday", and "Weekend"
   */
  const dailyCondition = ["*", "1-5", "0,6", "6,0"];

  let newFreq;
  let radioValue = 0;
  let selectValue = [];
  let dateValue = { start: 1, end: 1 };
  let minuteInterval = "";
  let freq = frequency;

  switch (freq) {
    case "OFF":
      newFreq = "ONCE";
      break;

    case "DAILY":
      if (dailyCondition.includes(cronSplit[4])) {
        newFreq = "DAILY";
        radioValue = dailyCondition
          .findIndex((ele) => ele === cronSplit[4])
          .toString();
        if (radioValue === "3") radioValue = "2";
      } else {
        newFreq = "WEEKLY";
        selectValue = cronSplit[4].split(",");
      }
      break;

    case "WEEKLY":
      newFreq = "WEEKLY";
      selectValue = cronSplit[4].split(",");
      break;

    case "MONTHLY":
      newFreq = "MONTHLY";
      if (dailyCondition.includes(cronSplit[4])) {
        radioValue = dailyCondition
          .findIndex((ele) => ele === cronSplit[4])
          .toString();
        if (radioValue === "3") radioValue = "2";
      }

      if (cronSplit[2].includes("-")) {
        const dateSplit = cronSplit[2].split("-");
        radioValue = "4";
        dateValue = { start: dateSplit[0], end: dateSplit[1] };
      } else if (cronSplit[2] !== "*") {
        radioValue = "3";
        dateValue = { start: cronSplit[2], end: cronSplit[2] };
      }

      selectValue = cronSplit[3].split(",");
      break;

    default:
      return frequency;
  }

  if (cronSplit[0].includes("/")) minuteInterval = cronSplit[0].split("/")[1];

  return {
    frequency: newFreq,
    radioValue,
    selectValue,
    dateValue,
    minuteInterval,
  };
};

export const getNextRunTimesUntilDate = (
  cronExpression,
  startDate,
  endDate
) => {
  const nextRunTimes = [];
  try {
    // Condition for checking minute interval
    const isMinuteInterval = cronExpression.split(" ")[0].includes("/");
    const dateStart = new Date(startDate);
    let options = {
      currentDate: dateStart, // Start calculating from now
      utc: true, // Use local time
    };

    let interval = parseExpression(cronExpression, options);

    let lastRunDate = dateStart;

    // Loop to get the next run times until endDate
    while (true) {
      let nextRun = interval.next().toDate();

      // Move to next day on every iteration of minute interval
      if (isMinuteInterval) {
        nextRun = new Date(dayjs(lastRunDate).add(1, "day"));
        lastRunDate = nextRun; // Update the last run date after skipping

        // Move the interval's current date to the new run date
        options.currentDate = nextRun;
        interval = parseExpression(cronExpression, options);
      } else {
        lastRunDate = nextRun; // Update the last run date
      }

      if (dayjs(nextRun).diff(dayjs(endDate), true) > 0) break; // Stop when next run exceeds end date

      nextRunTimes.push(dayjs(new Date(nextRun))); // Add the next run time to the array
    }
  } catch (err) {
    logger.error("Error parsing cron expression:", err);
  } finally {
    return nextRunTimes;
  }
};

export const getTimeFromCron = (
  cronExpression,
  timezoneName,
  addDuration = 0
) => {
  // Parse the cron expression
  const interval = parseExpression(cronExpression, { tz: timezoneName });

  // Get the next scheduled date in the specified timezone
  const nextExecution = interval.next();

  // Convert the next scheduled date to the desired timezone
  let nextExecutionInTimezone = dayjs(nextExecution.toDate()).tz(timezoneName);

  // If addDuration is provided, add it
  if (addDuration) {
    nextExecutionInTimezone = nextExecutionInTimezone.add(
      addDuration,
      "millisecond"
    );
  }

  // Convert to the user's guessed timezone
  const userTimezone = dayjs.tz.guess();
  const nextExecutionInUserTimezone = nextExecutionInTimezone.tz(userTimezone);

  return nextExecutionInUserTimezone.format("HH:mm");
};

export const getTimeDifferenceInMilliseconds = (startTime, endTime) => {
  // Parse the start and end times
  const [startHours, startMinutes] = startTime.split(":").map(Number);
  const [endHours, endMinutes] = endTime.split(":").map(Number);

  // Convert both times to total minutes
  const startTotalMinutes = startHours * 60 + startMinutes;
  const endTotalMinutes = endHours * 60 + endMinutes;

  // Calculate the difference in minutes
  let differenceInMinutes;

  if (endTotalMinutes >= startTotalMinutes) {
    differenceInMinutes = endTotalMinutes - startTotalMinutes;
  } else {
    // Crosses midnight
    differenceInMinutes = 24 * 60 - startTotalMinutes + endTotalMinutes;
  }

  // Convert the difference from minutes to milliseconds
  const differenceInMilliseconds = differenceInMinutes * 60 * 1000;

  return differenceInMilliseconds;
};

export const getEndTimeFromDifference = (
  startTime,
  differenceInMilliseconds
) => {
  // Parse the start time
  const [startHours, startMinutes] = startTime.split(":").map(Number);

  // Convert start time to total minutes
  const startTotalMinutes = startHours * 60 + startMinutes;

  // Convert difference from milliseconds to minutes
  const differenceInMinutes = Math.floor(
    differenceInMilliseconds / (60 * 1000)
  );

  // Calculate the end total minutes
  const endTotalMinutes = startTotalMinutes + differenceInMinutes;

  // Handle crossing midnight
  const totalMinutesInDay = 24 * 60;
  const adjustedEndTotalMinutes = endTotalMinutes % totalMinutesInDay;

  // Convert total minutes back to hours and minutes
  const endHours = Math.floor(adjustedEndTotalMinutes / 60);
  const endMinutes = adjustedEndTotalMinutes % 60;

  // Format the end time as HH:MM
  const formattedEndTime = `${String(endHours).padStart(2, "0")}:${String(
    endMinutes
  ).padStart(2, "0")}`;

  return formattedEndTime;
};

export const convertCronTimeToTimezone = (
  cronHour,
  cronMinute,
  sourceTimezone,
  targetTimezone
) => {
  // Create a Day.js object for the cron time in the source timezone
  const cronTime = dayjs.tz(
    `${new Date().toISOString().split("T")[0]} ${cronHour}:${cronMinute}`,
    sourceTimezone
  );

  // Convert to the target timezone
  const convertedTime = cronTime.tz(targetTimezone);

  // Return the converted hour and minute
  return {
    hour: convertedTime.hour(),
    minute: convertedTime.minute(),
  };
};
