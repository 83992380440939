// Enum: "NONE"
export const None = {
  id: "none",
  label: "None",
  speed: 100,
  effect: {
    prev: {
      translate: [0, 0, -1],
    },
    next: {
      translate: [0, 0, 0],
    },
  },
};
