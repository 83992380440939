import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  SET_ANNOUNCEMENT_FILTER,
  SET_LOADING_ANNOUNCEMENT,
} from "../../store/actionTypes/announcementActionType";
import { colorStyling } from "../../helpers/color";

const AnnouncementFilter = () => {
  const dispatch = useDispatch();
  const { announcementFilters } = useSelector((state) => state.announcement);

  const { t } = useTranslation("announcement");
  const { t: tCommon } = useTranslation();

  const [announcementName, setAnnouncementName] = useState(
    announcementFilters.nameLike
  );

  const [status, setStatus] = useState(announcementFilters.isActive);

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const handleDispatchFilter = (payload) => {
    dispatch({
      type: SET_ANNOUNCEMENT_FILTER,
      payload,
    });
  };

  const handleLoading = (value) => {
    dispatch({
      type: SET_LOADING_ANNOUNCEMENT,
      payload: value,
    });
  };

  const handleChangeSortBy = (value) => {
    handleLoading(true);
    handleDispatchFilter({ ...announcementFilters, sortBy: value });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetState = () => {
    setAnnouncementName("");
    setStatus("");
  };

  const handleRefresh = () => {
    handleDispatchFilter({ sortBy: "isActiveDesc" });
    resetState();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let query = { ...announcementFilters };

    if (announcementName) query["nameLike"] = announcementName;
    else delete query["nameLike"];

    if (status) query["isActive"] = status;
    else delete query["isActive"];

    handleLoading(true);
    handleDispatchFilter(query);
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TextField
          onChange={(e) => setAnnouncementName(e.target.value)}
          value={announcementName}
          fullWidth
          type="Search"
          placeholder={t("filter.searchPlaceholder")}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          size="small"
          fullWidth
          select
          SelectProps={{ native: true }}
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          sx={{ mx: 2 }}
        >
          <option defaultValue value="">
            {t("filter.statusAny")}
          </option>
          <option value="true">{t("filter.statusON")}</option>
          <option value="false">{t("filter.statusOFF")}</option>
        </TextField>

        <Button variant="contained" type="submit">
          {tCommon("searchBtn")}
        </Button>

        <IconButton
          sx={{ ml: 2, color: colorStyling.black }}
          onClick={handleClick}
        >
          <Tooltip title={tCommon("sortTip")}>
            <SortIcon fontSize="small" />
          </Tooltip>
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openSortButton}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => handleChangeSortBy("isActiveDesc")}>
            <FormControlLabel
              disableTypography
              value="isActiveDesc"
              control={
                <Radio
                  size="small"
                  checked={announcementFilters.sortBy === "isActiveDesc"}
                />
              }
              label={t("filter.sortByActive")}
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>

          <MenuItem onClick={() => handleChangeSortBy("idDesc")}>
            <FormControlLabel
              disableTypography
              value="idDesc"
              control={
                <Radio
                  size="small"
                  checked={announcementFilters.sortBy === "idDesc"}
                />
              }
              label={t("filter.sortByNewest")}
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>

          <MenuItem onClick={() => handleChangeSortBy("idAsc")}>
            <FormControlLabel
              disableTypography
              value="idAsc"
              control={
                <Radio
                  size="small"
                  checked={announcementFilters.sortBy === "idAsc"}
                />
              }
              label={t("filter.sortByOldest")}
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>

          <MenuItem onClick={() => handleChangeSortBy("nameAsc")}>
            <FormControlLabel
              disableTypography
              value="nameAsc"
              control={
                <Radio
                  size="small"
                  checked={announcementFilters.sortBy === "nameAsc"}
                />
              }
              label={t("filter.sortByAtoZ")}
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>

          <MenuItem onClick={() => handleChangeSortBy("nameDesc")}>
            <FormControlLabel
              disableTypography
              value="nameDesc"
              control={
                <Radio
                  size="small"
                  checked={announcementFilters.sortBy === "nameDesc"}
                />
              }
              label={t("filter.sortByZtoA")}
              sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
            />
          </MenuItem>
        </Menu>

        <IconButton
          sx={{ ml: 1, color: colorStyling.black }}
          onClick={handleRefresh}
        >
          <Tooltip title={tCommon("resetFilterTip")}>
            <RestartAltIcon fontSize="small" />
          </Tooltip>
        </IconButton>
      </Box>
    </>
  );
};

export default AnnouncementFilter;
