import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  Menu,
  Tooltip,
  MenuItem,
  Radio,
  FormControlLabel,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import SortIcon from "@mui/icons-material/Sort";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  SET_GROUP_FILTER,
  SET_LOADING_GROUP,
} from "../../store/actionTypes/groupActionType";
import { colorStyling } from "../../helpers/color";

const FilterGroup = () => {
  const dispatch = useDispatch();
  const { groupFilters } = useSelector((state) => state.group);

  const { t } = useTranslation("group");
  const { t: tCommon } = useTranslation();

  const [groupName, setGroupName] = useState(groupFilters.nameLike);

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDispatchFilter = (payload) => {
    dispatch({
      type: SET_GROUP_FILTER,
      payload,
    });
  };

  const handleLoading = (value) => {
    dispatch({
      type: SET_LOADING_GROUP,
      payload: value,
    });
  };

  const handleChangeSortBy = (value) => {
    handleLoading(true);
    handleDispatchFilter({ ...groupFilters, sortBy: value });
  };

  const handleRefresh = () => {
    handleLoading(true);
    handleDispatchFilter({ sortBy: "idDesc" });
    setGroupName("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const query = { ...groupFilters };
    if (groupName) query["nameLike"] = groupName;
    else delete query["nameLike"];

    handleLoading(true);
    handleDispatchFilter(query);
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onSubmit={handleSubmit}
      >
        <TextField
          fullWidth
          size="small"
          type="search"
          placeholder={t("filter.searchPlaceholder")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />

        <Button onClick={handleSubmit} variant="contained" sx={{ ml: 2 }}>
          {tCommon("searchBtn")}
        </Button>

        <Box sx={{ display: "flex", ml: 2 }}>
          <IconButton
            sx={{ color: colorStyling.black }}
            onClick={handleClick}
            aria-controls={openSortButton ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openSortButton ? "true" : undefined}
          >
            <Tooltip title={tCommon("sortTip")}>
              <SortIcon fontSize="small" />
            </Tooltip>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openSortButton}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => handleChangeSortBy("idDesc")}>
              <FormControlLabel
                disableTypography
                value="idDesc"
                control={
                  <Radio
                    size="small"
                    checked={groupFilters.sortBy === "idDesc"}
                  />
                }
                label={t("filter.sortByNewest")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("idAsc")}>
              <FormControlLabel
                disableTypography
                value="idAsc"
                control={
                  <Radio
                    size="small"
                    checked={groupFilters.sortBy === "idAsc"}
                  />
                }
                label={t("filter.sortByOldest")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("nameAsc")}>
              <FormControlLabel
                disableTypography
                value="nameAsc"
                control={
                  <Radio
                    size="small"
                    checked={groupFilters.sortBy === "nameAsc"}
                  />
                }
                label={t("filter.sortByAtoZ")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("nameDesc")}>
              <FormControlLabel
                disableTypography
                value="nameDesc"
                control={
                  <Radio
                    size="small"
                    checked={groupFilters.sortBy === "nameDesc"}
                  />
                }
                label={t("filter.sortByZtoA")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
          </Menu>

          <IconButton
            sx={{ color: colorStyling.black, ml: 2 }}
            color="primary"
            onClick={handleRefresh}
          >
            <Tooltip title={tCommon("resetFilterTip")}>
              <RestartAltIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default FilterGroup;
