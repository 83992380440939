import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Box, Menu, Tooltip } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import { grey } from "@mui/material/colors";
import {
  setContentLayers,
  updateContentLayerById,
} from "../../../../store/actions/cmsAction";
import { TypographyLarge } from "../../../customComponent";
import FONTS from "../../../../fonts";
import update from "immutability-helper";
import Marquee from "react-fast-marquee";
import { Rnd } from "react-rnd";

const PreviewItem = ({ id, index, layer, pauseViewer }) => {
  const dispatch = useDispatch();
  const {
    contentLayers,
    layerMedias,
    canvasRes,
    contentRes,
    widthRatio,
    heightRatio,
  } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");

  const [layerPreview, setLayerPreview] = useState(null);
  const [layerSize, setLayerSize] = useState({
    width: 0,
    height: 0,
    left: 0,
    top: 0,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);

  const [contextMenu, setContextMenu] = useState(null);
  // TODO: Right-Click Menu
  // eslint-disable-next-line no-unused-vars
  const [contextMenuEnabled, setContextMenuEnabled] = useState(false);

  const videoRef = useRef(null);

  // TODO: Right-Click Menu
  // eslint-disable-next-line no-unused-vars
  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null
    );
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  const parseObjectFit = (assetFit) => {
    switch (assetFit) {
      default:
      case "FIT":
        return "contain";
      case "STRETCH":
        return "fill";
    }
  };

  useEffect(() => {
    const ratiodWidth = Math.round(layer.width / widthRatio);
    const ratiodHeight = Math.round(layer.height / heightRatio);

    if (layerMedias[id]?.length > 0) {
      const medias = [...layerMedias[id]];
      let previewEl = null;
      let fileSrc = null;

      const firstMedia = medias[0];
      switch (firstMedia.type) {
        case "VIDEO":
          fileSrc = firstMedia.vidSrc;
          previewEl = (
            <video
              ref={(ref) => {
                videoRef.current = ref;
                if (videoRef.current) {
                  if (pauseViewer) {
                    videoRef?.current?.pause();
                  } else {
                    videoRef?.current?.play();
                  }
                }
              }}
              draggable={false}
              alt={firstMedia.name}
              loop={true}
              muted={true}
              controls={false}
              src={firstMedia.url ?? fileSrc}
              onClick={(e) => e.preventDefault()}
              style={{
                height: "100%",
                width: "100%",
                objectFit: parseObjectFit(firstMedia.assetFit),
              }}
            />
          );
          break;

        case "IMAGE":
          fileSrc = firstMedia.url ?? firstMedia.src;
          previewEl = (
            <img
              draggable={false}
              src={fileSrc}
              alt={`${t("designer.previewItemAltTextPrefix")} #${index}`}
              style={{
                height: "100%",
                width: "100%",
                objectFit: parseObjectFit(firstMedia.assetFit),
              }}
            />
          );
          break;

        default:
        case "VISTAR_ADVERTISEMENT":
        case "HIVESTACK_ADVERTISEMENT":
          previewEl = (
            <img
              draggable={false}
              src={`/ad_slot.png`}
              alt={`${t("designer.previewItemAltTextPrefix")} #${index}`}
              style={{
                height: "100%",
                width: "100%",
                objectFit: "fill",
              }}
            />
          );
          break;
      }
      setLayerPreview(previewEl);
      setContextMenuEnabled(true);
    } else {
      setLayerPreview(null);
      setContextMenuEnabled(false);
    }

    const updatedSize = {
      top: layer.top,
      left: layer.left,
      width: ratiodWidth,
      height: ratiodHeight,
    };
    setLayerSize(updatedSize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layer,
    layerMedias,
    isResizing,
    isDragging,
    pauseViewer,
    widthRatio,
    heightRatio,
  ]);

  const textLayer = useMemo(() => {
    return layer.text;
  }, [layer]);

  const textSizeRatiod = useMemo(() => {
    if (!textLayer) {
      return 14;
    }

    let ratiodSize = Math.ceil(textLayer.size / widthRatio);
    if (ratiodSize <= 0) ratiodSize = 1;
    return ratiodSize;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layer, contentRes, canvasRes, widthRatio]);

  const parseAlignTextHorizontal = useMemo(() => {
    switch (textLayer?.hAlign) {
      default:
      case "LEFT":
        return "left";
      case "CENTER":
        return "center";
      case "RIGHT":
        return "right";
      case "JUSTIFY":
        return "justify";
    }
  }, [textLayer]);

  const parseAlignTextVertical = useMemo(() => {
    switch (textLayer?.vAlign) {
      case "TOP":
        return "baseline";
      default:
      case "CENTER":
        return "center";
      case "BOTTOM":
        return "end";
    }
  }, [textLayer]);

  const textComponent = textLayer ? (
    <TypographyLarge
      sx={{
        pl: 1,
        pr: textLayer?.scrolling ? 10 : 1,
        height: layerSize.height,
        textAlign: parseAlignTextHorizontal,
        alignContent: parseAlignTextVertical,
        overflow: "hidden",
        textWrap: "nowrap",
        textOverflow: "clip",
        fontSize: `${textSizeRatiod}px`,
        fontWeight: textLayer.weight,
        color: textLayer.color,
        display: "grid",
        fontFamily: FONTS[textLayer.family].value,
      }}
    >
      {textLayer.text}
    </TypographyLarge>
  ) : null;

  const previewElement = (
    <>
      {layer.type !== "text" &&
        (layerPreview ?? (
          <img
            draggable={false}
            src={`https://placehold.co/${layer.width}x${layer.height}`}
            alt={`${t("designer.previewItemAltTextPrefix")} #${index}`}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "fill",
            }}
          />
        ))}

      {layer.type === "text" && textLayer?.scrolling ? (
        <Marquee
          play={!pauseViewer}
          speed={textLayer?.speed * 40}
          direction={textLayer?.direction?.toLowerCase()}
        >
          {textComponent}
        </Marquee>
      ) : (
        <Box sx={{ width: "100%" }}>{textComponent}</Box>
      )}
    </>
  );

  const setBoxToActive = useCallback(
    (id) => {
      let mergedUpdates = { ...contentLayers };
      const layerKeys = Object.keys(mergedUpdates);
      for (let i = 0; i < layerKeys.length; i++) {
        const layerID = layerKeys[i];
        if (layerID === id) {
          mergedUpdates = update(mergedUpdates, {
            [layerID]: { $merge: { active: true } },
          });
        } else {
          mergedUpdates = update(mergedUpdates, {
            [layerID]: { $merge: { active: false } },
          });
        }
      }

      const finalUpdates = update(contentLayers, { $merge: mergedUpdates });
      dispatch(setContentLayers({ ...finalUpdates }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentLayers]
  );

  const moveBox = useCallback(
    (id, left, top) => {
      let mergedUpdates = contentLayers[id];
      mergedUpdates = update(mergedUpdates, {
        dimensions: { $merge: { left, top } },
      });
      mergedUpdates = update(mergedUpdates, { $merge: { dragFlag: true } });

      dispatch(
        updateContentLayerById({
          contentLayers,
          layerID: id,
          update: mergedUpdates,
        })
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentLayers]
  );

  const resizeBox = useCallback(
    (id, delta, position) => {
      let adjustedPosX = position.x;
      let adjustedPosY = position.y;
      let adjustedWidth = layer.width + Math.round(delta.width * widthRatio);
      let adjustedHeight =
        layer.height + Math.round(delta.height * heightRatio);

      // Snap box to fit
      let paddingLimitWidth = contentRes.width;
      if (!(contentRes.free || contentRes.width > 2000)) {
        paddingLimitWidth = contentRes.width * 0.95;
      }
      let paddingLimitHeight = contentRes.height;
      if (!(contentRes.free || contentRes.height > 2000)) {
        paddingLimitHeight = contentRes.height * 0.95;
      }

      const originalAspectRatio = layer.width / layer.height;
      if (
        adjustedWidth > paddingLimitWidth &&
        adjustedWidth !== contentRes.width
      ) {
        adjustedWidth = contentRes.width;
        adjustedPosX = 0;
        if (layer.lockRatio) {
          adjustedHeight = Math.round(adjustedWidth / originalAspectRatio);
          if (adjustedHeight > paddingLimitHeight) {
            adjustedHeight = contentRes.height;
          }

          const combinedHeight = adjustedPosY * heightRatio + adjustedHeight;
          if (combinedHeight > contentRes.height) {
            adjustedPosY -= Math.round(
              (combinedHeight - contentRes.height) / heightRatio
            );
          }
        }
      } else if (
        adjustedHeight > paddingLimitHeight &&
        adjustedHeight !== contentRes.height
      ) {
        adjustedHeight = contentRes.height;
        adjustedPosY = 0;
        if (layer.lockRatio) {
          adjustedWidth = Math.round(adjustedHeight * originalAspectRatio);
          if (adjustedWidth > paddingLimitWidth) {
            adjustedWidth = contentRes.width;
          }

          const combinedWidth = adjustedPosX * widthRatio + adjustedWidth;
          if (combinedWidth > contentRes.width) {
            adjustedPosX -= Math.round(
              (combinedWidth - contentRes.width) / widthRatio
            );
          }
        }
      }

      let mergedUpdates = contentLayers[id];
      mergedUpdates = update(mergedUpdates, {
        dimensions: {
          $merge: {
            width: adjustedWidth,
            height: adjustedHeight,
            left: adjustedPosX,
            top: adjustedPosY,
          },
        },
      });
      mergedUpdates = update(mergedUpdates, { $merge: { dragFlag: true } });

      dispatch(
        updateContentLayerById({
          contentLayers,
          layerID: id,
          update: mergedUpdates,
        })
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layer, contentLayers, contentRes, canvasRes, widthRatio, heightRatio]
  );

  const handleOnDragStart = (e, d) => {
    setIsDragging(true);
    setBoxToActive(id);
  };

  const handleOnDragStop = (e, d) => {
    moveBox(id, d.x, d.y);
    setIsDragging(false);
  };

  const handleOnResizeStart = () => {
    setIsResizing(true);
  };

  const handleOnResizeStop = (delta, position) => {
    resizeBox(id, delta, position);
    setIsResizing(false);
  };

  return (
    <Rnd
      size={{ width: layerSize.width, height: layerSize.height }}
      position={{ x: layerSize.left, y: layerSize.top }}
      bounds={"parent"}
      lockAspectRatio={layer.lockRatio}
      onDragStart={(e, d) => handleOnDragStart(e, d)}
      onDragStop={(e, d) => handleOnDragStop(e, d)}
      onResizeStart={() => handleOnResizeStart()}
      onResizeStop={(e, dir, ref, delta, position) =>
        handleOnResizeStop(delta, position)
      }
      // TODO: Right-Click Menu
      // onContextMenu={contextMenuEnabled ? handleContextMenu : undefined}
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        borderStyle: "solid",
        borderWidth: layer.active ? 2 : 1,
        borderColor: layer.active ? "dodgerblue" : "burlywood",
        backgroundColor:
          layer.bgColor?.length > 0 ? layer.bgColor : "lightgrey",
        zIndex: layer.zIndex ?? 0,
      }}
    >
      {previewElement}
      {/* // TODO: Right-Click Menu */}
      {false && (
        <Menu
          open={contextMenu !== null}
          onClose={handleCloseContextMenu}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          <Box display={"flex"} alignItems={"center"} sx={{ marginX: "4px" }}>
            <Tooltip arrow disableInteractive title={"Bold"}>
              <Button
                variant="contained"
                sx={{
                  padding: "4px",
                  minWidth: 36,
                  height: 33,
                  color: "black",
                  backgroundColor: grey[300],
                }}
              >
                <FormatBoldIcon />
              </Button>
            </Tooltip>
          </Box>
        </Menu>
      )}
    </Rnd>
  );
};

export default PreviewItem;
