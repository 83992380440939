import * as yup from "yup";
import i18n from 'i18next';
import { parseVariables } from "../helpers/localize";

export const createUserRoleSchema = yup.object().shape({
  name: yup.string().min(6, ({ min }) => parseVariables(i18n.t("validation.user.nameMinMsg"), { min })).required(i18n.t("validation.user.nameRequiredMsg")),
  email: yup.string().email(i18n.t("validation.user.emailValidMsg")).required(i18n.t("validation.user.emailRequiredMsg")),
  password: yup.string().min(8, ({ min }) => parseVariables(i18n.t("validation.user.passwordMinMsg"), { min })).max(30, ({ max }) => parseVariables(i18n.t("validation.user.passwordMaxMsg"), { max })).required(i18n.t("validation.user.passwordRequiredMsg")),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
  role: yup.string().required(i18n.t("validation.user.roleRequiredMsg")),
});

export const createAdminRoleSchema = yup.object().shape({
  name: yup.string().min(6, ({ min }) => parseVariables(i18n.t("validation.user.nameMinMsg"), { min })).required(i18n.t("validation.user.nameRequiredMsg")),
  email: yup.string().email(i18n.t("validation.user.emailValidMsg")).required(i18n.t("validation.user.emailRequiredMsg")),
  password: yup.string().min(8, ({ min }) => parseVariables(i18n.t("validation.user.passwordMinMsg"), { min })).max(30, ({ max }) => parseVariables(i18n.t("validation.user.passwordMaxMsg"), { max })).required(i18n.t("validation.user.passwordRequiredMsg")),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
  role: yup.string().required(i18n.t("validation.user.roleRequiredMsg")),
});
