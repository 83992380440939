import React, { useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import {
  DangerButton,
  SecondaryDangerButton,
} from "../../../../../components/customComponent";
import RefreshIcon from "@mui/icons-material/Refresh";
import ClearIcon from "@mui/icons-material/Clear";
import DoneIcon from "@mui/icons-material/Done";
import { useDispatch } from "react-redux";
import { editAllSMPDisruption } from "../../../../store/actions/bplrt/smpAction";
import { useTranslation } from "react-i18next";
import parser from "html-react-parser";

const ResetAllDisruption = ({
  platformData,
  trackData,
  handleResetState,
  setIsChanged,
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("bplrtSmp");

  const [openResetAllDialog, setOpenResetAllDialog] = useState(false);

  const resetMap = () => {
    const payload = {};
    const platformsArr = platformData.map(({ id }) => ({
      id,
      isDisrupted: false,
    }));
    const tracksArr = trackData.map(({ id }) => ({
      id,
      isDisrupted: false,
    }));
    payload["platforms"] = platformsArr;
    payload["tracks"] = tracksArr;
    dispatch(editAllSMPDisruption(payload));
    setOpenResetAllDialog(false);
    handleResetState();
    setIsChanged(false);
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<RefreshIcon />}
        onClick={() => {
          setOpenResetAllDialog(true);
        }}
        color="error"
        sx={{ boxShadow: 3, mr: "8px" }}
      >
        {t("resetDisruptionBtn")}
      </Button>

      <Dialog
        maxWidth="sm"
        open={openResetAllDialog}
        onClose={() => {
          setOpenResetAllDialog(false);
        }}
      >
        <DialogContent>
          <DialogContentText
            sx={{
              textAlign: "center",
              mb: 2,
            }}
          >
            {parser(t("resetDisruptionHint"))}
          </DialogContentText>

          <Box>
            <Alert severity="warning" variant="outlined">
              <strong>{t("resetDisruptionAlert")}</strong>
            </Alert>
          </Box>
        </DialogContent>

        <DialogActions>
          <SecondaryDangerButton
            fullWidth
            startIcon={<ClearIcon />}
            onClick={() => {
              setOpenResetAllDialog(false);
            }}
          >
            {t("cancelBtn")}
          </SecondaryDangerButton>

          <DangerButton
            fullWidth
            startIcon={<DoneIcon />}
            onClick={() => resetMap()}
          >
            {t("resetDisruptionSubmitBtn")}
          </DangerButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResetAllDisruption;
