import React from "react";
import { Box, Container, Grid, Paper, Skeleton } from "@mui/material";

const CoreDetailPageLoader = ({ infoLine = 0 }) => {
  return (
    <Container maxWidth="xl">
      <Box sx={{ mb: 2 }}>
        <Skeleton
          variant="text"
          sx={{ fontSize: "16px", lineHeight: "26px", width: "300px" }}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Skeleton variant="rounded" sx={{ height: "38px", width: "150px" }} />
      </Box>

      <Grid container spacing={2} direction="column">
        <Grid item xs={4}>
          <Paper elevation={2} sx={{ p: 2, borderRadius: "6px" }}>
            <Box sx={{ px: 2, pb: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: 2,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Skeleton
                    variant="circular"
                    sx={{ width: "48px", height: "48px" }}
                  />
                  <Box display={"flex"} flexDirection={"column"} sx={{ ml: 2 }}>
                    <Skeleton
                      variant="text"
                      sx={{
                        fontSize: "20px",
                        lineHeight: "24px",
                        width: "300px",
                      }}
                    />
                  </Box>
                </Box>
                <Skeleton
                  variant="text"
                  sx={{
                    fontSize: "20px",
                    lineHeight: "24px",
                    width: "100px",
                  }}
                />
              </Box>

              <Box sx={{ pl: 2, pr: 1 }}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {Array.from({ length: +infoLine * 3 }, (_, index) => (
                    <Grid item xs={4} key={"info" + index}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Skeleton
                          variant="rounded"
                          sx={{ height: "20px", width: "20px" }}
                        />

                        <Box sx={{ ml: 2 }}>
                          <Skeleton
                            variant="text"
                            sx={{
                              fontSize: "14px",
                              lineHeight: "22px",
                              fontWeight: 300,
                              width: "150px",
                            }}
                          />
                          <Skeleton
                            variant="text"
                            sx={{
                              fontSize: "16px",
                              lineHeight: "26px",
                              fontWeight: 600,
                              width: "200px",
                            }}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Skeleton
            variant="rounded"
            sx={{ height: "38px", width: "150px", mr: 2 }}
          />
          <Skeleton variant="rounded" sx={{ height: "38px", width: "150px" }} />
        </Box>

        <Grid item xs={4}>
          <Skeleton variant="rounded" sx={{ height: "66px", width: "100%" }} />
        </Grid>

        <Grid item xs={4}>
          <Skeleton variant="rounded" sx={{ height: "66px", width: "100%" }} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CoreDetailPageLoader;
