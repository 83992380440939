import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import { TypographyNormal } from "../../customComponent";

const dayData = [
  { labelID: "Monday", value: "1" },
  { labelID: "Tuesday", value: "2" },
  { labelID: "Wednesday", value: "3" },
  { labelID: "Thursday", value: "4" },
  { labelID: "Friday", value: "5" },
  { labelID: "Saturday", value: "6" },
  { labelID: "Sunday", value: "0" },
];

const monthData = [
  { labelID: "Jan", value: "1" },
  { labelID: "Feb", value: "2" },
  { labelID: "Mar", value: "3" },
  { labelID: "Apr", value: "4" },
  { labelID: "May", value: "5" },
  { labelID: "Jun", value: "6" },
  { labelID: "Jul", value: "7" },
  { labelID: "Aug", value: "8" },
  { labelID: "Sep", value: "9" },
  { labelID: "Oct", value: "10" },
  { labelID: "Nov", value: "11" },
  { labelID: "Dec", value: "12" },
];

const RecurrenceOption = ({
  frequency,
  setRecurrenceState,
  getRecurrenceState,
  loading,
}) => {
  const { t } = useTranslation("schedule");
  const { t: tCommon } = useTranslation();

  /**
   * Returning list UI and function for weekly day checkbox
   * @returns Checkbox Day JSX element
   */
  const weeklyCheckbox = () => {
    const handleChangeDayCheckbox = (e, day) => {
      if (e.target.checked)
        setRecurrenceState("checkDay", [
          ...getRecurrenceState("checkDay"),
          day,
        ]);
      else
        setRecurrenceState(
          "checkDay",
          getRecurrenceState("checkDay").filter((d) => d !== day)
        );
    };

    return dayData.map((day) => (
      <Box
        key={"check" + day.labelID}
        display={"flex"}
        flexDirection={"column"}
        flexWrap={"nowrap"}
        alignItems={"center"}
      >
        <TypographyNormal>
          {tCommon(`day.full.${day.labelID}`)}
        </TypographyNormal>
        <Checkbox
          checked={getRecurrenceState("checkDay").includes(day.value)}
          onChange={(e) => {
            handleChangeDayCheckbox(e, day.value);
          }}
          disabled={loading}
        />
      </Box>
    ));
  };

  const monthlyCheckbox = () => {
    const handleChangeMonthCheckbox = (e, day) => {
      if (e.target.checked)
        setRecurrenceState("checkMonth", [
          ...getRecurrenceState("checkMonth"),
          day,
        ]);
      else
        setRecurrenceState(
          "checkMonth",
          getRecurrenceState("checkMonth").filter((d) => d !== day)
        );
    };

    return monthData.map((month) => (
      <Box
        key={"check" + month.labelID}
        display={"flex"}
        flexDirection={"column"}
        flexWrap={"nowrap"}
        alignItems={"center"}
      >
        <TypographyNormal>
          {tCommon(`month.short.${month.labelID}`)}
        </TypographyNormal>
        <Checkbox
          checked={getRecurrenceState("checkMonth").includes(month.value)}
          onChange={(e) => {
            handleChangeMonthCheckbox(e, month.value);
          }}
          disabled={loading}
        />
      </Box>
    ));
  };

  return (
    <Box
      sx={{
        mt: 0.25,
        px: 1,
        pt: 1,
        borderRadius: "4px",
        border: "1px solid gray",
      }}
    >
      <InputLabel>{t("form.recurrenceSettingsLabel")}</InputLabel>
      {frequency === "DAILY" && (
        <RadioGroup
          value={getRecurrenceState("radio")}
          onChange={(e) => {
            setRecurrenceState("radio", e.target.value);
          }}
        >
          <FormControlLabel
            value={0}
            control={<Radio size="small" />}
            label={t("form.recurrenceEveryday")}
            disabled={loading}
          />
          <FormControlLabel
            value={1}
            control={<Radio size="small" />}
            label={t("form.recurrenceWeekdays")}
            disabled={loading}
          />
          <FormControlLabel
            value={2}
            control={<Radio size="small" />}
            label={t("form.recurrenceWeekends")}
            disabled={loading}
          />
        </RadioGroup>
      )}

      {frequency === "WEEKLY" && (
        <Box sx={{ mt: 0.5 }} display={"flex"} justifyContent={"space-around"}>
          {weeklyCheckbox()}
        </Box>
      )}

      {frequency === "MONTHLY" && (
        <Box>
          <RadioGroup
            value={getRecurrenceState("radio")}
            onChange={(e) => {
              setRecurrenceState("radio", e.target.value);
            }}
          >
            <FormControlLabel
              value={0}
              control={<Radio size="small" />}
              label={t("form.recurrenceEveryday")}
              disabled={loading}
            />
            <FormControlLabel
              value={1}
              control={<Radio size="small" />}
              label={t("form.recurrenceWeekdays")}
              disabled={loading}
            />
            <FormControlLabel
              value={2}
              control={<Radio size="small" />}
              label={t("form.recurrenceWeekends")}
              disabled={loading}
            />
            <FormControlLabel
              value={3}
              control={<Radio size="small" />}
              label={t("form.recurrenceSingle")}
              disabled={loading}
            />
            <FormControlLabel
              value={4}
              control={<Radio size="small" />}
              label={t("form.recurrenceDateRange")}
              disabled={loading}
            />
          </RadioGroup>

          {(getRecurrenceState("radio") === "3" ||
            getRecurrenceState("radio") === "4") && (
            <Box display={"flex"} alignItems={"center"}>
              <Tooltip
                title={
                  getRecurrenceState("radio") === "4"
                    ? t("form.startDateSelectTip")
                    : t("form.dateSelectTip")
                }
                placement="top"
              >
                <TextField
                  size="small"
                  select
                  SelectProps={{ native: true }}
                  value={getRecurrenceState("selectMonthDate")["start"]}
                  onChange={(e) => {
                    setRecurrenceState("selectMonthDate", {
                      start: e.target.value,
                      end: +e.target.value + 1,
                    });
                  }}
                  disabled={loading}
                >
                  {[
                    ...Array(getRecurrenceState("radio") === "3" ? 31 : 30),
                  ].map((x, i) => (
                    <option value={i + 1} key={i + 1}>
                      {i + 1}
                    </option>
                  ))}
                </TextField>
              </Tooltip>
              {getRecurrenceState("radio") === "4" && (
                <>
                  <Box sx={{ mx: 1 }}>{"-"}</Box>
                  <Tooltip title={t("form.endDateSelectTip")} placement="top">
                    <TextField
                      size="small"
                      select
                      SelectProps={{ native: true }}
                      value={getRecurrenceState("selectMonthDate")["end"]}
                      onChange={(e) => {
                        setRecurrenceState("selectMonthDate", {
                          end: e.target.value,
                          start: getRecurrenceState("selectMonthDate").start,
                        });
                      }}
                      disabled={loading}
                    >
                      {[
                        ...Array(
                          31 - +getRecurrenceState("selectMonthDate").start
                        ),
                      ].map((x, i) => (
                        <option
                          value={
                            i + 1 + +getRecurrenceState("selectMonthDate").start
                          }
                          key={
                            i + 1 + +getRecurrenceState("selectMonthDate").start
                          }
                        >
                          {i + 1 + +getRecurrenceState("selectMonthDate").start}
                        </option>
                      ))}
                    </TextField>
                  </Tooltip>
                </>
              )}
            </Box>
          )}

          <Box
            sx={{ mt: 0.5 }}
            display={"flex"}
            justifyContent={"space-around"}
          >
            {monthlyCheckbox()}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RecurrenceOption;
