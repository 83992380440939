import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import ContentTemplateItem from "./ContentTemplateItem";
import ContentDetails from "./detail/ContentDetails";
import { TypographyNormal, TypographyLarge } from "../../customComponent";
import {
  getAllContents,
  setContentLayers,
  setLayerMedias,
  setContentName,
  setCanvasRes,
} from "../../../store/actions/cmsAction";
import {
  SET_CMS_ROWS_PER_PAGE,
  SET_CONTENT_DETAIL,
  SET_CONTENT_FIRST_LOAD,
  SET_FORCE_LOAD,
} from "../../../store/actionTypes/cmsActionType";
import { colorStyling } from "../../../helpers/color";
import { useDebounce } from "../../hooks";
import { logger } from "../../../helpers/logger";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";

const DATA_FETCH_LIMIT = 100;
const tableStyling = {
  padding: "12px 12px",
};

const ContentTemplateList = ({ permission }) => {
  const dispatch = useDispatch();
  const {
    cmsData,
    cmsFilter,
    loadingCMS,
    forceLoad,
    firstLoad,
    rowsPerPage,
    cmsDetails,
  } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [currentPage, setCurrentPage] = useState(0);
  const [dataFrom, setDataFrom] = useState(0);
  const [dataOffset, setDataOffset] = useState(0);
  const [dataPayload, setDataPayload] = useState({});
  const [updateFlag, setUpdateFlag] = useState(false);
  const [appendLoading, setAppendLoading] = useState(false);

  const [openContentDetails, setOpenContentDetails] = useState(false);
  const [detailsID, setDetailsID] = useState({});
  const tableDiv = useRef(null);

  // First load
  useEffect(() => {
    if (cmsData?.items?.length === 0) {
      dispatch({ type: SET_FORCE_LOAD, payload: true });
    }

    updateDataList(0);
    dispatch(setContentLayers({}));
    dispatch(setLayerMedias({ reset: true }));
    dispatch(setContentName(""));
    dispatch(
      setCanvasRes({
        width: 360,
        height: 640,
        orientation: "P",
      })
    );

    return () => {
      dispatch({ type: SET_CONTENT_DETAIL, payload: {} });
      dispatch({ type: SET_CONTENT_FIRST_LOAD, payload: false });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenContentDetails = (contentID) => {
    setDetailsID(contentID);
    setOpenContentDetails(true);
  };

  const handleCloseContentDetails = () => {
    setOpenContentDetails(false);
  };

  const fetchContents = (payload) => {
    const { offset, append, filterUsed } = payload;

    const getPayload = {
      payload: {
        ...cmsFilter,
        limit: DATA_FETCH_LIMIT,
        offset: offset ?? dataOffset,
      },
      cache: cmsData,
      append: append,
      filter: filterUsed,
      cacheDetails: cmsDetails,
    };

    setDataOffset(offset);
    setDataPayload(getPayload.payload);
    dispatch(
      getAllContents(getPayload, () => {
        setAppendLoading(false);
      })
    );
  };

  const updateDataList = (page, filterUsed) => {
    const nextSlice = page * rowsPerPage;
    setDataFrom(nextSlice);

    let appendData = false;
    if (cmsData.items.length > 0 && nextSlice >= cmsData.items.length) {
      appendData = true;
    }

    const nextOffset =
      Math.floor(nextSlice / DATA_FETCH_LIMIT) * DATA_FETCH_LIMIT;
    const payload = { offset: nextOffset, append: appendData, filterUsed };
    fetchContents(payload);
  };

  const debouncedUpdate = useDebounce(currentPage, 1111);
  useEffect(() => {
    if (updateFlag === true) {
      updateDataList(currentPage);
      setUpdateFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUpdate]);

  const updateDataSlice = ({ page, rows }) => {
    const nextPage = page >= 0 ? page : currentPage;
    const nextSlice = nextPage * (rows ?? rowsPerPage);
    if (nextSlice >= cmsData.items.length) {
      setAppendLoading(true);
    }

    setDataFrom(nextSlice);
    setCurrentPage(nextPage);
    setUpdateFlag(true);

    if (tableDiv.current) {
      tableDiv.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleChangePagination = (e, page) => {
    updateDataSlice({ page });
  };

  const handleChangeRowsPerPage = (event) => {
    const updatedRows = parseInt(event.target.value, 10);
    dispatch({
      type: SET_CMS_ROWS_PER_PAGE,
      payload: updatedRows,
    });
    updateDataSlice({ page: 0, rows: updatedRows });
  };

  const displayedRowsLabel = ({ from, to, count }) => {
    return parseVariables(tCommon("table.displayedRowsLabel"), {
      prefix: `${from}–${to}`,
      suffix:
        count !== -1
          ? count
          : `${tCommon("table.displayedRowsMoreThan")} ${to}`,
    });
  };

  useEffect(() => {
    if (firstLoad) {
      setCurrentPage(0);
      updateDataList(0, true);
    } else {
      dispatch({ type: SET_CONTENT_FIRST_LOAD, payload: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsFilter]);

  const dataSliced = useMemo(() => {
    const lastIndex = dataFrom + rowsPerPage;
    logger.log(`[${dataFrom}, ${lastIndex}]`);
    return cmsData.items.slice(dataFrom, lastIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cmsData, dataFrom, rowsPerPage]);

  if ((loadingCMS && !firstLoad) || forceLoad) {
    return (
      <Box
        height={"20vh"}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <CircularProgress
          size={20}
          thickness={3}
          sx={{ color: colorStyling.primary }}
        />
        <TypographyNormal
          sx={{ color: colorStyling.primary, mt: 2, fontWeight: 400 }}
        >
          {tCommon("loadingDataList")}
        </TypographyNormal>
      </Box>
    );
  }

  return (
    <>
      <ContentDetails
        open={openContentDetails}
        handleClose={handleCloseContentDetails}
        contentID={detailsID}
      />

      <Box sx={{ maxHeight: 500 }}>
        <TableContainer
          ref={tableDiv}
          component={Paper}
          sx={{
            overflowY: "auto",
            maxHeight: 500,
            borderRadius: "6px 6px 0 0",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ ...tableStyling, paddingLeft: 4 }}>
                  {t("tabLabelContentName")}
                </TableCell>
                <TableCell align="center" sx={{ ...tableStyling, width: 222 }}>
                  {parser(t("tabLabelModifiedDate"))}
                </TableCell>
                <TableCell align="center" sx={{ ...tableStyling, width: 222 }}>
                  {t("tabLabelLastEditedBy")}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ ...tableStyling, width: 222, paddingRight: 7 }}
                >
                  {t("tabLabelAction")}
                </TableCell>
              </TableRow>
            </TableHead>

            {cmsData.totalItem !== 0 && (
              <TableBody>
                {dataSliced.length !== 0 ? (
                  dataSliced.map((data) => (
                    <ContentTemplateItem
                      key={data.id}
                      content={data}
                      openContentDetails={handleOpenContentDetails}
                      currentPayload={dataPayload}
                      permission={permission}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Box
                        sx={{ height: "20vh" }}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                      >
                        <CircularProgress
                          size={20}
                          thickness={3}
                          sx={{ color: colorStyling.primary }}
                        />
                        <TypographyNormal
                          sx={{
                            color: colorStyling.primary,
                            mt: 2,
                            fontWeight: 400,
                          }}
                        >
                          {tCommon("loadingDataList")}
                        </TypographyNormal>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>

          {cmsData.items.length === 0 && (
            <Box
              alignItems={"center"}
              bgcolor={colorStyling.cms.white}
              sx={{ p: 5 }}
            >
              <TypographyLarge textAlign={"center"}>
                {t("noDataFound")}
              </TypographyLarge>
            </Box>
          )}
        </TableContainer>
      </Box>

      {!loadingCMS && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 4,
            pb: 2,
            pr: 2,
          }}
        >
          <Paper component="div" elevation={1} sx={{ px: 1 }}>
            <TablePagination
              component="div"
              count={cmsData.totalItem}
              page={currentPage}
              onPageChange={handleChangePagination}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={displayedRowsLabel}
              labelRowsPerPage={tCommon("table.rowsPerPageLabel")}
              disabled={appendLoading}
            />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default ContentTemplateList;
