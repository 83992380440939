// Enum: "SLIDE_FROM_BOTTOM_RIGHT"
export const SlideInFromBottomRight = {
  id: "slideInFromBottomRight",
  label: "Slide In From Bottom Right",
  speed: 2000,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "bottom right",
      translate: ["100%", "100%", 10],
    },
  },
};
