// Enum: "EXPAND_FROM_TOP"
export const ExpandFromTop = {
  id: "expandFromTop",
  label: "Expand From Top",
  speed: 2000,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, -3],
    },
    next: {
      origin: "top center",
      scale: 0.1,
      translate: [0, "-10%", 0],
    },
  },
};
