import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import UserItem from "./UserItem";
import { getUsers } from "../../store/actions/userAction";
import { TypographyNormal } from "../customComponent";
import { decryptUser } from "../../helpers/crypto";
import Role from "../../helpers/roles";
import { colorStyling } from "../../helpers/color";
import { filterObjtoString } from "../../helpers/filterHelper";
import {
  SET_LOADING_USER,
  SET_USER_PAGE,
  SET_USER_ROWS_PER_PAGE,
} from "../../store/actionTypes/userActionType";
import { parseVariables } from "../../helpers/localize";

const UserList = () => {
  const dispatch = useDispatch();
  const {
    users,
    userFilters,
    loadingUser: loading,
    userPage: page,
    userRowsPerPage: rowsPerPage,
  } = useSelector((state) => state.user);

  const { t } = useTranslation("user");
  const { t: tCommon } = useTranslation();

  const scrollRef = useRef(null);

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);
  const userRole = decryptedLoggedUser.type;
  const userGroupPermission = decryptedLoggedUser?.permission.userGroup;

  const handleLoading = (value) => {
    dispatch({ type: SET_LOADING_USER, payload: value });
  };

  const handleChangePagination = (e, page) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    dispatch({ type: SET_USER_PAGE, payload: page });
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: SET_USER_ROWS_PER_PAGE,
      payload: parseInt(event.target.value, 10),
    });
    handleChangePagination(null, 0);
  };

  const fetchData = async (filterObj) => {
    let isPrefetch = false;
    let limit = rowsPerPage;
    let offset = page * rowsPerPage;
    if (offset === 0) {
      limit = limit * 2; //fetch 2 page worth of data
      isPrefetch = false;
    } else {
      offset = (page + 1) * rowsPerPage;
      isPrefetch = true;
    }
    let filterString = filterObjtoString(filterObj, limit, offset);

    const usersLength = users?.items?.length;
    if (usersLength === 0) handleLoading(true);
    if (usersLength !== users.totalItem || usersLength === 0 || offset === 0)
      dispatch(getUsers(filterString, isPrefetch, handleLoading));
  };

  useEffect(() => {
    fetchData(userFilters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (page === 0) {
      fetchData(userFilters);
    } else handleChangePagination(null, 0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFilters]);

  const displayedRowsLabel = ({ from, to, count }) => {
    return parseVariables(tCommon("table.displayedRowsLabel"), {
      prefix: `${from}–${to}`,
      suffix:
        count !== -1
          ? count
          : `${tCommon("table.displayedRowsMoreThan")} ${to}`,
    });
  };

  return (
    <>
      <Box sx={{ maxHeight: 500 }}>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ height: "20vh" }}
          >
            <CircularProgress
              size={20}
              thickness={3}
              sx={{ color: colorStyling.primary }}
            />
            <TypographyNormal
              sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
            >
              {tCommon("loadingDataList")}
            </TypographyNormal>
          </Box>
        ) : (
          <>
            {!users.items.length ? (
              <Paper elevation={2} sx={{ borderRadius: "6px", p: 2 }}>
                <Alert severity="info" variant="outlined">
                  <strong>{tCommon("noData")}</strong>
                </Alert>
              </Paper>
            ) : (
              <TableContainer
                ref={scrollRef}
                component={Paper}
                sx={{
                  boxShadow: 3,
                  borderRadius: "6px",
                  overflowY: "auto",
                  maxHeight: 500,
                }}
              >
                <Table stickyHeader sx={{ tableLayout: "fixed" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" width={"90px"}>
                        {t("header.no")}
                      </TableCell>
                      <TableCell align="left" sx={{ pl: 4 }}>
                        {t("header.name")}
                      </TableCell>
                      <TableCell align="center" width={"140px"}>
                        {t("header.role")}
                      </TableCell>
                      {userGroupPermission.read && (
                        <TableCell align="center" width={"200px"}>
                          {t("header.userGroup")}
                        </TableCell>
                      )}
                      {userRole === Role.Eins && (
                        <TableCell align="center" width={"200px"}>
                          {t("header.organization")}
                        </TableCell>
                      )}
                      <TableCell align="right" width={"190px"} sx={{ pr: 4 }}>
                        {t("header.action")}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {users?.items
                      ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                      ?.map((user, index) => (
                        <UserItem
                          key={user.id}
                          user={user}
                          index={index}
                          userRole={userRole}
                          userGroupPermission={userGroupPermission}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Box>

      {(!loading || users?.totalItem > 0) && (
        <Box
          sx={{
            pt: 2,
            pb: 1,
            pr: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Paper component="div" elevation={1} sx={{ px: 1 }}>
            <TablePagination
              component="div"
              count={users.totalItem}
              page={page}
              onPageChange={handleChangePagination}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[5, 10, 25, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={displayedRowsLabel}
              labelRowsPerPage={tCommon("table.rowsPerPageLabel")}
            />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default UserList;
