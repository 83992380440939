// Enum: "EXPAND_OUT_FROM_MIDDLE"
export const ExpandOutFromMiddle = {
  id: "expandOutFromMiddle",
  label: "Expand Out From Middle",
  speed: 1500,
  effect: {
    prev: {
      shadow: true,
      translate: [0, 0, 0],
      origin: "center center",
    },
    next: {
      shadow: true,
      opacity: 0.9,
      translate: [0, 0, 500],
      rotate: [0, -100, 0],
      origin: "center center",
    },
  },
};
