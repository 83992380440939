// Enum: "EXPAND_IN_TO_MIDDLE"
export const ExpandInToMiddle = {
  id: "expandInToMiddle",
  label: "Expand In To Middle",
  speed: 1500,
  effect: {
    prev: {
      shadow: true,
      opacity: 0,
      scale: 1,
      translate: [0, 0, 0],
      origin: "center center",
    },
    next: {
      shadow: true,
      opacity: 0,
      scale: 3,
      translate: [0, 0, 400],
      origin: "center center",
    },
  },
};
