export const colorStyling = {
  // Blue
  primary: "#1C4ED8",
  // Green
  green: "#065F46",
  lightgreen: "#8BC34A",
  // Black
  black: "#111827",
  // White
  white: {
    //For active status
    active: "#D1FAE5",
    //For inactive status
    inactive: "#DFDFDF",
    // Hover over item
    hover: "#F3F8FD",
    // Hover over panel
    panel: "#DBE9FE",
    //border color
    border: "#DCDEE3",
    full: "#FFFFFF",
    blue: "#A7C6EF",
    lightblue: "#eaf5ff",
  },
  // Gray
  gray: {
    label: "#3e3e3e",
    normal: "#9CA3AF",
    darkblue: "#454E5C",
    transparent: "#00000040",
  },
  // Red
  red: "#EB4826",
  // Orange
  orange: "#F55858",
  // Blue
  blue: { sky: "#63D5F9", light: "#3366BB", deep: "#183CB2" },
  // Yellow
  yellow: "#FFBD80",
  teal: "#25D3B4",
  cms: {
    darkgray: "#CCCCCC",
    white: "#F9F9F9",
    gray: "#eaeaea",
    blue: "#3085d6",
    red: "#DD3333",
    black: "#111111",
  },
  traffic: {
    red: "#f84824",
    yellow: "#ffcc2c",
    green: "#18ac5c",
    gray: "#9ea2a5",
  },
  darkpurple: "#2e3465",
};
