import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/Email";
import ActivateEmailMFA from "./ActivateEmailMFA";
import ActivateTOTPMFA from "./ActivateTOTPMFA";

const SelectMFAActivation = () => {
  const { t } = useTranslation("user");
  const [open, setOpen] = useState(false);
  const [mfaType, setMfaType] = useState("");

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setMfaType("");
  };

  const handleChangeMfaType = (e) => {
    setMfaType(e.target.value);
  };

  return (
    <>
      <Badge
        color="warning"
        variant="dot"
        sx={{
          "& .MuiBadge-dot": {
            width: "10px",
            height: "10px",
            borderRadius: "50%",
          },
        }}
      >
        <Button
          variant="outlined"
          size="small"
          sx={{ px: "5px", py: 0 }}
          onClick={handleOpenDialog}
        >
          {t("account.activateMFABtn")}
        </Button>
      </Badge>

      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {t("account.selectMFADialogTitle")}
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            sx={{ width: "100%", mt: 1 }}
            display={"flex"}
            justifyContent={"center"}
          >
            <Box>
              <RadioGroup
                sx={{ mt: 1, mb: 2 }}
                value={mfaType}
                onChange={handleChangeMfaType}
              >
                <FormControlLabel
                  value="EMAIL"
                  control={<Radio />}
                  label={
                    <Box display={"flex"} alignItems={"center"}>
                      <EmailIcon sx={{ mr: 1 }} color="primary" />
                      {t("account.mfaEmailLabel")}
                    </Box>
                  }
                />
                <FormControlLabel
                  value="TOTP"
                  control={<Radio />}
                  label={
                    <Box display={"flex"} alignItems={"center"}>
                      <GoogleIcon sx={{ mr: 1 }} color="primary" />
                      {t("account.mfaTotpLabel")}
                    </Box>
                  }
                />
              </RadioGroup>
              <Box
                sx={{ width: "100&" }}
                display={"flex"}
                justifyContent={"center"}
              >
                {mfaType === "EMAIL" && (
                  <ActivateEmailMFA callback={handleCloseDialog} />
                )}

                {mfaType === "TOTP" && (
                  <ActivateTOTPMFA callback={handleCloseDialog} />
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="outlined" onClick={handleCloseDialog}>
            {t("account.selectMFACloseBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectMFAActivation;
