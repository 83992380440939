// TODO SMRT
// import TrainIcon from "@mui/icons-material/Train";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { bplrtFlag, stellarFlag } from "./componentsFlag";
import { colorStyling } from "../helpers/color";
import { TrakoIcon } from "../icons";
import Role from "../helpers/roles";
import i18n from "i18next";
const tCommon = i18n.getFixedT(null, "common");

export const externalSidebarConfig = [
  ...(stellarFlag
    ? [
        {
          flag: "SMRT.TRAKO",
          path: "/analytics",
          icon: <TrakoIcon color={colorStyling.orange} />,
          label: tCommon("sidebar.StellarTrakoAnalytics"),
          condition: (_, userRole) => userRole !== Role.Eins,
        },
      ]
    : []),
  ...(bplrtFlag
    ? [
        {
          flag: "BPLRT.SMP",
          path: "/bplrt/smp",
          icon: (
            <MyLocationIcon
              fontSize="medium"
              sx={{
                color: colorStyling.orange,
              }}
            />
          ),
          label: tCommon("sidebar.BplrtSmp"),
          condition: (_, userRole) => userRole !== Role.Eins,
        },
      ]
    : []),
];

// TODO SMRT
// const linkSmrtTrainDisruption = (
//   <Link style={{ textDecoration: "none" }} to="/smrt/train-disruption">
//     <StyledListItem
//       button
//       dense
//       sx={{
//         backgroundColor:
//           pathname === "smrt/train-disruption" ? "#183CB2" : null,
//       }}
//     >
//       <TrainIcon
//         fontSize="medium"
//         sx={{
//           color: "#F55858",
//         }}
//       />

//       <StyledListItemText
//         sx={{
//           color: pathname === "/train-disruption" ? "#FFFF" : "#A7C6EF",
//         }}
//         primary="Train Disruption"
//       />
//     </StyledListItem>
//   </Link>
// );
