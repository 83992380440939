import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, CircularProgress, Paper } from "@mui/material";
import { Typography2XLarge, TypographyLarge } from "../customComponent";
import { colorStyling } from "../../helpers/color";

const ScheduleCard = () => {
  const { t } = useTranslation("home");
  const { activeSchedulesData, loadingSchedulesData } = useSelector(
    (state) => state.home
  );

  if (loadingSchedulesData && activeSchedulesData === "")
    return (
      <Paper
        elevation={2}
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "6px",
        }}
      >
        <Box
          p={2}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <CircularProgress
            size={22}
            thickness={3}
            sx={{ color: colorStyling.primary }}
          />
        </Box>
      </Paper>
    );

  return (
    <Paper
      elevation={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "6px",
      }}
    >
      <Box display="flex" p={2}>
        <Box p={1} alignSelf="center">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.6002 3.2002C8.71655 3.2002 8.0002 3.91654 8.0002 4.8002V6.4002H6.4002C4.63289 6.4002 3.2002 7.83288 3.2002 9.60019V25.6002C3.2002 27.3675 4.63289 28.8002 6.4002 28.8002H25.6002C27.3675 28.8002 28.8002 27.3675 28.8002 25.6002V9.60019C28.8002 7.83288 27.3675 6.4002 25.6002 6.4002H24.0002V4.8002C24.0002 3.91654 23.2839 3.2002 22.4002 3.2002C21.5166 3.2002 20.8002 3.91654 20.8002 4.8002V6.4002H11.2002V4.8002C11.2002 3.91654 10.4839 3.2002 9.6002 3.2002ZM9.6002 11.2002C8.71655 11.2002 8.0002 11.9165 8.0002 12.8002C8.0002 13.6839 8.71655 14.4002 9.6002 14.4002H22.4002C23.2839 14.4002 24.0002 13.6839 24.0002 12.8002C24.0002 11.9165 23.2839 11.2002 22.4002 11.2002H9.6002Z"
              fill={colorStyling.primary}
            />
            <circle cx="25" cy="25" r="5" fill={colorStyling.teal} />
          </svg>
        </Box>

        <Box ml={3}>
          <Typography2XLarge>
            {!activeSchedulesData ? 0 : activeSchedulesData}
          </Typography2XLarge>
          <TypographyLarge sx={{ fontWeight: 400 }}>
            {t("activeScheduleLabel")}
          </TypographyLarge>
        </Box>
      </Box>
    </Paper>
  );
};

export default ScheduleCard;
