import React from "react";

function TrakoIcon({ color }) {
  return (
    <svg
      fill={color}
      width="20"
      height="20"
      viewBox="0 0 335 335"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1416 3115c-358-58-652-210-912-469-240-241-381-503-456-846-19-91-22-134-22-325 0-190 3-234 22-322 46-211 128-412 235-576 56-86 202-267 215-267 12 0 562 555 562 567 0 5-12 20-26 34-42 39-119 160-152 240-68 164-81 390-32 559 81 280 300 500 585 586 80 24 106 27 230 27s150-3 230-27c285-86 504-306 586-588 48-167 35-394-33-557-33-80-110-201-152-240-14-14-26-29-26-35 0-12 550-566 562-566 13 0 159 182 215 267 107 164 189 365 235 576 19 88 22 132 22 322 0 191-3 234-22 325-75 343-216 605-456 846-239 239-504 383-833 455-130 28-445 35-577 14z"
        transform="matrix(.1 0 0 -.1 0 335)"
      ></path>
    </svg>
  );
}

export default TrakoIcon;
