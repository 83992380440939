import {
  LinkOutlined as LinkIcon,
  MoreVert as MenuIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import {
  alpha,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  styled,
  Switch,
  CircularProgress,
  IconButton,
  Menu,
  DialogContentText,
} from "@mui/material";

import { red } from "@mui/material/colors";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activateAllSMPAnnouncement,
  activateSMPAnnouncement,
  getSMPStation,
} from "../../../../store/actions/bplrt/smpAction";
import { CustomMenuItem } from "../../../../../components/customComponent";
import DetailLinkAnnouncement from "./DetailLinkAnnouncement";
import LinkAnnouncementErrorPrompt from "./LinkAnnouncementErrorPrompt";
import { useTranslation } from "react-i18next";
import parser from "html-react-parser";

const DangerSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: red[600],
    "&:hover": {
      backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: red[600],
  },
}));

const DelayActivation = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation("bplrtSmp");

  const [open, setOpen] = useState(false);
  const [openActivateAll, setOpenActivateAll] = useState(false);
  const [openDeactivateAll, setOpenDeactivateAll] = useState(false);

  const { stationDisruptions, loadingAnnouncementSMPDisruption } = useSelector(
    (state) => state.bplrtSmp
  );

  useEffect(() => {
    if (open) dispatch(getSMPStation());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenActivateAll = () => {
    setOpenActivateAll(true);
  };

  const handleCloseActivateAll = () => {
    setOpenActivateAll(false);
  };

  const handleOpenDeactivateAll = () => {
    setOpenDeactivateAll(true);
  };

  const handleCloseDeactivateAll = () => {
    setOpenDeactivateAll(false);
  };

  const handleActivateAll = async (type) => {
    handleCloseActivateAll();
    const payload = {};
    payload.active = true;
    if (type === "delay") {
      payload.type = "delayAnnouncement";
      let announcements = [];

      stationDisruptions.forEach((item) => {
        if (
          item.delayAnnouncementId !== null &&
          item.delayAnnouncementActive === false
        ) {
          announcements.push({
            id: item.id,
            announcementId: item.delayAnnouncementId,
          });
        }
      });

      payload.announcements = announcements;
    } else if (type === "freeBus") {
      payload.type = "delayFreeBusAnnouncement";
      let announcements = [];

      stationDisruptions.forEach((item) => {
        if (
          item.delayFreeBusAnnouncementId !== null &&
          item.delayFreeBusAnnouncementActive === false
        ) {
          announcements.push({
            id: item.id,
            announcementId: item.delayFreeBusAnnouncementId,
          });
        }
      });

      payload.announcements = announcements;
    } else {
      return;
    }

    dispatch(activateAllSMPAnnouncement(payload));
  };

  const handleDeactivateAll = async (type) => {
    handleCloseDeactivateAll();
    const payload = {};
    payload.active = false;
    if (type === "delay") {
      payload.type = "delayAnnouncement";
      let announcements = [];

      stationDisruptions.forEach((item) => {
        if (
          item.delayAnnouncementId !== null &&
          item.delayAnnouncementActive === true
        ) {
          announcements.push({
            id: item.id,
            announcementId: item.delayAnnouncementId,
          });
        }
      });

      payload.announcements = announcements;
    } else if (type === "freeBus") {
      payload.type = "delayFreeBusAnnouncement";
      let announcements = [];

      stationDisruptions.forEach((item) => {
        if (
          item.delayFreeBusAnnouncementId !== null &&
          item.delayFreeBusAnnouncementActive === true
        ) {
          announcements.push({
            id: item.id,
            announcementId: item.delayFreeBusAnnouncementId,
          });
        }
      });

      payload.announcements = announcements;
    } else {
      return;
    }

    dispatch(activateAllSMPAnnouncement(payload));
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
        fullWidth
        sx={{ boxShadow: 3 }}
        color="warning"
      >
        {t("delayBtn")}
      </Button>

      <Dialog open={open} maxWidth="md" fullWidth onClose={handleClose}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {t("delayDialogTitle")}
          <Box>
            <Button
              variant="contained"
              sx={{ boxShadow: 3 }}
              onClick={() => {
                handleOpenActivateAll();
              }}
            >
              {t("delayActivateAllButton")}
            </Button>
            <Dialog
              fullWidth
              maxWidth="md"
              open={openActivateAll}
              onClose={handleCloseActivateAll}
            >
              <DialogContent>
                <DialogContentText
                  sx={{
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  {parser(t("delayActivateConfirmHint"))}
                </DialogContentText>
                <DialogContentText
                  sx={{
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  <strong>{t("delayActivateChooseHint")}</strong>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  fullWidth
                  startIcon={<ClearIcon />}
                  onClick={handleCloseActivateAll}
                >
                  {t("cancelBtn")}
                </Button>

                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleActivateAll("delay");
                  }}
                >
                  {t("delayAActivateBtn")}
                </Button>

                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleActivateAll("freeBus");
                  }}
                >
                  {t("delayBActivateBtn")}
                </Button>
              </DialogActions>
            </Dialog>

            <Button
              variant="contained"
              sx={{ boxShadow: 3, ml: "8px" }}
              onClick={() => {
                handleOpenDeactivateAll();
              }}
            >
              {t("delayDeactivateAllButton")}
            </Button>
            <Dialog
              fullWidth
              maxWidth="md"
              open={openDeactivateAll}
              onClose={handleCloseDeactivateAll}
            >
              <DialogContent>
                <DialogContentText
                  sx={{
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  {parser(t("delayDeactivateConfirmHint"))}
                </DialogContentText>
                <DialogContentText
                  sx={{
                    textAlign: "center",
                    mb: 2,
                  }}
                >
                  <strong>{t("delayDeactivateChooseHint")}</strong>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  fullWidth
                  startIcon={<ClearIcon />}
                  onClick={handleCloseDeactivateAll}
                >
                  {t("cancelBtn")}
                </Button>

                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleDeactivateAll("delay");
                  }}
                >
                  {t("delayADeactivateBtn")}
                </Button>

                <Button
                  color="error"
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    handleDeactivateAll("freeBus");
                  }}
                >
                  {t("delayBDeactivateBtn")}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </DialogTitle>
        <DialogContent>
          <LinkAnnouncementErrorPrompt />
          {loadingAnnouncementSMPDisruption ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress size={24} sx={{ color: "#1C4ED8" }} />
            </Box>
          ) : (
            <Grid container>
              {stationDisruptions.map((item) => (
                <Grid item xs={6} key={item.id}>
                  <SwitchItem data={item} />
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="outlined" onClick={handleClose}>
            {t("closeBtn")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const SwitchItem = ({ data }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("bplrtSmp");

  const [anchorEl, setAnchorEl] = useState(null);
  const [openDetail, setOpenDetail] = useState(false);
  const [switchCheck, setSwitchCheck] = useState(false);
  const [freeBusCheck, setFreeBusCheck] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (data.delayAnnouncementId !== null && data.delayAnnouncementActive) {
      setSwitchCheck(true);
    }

    if (
      data.delayFreeBusAnnouncementId !== null &&
      data.delayFreeBusAnnouncementActive
    ) {
      setFreeBusCheck(true);
    }
  }, [dispatch, data]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
    setAnchorEl(null);
  };

  const handleSwitch = (type) => {
    const payload = { id: data.id };
    if (type === "delay") {
      payload.announcementId = data.delayAnnouncementId;
      payload.type = "delayAnnouncement";
      payload.active = !switchCheck;
    }

    if (type === "freeBus") {
      payload.announcementId = data.delayFreeBusAnnouncementId;
      payload.type = "delayFreeBusAnnouncement";
      payload.active = !freeBusCheck;
    }

    dispatch(activateSMPAnnouncement(payload));
  };

  const handleOpenDetail = () => {
    setOpenDetail(true);
  };

  return (
    <Box
      sx={{
        border: 1,
        borderRadius: "4px",
        borderColor: "#9e9e9e",
        p: "8px",
        m: "4px",
      }}
    >
      <Grid container direction="column">
        <Grid item>
          <Box display={"flex"} justifyContent={"space-between"}>
            <FormControlLabel
              value={switchCheck}
              control={<DangerSwitch />}
              checked={switchCheck}
              label={data.code + " " + t("delayALabel")}
              onChange={() => {
                handleSwitch("delay");
              }}
              disabled={
                data.delayAnnouncementId === null ||
                data.delayAnnouncementId === ""
                  ? true
                  : false
              }
            />

            <IconButton
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              color="inherit"
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Menu
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <CustomMenuItem dense onClick={() => handleOpenDetail()}>
              <LinkIcon
                sx={{
                  width: 20,
                  height: 20,
                  marginRight: "10px",
                  color: "#111827",
                }}
              />
              {t("linkAnnouncementMenu")}
            </CustomMenuItem>
            <DetailLinkAnnouncement
              type="Delay"
              open={openDetail}
              onClose={handleCloseDetail}
              data={data}
            />
          </Menu>
        </Grid>
        <Grid item>
          {data.delayFreeBusAnnouncementId !== null &&
            data.delayFreeBusAnnouncementId !== "" && (
              <FormControlLabel
                value={freeBusCheck}
                control={<DangerSwitch />}
                checked={freeBusCheck}
                label={data.code + " " + t("delayBLabel")}
                onChange={() => {
                  handleSwitch("freeBus");
                }}
              />
            )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DelayActivation;
