import dayjs from "dayjs";
import {
  SET_CONTENT_REPORT,
  SET_CONTENT_REPORT_FILTER,
  SET_CONTENT_REPORT_START_FILTER,
  SET_ERROR_MESSAGE_REPORTING_PAGE,
  SET_ERROR_PROMPT_REPORTING_PAGE,
  SET_LOADING_CONTENT_REPORT,
  SET_SUCCESS_MESSAGE_REPORTING_PAGE,
  SET_SUCCESS_PROMPT_REPORTING_PAGE,
} from "../actionTypes/reportingActionType";

const initialState = {
  contentReports: [],
  contentReportFilters: {
    nameLike: "",
    type: "",
    recordedAtStart: "",
    recordedAtEnd: "",
    createdAtGte: dayjs().subtract(1, "M").format("YYYY-MM-DD"),
    createdAtLte: dayjs().subtract(1, "d").format("YYYY-MM-DD"),
  },
  updatingFilter: true,
  contentReportStartFilter: false,
  loadingContentReport: false,
  isSuccessPromptReportingPage: false,
  isErrorPromptReportingPage: false,
  successMessageReportingPage: "",
  errorMessageReportingPage: "",
};

export const reportingReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_CONTENT_REPORT:
      return { ...state, contentReports: payload, updatingFilter: false };

    case SET_CONTENT_REPORT_FILTER:
      return { ...state, contentReportFilters: payload, updatingFilter: true };

    case SET_CONTENT_REPORT_START_FILTER:
      return { ...state, contentReportStartFilter: payload };

    case SET_LOADING_CONTENT_REPORT:
      return { ...state, loadingContentReport: payload };

    case SET_SUCCESS_PROMPT_REPORTING_PAGE:
      return { ...state, isSuccessPromptReportingPage: payload };

    case SET_SUCCESS_MESSAGE_REPORTING_PAGE:
      return { ...state, successMessageReportingPage: payload };

    case SET_ERROR_PROMPT_REPORTING_PAGE:
      return { ...state, isErrorPromptReportingPage: payload };

    case SET_ERROR_MESSAGE_REPORTING_PAGE:
      return { ...state, errorMessageReportingPage: payload };

    default:
      return state;
  }
};
