import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Container, CssBaseline, Toolbar, Paper } from "@mui/material";
import Sidebar from "../../sidebar/Sidebar";
import AssetsManagerList from "./AssetsManagerList";
import AssetsManagerFilter from "./AssetsManagerFilter";
import AssetsManagerSuccessPrompt from "./AssetsManagerSuccessPrompt";
import AssetsManagerErrorPrompt from "./AssetsManagerErrorPrompt";
import { TypographyXLarge, TypographyNormal } from "../../customComponent";
import { decryptUser } from "../../../helpers/crypto";

const AssetManager = () => {
  const navigate = useNavigate();

  const { t } = useTranslation("assetmanager");

  const { loggedUser } = useSelector((state) => state.auth);

  const decryptedLoggedUser = decryptUser(loggedUser);
  const permission = decryptedLoggedUser?.permission;

  useEffect(() => {
    if (!permission?.asset?.read) {
      navigate("/");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              {t("titleExplain")}
            </TypographyNormal>
          </Box>

          <AssetsManagerSuccessPrompt />
          <AssetsManagerErrorPrompt />

          <Box sx={{ my: 2 }}>
            <Paper
              component="section"
              elevation={2}
              sx={{ p: 2, my: 2, borderRadius: "6px" }}
            >
              <AssetsManagerFilter />
            </Paper>

            <AssetsManagerList assetPermission={permission?.asset} />
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default AssetManager;
