import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import { createOrganization } from "../../store/actions/organizationAction";
import { useTranslation } from "react-i18next";

const CreateOrganization = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("organization");

  const [organizationName, setOrganizationName] = useState("");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
    setOrganizationName("");
  };

  const handleOrganizationName = (e) => {
    setOrganizationName(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      name: organizationName,
    };
    dispatch(createOrganization(payload));

    handleClickClose();
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        startIcon={<AddIcon />}
      >
        {t("createBtn")}
      </Button>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        keepMounted
        onClose={handleClickClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {t("createDialogTitle")}
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent>
            <Box sx={{ mb: 2 }}>
              <InputLabel>{t("form.nameLabel")}</InputLabel>
              <TextField
                fullWidth
                margin="dense"
                name="groupName"
                value={organizationName}
                onChange={handleOrganizationName}
                placeholder={t("form.namePlaceholder")}
                required
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleClickClose} variant="outlined" fullWidth>
              {t("form.cancelBtn")}
            </Button>
            <Button type="submit" variant="contained" fullWidth>
              {t("form.createBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateOrganization;
