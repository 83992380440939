import { logger } from "./logger";

export const cloneArray = (arr) => {
  try {
    return JSON.parse(JSON.stringify(arr));
  } catch (e) {
    logger.error(e);
    return arr;
  }
};
