import {
  BplrtEquipment,
  BplrtSMP,
  // SmrtTrainDisruption,
  TrakoDashboard,
} from "./components";
import {
  AuthorizationRoute,
  // AuthorizationSmrtTdRoute,
} from "../auth/AuthorizationRoute";
import { bplrtFlag, stellarFlag } from "./componentsFlag";

const bplrtRoutes = [
  {
    flag: "BPLRT.SMP",
    path: "/bplrt/smp",
    element: <BplrtSMP />,
    wrapper: AuthorizationRoute,
  },
  {
    flag: "BPLRT.EQUIPMENT",
    path: "/bplrt/equipment",
    element: <BplrtEquipment />,
    wrapper: AuthorizationRoute,
  },
];

const stellarRoutes = [
  {
    flag: "SMRT.TRAKO",
    path: "/analytics",
    element: <TrakoDashboard />,
    wrapper: AuthorizationRoute,
  },
];

// const tdRoute = (
//   <Route
//     key="smrtTd"
//     path="/smrt/train-disruption"
//     element={
//       <AuthorizationSmrtTdRoute>
//         <SmrtTrainDisruption />
//       </AuthorizationSmrtTdRoute>
//     }
//   />
// );

export const externalRoutes = [
  ...(bplrtFlag ? bplrtRoutes : []),
  ...(stellarFlag ? stellarRoutes : []),
  // tdRoute,
];

export default externalRoutes;
