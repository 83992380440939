import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Button, CircularProgress, Alert } from "@mui/material";
import { TypographyNormal } from "../customComponent";
import useCountDown from "react-countdown-hook";
import { initiateAXIOS } from "../../config/axios";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
} from "../../store/actionTypes/globalActionType";
import { colorStyling } from "../../helpers/color";
import { decrypt } from "../../helpers/crypto";
import { parseVariables } from "../../helpers/localize";

const TimerCountdown = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("user");

  const SIXTY_SECONDS = +process.env.REACT_APP_OTP_EXPIRY || 60 * 1000;

  const interval = 1000; // interval to change remaining time amount, defaults to 1000

  const [timeLeft, { start }] = useCountDown(SIXTY_SECONDS, interval);

  const [loadingResendCode, setLoadingResendCode] = useState(false);

  const [counter, setCounter] = useState(1);

  // start the timer during the first render
  useEffect(() => {
    start();
  }, [start]);

  const handleResendCode = async (e) => {
    e.preventDefault();

    setLoadingResendCode(true);
    try {
      const loginPayload = decrypt(sessionStorage.getItem("userLogin"));

      const { data } = await initiateAXIOS.post(
        "/api/auth/login",
        loginPayload
      );

      sessionStorage.setItem("requestToken", data.otpToken);
    } catch (error) {
      const errorMessage = error.response.data.message;
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: errorMessage });
    } finally {
      start();
      setCounter(counter + 1);
      setLoadingResendCode(false);
    }
  };

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
      {counter === 3 ? (
        <Alert severity="warning">{t("mfa.resendLimitReached")}</Alert>
      ) : timeLeft / 1000 === 0 ? (
        loadingResendCode ? (
          <Button
            sx={{
              fontSize: 14,
              textTransform: "capitalize",
              color: colorStyling.primary,
            }}
            disabled={counter === 3}
            onClick={handleResendCode}
          >
            <CircularProgress
              size={22}
              thickness={3}
              sx={{ color: colorStyling.primary }}
            />
          </Button>
        ) : (
          <Button
            sx={{
              fontSize: 14,
              textTransform: "capitalize",
              color: colorStyling.primary,
            }}
            disabled={counter === 3}
            onClick={handleResendCode}
          >
            {t("mfa.resendBtn")}
          </Button>
        )
      ) : (
        <TypographyNormal sx={{ fontWeight: 300 }} gutterBottom>
          {parseVariables(t("mfa.resendHint"), { time: timeLeft / 1000 })}
        </TypographyNormal>
      )}
    </Box>
  );
};

export default TimerCountdown;
