import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { calculateDuration } from "../../../helpers/scheduleHelper";
import ContentReportViewAsset from "./ContentReportViewAsset";
import { colorStyling } from "../../../helpers/color";
import ContentReportDailyDetails from "./ContentReportDailyDetails";

const ContentReportItem = ({ data, index, filter, pageData }) => {
  const { t } = useTranslation("contentreport");

  const [expanded, setExpanded] = useState(false);
  const dataNumber = pageData.page * pageData.rowsPerPage + index + 1;

  useEffect(() => {
    setExpanded(false);
  }, [filter]);

  const handleOpenDetail = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <TableRow
        sx={{
          ...((index + 1) % 2 !== 0
            ? {
                backgroundColor: colorStyling.white.hover,
              }
            : {
                backgroundColor: colorStyling.white.full,
              }),
        }}
      >
        <TableCell align="center" width={"5%"}>
          {dataNumber}
        </TableCell>
        <TableCell align="left" width={"30%"}>
          {data.assetName}
        </TableCell>
        <TableCell align="left" width={"25%"}>
          {data.assetCampaignName ?? "-"}
        </TableCell>
        <TableCell align="center" width={"10%"}>
          {data.assetType}
        </TableCell>
        <TableCell align="right" width={"20%"}>
          {calculateDuration(data.assetDuration)}
        </TableCell>
        <TableCell width={"10%"}>
          <Box display={"flex"} justifyContent={"center"}>
            <ContentReportViewAsset asset={data} />

            <Tooltip title={t("table.dailyDetailsTip")} placement="top-start">
              <Box>
                <IconButton
                  sx={{
                    color: colorStyling.primary,
                  }}
                  variant="contained"
                  disabled={filter.createdAtGte === filter.createdAtLte}
                  {...(filter.createdAtGte !== filter.createdAtLte && {
                    onClick: () => {
                      handleOpenDetail();
                    },
                  })}
                >
                  <FormatListBulletedIcon />
                </IconButton>
              </Box>
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ p: 0 }} colSpan={8}>
          <Collapse in={expanded} sx={{ ml: 8, mr: 4 }}>
            <ContentReportDailyDetails
              asset={data}
              expanded={expanded}
              filter={filter}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ContentReportItem;
