import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, TableCell, TableRow, Tooltip } from "@mui/material";
import LaunchIcon from "@mui/icons-material/Launch";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { dateOnlyFormatDDMMYYYY } from "../../../../helpers/dateFormat";
import { getTimeFromCron } from "../../../../helpers/cronHelper";
import { colorStyling } from "../../../../helpers/color";
// TODO after imported flag
// import AdvertisementScheduleMenu from "./AdvertisementScheduleMenu";
import {
  switchBackgroundColorBasedOnStatus,
  switchColorOnScheduleStatus,
} from "../../../../helpers/switchColor";
import { useNavigate } from "react-router-dom";
import { TypographySmall } from "../../../../components/customComponent";
import { calculateDuration } from "../../../../helpers/scheduleHelper";
import { SET_SCHEDULE_DETAIL } from "../../../../store/actionTypes/scheduleActionType";
import { useTranslation } from "react-i18next";
import { AdsScheduleGreenIcon } from "../../../../icons";

const AdvertisementScheduleItem = ({ schedule, index, openContent }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { schedule: detail } = useSelector((state) => state.schedule);

  const { t } = useTranslation("advertschedule");
  const { t: tSchedule } = useTranslation("schedule");

  const [hoveredContent, setHoveredContent] = useState(false);

  const handleNavigate = (id) => {
    const newDetail = {
      ...schedule,
      ...(detail.id === schedule.id && {
        content: detail.content,
        screenGroups: detail.screenGroups,
        screens: detail.screens,
      }),
    };
    dispatch({ type: SET_SCHEDULE_DETAIL, payload: newDetail });
    navigate(`/rotating-schedule/detail/${id}`);
  };

  const handleOpenContentDetail = (event, id) => {
    event.stopPropagation();
    openContent(id);
  };

  return (
    <TableRow
      key={schedule.name + schedule.id}
      onClick={() => {
        handleNavigate(schedule.id);
      }}
      sx={{
        cursor: "pointer",
        backgroundColor:
          (index + 1) % 2 !== 0
            ? colorStyling.white.hover
            : colorStyling.white.full,
        "&:hover": {
          backgroundColor: colorStyling.white.lightblue,
        },
      }}
    >
      <TableCell>
        <AdsScheduleGreenIcon />
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          {schedule.isApproved ? (
            <Tooltip title={tSchedule("item.approvedTip")} placement="top">
              <CheckCircleOutlineIcon
                fontSize="small"
                sx={{
                  mr: 0.5,
                  color: switchBackgroundColorBasedOnStatus("APPROVED"),
                  borderRadius: "16px",
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title={tSchedule("item.notApprovedTip")} placement="top">
              <ErrorOutlineIcon
                fontSize="small"
                sx={{
                  mr: 0.5,
                  color: switchBackgroundColorBasedOnStatus("NOT_APPROVED"),
                  borderRadius: "16px",
                }}
              />
            </Tooltip>
          )}
          <Tooltip
            title={schedule?.name?.length > 30 ? schedule?.name : ""}
            placement="top-start"
          >
            {schedule?.name?.length > 30
              ? schedule?.name?.substring(0, 27).trim() + "..."
              : schedule?.name}
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell align="center">
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <TypographySmall
            sx={{
              color: switchColorOnScheduleStatus(
                schedule.isActive ? "ACTIVE" : "PENDING"
              ),
              backgroundColor: switchBackgroundColorBasedOnStatus(
                schedule.isActive ? "ACTIVE" : "PENDING"
              ),
              borderRadius: "15px",
              p: 1,
              fontWeight: 600,
              minWidth: "80px",
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color: switchColorOnScheduleStatus(
                  schedule.isActive ? "ACTIVE" : "PENDING"
                ),
                fontSize: 8,
                mr: "5px",
              }}
            />
            {schedule.isActive
              ? tSchedule("item.activeLabel")
              : tSchedule("item.inactiveLabel")}
          </TypographySmall>
        </Box>
      </TableCell>
      <Tooltip title={t("contentDetailHint")} placement="top-start">
        <TableCell
          onClick={(event) =>
            handleOpenContentDetail(event, schedule.contentId)
          }
          onMouseEnter={() => setHoveredContent(true)}
          onMouseLeave={() => setHoveredContent(false)}
          sx={{
            ...(hoveredContent && {
              color: colorStyling.primary,
              textDecoration: "underline",
              fontWeight: 600,
            }),
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {schedule?.content?.name}
            <LaunchIcon
              fontSize="xs"
              sx={{
                color: hoveredContent
                  ? colorStyling.primary
                  : colorStyling.gray.label,
              }}
            />
          </Box>
        </TableCell>
      </Tooltip>
      <TableCell>
        {dateOnlyFormatDDMMYYYY(schedule.startAt) +
          " - " +
          (schedule.endAt
            ? dateOnlyFormatDDMMYYYY(schedule.endAt)
            : tSchedule("item.endTimeIndefinitely"))}
      </TableCell>
      <TableCell>
        {getTimeFromCron(schedule.cron, schedule.cronTimeZone) +
          " - " +
          getTimeFromCron(
            schedule.cron,
            schedule.cronTimeZone,
            schedule.duration
          )}
      </TableCell>
      <TableCell align="center">
        {calculateDuration(+schedule.advertDuration)}
      </TableCell>

      {/* TODO if imported */}
      {/* <TableCell align="right">
        <AdvertisementScheduleMenu schedule={schedule} />
      </TableCell> */}
    </TableRow>
  );
};

export default AdvertisementScheduleItem;
