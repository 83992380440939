import React from "react";

const BringToFrontIcon = () => {
  return (
    <svg
      fill="#000000"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>{"\n      .cls-1 {\n        fill: none;\n      }\n    "}</style>
      </defs>
      <path d="M28,10H22V4a2.0023,2.0023,0,0,0-2-2H4A2.0023,2.0023,0,0,0,2,4V20a2.0023,2.0023,0,0,0,2,2h6v6a2,2,0,0,0,2,2H28a2,2,0,0,0,2-2V12A2,2,0,0,0,28,10ZM4,20,3.9985,4H20v6H12a2,2,0,0,0-2,2v8Z" />
      <rect
        id="_Transparent_Rectangle_"
        data-name="&lt;Transparent Rectangle&gt;"
        className="cls-1"
        width={32}
        height={32}
      />
    </svg>
  );
};

export default BringToFrontIcon;
