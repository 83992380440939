import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Box,
  Toolbar,
  CssBaseline,
  Container,
  Tab,
  Tabs,
  Grid,
  Paper,
  Tooltip,
  Alert,
} from "@mui/material";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

import GlobalSuccessPrompt from "../global/GlobalSuccessPrompt";
import GlobalErrorPrompt from "../global/GlobalErrorPrompt";
import EditPassword from "./EditPassword";
import ValidateEmail from "./ValidateEmail";
import Sidebar from "../sidebar/Sidebar";

import { TypographyNormal } from "../customComponent";
import { decryptUser } from "../../helpers/crypto";
import DeactivateMFA from "./DeactivateMFA";
import SelectMFAActivation from "./mfa/SelectMFAActivation";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const MyAccount = () => {
  const { loggedUser, features } = useSelector((state) => state.auth);

  const { t } = useTranslation("user");

  const decryptedLoggedUser = decryptUser(loggedUser);
  const isEmailValid = decryptedLoggedUser.isEmailValid;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, py: 2, px: 1 }}>
        <Toolbar />
        <CssBaseline />
        <GlobalSuccessPrompt />
        <GlobalErrorPrompt />

        <Container maxWidth="md" sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  label={
                    <TypographyNormal
                      sx={{ textTransform: "capitalize", fontWeight: 300 }}
                    >
                      {t("account.generalTab")}
                    </TypographyNormal>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    <TypographyNormal
                      sx={{ textTransform: "capitalize", fontWeight: 300 }}
                    >
                      {t("account.editPasswordTab")}
                    </TypographyNormal>
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              {!isEmailValid && (
                <Alert severity="warning" icon={false} sx={{ mb: 2 }}>
                  {t("account.emailNotVerifiedHint")}
                </Alert>
              )}

              <Paper elevation={2} sx={{ borderRadius: "6px", px: 4, py: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      {t("account.usernameLabel")}
                    </TypographyNormal>
                  </Grid>
                  <Grid item xs={9}>
                    <TypographyNormal sx={{ fontWeight: 300 }}>
                      {decryptedLoggedUser?.name}
                    </TypographyNormal>
                  </Grid>

                  <Grid item xs={3}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      sx={{ height: "100%" }}
                    >
                      <TypographyNormal sx={{ fontWeight: 600 }}>
                        {t("account.emailLabel")}
                      </TypographyNormal>
                    </Box>
                  </Grid>

                  <Grid item xs={9}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      flexWrap={"wrap"}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        sx={{ height: "100%", mr: 2 }}
                      >
                        <TypographyNormal sx={{ fontWeight: 300 }}>
                          {decryptedLoggedUser?.email}
                        </TypographyNormal>
                        {isEmailValid ? (
                          <CheckCircleOutlinedIcon
                            color="success"
                            sx={{ fontSize: 20, ml: 0.3 }}
                          />
                        ) : (
                          <Tooltip
                            arrow
                            disableInteractive
                            placement="top"
                            title={
                              isEmailValid
                                ? t("account.emailVerifiedTip")
                                : t("account.emailNotVerifiedTip")
                            }
                          >
                            <ErrorOutlineOutlinedIcon
                              color="warning"
                              sx={{ fontSize: 20, ml: 0.3 }}
                            />
                          </Tooltip>
                        )}
                      </Box>
                      {!isEmailValid && <ValidateEmail />}
                    </Box>
                  </Grid>

                  {isEmailValid && features["MFA"] && (
                    <>
                      <Grid item xs={3}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          sx={{ height: "100%" }}
                        >
                          <TypographyNormal sx={{ fontWeight: 600 }}>
                            {t("account.mfaLabel")}
                          </TypographyNormal>
                        </Box>
                      </Grid>

                      <Grid item xs={9}>
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          flexWrap={"wrap"}
                        >
                          <Box
                            display={"flex"}
                            alignItems={"center"}
                            sx={{ height: "100%", mr: 2 }}
                          >
                            <TypographyNormal
                              sx={{
                                fontWeight: 300,
                              }}
                            >
                              {t(`mfa.type${decryptedLoggedUser?.mfaType}`)}
                            </TypographyNormal>
                          </Box>
                          {decryptedLoggedUser?.mfaType === "DISABLED" ? (
                            <SelectMFAActivation />
                          ) : (
                            <DeactivateMFA />
                          )}
                        </Box>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={3}>
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      {t("account.organizationLabel")}
                    </TypographyNormal>
                  </Grid>
                  <Grid item xs={9}>
                    <TypographyNormal sx={{ fontWeight: 300 }}>
                      {decryptedLoggedUser?.organization?.name}
                    </TypographyNormal>
                  </Grid>

                  <Grid item xs={3}>
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      {t("account.userGroupLabel")}
                    </TypographyNormal>
                  </Grid>
                  <Grid item xs={9}>
                    <TypographyNormal sx={{ fontWeight: 300 }}>
                      {decryptedLoggedUser?.userGroup?.name}
                    </TypographyNormal>
                  </Grid>
                </Grid>
              </Paper>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <EditPassword />
            </TabPanel>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default MyAccount;
