import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  IconButton,
  Tab,
  Tabs,
  styled,
  Grid,
  Tooltip,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
  TypographyXLarge,
} from "../../customComponent";

import { formattedDate } from "../../../helpers/dateFormat";
import {
  ViewerBlueIcon,
  GroupBlueIcon,
  ScheduleYellowIcon,
  AnnouncementPurpleIcon,
} from "../../../icons";
import { colorStyling } from "../../../helpers/color";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const RenderViewDetailSchedule = ({ schedule, onClose }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {schedule.status !== undefined &&
          schedule.status?.includes("ANNOUNCEMENT") ? (
            <AnnouncementPurpleIcon />
          ) : (
            <ScheduleYellowIcon />
          )}

          <TypographyXLarge sx={{ ml: 2, fontWeight: 600 }}>
            {schedule.name}
          </TypographyXLarge>
        </Box>

        <IconButton onClick={() => onClose(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={6} lg={3}>
          <TypographyNormal sx={{ fontWeight: 600 }}>Status</TypographyNormal>
          <TypographyNormal sx={{ fontWeight: 300 }}>
            {schedule.status}
          </TypographyNormal>
        </Grid>

        <Grid item xs={6} lg={3}>
          <TypographyNormal sx={{ fontWeight: 600 }}>
            Frequency
          </TypographyNormal>
          <TypographyNormal sx={{ fontWeight: 300 }}>
            {schedule.frequencyName}
          </TypographyNormal>
        </Grid>

        <Grid item xs={6} lg={3}>
          <TypographyNormal sx={{ fontWeight: 600 }}>
            Start Date
          </TypographyNormal>
          <TypographyNormal sx={{ fontWeight: 300 }}>
            {formattedDate(schedule.startAt)}
          </TypographyNormal>
        </Grid>

        <Grid item xs={6} lg={3}>
          <TypographyNormal sx={{ fontWeight: 600 }}>End Date</TypographyNormal>
          <TypographyNormal sx={{ fontWeight: 300 }}>
            {formattedDate(schedule.endAt)}
          </TypographyNormal>
        </Grid>

        <Grid item xs={6} lg={3}>
          <TypographyNormal sx={{ fontWeight: 600 }}>Type</TypographyNormal>
          <TypographyNormal sx={{ fontWeight: 300 }}>
            {schedule.type}
          </TypographyNormal>
        </Grid>

        <Grid item xs={6} lg={3}>
          <TypographyNormal sx={{ fontWeight: 600 }}>Command</TypographyNormal>
          <TypographyNormal sx={{ fontWeight: 300 }}>
            {schedule.command}
          </TypographyNormal>
        </Grid>

        <Grid item xs={6} lg={3}>
          <TypographyNormal sx={{ fontWeight: 600 }}>
            Next Scheduled Play Date
          </TypographyNormal>
          <TypographyNormal sx={{ fontWeight: 300 }}>
            {schedule.nextAt ? formattedDate(schedule.nextAt) : "-"}
          </TypographyNormal>
        </Grid>

        <Grid item xs={12} lg={12}>
          <TypographyNormal sx={{ fontWeight: 600 }}>URL</TypographyNormal>
          <TypographyNormal sx={{ fontWeight: 300 }}>
            {schedule.status?.includes("ANNOUNCEMENT")
              ? schedule.args[0]
              : schedule.url
              ? schedule.url
              : "-"}
          </TypographyNormal>
        </Grid>
      </Grid>

      <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 2 }}>
        <Tabs
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label={
              <TypographyNormal
                sx={{
                  textTransform: "capitalize",
                  color: colorStyling.primary,
                }}
              >
                Subgroup{" "}
                <Box
                  component="span"
                  sx={{
                    backgroundColor: colorStyling.white.hover,
                    color: colorStyling.primary,
                    borderRadius: "50%",
                    padding: "5px",
                    fontSize: "12px",
                  }}
                >
                  {!schedule.viewerGroups ? 0 : schedule.viewerGroups.length}
                </Box>
              </TypographyNormal>
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <TypographyNormal
                sx={{
                  textTransform: "capitalize",
                  color: colorStyling.primary,
                }}
              >
                Viewer{" "}
                <Box
                  component="span"
                  sx={{
                    backgroundColor: colorStyling.white.hover,
                    color: colorStyling.primary,
                    borderRadius: "50%",
                    padding: "5px",
                    fontSize: "12px",
                  }}
                >
                  {!schedule.viewers ? 0 : schedule.viewers.length}
                </Box>
              </TypographyNormal>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0}>
        {Array.isArray(schedule.viewerGroups) &&
        !schedule.viewerGroups.length ? (
          <TypographyLarge sx={{ textAlign: "center" }}>
            No group has been assigned at this time.
          </TypographyLarge>
        ) : (
          schedule.viewerGroups?.map((subgroup) => (
            <RenderSubgroupList subgroup={subgroup} key={subgroup.id} />
          ))
        )}
      </TabPanel>

      <TabPanel value={value} index={1}>
        {Array.isArray(schedule.viewers) && !schedule.viewers.length ? (
          <TypographyLarge sx={{ textAlign: "center" }}>
            No viewer has been assigned at this time.
          </TypographyLarge>
        ) : (
          schedule.viewers?.map((viewer) => (
            <RenderViewerList viewer={viewer} key={viewer.id} />
          ))
        )}
      </TabPanel>
    </>
  );
};

export default RenderViewDetailSchedule;

const RenderViewerList = ({ viewer }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          backgroundColor: colorStyling.white.full,
          border: `1px solid ${colorStyling.white.border}`,
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
          p: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ViewerBlueIcon />

          <Box ml={2}>
            <TypographyNormal sx={{ fontWeight: 600 }}>
              {viewer.name}
            </TypographyNormal>

            <TypographySmall sx={{ fontWeight: 300 }}>
              {viewer.ipAddress}
            </TypographySmall>
          </Box>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TypographySmall
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "15px",
              color:
                viewer.status === "ACTIVE"
                  ? colorStyling.green
                  : colorStyling.black,
              backgroundColor:
                viewer.status === "ACTIVE"
                  ? colorStyling.white.active
                  : colorStyling.white.inactive,
              padding: "8px",
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color:
                  viewer.status === "ACTIVE"
                    ? colorStyling.green
                    : colorStyling.gray.normal,
                fontSize: 8,
                marginRight: "5px",
              }}
            />
            {viewer.status === "ACTIVE" ? "Active" : "Inactive"}
          </TypographySmall>

          <TypographySmall
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "15px",
              color:
                viewer.browserStatus === "ON"
                  ? colorStyling.green
                  : colorStyling.black,
              backgroundColor:
                viewer.browserStatus === "ON"
                  ? colorStyling.white.active
                  : colorStyling.white.inactive,
              padding: "8px",
              mx: 2,
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color:
                  viewer.browserStatus === "ON"
                    ? colorStyling.green
                    : colorStyling.gray.normal,
                fontSize: 8,
                marginRight: "5px",
              }}
            />
            Browser : {viewer.browserStatus === "ON" ? "On" : "Off"}
          </TypographySmall>

          <TypographySmall
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "15px",
              color:
                viewer.tvStatus === "ON"
                  ? colorStyling.green
                  : colorStyling.black,
              backgroundColor:
                viewer.tvStatus === "ON"
                  ? colorStyling.white.active
                  : colorStyling.white.inactive,
              padding: "8px",
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color:
                  viewer.tvStatus === "ON"
                    ? colorStyling.green
                    : colorStyling.gray.normal,
                fontSize: 8,
                marginRight: "5px",
              }}
            />
            TV : {viewer.tvStatus === "ON" ? "On" : "Off"}
          </TypographySmall>

          <IconButton
            sx={{ color: colorStyling.black, ml: 4 }}
            onClick={() => navigate("/viewer/detail/" + viewer.id)}
          >
            <Tooltip title="See Detail">
              <NavigateNextIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

const RenderSubgroupList = ({ subgroup }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: colorStyling.white.full,
          border: `1px solid ${colorStyling.white.border}`,
          borderRadius: "6px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          p: 2,
        }}
      >
        <Box sx={{ display: "flex" }}>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>

          <Box
            mx={2}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <GroupBlueIcon />
            <TypographyNormal sx={{ ml: 2, fontWeight: 600 }}>
              {subgroup.name}
            </TypographyNormal>
          </Box>
        </Box>
      </Box>
    </>
  );
};
