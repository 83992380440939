import React from "react";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Link, Tooltip } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { colorStyling } from "../../../helpers/color";
import { useNavigate } from "react-router-dom";

const ScheduleBreadCrumb = ({ schedule }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("schedule");

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon
          sx={{ color: colorStyling.primary }}
          fontSize="small"
        />
      }
      aria-label="breadcrumb"
    >
      <Link
        onClick={() =>
          navigate(
            schedule.type === "ADVERTISEMENT"
              ? "/rotating-schedules"
              : "/schedules"
          )
        }
        underline="always"
        sx={{ cursor: "pointer" }}
      >
        {t("mainBreadcrumb")}
      </Link>
      <Tooltip
        title={schedule?.name?.length > 30 ? schedule?.name : ""}
        placement="top-start"
      >
        <Link href="#" underline="hover" sx={{ cursor: "pointer" }}>
          {schedule?.name?.length > 30
            ? schedule?.name?.substring(0, 27).trim() + "..."
            : schedule?.name}
        </Link>
      </Tooltip>
    </Breadcrumbs>
  );
};

export default ScheduleBreadCrumb;
