import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Radio,
  TextField,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { colorStyling } from "../../../helpers/color";
import { SET_USER_HISTORY_FILTER } from "../../../store/actionTypes/userActionType";
import dayjs from "dayjs";

const initFilter = {
  name: "",
  type: "",
  recordedAtStart: "",
  sortBy: "idDesc",
};

const UserHistoryFilter = () => {
  const dispatch = useDispatch();
  const { userHistoryFilter } = useSelector((state) => state.user);

  const { t } = useTranslation("auditlog");
  const { t: tCommon } = useTranslation();

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [recordedAtStart, setRecordedAtStart] = useState("");
  const [sortBy, setSortBy] = useState("idDesc");

  const [anchorEl, setAnchorEl] = useState(null);
  const openSortButton = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setName(userHistoryFilter.name);
    setType(userHistoryFilter.type);
    setSortBy(userHistoryFilter.sortBy);

    if (userHistoryFilter.recordedAtStart?.length > 0) {
      setRecordedAtStart(userHistoryFilter.recordedAtStart);
    }
  }, [userHistoryFilter]);

  const handleDispatchFilter = (payload) => {
    dispatch({
      type: SET_USER_HISTORY_FILTER,
      payload,
    });
  };

  const handleChangeSortBy = (value) => {
    setSortBy(value);
    handleDispatchFilter({ ...userHistoryFilter, sortBy: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let filterPayload = { ...userHistoryFilter };
    filterPayload["userType"] = type;
    filterPayload["type"] = type;

    if (type === "USER") {
      filterPayload["userNameLike"] = name;
      filterPayload["name"] = name;
    } else {
      filterPayload["userNameLike"] = "";
      filterPayload["name"] = "";
    }

    delete filterPayload["recordedAtStart"];
    delete filterPayload["createdAtGte"];
    delete filterPayload["createdAtLte"];

    if (recordedAtStart) {
      const startDate = new Date(
        dayjs(recordedAtStart).format("YYYY-MM-DDT00:00:00")
      ).toISOString();
      const endDate = new Date(
        dayjs(recordedAtStart).format("YYYY-MM-DDT23:59:59")
      ).toISOString();

      filterPayload["createdAtGte"] = startDate;
      filterPayload["createdAtLte"] = endDate;
      filterPayload["recordedAtStart"] = recordedAtStart;
    }

    handleDispatchFilter({ ...filterPayload });
  };

  const handleRefresh = () => {
    handleDispatchFilter(initFilter);
    setName("");
    setType("");
    setRecordedAtStart("");
    setSortBy("idDesc");
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      onSubmit={handleSubmit}
    >
      <TextField
        size="small"
        fullWidth
        select
        SelectProps={{ native: true }}
        value={type}
        onChange={(e) => setType(e.target.value)}
        sx={{ mx: 2 }}
      >
        <option defaultValue value="">
          {t("filter.searchTypeAll")}
        </option>
        <option value="USER">{t("filter.searchTypeUser")}</option>
        <option value="SYSTEM">{t("filter.searchTypeSystem")}</option>
        <option value="SCREEN">{t("filter.searchTypeScreen")}</option>
      </TextField>

      {type === "USER" && (
        <TextField
          fullWidth
          size="small"
          sx={{ mr: 2 }}
          placeholder={t("filter.searchPlaceholder")}
          type="search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      )}

      <TextField
        sx={{ mr: 2, maxWidth: 185 }}
        size="small"
        fullWidth
        value={recordedAtStart}
        onChange={(e) => {
          setRecordedAtStart(e.target.value);
        }}
        type="date"
      />

      <Button type="submit" variant="contained">
        {tCommon("searchBtn")}
      </Button>

      <IconButton
        sx={{ color: colorStyling.black, mx: 2 }}
        onClick={handleClick}
      >
        <Tooltip title={tCommon("sortTip")}>
          <SortIcon fontSize="small" />
        </Tooltip>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openSortButton}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleChangeSortBy("idDesc")}>
          <FormControlLabel
            disableTypography
            value="idDesc"
            control={<Radio size="small" checked={sortBy === "idDesc"} />}
            label={t("filter.sortByNewest")}
            sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
          />
        </MenuItem>

        <MenuItem onClick={() => handleChangeSortBy("idAsc")}>
          <FormControlLabel
            disableTypography
            value="idAsc"
            control={<Radio size="small" checked={sortBy === "idAsc"} />}
            label={t("filter.sortByOldest")}
            sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
          />
        </MenuItem>
      </Menu>

      <IconButton onClick={handleRefresh} sx={{ color: colorStyling.black }}>
        <Tooltip title={tCommon("resetFilterTip")}>
          <RestartAltIcon fontSize="small" />
        </Tooltip>
      </IconButton>
    </Box>
  );
};

export default UserHistoryFilter;
