import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, CssBaseline, Paper, Toolbar } from "@mui/material";
import { TypographyXLarge } from "../../customComponent";
import Sidebar from "../../sidebar/Sidebar";
import ContentReportList from "./ContentReportList";
import ReportingErrorPrompt from "../ReportingErrorPrompt";
import ContentReportFilter from "./ContentReportFilter";
import ExportContentReport from "./ExportContentReport";
import { useSelector } from "react-redux";

const ContentReporting = () => {
  const { t } = useTranslation("contentreport");

  const { features } = useSelector((state) => state.auth);

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ pb: 2 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
          </Box>

          <ReportingErrorPrompt />

          {features["LOG.CONTENT.EXPORT"] && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <ExportContentReport />
            </Box>
          )}

          <Box sx={{ my: 2 }}>
            <Paper
              component="section"
              elevation={2}
              sx={{ p: 2, my: 2, borderRadius: "6px" }}
            >
              <ContentReportFilter />
            </Paper>

            <ContentReportList />
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default ContentReporting;
