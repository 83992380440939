import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { decryptUser, encrypt } from "../../helpers/crypto";
import { SET_AUTH_USER } from "../../store/actionTypes/userActionType";
import {
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../../store/actionTypes/globalActionType";
import { handleTimeoutPrompt } from "../../store/handlePrompt";
import { useTranslation } from "react-i18next";
import DisableMFADialogFormOTP from "./mfa/DisableMFADialogFormOTP";

const DeactivateMFA = () => {
  const dispatch = useDispatch();
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);

  const { t } = useTranslation("user");

  const [open, setOpen] = useState(false);
  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    const newUserData = { ...decryptedLoggedUser };
    newUserData.mfaType = "DISABLED";
    const encryptedNewUser = encrypt(newUserData);
    dispatch({ type: SET_AUTH_USER, payload: encryptedNewUser });

    dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
    dispatch({
      type: SET_GLOBAL_SUCCESS_MESSAGE,
      payload: t("account.deactivateMFASuccess"),
    });

    handleTimeoutPrompt(dispatch, SET_GLOBAL_SUCCESS);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="warning"
        size="small"
        sx={{ px: "5px", py: 0 }}
        onClick={handleOpenDialog}
      >
        {t("account.deactivateMFABtn")}
      </Button>

      <DisableMFADialogFormOTP
        open={open}
        onClose={handleCloseDialog}
        user={decryptedLoggedUser}
        handleSuccess={handleSuccess}
        dialogTitle={t("account.deactivateMFADialogTitle")}
      />
    </>
  );
};

export default DeactivateMFA;
