import React from "react";
import { Box } from "@mui/material";

const EventHover = ({ event, position }) => {
  return (
    <Box
      className="tooltip"
      sx={{
        left: `${position.x + 10}px`,
        top: `${position.y + 10}px`,
      }}
    >
      {event.title}
    </Box>
  );
};

export default EventHover;
