import React from "react";
import { useDispatch } from "react-redux";
import { Alert } from "@mui/material";
import { styled } from "@mui/material/styles";
import { handleClosePrompt } from "../store/handlePrompt";

const CustomAlert = styled(Alert)({
  "& div": {
    "& p": {
      margin: 0,
    },
  },
});

const DispatchErrorPromptAlert = ({
  isError,
  errorMessage,
  closeActionType,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      {isError && (
        <CustomAlert
          sx={{ mt: 1, mb: 2 }}
          severity="error"
          variant="filled"
          onClose={() => handleClosePrompt(dispatch, closeActionType)}
        >
          {errorMessage}
        </CustomAlert>
      )}
    </>
  );
};

export default DispatchErrorPromptAlert;
