import { initiateAXIOS } from "../../config/axios";
import Viewer from "../../models/ViewerModel";
import Schedule from "../../models/ScheduleModel";
import { handleError } from "../handleError";
import {
  SET_HOME_ACTIVE_ANNOUNCEMENT,
  SET_HOME_ACTIVE_SCHEDULE,
  SET_HOME_ACTIVE_VIEWER,
  SET_HOME_GROUP,
  SET_HOME_LAST_VIEWER_ACTIVE,
  SET_HOME_LOADING_GROUP,
  SET_HOME_LOADING_LAST_VIEWER_ACTIVE,
  SET_HOME_LOADING_SCHEDULE,
  SET_HOME_LOADING_SCHEDULE_PENDING_ACTIVE_STATUS,
  SET_HOME_LOADING_VIEWER,
  SET_HOME_SCHEDULE_PENDING_ACTIVE_STATUS,
  SET_HOME_VIEWER,
  SET_HOME_LOADING_ACTIVE_ANNOUNCEMENT,
} from "../actionTypes/homeActionType";
import {
  SET_GLOBAL_ERROR,
  SET_GLOBAL_ERROR_MESSAGE,
} from "../actionTypes/globalActionType";
import {
  initiateViewerAPI,
  initiateGroupAPI,
  initiateScheduleAPI,
  announcementAPILimit,
} from "../../api";
import { fetchScheduleById, scheduleTypeQuery } from "./scheduleAction";
import { getViewerStatus } from "../../helpers/viewerHelper";
import { stellarFlag } from "../../externalFeatures/componentsFlag";

const advScheduleQuery = "&type=ADVERTISEMENT";

export const getAllViewers = () => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_HOME_LOADING_VIEWER, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateViewerAPI + `?limit=100&sortBy=activeAtDesc`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let viewers = [];
      const listViewer = data.items;
      listViewer.map((item) => viewers.push(new Viewer(item)));

      const active = viewers.filter((viewer) =>
        getViewerStatus(viewer.activeAt)
      );

      dispatch({ type: SET_HOME_VIEWER, payload: data.totalItem });
      dispatch({ type: SET_HOME_ACTIVE_VIEWER, payload: active.length });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: message });
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_HOME_LOADING_VIEWER, payload: false });
    }
  };
};

export const getHomeGroups = () => {
  const access_token = sessionStorage.getItem("access_token");
  return async (dispatch) => {
    dispatch({ type: SET_HOME_LOADING_GROUP, payload: true });

    try {
      const { data } = await initiateAXIOS.get(initiateGroupAPI, {
        headers: { authorization: `Bearer ${access_token}` },
      });

      dispatch({ type: SET_HOME_GROUP, payload: data.totalItem });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: message });
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_HOME_LOADING_GROUP, payload: false });
    }
  };
};

export const getActiveSchedules = () => {
  const access_token = sessionStorage.getItem("access_token");
  return async (dispatch) => {
    dispatch({ type: SET_HOME_LOADING_SCHEDULE, payload: true });

    try {
      const scheduleQuery =
        "?isActive=true" +
        scheduleTypeQuery +
        (stellarFlag ? advScheduleQuery : "");
      const { data } = await initiateAXIOS.get(
        initiateScheduleAPI + scheduleQuery,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      dispatch({ type: SET_HOME_ACTIVE_SCHEDULE, payload: data.totalItem });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: message });
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_HOME_LOADING_SCHEDULE, payload: false });
    }
  };
};

export const getLastActiveViewers = (limit) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_HOME_LOADING_LAST_VIEWER_ACTIVE, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        initiateViewerAPI + `?limit=${limit}&sortBy=activeAtDesc`,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let viewers = [];
      const listViewer = data.items;
      listViewer.map((item) => viewers.push(new Viewer(item)));

      dispatch({
        type: SET_HOME_LAST_VIEWER_ACTIVE,
        payload: { viewers, limit },
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: message });
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_HOME_LOADING_LAST_VIEWER_ACTIVE, payload: false });
    }
  };
};

export const getPendingActiveSchedules = (limit) => {
  const scheduleQuery =
    "&sortBy=isActiveDesc" +
    scheduleTypeQuery +
    (stellarFlag ? advScheduleQuery : "");
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");

    dispatch({
      type: SET_HOME_LOADING_SCHEDULE_PENDING_ACTIVE_STATUS,
      payload: true,
    });

    try {
      const { data } = await initiateAXIOS.get(
        initiateScheduleAPI + `?limit=${limit}` + scheduleQuery,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let schedules = [];
      const dataSchedule = data.items;
      dataSchedule.map((item) => schedules.push(new Schedule(item)));
      dispatch({
        type: SET_HOME_SCHEDULE_PENDING_ACTIVE_STATUS,
        payload: { schedules, limit },
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: message });
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({
        type: SET_HOME_LOADING_SCHEDULE_PENDING_ACTIVE_STATUS,
        payload: false,
      });
    }
  };
};

export const getActiveAnnouncements = () => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_HOME_LOADING_ACTIVE_ANNOUNCEMENT, payload: true });

    try {
      const { data } = await initiateAXIOS.get(
        announcementAPILimit + "&isActive=true",
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      let announcements = [];
      await Promise.all(
        data.items.map(async (ann) => {
          const announcementData = await fetchScheduleById(
            ann.id,
            access_token
          );
          announcements.push(new Schedule(announcementData));
        })
      );

      dispatch({
        type: SET_HOME_ACTIVE_ANNOUNCEMENT,
        payload: { items: announcements, totalItem: data.totalItem },
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_GLOBAL_ERROR, payload: true });
      dispatch({ type: SET_GLOBAL_ERROR_MESSAGE, payload: message });
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_HOME_LOADING_ACTIVE_ANNOUNCEMENT, payload: false });
    }
  };
};
