import React from "react";
import { useSelector } from "react-redux";

import { Box, Button, Container, CssBaseline, Toolbar } from "@mui/material";

import TodayIcon from "@mui/icons-material/Today";
import { useNavigate } from "react-router-dom";
import { decryptUser } from "../../../../helpers/crypto";
import { Sidebar } from "../../../../components";
import ScheduleSuccessPrompt from "../../../../components/schedule/ScheduleSuccessPrompt";
import ScheduleErrorPrompt from "../../../../components/schedule/ScheduleErrorPrompt";
import CreateAdvertisementSchedule from "./CreateAdvertisementSchedule";
import AdvertisementScheduleList from "./AdvertisementScheduleList";
import {
  TypographyNormal,
  TypographyXLarge,
} from "../../../../components/customComponent";
import { useTranslation } from "react-i18next";

const AdvertisementSchedule = () => {
  const navigate = useNavigate();

  const { loggedUser, features } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);
  const userPermission = decryptedLoggedUser?.permission.schedule;

  const { t } = useTranslation("advertschedule");

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ pb: 2 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              {t("titleExplain")}
            </TypographyNormal>
          </Box>

          <ScheduleSuccessPrompt />
          <ScheduleErrorPrompt />

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {features["SCHEDULE.CALENDAR"] ? (
              <Button
                variant="contained"
                startIcon={<TodayIcon />}
                onClick={() => navigate("/schedule/calendar")}
              >
                {t("calendarBtn")}
              </Button>
            ) : (
              <Box></Box>
            )}
            <Box>
              {userPermission.create && <CreateAdvertisementSchedule />}
            </Box>
          </Box>

          <AdvertisementScheduleList />
        </Container>
      </Box>
    </div>
  );
};

export default AdvertisementSchedule;
