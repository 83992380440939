// Enum: "EXPAND_LEFT"
export const ExpandFromRight = {
  id: "expandFromRight",
  label: "Expand From Right",
  speed: 2000,
  effect: {
    prev: {
      shadow: true,
      origin: "left center",
      translate: [0, 0, -1],
    },
    next: {
      origin: "right center",
      scale: 0.1,
      translate: ["50%", 0, 0],
    },
  },
};
