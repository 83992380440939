import { instanceContentReportAPI } from "../../api";
import { initiateAXIOS } from "../../config/axios";
import { logger } from "../../helpers/logger";
import { ascending } from "../../helpers/sorting";
import {
  SET_CONTENT_REPORT,
  SET_ERROR_MESSAGE_REPORTING_PAGE,
  SET_ERROR_PROMPT_REPORTING_PAGE,
  SET_LOADING_CONTENT_REPORT,
} from "../actionTypes/reportingActionType";
import { handleError } from "../handleError";

const handleTimeoutErrorPrompt = (dispatch) => {
  setTimeout(() => {
    dispatch({ type: SET_ERROR_PROMPT_REPORTING_PAGE, payload: false });
  }, 5000);
};

export const cleanReportData = (data) => {
  let cleanedData = data.filter(
    (item) =>
      item.assetName !== "Unknown" &&
      item.assetType !== "UNKNOWN" &&
      item.assetType !== "TEXT" &&
      item.assetId
  );

  cleanedData.forEach((item) => {
    if (item.assetCampaignName === null) item.assetCampaignName = "-";
  });

  return cleanedData;
};

export const getContentReports = (query) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_CONTENT_REPORT, payload: true });

    try {
      logger.log(`getContentReports:`, query);
      const { data } = await initiateAXIOS.get(
        instanceContentReportAPI + query,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      const filtered = cleanReportData(data.items);

      dispatch({
        type: SET_CONTENT_REPORT,
        payload: ascending(filtered, "assetName"),
      });
    } catch (e) {
      const message = handleError(e);
      dispatch({ type: SET_ERROR_PROMPT_REPORTING_PAGE, payload: true });
      dispatch({ type: SET_ERROR_MESSAGE_REPORTING_PAGE, payload: message });

      handleTimeoutErrorPrompt(dispatch);
      if (e.response?.status >= 500) {
        throw new Error(JSON.stringify(e.response?.data) || message);
      }
    } finally {
      dispatch({ type: SET_LOADING_CONTENT_REPORT, payload: false });
    }
  };
};
