import { logger } from "./logger";

const debugMode = false;
const logging = (prefix, value) => {
  if (!debugMode) {
    return;
  }

  logger.log(prefix, value);
};

const isCharSymbol = (char) => {
  const charRep = char.replace(/[`'"$[(<*!@#%^&]/g, "");
  return charRep === "" ? true : false;
};

export const parseVariables = (text, vars = {}) => {
  const splitText = text.split(" ");
  logging(`vars:`, vars);
  logging(`splitText:`, splitText);

  for (let i = 0; i < splitText.length; i++) {
    const cTxt = splitText[i];
    if (cTxt.length === 0 || !isCharSymbol(cTxt[0])) {
      continue;
    }

    let varPoint = "";
    let startFlag = false;
    for (let c = 0; c < cTxt.length; c++) {
      if (cTxt[c] === "$") {
        startFlag = true;
      }

      if (startFlag === true) {
        varPoint = varPoint.concat(cTxt[c]);
      }

      if (cTxt[c] === "}") {
        startFlag = false;
        break;
      }
    }

    if (varPoint.length > 0) {
      const varName = varPoint.replace(/[${}]/g, "");
      const splitVarName = varName.split(".");

      let getVar = vars[splitVarName[0]];
      if (splitVarName.length > 1) {
        for (let j = 1; j < splitVarName.length; j++) {
          if (splitVarName[j] === "") break;
          getVar = getVar[splitVarName[j]];
        }
      }

      logging(`varPoint: ${varPoint} => [${getVar}]`);
      splitText[i] = splitText[i].replace(varPoint, getVar);
    }
  }

  return splitText.join(" ");
};

export const checkUserGroupTooltipPermission = {
  alert: true,
};
