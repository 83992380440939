import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  FormControlLabel,
  Radio,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SortIcon from "@mui/icons-material/Sort";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  SET_LOADING_VIEWER,
  SET_VIEWER_FILTER,
} from "../../store/actionTypes/viewerActionTypes";
import dayjs from "dayjs";
import { getViewerStatusMinuteDiff } from "../../helpers/viewerHelper";
import { colorStyling } from "../../helpers/color";

const FilterViewer = () => {
  const dispatch = useDispatch();
  const { viewerFilters } = useSelector((state) => state.viewer);

  const { t } = useTranslation("viewer");
  const { t: tCommon } = useTranslation();

  const [viewerName, setViewerName] = useState(viewerFilters.nameLike);

  const [status, setStatus] = useState(
    viewerFilters.activeAtGte ? "ONLINE" : viewerFilters.connectionStatus
  );

  const [displayStatus, setDisplayStatus] = useState(
    viewerFilters.displayStatus
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const handleOpenSort = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorEl(null);
  };

  const handleDispatchFilter = (payload) => {
    dispatch({
      type: SET_VIEWER_FILTER,
      payload,
    });
  };

  const handleLoading = (value) => {
    dispatch({
      type: SET_LOADING_VIEWER,
      payload: value,
    });
  };

  const handleChangeSortBy = (value) => {
    handleLoading(true);
    handleDispatchFilter({ ...viewerFilters, sortBy: value });
  };

  const handleChangeStatus = (event) => {
    const value = event.target.value;
    setStatus(value);
    if (value === "OFFLINE") setDisplayStatus("");
  };

  const handleChangeDisplayStatus = (event) => {
    setDisplayStatus(event.target.value);
  };

  const handleRefresh = () => {
    handleDispatchFilter({ sortBy: "activeAtDesc" });

    setViewerName("");
    setStatus("");
    setDisplayStatus("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let query = { ...viewerFilters };

    if (viewerName) query["nameLike"] = viewerName;
    else delete query["nameLike"];

    switch (status) {
      case "OFFLINE":
        query["connectionStatus"] = status;
        query["activeAtLte"] = dayjs()
          .subtract(getViewerStatusMinuteDiff(), "m")
          .toISOString();
        delete query["activeAtGte"];
        break;

      case "ONLINE":
        query["activeAtGte"] = dayjs()
          .subtract(getViewerStatusMinuteDiff(), "m")
          .toISOString();
        query["activeAtLte"] = dayjs()
          .add(getViewerStatusMinuteDiff(), "m")
          .toISOString();
        delete query["connectionStatus"];
        break;

      default:
        delete query["connectionStatus"];
        delete query["activeAtGte"];
        delete query["activeAtLte"];
        break;
    }

    switch (displayStatus) {
      case "ON":
        if (status !== "OFFLINE") {
          query["activeAtGte"] = dayjs()
            .subtract(getViewerStatusMinuteDiff(), "m")
            .toISOString();
          query["activeAtLte"] = dayjs()
            .add(getViewerStatusMinuteDiff(), "m")
            .toISOString();
          query["displayStatus"] = displayStatus;
        }
        break;

      default:
        if (displayStatus) query["displayStatus"] = displayStatus;
        else delete query["displayStatus"];
        if (status === "") {
          delete query["activeAtGte"];
          delete query["activeAtLte"];
        }
        break;
    }

    handleLoading(true);
    handleDispatchFilter(query);
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box
          component="form"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            fullWidth
            type="search"
            size="small"
            sx={{ mr: 2 }}
            value={viewerName}
            placeholder={t("filter.searchPlaceholder")}
            onChange={(event) => setViewerName(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            sx={{ mr: 2 }}
            InputLabelProps={{ shrink: true }}
            size="small"
            value={status}
            onChange={handleChangeStatus}
          >
            <option value="">{t("filter.statusAny")}</option>
            <option value="ONLINE">{t("filter.statusOnline")}</option>
            <option value="OFFLINE">{t("filter.statusOffline")}</option>
          </TextField>

          <TextField
            fullWidth
            select
            SelectProps={{
              native: true,
            }}
            InputLabelProps={{ shrink: true }}
            size="small"
            value={displayStatus}
            onChange={handleChangeDisplayStatus}
          >
            <option value="">{t("filter.displayAny")}</option>
            {status !== "OFFLINE" && (
              <option value="ON">{t("filter.displayOn")}</option>
            )}
            <option value="OFF">{t("filter.displayOff")}</option>
            <option value="NO_DATA">{t("filter.displayNoData")}</option>
          </TextField>

          <Button sx={{ ml: 2 }} variant="contained" type="submit">
            {tCommon("searchBtn")}
          </Button>
        </Box>

        <Box sx={{ display: "flex", ml: 2 }}>
          <IconButton
            sx={{ color: colorStyling.black }}
            onClick={handleOpenSort}
            aria-controls={openSortButton ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openSortButton ? "true" : undefined}
          >
            <Tooltip title={tCommon("sortTip")}>
              <SortIcon fontSize="small" />
            </Tooltip>
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openSortButton}
            onClose={handleCloseSort}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => handleChangeSortBy("activeAtDesc")}>
              <FormControlLabel
                disableTypography
                value="activeAtDesc"
                control={
                  <Radio
                    size="small"
                    checked={viewerFilters.sortBy === "activeAtDesc"}
                  />
                }
                label={t("filter.sortByMostActive")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("activeAtAsc")}>
              <FormControlLabel
                disableTypography
                value="activeAtAsc"
                control={
                  <Radio
                    size="small"
                    checked={viewerFilters.sortBy === "activeAtAsc"}
                  />
                }
                label={t("filter.sortByLeastActive")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("idAsc")}>
              <FormControlLabel
                disableTypography
                value="idAsc"
                control={
                  <Radio
                    size="small"
                    checked={viewerFilters.sortBy === "idAsc"}
                  />
                }
                label={t("filter.sortByOldest")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("idDesc")}>
              <FormControlLabel
                disableTypography
                value="idDesc"
                control={
                  <Radio
                    size="small"
                    checked={viewerFilters.sortBy === "idDesc"}
                  />
                }
                label={t("filter.sortByNewest")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("nameAsc")}>
              <FormControlLabel
                disableTypography
                value="nameAsc"
                control={
                  <Radio
                    size="small"
                    checked={viewerFilters.sortBy === "nameAsc"}
                  />
                }
                label={t("filter.sortByAtoZ")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("nameDesc")}>
              <FormControlLabel
                disableTypography
                value="nameDesc"
                control={
                  <Radio
                    size="small"
                    checked={viewerFilters.sortBy === "nameDesc"}
                  />
                }
                label={t("filter.sortByZtoA")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
          </Menu>

          <IconButton
            sx={{ ml: 2, color: colorStyling.black }}
            color="primary"
            onClick={handleRefresh}
          >
            <Tooltip title={tCommon("resetFilterTip")}>
              <RestartAltIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default FilterViewer;
