import {
  SET_SMP_DISRUPTION,
  SET_LOADING_SMP_DISRUPTION,
  SET_ERROR_MESSAGE_SMP_DISRUPTION,
  SET_ERROR_PROMPT_SMP_DISRUPTION,
  SET_LOADING_ANNOUNCEMENT_SMP_DISRUPTION,
  SET_LOADING_LINK_SMP_DISRUPTION,
  SET_EDIT_SMP_LINK_ANNOUNCEMENT,
  SET_ERROR_MESSAGE_ANNOUNCEMENT_SMP_DISRUPTION,
  SET_ERROR_PROMPT_ANNOUNCEMENT_SMP_DISRUPTION,
  SET_SUCCESS_PROMPT_SMP_DISRUPTION,
  SET_SUCCESS_MESSAGE_SMP_DISRUPTION,
  SET_SMP_STATION,
} from "../../actionTypes/bplrt/smpActionType";
import { bplrtAXIOS, initiateAXIOS } from "../../../../config/axios";
import {
  initiateScheduleAPI,
  instanceBPLRTDisruptionAPI,
  instanceSMPAPI as smpAPI,
} from "../../../../api";
import { handleError } from "../../../../store/handleError";
import { logger } from "../../../../helpers/logger";
import { getActiveAnnouncements } from "../../../../store/actions/homeAction";
import i18n from "i18next";
const t = i18n.getFixedT(null, "bplrtSmp");

export const handleTimeoutPrompt = (dispatch, actionType) => {
  setTimeout(() => {
    dispatch({ type: actionType, payload: false });
  }, 5000);
};

const handleTimeoutErrorPrompt = (dispatch, actionType) => {
  setTimeout(() => {
    dispatch({ type: actionType, payload: false });
  }, 5000);
};

export const getSMPDisruption = () => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_SMP_DISRUPTION, payload: true });

    try {
      let payloadObj = {};
      const dataPlatform = await bplrtAXIOS.get(smpAPI + "platform?limit=100", {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const dataTrack = await bplrtAXIOS.get(smpAPI + "track?limit=100", {
        headers: { authorization: `Bearer ${access_token}` },
      });

      const dataMessage = await bplrtAXIOS.get(
        instanceBPLRTDisruptionAPI + "track",
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      payloadObj["platforms"] = dataPlatform.data.items;
      payloadObj["tracks"] = dataTrack.data.items;
      payloadObj["messages"] = dataMessage.data.messages;

      dispatch({ type: SET_SMP_DISRUPTION, payload: payloadObj });
    } catch (error) {
      const errorMessage = handleError(error);
      logger.error(errorMessage);
      dispatch({ type: SET_ERROR_PROMPT_SMP_DISRUPTION, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_SMP_DISRUPTION,
        payload: errorMessage,
      });
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SMP_DISRUPTION);
    } finally {
      dispatch({ type: SET_LOADING_SMP_DISRUPTION, payload: false });
    }
  };
};

const getAnnouncement = async (id, access_token) => {
  const { data } = await initiateAXIOS.get(initiateScheduleAPI + id, {
    headers: { authorization: `Bearer ${access_token}` },
  });
  return data;
};

export const getSMPStation = () => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_ANNOUNCEMENT_SMP_DISRUPTION, payload: true });

    try {
      const { data } = await bplrtAXIOS.get(smpAPI + "station?limit=100", {
        headers: { authorization: `Bearer ${access_token}` },
      });
      let payload = data.items;

      for (let i = 0; i < data.items.length; i++) {
        let forItem = {
          ...data.items[i],
          delayAnnouncementActive: false,
          delayFreeBusAnnouncementActive: false,
          disruptionAnnouncementActive: false,
        };

        if (forItem.delayAnnouncementId !== null) {
          const delayAnnnouncement = await getAnnouncement(
            forItem.delayAnnouncementId,
            access_token
          );

          forItem.delayAnnouncementActive = delayAnnnouncement.isActive;
        }

        if (forItem.delayFreeBusAnnouncementId !== null) {
          const delayFreeBusAnnouncement = await getAnnouncement(
            forItem.delayFreeBusAnnouncementId,
            access_token
          );

          forItem.delayFreeBusAnnouncementActive =
            delayFreeBusAnnouncement.isActive;
        }

        if (forItem.disruptionAnnouncementId !== null) {
          const disruptionAnnouncement = await getAnnouncement(
            forItem.disruptionAnnouncementId,
            access_token
          );

          forItem.disruptionAnnouncementActive =
            disruptionAnnouncement.isActive;
        }

        payload[i] = forItem;
      }

      dispatch({ type: SET_SMP_STATION, payload: payload });
    } catch (error) {
      logger.error(error);
      const errorMessage = handleError(error);
      dispatch({
        type: SET_ERROR_PROMPT_ANNOUNCEMENT_SMP_DISRUPTION,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_ANNOUNCEMENT_SMP_DISRUPTION,
        payload: errorMessage,
      });
      handleTimeoutPrompt(
        dispatch,
        SET_ERROR_PROMPT_ANNOUNCEMENT_SMP_DISRUPTION
      );
    } finally {
      dispatch({
        type: SET_LOADING_ANNOUNCEMENT_SMP_DISRUPTION,
        payload: false,
      });
    }
  };
};

export const editAllSMPDisruption = (payload) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_SMP_DISRUPTION, payload: true });

    try {
      if (payload.tracks.length !== 0) {
        await bplrtAXIOS.patch(
          smpAPI + "track/",
          { tracks: payload.tracks },
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );
      }
      if (payload.platforms.length !== 0) {
        await bplrtAXIOS.patch(
          smpAPI + "platform/",
          { platforms: payload.platforms },
          {
            headers: { authorization: `Bearer ${access_token}` },
          }
        );
      }

      dispatch({ type: SET_SUCCESS_PROMPT_SMP_DISRUPTION, payload: true });
      dispatch({
        type: SET_SUCCESS_MESSAGE_SMP_DISRUPTION,
        payload: t("message.mapUpdateSuccess"),
      });
      handleTimeoutPrompt(dispatch, SET_SUCCESS_PROMPT_SMP_DISRUPTION);
    } catch (error) {
      const errorMessage = handleError(error);
      logger.error(errorMessage);
      dispatch({ type: SET_ERROR_PROMPT_SMP_DISRUPTION, payload: true });
      dispatch({
        type: SET_ERROR_MESSAGE_SMP_DISRUPTION,
        payload: errorMessage,
      });
      handleTimeoutPrompt(dispatch, SET_ERROR_PROMPT_SMP_DISRUPTION);
    } finally {
      dispatch(getSMPDisruption());
    }
  };
};

export const editSMPLinkAnnouncement = (payload) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    if (payload.type === "submit")
      dispatch({
        type: SET_LOADING_ANNOUNCEMENT_SMP_DISRUPTION,
        payload: true,
      });

    if (payload.type === "remove")
      dispatch({
        type: SET_LOADING_LINK_SMP_DISRUPTION,
        payload: true,
      });

    try {
      await bplrtAXIOS.patch(
        smpAPI + "station/" + payload.id,
        payload.announcement,
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );

      dispatch(getSMPStation());
    } catch (error) {
      const errorMessage = handleError(error);
      console.log(errorMessage);
      dispatch({
        type: SET_ERROR_PROMPT_ANNOUNCEMENT_SMP_DISRUPTION,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_ANNOUNCEMENT_SMP_DISRUPTION,
        payload: errorMessage,
      });
      handleTimeoutErrorPrompt(
        dispatch,
        SET_ERROR_PROMPT_ANNOUNCEMENT_SMP_DISRUPTION
      );
    } finally {
      if (payload.type === "submit")
        dispatch({
          type: SET_LOADING_ANNOUNCEMENT_SMP_DISRUPTION,
          payload: false,
        });

      if (payload.type === "remove")
        dispatch({
          type: SET_LOADING_LINK_SMP_DISRUPTION,
          payload: false,
        });
    }
  };
};

export const activateSMPAnnouncement = (payload) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_ANNOUNCEMENT_SMP_DISRUPTION, payload: true });

    try {
      await initiateAXIOS.post(
        initiateScheduleAPI +
          payload.announcementId +
          (payload.active ? "/activate" : "/deactivate"),
        {},
        {
          headers: { authorization: `Bearer ${access_token}` },
        }
      );
      dispatch({ type: SET_EDIT_SMP_LINK_ANNOUNCEMENT, payload: payload });
    } catch (error) {
      const errorMessage = handleError(error);
      console.log(errorMessage);
      dispatch({
        type: SET_ERROR_PROMPT_ANNOUNCEMENT_SMP_DISRUPTION,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_ANNOUNCEMENT_SMP_DISRUPTION,
        payload: errorMessage,
      });
      // handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({
        type: SET_LOADING_ANNOUNCEMENT_SMP_DISRUPTION,
        payload: false,
      });
      dispatch(getActiveAnnouncements());
    }
  };
};

export const activateAllSMPAnnouncement = (payload) => {
  return async (dispatch) => {
    const access_token = sessionStorage.getItem("access_token");
    dispatch({ type: SET_LOADING_ANNOUNCEMENT_SMP_DISRUPTION, payload: true });

    try {
      await Promise.all(
        payload.announcements.map(async (item) => {
          await initiateAXIOS.post(
            initiateScheduleAPI +
              item.announcementId +
              (payload.active ? "/activate" : "/deactivate"),
            {},
            {
              headers: { authorization: `Bearer ${access_token}` },
            }
          );

          const data = {
            announcementId: item.announcementId,
            type: payload.type,
            active: payload.active,
            id: item.id,
          };
          dispatch({ type: SET_EDIT_SMP_LINK_ANNOUNCEMENT, payload: data });
        })
      );
    } catch (error) {
      const errorMessage = handleError(error);
      console.log(errorMessage);
      dispatch({
        type: SET_ERROR_PROMPT_ANNOUNCEMENT_SMP_DISRUPTION,
        payload: true,
      });
      dispatch({
        type: SET_ERROR_MESSAGE_ANNOUNCEMENT_SMP_DISRUPTION,
        payload: errorMessage,
      });
      // handleTimeoutErrorPrompt(dispatch);
    } finally {
      dispatch({
        type: SET_LOADING_ANNOUNCEMENT_SMP_DISRUPTION,
        payload: false,
      });
      dispatch(getActiveAnnouncements());
    }
  };
};
