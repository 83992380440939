import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { TypographySmall } from "../customComponent";
import { createInstantSchedule } from "../../store/actions/scheduleAction";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const CreateInstantSchedule = ({ data, type }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("schedule");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("false");

  const [actionType, setActionType] = useState("");
  const [action, setAction] = useState("");

  const handleChangeActionType = (e) => {
    setActionType(e.target.value);
    setAction("");
  };
  const handleChangeAction = (e) => {
    setAction(e.target.value);
    if (isError) setIsError(false);
  };

  const handleClickClose = () => {
    setIsError(false);
    setOpen(false);
  };

  const handleCloseErr = () => {
    setIsError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (action === "") {
      setIsError(true);
      setErrorMessage(t("instant.errNoAction"));
      setTimeout(() => setIsError(false), 5000);
      return;
    }

    let payload = {};
    payload["command"] = action;
    switch (type) {
      case "viewer":
        payload["screens"] = [{ id: data.id }];
        break;

      case "group":
        payload["screenGroups"] = [{ id: data.id }];
        break;

      default:
        break;
    }

    dispatch(
      createInstantSchedule(payload, () => {
        setActionType("");
        setAction("");
      })
    );

    handleClickClose();
  };

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        variant="contained"
        sx={{ boxShadow: 3, ml: 2 }}
        onClick={() => setOpen(true)}
      >
        {t("instant.createBtn")}
      </Button>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        keepMounted
        onClose={handleClickClose}
      >
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {t("instant.createDialogTitle")}
            <IconButton onClick={handleClickClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Divider />

          <DialogContent>
            {isError && (
              <Alert
                sx={{ my: 2 }}
                severity="error"
                variant="filled"
                onClose={handleCloseErr}
              >
                {errorMessage}
              </Alert>
            )}

            <Box sx={{ display: "flex" }}>
              <StyledBox sx={{ width: "100%" }}>
                <InputLabel>{t("instant.actionTypeLabel")}</InputLabel>

                <TextField
                  size="small"
                  fullWidth
                  select
                  SelectProps={{
                    native: true,
                  }}
                  margin="dense"
                  required
                  value={actionType}
                  onChange={handleChangeActionType}
                >
                  <option value="">{t("instant.actionTypeSelect")}</option>
                  <option value="DISPLAY">
                    {t("instant.actionTypeDisplay")}
                  </option>
                  <option value="PC">{t("instant.actionTypePC")}</option>
                </TextField>
              </StyledBox>

              <StyledBox sx={{ width: "100%", ml: 2 }}>
                <InputLabel>{t("action.label")}</InputLabel>

                <TextField
                  size="small"
                  select
                  margin="dense"
                  SelectProps={{
                    native: true,
                  }}
                  required
                  fullWidth
                  value={action}
                  onChange={handleChangeAction}
                >
                  <option value="">{t("action.select")}</option>

                  {actionType === "PC" ? (
                    <option value="REBOOT">{t("action.reboot")}</option>
                  ) : null}
                  {actionType === "PC" ? (
                    <option value="SHUTDOWN">{t("action.shutDown")}</option>
                  ) : null}

                  {actionType === "DISPLAY" ? (
                    <option value="DISPLAY_ON">{t("action.turnOn")}</option>
                  ) : null}
                  {actionType === "DISPLAY" ? (
                    <option value="DISPLAY_OFF">{t("action.turnOff")}</option>
                  ) : null}
                  {actionType === "DISPLAY" ? (
                    <option value="DISPLAY_HDMI1">{t("action.HDMI1")}</option>
                  ) : null}
                  {actionType === "DISPLAY" ? (
                    <option value="DISPLAY_HDMI2">{t("action.HDMI2")}</option>
                  ) : null}
                </TextField>

                <TypographySmall>
                  {t("instant.actionTypeFillHint")}
                </TypographySmall>
              </StyledBox>
            </Box>
          </DialogContent>

          <DialogActions>
            <Button fullWidth variant="outlined" onClick={handleClickClose}>
              {tCommon("cancelBtn")}
            </Button>
            <Button fullWidth variant="contained" onClick={handleSubmit}>
              {tCommon("submitBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default CreateInstantSchedule;
