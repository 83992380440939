import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Badge, Button } from "@mui/material";
import { decryptUser, encrypt } from "../../helpers/crypto";
import { SET_AUTH_USER } from "../../store/actionTypes/userActionType";
import {
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_SUCCESS_MESSAGE,
} from "../../store/actionTypes/globalActionType";
import { handleTimeoutPrompt } from "../../store/handlePrompt";
import EmailDialogFormOTP from "./mfa/EmailDialogFormOTP";

const ValidateEmail = () => {
  const dispatch = useDispatch();
  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);

  const { t } = useTranslation("user");

  const [open, setOpen] = useState(false);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleSuccess = () => {
    const newUserData = { ...decryptedLoggedUser };
    newUserData.isEmailValid = true;
    const encryptedNewUser = encrypt(newUserData);
    dispatch({ type: SET_AUTH_USER, payload: encryptedNewUser });

    dispatch({ type: SET_GLOBAL_SUCCESS, payload: true });
    dispatch({
      type: SET_GLOBAL_SUCCESS_MESSAGE,
      payload: t("account.verifyEmailSuccess"),
    });

    handleTimeoutPrompt(dispatch, SET_GLOBAL_SUCCESS);
  };

  return (
    <>
      <Badge
        color="error"
        variant="dot"
        sx={{
          "& .MuiBadge-dot": {
            width: "10px",
            height: "10px",
            borderRadius: "50%",
          },
        }}
      >
        <Button
          variant="outlined"
          size="small"
          sx={{ px: "5px", py: 0 }}
          onClick={handleOpenDialog}
        >
          {t("account.verifyEmailBtn")}
        </Button>
      </Badge>

      <EmailDialogFormOTP
        open={open}
        onClose={handleCloseDialog}
        email={decryptedLoggedUser?.email}
        handleSuccess={handleSuccess}
        dialogTitle={t("account.verifyEmailDialogTitle")}
      />
    </>
  );
};

export default ValidateEmail;
