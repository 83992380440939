import { getFrequency } from "../helpers/scheduleHelper";

export default class ScheduleCalendar {
  constructor(payload) {
    this.scheduleId = payload.id;
    this.type = payload.type;
    this.title = payload.name;
    this.start = payload.start;
    this.end = payload.end;
    this.cron = payload.cron;
    this.duration = payload.duration;
    this.content = payload.content;
    this.contentId = payload.contentId;
    this.frequency = getFrequency(payload.repetition, payload.cron);
    this.startHour = payload.startHour;
    this.endHour = payload.endHour;
    this.minuteInterval = payload.minuteInterval;
    this.slot = payload.slot;
    if (payload.backgroundColor) {
      this.backgroundColor = payload.backgroundColor;
    } else {
      delete this.backgroundColor;
    }
  }
}
