import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
// TODO for master
// import { TypographyNormal } from "../../customComponent";
import {
  Box,
  //  TODO for master
  // Box,
  // Collapse,
  // Grid,
  // IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { formattedDate } from "../../../helpers/dateFormat";
//  TODO for master
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Role from "../../../helpers/roles";
import { colorStyling } from "../../../helpers/color";
import parser from "html-react-parser";

const UserHistoryItem = ({ data, index, role }) => {
  //  TODO for master
  // const [expanded, setExpanded] = useState(false);

  const { t } = useTranslation("auditlog");

  const parsedDataEvent = useMemo(() => {
    const event = t(`event.${data.event}`, { defaultValue: null });
    return event ?? data.event;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const parsedDataObject = useMemo(() => {
    let type = t(`object.${data.objectType}`, { defaultValue: null });
    type = type ?? data.objectType;

    const objectName = data?.objectName;
    if (objectName?.length > 22) {
      return `<b style="font-weight: 600;">${type}</b> [${objectName
        .substring(0, 22)
        .trim()}...]`;
    }

    return `<b style="font-weight: 600;">${type}</b> [${objectName}]`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <TableRow
        sx={{
          ...((index + 1) % 2 !== 0
            ? {
                backgroundColor: colorStyling.white.hover,
              }
            : {
                backgroundColor: colorStyling.white.full,
              }),
        }}
        // TODO for master
        // onClick={() => {
        //   if (role === Role.Eins) setExpanded(!expanded);
        // }}
      >
        {/*  TODO for master */}
        {/* {role === Role.Eins && (
          <TableCell sx={{ py: 1, width: "50px" }}>
            <IconButton>
              {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </TableCell>
        )} */}
        <TableCell align="center">{data.id}</TableCell>
        <TableCell align="center">{t(`type.${data.userType}`)}</TableCell>
        <TableCell align="center">{data.userName ?? "—"}</TableCell>
        <TableCell align="center">{parsedDataEvent}</TableCell>
        <TableCell>
          <Tooltip
            placement="top"
            title={data?.objectName?.length > 22 ? data?.objectName : ""}
          >
            <Box>{parser(parsedDataObject)}</Box>
          </Tooltip>
        </TableCell>
        {role === Role.Eins && (
          <TableCell align="center">{data?.objectOrganizationId}</TableCell>
        )}
        <TableCell align="center">{formattedDate(data.createdAt)}</TableCell>
      </TableRow>
      {/* TODO for master */}
      {/* {role === Role.Eins && (
        <TableRow>
          <TableCell sx={{ p: 0 }} colSpan={8}>
            <Collapse in={expanded} sx={{ ml: 8, mr: 4 }}>
              <Grid container sx={{ my: 2 }} justifyContent={"flex-start"}>
                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                >
                  <TypographyNormal sx={{ fontWeight: 600 }}>
                    Request ID :
                  </TypographyNormal>
                  <TypographyNormal>{data.requestId}</TypographyNormal>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                >
                  <TypographyNormal sx={{ fontWeight: 600 }}>
                    Recorded Time :
                  </TypographyNormal>
                  <TypographyNormal>
                    {formattedDate(data.recordedAt)}
                  </TypographyNormal>
                </Grid>

                <Grid
                  item
                  xs={4}
                  sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                >
                  <TypographyNormal sx={{ fontWeight: 600 }}>
                    Action :
                  </TypographyNormal>
                  <TypographyNormal>{data.action}</TypographyNormal>
                </Grid>

                <Grid
                  item
                  xs={8}
                  sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                >
                  <TypographyNormal sx={{ fontWeight: 600 }}>
                    StatusCode :
                  </TypographyNormal>
                  <TypographyNormal>{data.statusCode}</TypographyNormal>
                </Grid>

                {data.requestParam && (
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                  >
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      Request Parameter :
                    </TypographyNormal>
                    <TypographyNormal>{data.requestParam}</TypographyNormal>
                  </Grid>
                )}

                {data.requestQuery && (
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                  >
                    <TypographyNormal sx={{ fontWeight: 600 }}>
                      Request Query :
                    </TypographyNormal>
                    <TypographyNormal>{data.requestQuery}</TypographyNormal>
                  </Grid>
                )}

                {data.requestBody && (
                  <Grid
                    item
                    xs={4}
                    sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", mb: 2 }}
                    >
                      <TypographyNormal sx={{ fontWeight: 600 }}>
                        Request Body :
                      </TypographyNormal>
                      <TypographyNormal>{data.requestBody}</TypographyNormal>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Collapse>
          </TableCell>
        </TableRow>
      )} */}
    </>
  );
};

export default UserHistoryItem;
