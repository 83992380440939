import React from "react";

export const ResolutionType = {
  "P": {
    "2160 x 3840": {
      id: "resPresetP1",
      label: "2160 x 3840",
      width: 2160,
      height: 3840,
      custom: false,
    },
    "1080 x 1920": {
      id: "resPresetP2",
      label: "1080 x 1920",
      width: 1080,
      height: 1920,
      custom: false,
    },
    "720 x 1280": {
      id: "resPresetP3",
      label: "720 x 1280",
      width: 720,
      height: 1280,
      custom: false,
    },
    "custom1": {
      id: "resCustomP1",
      label: "Custom (10 : 16)",
      width: 1200,
      height: 1920,
      custom: true,
    },
    "custom2": {
      id: "resCustomP2",
      label: "Custom (9 : 16)",
      width: 1080,
      height: 1920,
      custom: true,
    },
    "custom3": {
      id: "resCustomP3",
      label: "Custom (3 : 4)",
      width: 768,
      height: 1024,
      custom: true,
    },
    "custom0": {
      id: "resCustomP0",
      label: "Custom (Free Resolution)",
      width: 400,
      height: 500,
      custom: true,
      free: true,
    },
  },

  "L": {
    "3840 x 2160": {
      id: "resPresetL1",
      label: "3840 x 2160",
      width: 3840,
      height: 2160,
      custom: false,
    },
    "1920 x 1080": {
      id: "resPresetL2",
      label: "1920 x 1080",
      width: 1920,
      height: 1080,
      custom: false,
    },
    "1280 x 720": {
      id: "resPresetL3",
      label: "1280 x 720",
      width: 1280,
      height: 720,
      custom: false,
    },
    "custom1": {
      id: "resCustomL1",
      label: "Custom (16 : 10)",
      width: 1920,
      height: 1200,
      custom: true,
    },
    "custom2": {
      id: "resCustomL2",
      label: "Custom (16 : 9)",
      width: 1920,
      height: 1080,
      custom: true,
    },
    "custom3": {
      id: "resCustomL3",
      label: "Custom (4 : 3)",
      width: 1024,
      height: 768,
      custom: true,
    },
    "custom0": {
      id: "resCustomL0",
      label: "Custom (Free Resolution)",
      width: 400,
      height: 500,
      custom: true,
      free: true,
    },
  },
};

// Context for Device Resolution (Currently not used. Saved for future reference.)
// Can be used independently of React state or reducers.
// ex. .\components\cms\contentTemplate\create\index.jsx
export const ResolutionContext = React.createContext({
  width: null,
  height: null,
  custom: null,
  orientation: null,
  setResolution: () => {},
});
