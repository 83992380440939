import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, IconButton, Tooltip } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LaunchIcon from "@mui/icons-material/Launch";
import { makeStyles } from "@mui/styles";
import { AnnouncementPurpleIcon } from "../../icons";
import { TypographyNormal, TypographySmall } from "../customComponent";
import { colorStyling } from "../../helpers/color";
import { switchBackgroundColorBasedOnStatus } from "../../helpers/switchColor";
import { SET_ANNOUNCEMENT_DETAIL } from "../../store/actionTypes/announcementActionType";

const useStyles = makeStyles(() => ({
  hover: {
    "&:hover": {
      backgroundColor: colorStyling.white.hover,
      cursor: "pointer",
    },
  },
}));

const AnnouncementItem = ({ announcement, dispatch, openContent }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { announcement: detail } = useSelector((state) => state.announcement);

  const [hoveredContent, setHoveredContent] = useState(false);

  const { t } = useTranslation("announcement");

  const handleOpenContentDetail = (e, id) => {
    e.stopPropagation();
    openContent(id);
  };

  const navigateToAnnouncementDetail = () => {
    const newDetail = {
      ...announcement,
      ...(detail.id === announcement.id && {
        screenGroups: detail.screenGroups,
        screens: detail.screens,
      }),
    };
    dispatch({ type: SET_ANNOUNCEMENT_DETAIL, payload: newDetail });
    navigate("/announcement/detail/" + announcement.id);
  };
  return (
    <Box
      onClick={() => navigateToAnnouncementDetail()}
      className={classes.hover}
      sx={{
        p: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid lightgrey",
      }}
    >
      <Box display={"flex"} flexWrap={"nowrap"} alignItems={"center"}>
        <Box display={"flex"} alignItems={"center"} sx={{ mr: 1 }}>
          <AnnouncementPurpleIcon />
          <Box display={"flex"} alignItems={"center"} sx={{ ml: 2 }}>
            {announcement.isApproved ? (
              <Tooltip title={t("item.approvedLabel")} placement="top">
                <CheckCircleOutlineIcon
                  fontSize="small"
                  sx={{
                    mr: 0.5,
                    color: switchBackgroundColorBasedOnStatus("APPROVED"),
                    borderRadius: "16px",
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={t("item.pendingApprovalLabel")} placement="top">
                <ErrorOutlineIcon
                  fontSize="small"
                  sx={{
                    mr: 0.5,
                    color: switchBackgroundColorBasedOnStatus("NOT_APPROVED"),
                    borderRadius: "16px",
                  }}
                />
              </Tooltip>
            )}
            <Tooltip
              title={announcement?.name?.length > 30 ? announcement?.name : ""}
              placement="top-start"
            >
              <TypographyNormal sx={{ fontWeight: 600, width: "20vw" }}>
                {announcement?.name?.length > 30
                  ? announcement?.name.substring(0, 27).trim() + "..."
                  : announcement?.name}
              </TypographyNormal>
            </Tooltip>
          </Box>
        </Box>
        <Box>
          <TypographySmall
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "15px",
              color:
                announcement.isActive === true
                  ? colorStyling.green
                  : colorStyling.black,
              backgroundColor:
                announcement.isActive === true
                  ? colorStyling.white.active
                  : colorStyling.white.inactive,
              p: 1,
              fontWeight: 600,
              whiteSpace: "nowrap",
            }}
          >
            <FiberManualRecordIcon
              sx={{
                color:
                  announcement.isActive === true
                    ? colorStyling.green
                    : colorStyling.gray.normal,
                fontSize: 8,
                mr: 1,
              }}
            />
            {announcement.isActive
              ? t("item.statusONLabel")
              : t("item.statusOFFLabel")}
          </TypographySmall>
        </Box>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{ width: "256px" }}
        onClick={(event) =>
          handleOpenContentDetail(event, announcement?.contentId)
        }
        onMouseEnter={() => setHoveredContent(true)}
        onMouseLeave={() => setHoveredContent(false)}
      >
        <TypographyNormal sx={{ fontWeight: 600 }}>
          {`${t("item.contentNameLabel")}:`}
        </TypographyNormal>
        <Tooltip
          title={
            announcement?.content?.name?.length <= 40
              ? ""
              : announcement?.content?.name
          }
        >
          <TypographyNormal
            sx={{
              ...(hoveredContent && {
                color: colorStyling.primary,
                textDecoration: "underline",
                fontWeight: 600,
              }),
            }}
          >
            {announcement?.content?.name?.length > 40
              ? announcement?.content?.name?.substring(0, 37) + "..."
              : announcement?.content?.name || "loading..."}
            <LaunchIcon
              fontSize="xs"
              sx={{
                color: hoveredContent
                  ? colorStyling.primary
                  : colorStyling.gray.label,
              }}
            />
          </TypographyNormal>
        </Tooltip>
      </Box>

      <IconButton sx={{ color: colorStyling.black }}>
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};

export default AnnouncementItem;
