import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Toolbar,
  Paper,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ContentTemplateSuccessPrompt from "./ContentTemplateSuccessPrompt";
import ContentTemplateErrorPrompt from "./ContentTemplateErrorPrompt";
import ContentTemplateFilter from "./ContentTemplateFilter";
import ContentTemplateList from "./ContentTemplateList";
import Sidebar from "../../sidebar/Sidebar";
import { TypographyNormal, TypographyXLarge } from "../../customComponent";
import { decryptUser } from "../../../helpers/crypto";
import parser from "html-react-parser";
import { SET_CMS_FILTER } from "../../../store/actionTypes/cmsActionType";

const initFilter = {
  nameLike: "",
  sortBy: "idDesc",
  searchByUser: "",
  searchByUserIDs: [],
};

const ContentTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cmsFilter } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);
  const permission = decryptedLoggedUser?.permission;
  const contentPermission = permission?.content;
  const assetPermission = permission?.asset;

  const [filter, setFilter] = useState(initFilter);

  useEffect(() => {
    if (!contentPermission?.read) {
      navigate("/");
      return;
    }

    setFilter(cmsFilter);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilterOptions = useCallback(
    (newFilter) => {
      const updatedFilter = { ...filter };
      const options = Object.keys(newFilter);
      options.forEach((option) => {
        updatedFilter[option] = newFilter[option];
      });

      setFilter(updatedFilter);
      dispatch({ type: SET_CMS_FILTER, payload: updatedFilter });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filter]
  );

  const setSorting = (value) => {
    const updatedFilter = {
      ...filter,
      sortBy: value,
    };

    setFilter(updatedFilter);
    dispatch({ type: SET_CMS_FILTER, payload: updatedFilter });
  };

  const resetFilter = () => {
    setFilter(initFilter);
    dispatch({ type: SET_CMS_FILTER, payload: initFilter });
  };

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              {t("titleExplain")}
            </TypographyNormal>
          </Box>

          <ContentTemplateSuccessPrompt />
          <ContentTemplateErrorPrompt />

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {contentPermission?.create && (
              <Tooltip
                arrow
                title={
                  assetPermission?.read ? (
                    ""
                  ) : (
                    <TypographyNormal sx={{ color: "white" }}>
                      {parser(t("reqPermission"))}
                    </TypographyNormal>
                  )
                }
              >
                <Box sx={{ width: "fit-content" }}>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{ boxShadow: 3 }}
                    onClick={() => navigate("/cms/content-template/create")}
                    disabled={!assetPermission?.read}
                  >
                    {t("createBtn")}
                  </Button>
                </Box>
              </Tooltip>
            )}
          </Box>

          <Box sx={{ my: 2 }}>
            <Paper
              component="section"
              elevation={2}
              sx={{
                p: 2,
                my: 2,
                borderRadius: "6px",
              }}
            >
              <ContentTemplateFilter
                filter={filter}
                setFilter={setFilterOptions}
                setSorting={setSorting}
                resetFilter={resetFilter}
              />
            </Paper>

            <ContentTemplateList permission={permission} />
          </Box>
        </Container>
      </Box>
    </div>
  );
};

export default ContentTemplate;
