export const SET_CONTENT_REPORT = "SET_CONTENT_REPORT";
export const SET_CONTENT_REPORT_FILTER = "SET_CONTENT_REPORT_FILTER";
export const SET_CONTENT_REPORT_START_FILTER =
  "SET_CONTENT_REPORT_START_FILTER";
export const SET_LOADING_CONTENT_REPORT = "SET_LOADING_CONTENT_REPORT";
export const SET_SUCCESS_PROMPT_REPORTING_PAGE =
  "SET_SUCCESS_PROMPT_REPORTING_PAGE";
export const SET_SUCCESS_MESSAGE_REPORTING_PAGE =
  "SET_SUCCESS_MESSAGE_REPORTING_PAGE";
export const SET_ERROR_PROMPT_REPORTING_PAGE =
  "SET_ERROR_PROMPT_REPORTING_PAGE";
export const SET_ERROR_MESSAGE_REPORTING_PAGE =
  "SET_ERROR_MESSAGE_REPORTING_PAGE";
