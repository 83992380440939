import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Box,
  Select,
  FormControl,
  CircularProgress,
  MenuItem,
  OutlinedInput,
  IconButton,
  Button,
  Paper,
} from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { ViewDetailViewer } from "..";
import {
  TypographyNormal,
  TypographyLarge,
  TypographySmall,
} from "../../components/customComponent";
import { getLastActiveViewers } from "../../store/actions/homeAction";
import { ViewerBlueIcon } from "../../icons";
import { colorStyling } from "../../helpers/color";
import ViewerStatusIndicator from "../viewer/ViewerStatusIndicator";

const HomeLastActiveViewerList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    lastActiveViewers: viewers,
    loadingLastActiveViewers: loading,
    lastActiveViewersLimit: limit,
  } = useSelector((state) => state.home);

  const { t } = useTranslation("home");
  const { t: tCommon } = useTranslation();

  const [integer, setInteger] = useState(limit);

  const handleChange = (e) => {
    setInteger(e.target.value);
    dispatch(getLastActiveViewers(e.target.value));
  };

  if (loading && viewers.length === 0)
    return (
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        height={250}
      >
        <CircularProgress
          size={22}
          thickness={3}
          sx={{ color: colorStyling.primary }}
        />
        <TypographyNormal
          sx={{
            color: colorStyling.primary,
            marginTop: "15px",
            fontWeight: 300,
          }}
        >
          {tCommon("loadingDataList")}
        </TypographyNormal>
      </Box>
    );

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <TypographyLarge sx={{ fontWeight: 600 }}>
            {t("recentLabel")}
          </TypographyLarge>

          <Box display="flex" alignItems="center">
            <FormControl>
              <Select
                input={<OutlinedInput size="small" />}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                onChange={handleChange}
                value={integer}
              >
                <MenuItem dense value={5}>
                  5
                </MenuItem>
                <MenuItem dense value={10}>
                  10
                </MenuItem>
              </Select>
            </FormControl>
            <TypographyLarge sx={{ ml: 2, fontWeight: 600 }}>
              {t("activeViewerListLabel")}
            </TypographyLarge>
          </Box>
        </Box>

        {Array.isArray(viewers) && !viewers.length ? (
          <TypographyNormal sx={{ fontWeight: 300 }} align="center">
            {t("noViewerList")}
          </TypographyNormal>
        ) : (
          <Paper
            elevation={2}
            sx={{
              maxHeight: 425,
              overflowY: "auto",
            }}
          >
            {viewers.map((viewer) => (
              <HomeLastActiveViewerItem key={viewer.id} viewer={viewer} />
            ))}
          </Paper>
        )}
      </Box>

      <Button
        variant="contained"
        onClick={() => navigate("/viewers")}
        fullWidth
      >
        {t("viewAllViewerBtn")}
      </Button>
    </>
  );
};

const HomeLastActiveViewerItem = ({ viewer }) => {
  const [openDetail, setOpenDetail] = useState(false);

  const handleOpenDetail = () => {
    setOpenDetail(true);
  };

  const handleCloseDetail = () => {
    setOpenDetail(false);
  };

  return (
    <>
      <Box
        onClick={handleOpenDetail}
        sx={{
          borderRadius: "6px",
          mb: 1,
          "&:hover": {
            backgroundColor: colorStyling.white.panel,
            cursor: "pointer",
          },
        }}
        boxShadow="0px 4px 20px rgba(158, 181, 195, 0.18)"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={1}
      >
        <Box display="flex">
          <ViewerBlueIcon />

          <Box ml={2}>
            <TypographyNormal sx={{ fontWeight: 600, mb: 0.5 }}>
              {viewer.name}
            </TypographyNormal>

            <TypographySmall sx={{ fontWeight: 300 }}>
              {viewer.viewerGroup !== null
                ? viewer.viewerGroup?.pathName[0]
                : null}
            </TypographySmall>
          </Box>
        </Box>

        <Box display="flex" alignItems="center">
          <ViewerStatusIndicator size={20} viewer={viewer} />

          <IconButton color="inherit">
            <NavigateNext />
          </IconButton>
        </Box>
      </Box>

      <ViewDetailViewer
        openDetail={openDetail}
        onClose={handleCloseDetail}
        viewer={viewer}
      />
    </>
  );
};

export default HomeLastActiveViewerList;
