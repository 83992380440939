import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import {
  TypographyLarge,
  TypographyNormal,
  TypographySmall,
} from "../../customComponent";
import { inputOnlyNumbers } from "../../../helpers/inputProcessing";
import { logger } from "../../../helpers/logger";

const StyledBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  ":last-child": {
    marginBottom: 0,
  },
}));

const MediaContentSetting = ({
  open,
  handleClose,
  container,
  editContent,
  saveEditChanges,
  assetCount,
}) => {
  const { layerMedias } = useSelector((state) => state.cms);

  const { t } = useTranslation("cms");
  const { t: tCommon } = useTranslation();

  const [assetIndex, setAssetIndex] = useState(null);
  const [assetDuration, setAssetDuration] = useState(10);
  const [assetFit, setAssetFit] = useState("FIT");

  const handleAssetFit = (e) => {
    const value = e.target.value;
    setAssetFit(value);
  };

  useEffect(() => {
    if (open) {
      if (editContent) {
        logger.log(`mediasetting opened!`, { editContent });
        const index = layerMedias[container.id].findIndex(
          (media) => media.id === editContent.id
        );

        setAssetIndex(index);
        setAssetDuration(editContent.interval);
        setAssetFit(editContent.assetFit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCloseDialog = async () => {
    handleClose();
  };

  const handleSaveEdit = () => {
    const edited = {
      interval: parseInt(assetDuration),
      assetFit: assetFit,
    };

    saveEditChanges(assetIndex, edited);
    handleClose();
  };

  const submitChanges = () => {
    handleSaveEdit();
  };

  const parseContentSubmitText = useMemo(() => {
    if (!editContent) {
      return tCommon("submitBtn");
    } else {
      return tCommon("saveBtn");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editContent]);

  const parseObjectFit = (assetFit) => {
    switch (assetFit) {
      default:
      case "FIT":
        return "contain";
      case "STRETCH":
        return "fill";
    }
  };

  const previewElement = useMemo(() => {
    if (!editContent) {
      return;
    }

    const mediaSrc = editContent.url ?? editContent.vidSrc ?? editContent.src;
    switch (editContent?.type) {
      case "VIDEO":
        return (
          <video
            is="x-muted"
            draggable={false}
            src={mediaSrc}
            alt={editContent.name}
            controls={true}
            onClick={(e) => e.stopPropagation()}
            style={{
              width: "100%",
              minHeight: "100%",
              objectFit: parseObjectFit(assetFit),
            }}
          />
        );

      case "IMAGE":
        return (
          <img
            draggable={false}
            src={mediaSrc}
            alt={t("contentImageAltText")}
            onClick={(e) => e.stopPropagation()}
            style={{
              width: "100%",
              objectFit: parseObjectFit(assetFit),
            }}
          />
        );

      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editContent, assetFit]);

  const enableDuration = useMemo(() => {
    if (assetCount > 1) {
      return true;
    } else if (assetCount === 1) {
      return !editContent ? true : false;
    } else {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetCount, editContent]);

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth
      disableEscapeKeyDown={true}
      onClose={() => handleCloseDialog()}
    >
      {/* TODO: Change to "Do you want to save your changes?" */}
      {/* <CloseConfirmation
        open={openCloseConfirmation}
        closeDialog={closeCloseConfirmation}
        handleConfirm={handleCloseConfirmation}
      /> */}

      <DialogTitle
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        {t("setting.mediaTitle")}
        <IconButton onClick={() => handleCloseDialog()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ minHeight: "33vh" }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          sx={{ mt: 3, px: 3 }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{ p: 4, width: "100%" }}
          >
            <Box
              display={"flex"}
              justifyContent={"left"}
              alignItems={"center"}
              sx={{
                py: 2,
                px: 4,
                width: "100%",
                border: "1px solid black",
                borderRadius: "22px",
              }}
            >
              <Box
                width={"50%"}
                display={"flex"}
                flexDirection={"column"}
                gap={1}
              >
                <StyledBox>
                  <Box display={"flex"} gap={"3px"} alignItems={"center"}>
                    <TypographyLarge sx={{ ml: 1 }}>
                      {t("setting.durationMediaLabel")}
                    </TypographyLarge>
                    <Tooltip
                      arrow
                      disableInteractive
                      title={t("setting.durationMediaTip")}
                      placement="top"
                    >
                      <InfoIcon fontSize="10px" />
                    </Tooltip>
                  </Box>

                  <Box
                    width={"70%"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={1}
                  >
                    <Tooltip
                      arrow
                      disableInteractive
                      title={
                        enableDuration ? (
                          ""
                        ) : (
                          <TypographySmall
                            sx={{ color: "white", textAlign: "center" }}
                          >
                            {t("setting.durationDisabledTip")}
                          </TypographySmall>
                        )
                      }
                    >
                      <TextField
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        size="small"
                        value={assetDuration}
                        disabled={!enableDuration}
                        onChange={(e) => setAssetDuration(e.target.value)}
                        onKeyDown={(e) => inputOnlyNumbers(e)}
                        sx={{ pl: 2 }}
                      />
                    </Tooltip>
                    <TypographyNormal flex={"none"}>
                      {t("setting.durationHint")}
                    </TypographyNormal>
                  </Box>
                </StyledBox>

                <StyledBox>
                  <Box display={"flex"} gap={"3px"} alignItems={"center"}>
                    <TypographyLarge sx={{ ml: 1 }}>
                      {t("setting.assetBehaviorLabel")}
                    </TypographyLarge>
                    <Tooltip
                      arrow
                      disableInteractive
                      title={t("setting.assetBehaviorTip")}
                      placement="top"
                    >
                      <InfoIcon fontSize="10px" />
                    </Tooltip>
                  </Box>

                  <Box width={"70%"} display={"flex"} alignItems={"center"}>
                    <TextField
                      margin="dense"
                      fullWidth
                      variant="outlined"
                      size="small"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      value={assetFit}
                      onChange={(e) => {
                        handleAssetFit(e);
                      }}
                      sx={{ pl: 2 }}
                    >
                      <option value={"FIT"} key={"fit"}>
                        {t("setting.assetBehaviorFit")}
                      </option>
                      <option value={"STRETCH"} key={"stretch"}>
                        {t("setting.assetBehaviorStretch")}
                      </option>
                    </TextField>
                  </Box>
                </StyledBox>
              </Box>

              <Box width={"50%"} display={"flex"} flexDirection={"column"}>
                <Box width={"100%"} sx={{ py: 1, px: 2 }}>
                  <TypographyLarge sx={{ mb: 1, ml: 1 }}>
                    {t("setting.previewLabel")}
                  </TypographyLarge>
                  <Box
                    width={"100%"}
                    display={"flex"}
                    alignContent={"center"}
                    sx={{
                      height: "250px",
                      backgroundColor: "black",
                    }}
                  >
                    {previewElement}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions sx={{ mx: 2, mb: 1 }}>
        <>
          <Button variant="outlined" onClick={() => handleCloseDialog()}>
            {tCommon("cancelBtn")}
          </Button>
          <Button variant="contained" onClick={() => submitChanges()}>
            {parseContentSubmitText}
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default MediaContentSetting;
