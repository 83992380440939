import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  IconButton,
  Menu,
  Tooltip,
  MenuItem,
  Radio,
  FormControlLabel,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import SortIcon from "@mui/icons-material/Sort";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import {
  SET_ADV_SCHEDULE_FILTER,
  SET_LOADING_ADV_SCHEDULE,
} from "../../../../store/actionTypes/scheduleActionType";
import { colorStyling } from "../../../../helpers/color";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const FilterAdvertisementSchedule = ({ updating }) => {
  const dispatch = useDispatch();
  const { advertisementScheduleFilters: scheduleFilters } = useSelector(
    (state) => state.schedule
  );

  const { t } = useTranslation("schedule");
  const { t: tCommon } = useTranslation();

  const [scheduleName, setScheduleName] = useState(scheduleFilters.nameLike);
  const [nextDate, setNextDate] = useState(
    scheduleFilters.startAtLte
      ? dayjs(scheduleFilters.startAtLte).format("YYYY-MM-DD")
      : ""
  );

  const [anchorEl, setAnchorEl] = useState(null);

  const openSortButton = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDispatchFilter = (payload) => {
    dispatch({
      type: SET_ADV_SCHEDULE_FILTER,
      payload,
    });
  };

  const handleLoading = (value) => {
    updating(true);
    dispatch({
      type: SET_LOADING_ADV_SCHEDULE,
      payload: value,
    });
  };

  const handleChangeSortBy = (value) => {
    handleLoading(true);
    handleDispatchFilter({ ...scheduleFilters, sortBy: value });
  };

  const handleRefresh = () => {
    handleLoading(true);
    handleDispatchFilter({ sortBy: "idDesc" });
    setScheduleName("");
    setNextDate("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const query = { ...scheduleFilters };
    if (scheduleName) query["nameLike"] = scheduleName;
    else delete query["nameLike"];

    if (nextDate) {
      const startDate = new Date(
        dayjs(nextDate).format("YYYY-MM-DDT23:59:59")
      ).toISOString();
      const endDate = new Date(
        dayjs(nextDate).format("YYYY-MM-DDT00:00:00")
      ).toISOString();
      query["startAtLte"] = startDate;
      query["endAtGte"] = endDate;
    } else {
      delete query["startAtLte"];
      delete query["endAtGte"];
    }

    handleLoading(true);
    handleDispatchFilter(query);
  };

  return (
    <>
      <Box
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        onSubmit={handleSubmit}
      >
        <TextField
          fullWidth
          size="small"
          type="search"
          placeholder={t("filter.searchPlaceholder")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={scheduleName}
          onChange={(e) => setScheduleName(e.target.value)}
        />

        <TextField
          type="date"
          size="small"
          fullWidth
          value={nextDate}
          onChange={(e) => setNextDate(e.target.value)}
          sx={{ mx: 2 }}
        />

        <Button onClick={handleSubmit} variant="contained" sx={{ ml: 2 }}>
          {tCommon("searchBtn")}
        </Button>

        <Box sx={{ display: "flex", ml: 2 }}>
          <IconButton
            sx={{ color: colorStyling.black }}
            onClick={handleClick}
            aria-controls={openSortButton ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={openSortButton ? "true" : undefined}
          >
            <Tooltip title={tCommon("sortTip")}>
              <SortIcon fontSize="small" />
            </Tooltip>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openSortButton}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => handleChangeSortBy("idDesc")}>
              <FormControlLabel
                disableTypography
                value="idDesc"
                control={
                  <Radio
                    size="small"
                    checked={scheduleFilters.sortBy === "idDesc"}
                  />
                }
                label={t("filter.sortByNewest")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("idAsc")}>
              <FormControlLabel
                disableTypography
                value="idAsc"
                control={
                  <Radio
                    size="small"
                    checked={scheduleFilters.sortBy === "idAsc"}
                  />
                }
                label={t("filter.sortByOldest")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("nameAsc")}>
              <FormControlLabel
                disableTypography
                value="nameAsc"
                control={
                  <Radio
                    size="small"
                    checked={scheduleFilters.sortBy === "nameAsc"}
                  />
                }
                label={t("filter.sortByAtoZ")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
            <MenuItem onClick={() => handleChangeSortBy("nameDesc")}>
              <FormControlLabel
                disableTypography
                value="nameDesc"
                control={
                  <Radio
                    size="small"
                    checked={scheduleFilters.sortBy === "nameDesc"}
                  />
                }
                label={t("filter.sortByZtoA")}
                sx={{ fontSize: "14px", fontFamily: "Inter, sans-serif" }}
              />
            </MenuItem>
          </Menu>

          <IconButton
            sx={{ color: colorStyling.black, ml: 2 }}
            color="primary"
            onClick={handleRefresh}
          >
            <Tooltip title={tCommon("resetFilterTip")}>
              <RestartAltIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default FilterAdvertisementSchedule;
