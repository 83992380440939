import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Container, CssBaseline, Paper, Toolbar } from "@mui/material";
import { TypographyNormal, TypographyXLarge } from "../customComponent";
import Sidebar from "../sidebar/Sidebar";
import AnnouncementSuccessPrompt from "./AnnouncementSuccessPrompt";
import AnnouncementErrorPrompt from "./AnnouncementErrorPrompt";
import CreateAnnouncement from "./CreateAnnouncement";
import AnnouncementList from "./AnnouncementList";
import AnnouncementFilter from "./AnnouncementFilter";
import { decryptUser } from "../../helpers/crypto";

const Announcement = () => {
  const { t } = useTranslation("announcement");

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);
  const userPermission = decryptedLoggedUser?.permission.schedule;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        <Container maxWidth="xl">
          <Box sx={{ mb: 1 }}>
            <TypographyXLarge sx={{ fontWeight: 700 }}>
              {t("title")}
            </TypographyXLarge>
            <TypographyNormal sx={{ fontWeight: 400 }}>
              {t("titleExplain")}
            </TypographyNormal>
          </Box>

          <AnnouncementSuccessPrompt />
          <AnnouncementErrorPrompt />

          {userPermission.create && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <CreateAnnouncement />
            </Box>
          )}

          <Paper
            component="section"
            elevation={2}
            sx={{
              borderRadius: "6px",
              p: 2,
              my: 2,
            }}
          >
            <AnnouncementFilter />
          </Paper>

          <AnnouncementList />
        </Container>
      </Box>
    </div>
  );
};

export default Announcement;
