// Enum: "EXPAND_RIGHT"
export const ExpandFromLeft = {
  id: "expandFromLeft",
  label: "Expand From Left",
  speed: 2000,
  effect: {
    prev: {
      shadow: true,
      origin: "right center",
      translate: [0, 0, -1],
    },
    next: {
      origin: "left center",
      scale: 0.1,
      translate: ["-10%", 0, 0],
    },
  },
};
