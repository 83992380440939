import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  CircularProgress,
  //  Alert
} from "@mui/material";

import { TypographyNormal } from "../../customComponent";
import useCountDown from "react-countdown-hook";
import { colorStyling } from "../../../helpers/color";
import { parseVariables } from "../../../helpers/localize";

const ResendOTPCountdown = ({ sendOtp }) => {
  const { t } = useTranslation("user");

  const SIXTY_SECONDS = +process.env.REACT_APP_OTP_EXPIRY || 60 * 1000;

  const interval = 1000; // interval to change remaining time amount, defaults to 1000

  const [timeLeft, { start }] = useCountDown(SIXTY_SECONDS, interval);

  const [loadingResendCode, setLoadingResendCode] = useState(false);

  // TODO if counter needed
  // const [counter, setCounter] = useState(1);

  // start the timer during the first render
  useEffect(() => {
    start();
  }, [start]);

  const handleResendCode = async (e) => {
    e.preventDefault();

    setLoadingResendCode(true);

    sendOtp(() => {
      start();
      // TODO if counter needed
      // setCounter(counter + 1);
      setLoadingResendCode(false);
    });

    // For Debug
    // setTimeout(() => {
    //   start();
    //   setCounter(counter + 1);
    //   setLoadingResendCode(false);
    // }, 2000);
  };

  return (
    <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
      {loadingResendCode ? (
        <Button
          sx={{
            fontSize: 14,
            textTransform: "capitalize",
            color: colorStyling.primary,
          }}
          // TODO if counter needed
          // disabled={counter === 3}
          disabled={loadingResendCode}
          onClick={handleResendCode}
        >
          <CircularProgress
            size={22}
            thickness={3}
            sx={{ color: colorStyling.primary }}
          />
        </Button>
      ) : timeLeft / interval === 0 ? (
        <Button
          sx={{
            fontSize: 14,
            textTransform: "capitalize",
            color: colorStyling.primary,
          }}
          // TODO if counter needed
          // disabled={counter === 3}
          disabled={loadingResendCode}
          onClick={handleResendCode}
        >
          {t("account.resendOtpBtn")}
        </Button>
      ) : (
        <TypographyNormal sx={{ fontWeight: 300 }} gutterBottom>
          {parseVariables(t("account.resendOtpHint"), {
            time: timeLeft / interval,
          })}
        </TypographyNormal>
      )}

      {/* TODO if counter needed */}
      {/* {counter === 3 ? (
        <Alert severity="warning">
          You have reached the limit for resending the code.
        </Alert>
      ) : timeLeft / interval === 0 ? (
        loadingResendCode ? (
          <Button
            sx={{
              fontSize: 14,
              textTransform: "capitalize",
              color: colorStyling.primary,
            }}
            disabled={counter === 3}
            onClick={handleResendCode}
          >
            <CircularProgress
              size={22}
              thickness={3}
              sx={{ color: colorStyling.primary }}
            />
          </Button>
        ) : (
          <Button
            sx={{
              fontSize: 14,
              textTransform: "capitalize",
              color: colorStyling.primary,
            }}
            disabled={counter === 3}
            onClick={handleResendCode}
          >
            Resend Code
          </Button>
        )
      ) : (
        <TypographyNormal sx={{ fontWeight: 300 }} gutterBottom>
          Didn’t receive the code? Resend it in {timeLeft / interval} seconds.
        </TypographyNormal>
      )} */}
    </Box>
  );
};

export default ResendOTPCountdown;
