import React from "react";
import { Box } from "@mui/material";
import { TypographySmall } from "../../customComponent";
import { colorStyling } from "../../../helpers/color";
import { useTranslation } from "react-i18next";

const ColorLegend = () => {
  const { t: tCommon } = useTranslation();

  return (
    <Box
      sx={{
        mt: 2,
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
        <Box
          sx={{
            width: 15,
            height: 15,
            backgroundColor: colorStyling.primary,
            mr: 0.5,
          }}
        />
        <TypographySmall>{tCommon("sidebar.StandardSchedule")}</TypographySmall>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
        <Box
          sx={{
            width: 15,
            height: 15,
            backgroundColor: colorStyling.green,
            mr: 0.5,
            ml: 1.5,
          }}
        />
        <TypographySmall>{tCommon("sidebar.RotatingSchedule")}</TypographySmall>
      </Box>
    </Box>
  );
};

export default ColorLegend;
