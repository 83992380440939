const timeoutDelay = 5000;

export const handleTimeoutPrompt = (dispatch, actionType, timeout = null) => {
  setTimeout(() => {
    handleClosePrompt(dispatch, actionType);
  }, timeout ?? timeoutDelay);
};

export const handleTimeoutErrorPrompt = (dispatch, actionType, message, timeout = null) => {
  setTimeout(() => {
    handleClosePrompt(dispatch, actionType);
  }, timeout ?? timeoutDelay);
  if (actionType.includes("ERROR")) {
    throw new Error(message);
  }
};

export const handleClosePrompt = (dispatch, actionType) => {
  dispatch({ type: actionType, payload: false });
};
