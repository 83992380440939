import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Alert, AlertTitle } from "@mui/material";
import { SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE } from "../../store/actionTypes/announcementActionType";

const AnnouncementErrorPrompt = () => {
  const dispatch = useDispatch();
  const {
    isErrorPromptAnnouncementPage: isError,
    errorMessageAnnouncementPage: errorMessage,
  } = useSelector((state) => state.announcement);

  const { t: tCommon } = useTranslation();

  const handleClose = (event, reason) => {
    dispatch({ type: SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE, payload: false });
  };

  return (
    isError && (
      <Alert
        sx={{ my: 2 }}
        severity="error"
        variant="filled"
        onClose={handleClose}
      >
        <AlertTitle>{tCommon("errorPromptTitle")}</AlertTitle>
        {errorMessage}
      </Alert>
    )
  );
};

export default AnnouncementErrorPrompt;
