import {
  Button,
  Typography,
  AppBar,
  TableCell,
  MenuItem,
  TextField,
  Checkbox,
  TableRow,
} from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { colorStyling } from "../helpers/color";

const fontFamily = "Inter, sans-serif";

export const PrimaryButton = styled(Button)({
  textTransform: "capitalize",
  color: "white",
  backgroundColor: colorStyling.primary,
  borderRadius: 6,
  fontSize: 14,
  fontWeight: 600,
  "&:hover": {
    backgroundColor: colorStyling.primary,
    opacity: 0.7,
  },
  "&:disabled": {
    backgroundColor: colorStyling.white.inactive,
    border: "1px solid lightgrey",
    cursor: "not-allowed",
    pointerEvents: "auto",
    color: "grey",
  },
});

export const SecondaryButton = styled(Button)({
  textTransform: "capitalize",
  color: colorStyling.primary,
  backgroundColor: "transparent",
  border: `1px solid ${colorStyling.primary}`,
  borderRadius: "6px",
  fontWeight: 600,
  fontSize: 14,
  "&:hover": {
    backgroundColor: colorStyling.white.hover,
  },
  "&:disabled": {
    backgroundColor: "none",
    border: "1px solid lightgrey",
  },
});

export const TertiaryButton = styled(Button)({
  textTransform: "capitalize",
  color: "black",
  backgroundColor: "transparent",
  border: "1px solid black",
  borderRadius: "6px",
  fontWeight: 400,
  fontSize: 14,
  "&:hover": {
    backgroundColor: colorStyling.white.hover,
    border: "1px solid black",
    opacity: 0.8,
  },
  "&:disabled": {
    backgroundColor: "none",
    border: "1px solid lightgrey",
  },
});

export const DangerButton = styled(Button)({
  textTransform: "capitalize",
  color: "white",
  backgroundColor: red[500],
  border: "none",
  borderRadius: "6px",
  fontWeight: 600,
  fontSize: 14,
  "&:hover": {
    backgroundColor: red[700],
    border: "none",
  },
  "&:disabled": {
    backgroundColor: "none",
    border: "1px solid lightgrey",
  },
});

export const SecondaryDangerButton = styled(Button)({
  textTransform: "capitalize",
  color: red[500],
  backgroundColor: "white",
  border: `1px solid ${red[500]}`,
  borderRadius: "6px",
  fontWeight: 600,
  fontSize: 14,
  "&:hover": {
    opacity: 0.8,
    border: `1px solid ${red[500]}`,
    backgroundColor: "white",
  },
  "&:disabled": {
    backgroundColor: "none",
    border: "1px solid lightgrey",
  },
});

export const CustomTypography = styled(Typography)({
  fontFamily: fontFamily,
  color: colorStyling.black,
});

export const CstTypo32W700 = styled(Typography)({
  fontFamily: fontFamily,
  color: colorStyling.black,
  fontSize: "32px",
  fontWeight: 700,
});

export const CstTypo25W600 = styled(Typography)({
  fontFamily: fontFamily,
  fontSize: "25px",
  fontWeight: 600,
  color: colorStyling.black,
});

export const CstTypo20W600 = styled(Typography)({
  fontFamily: fontFamily,
  fontSize: "20px",
  fontWeight: 600,
  color: colorStyling.black,
});

export const CstTypo16W600 = styled(Typography)({
  fontFamily: fontFamily,
  fontSize: "16px",
  fontWeight: 600,
  color: colorStyling.black,
});

export const CstTypo16W300 = styled(Typography)({
  fontFamily: fontFamily,
  fontSize: "16px",
  fontWeight: 300,
  color: colorStyling.gray.darkblue,
});

export const CstTypo14W600 = styled(Typography)({
  fontFamily: fontFamily,
  fontSize: "14px",
  fontWeight: 600,
  color: colorStyling.black,
});

export const CstTypo14W300 = styled(Typography)({
  fontFamily: fontFamily,
  fontSize: "14px",
  fontWeight: 300,
  color: colorStyling.gray.darkblue,
});

export const CstTypo12W300 = styled(Typography)({
  fontFamily: fontFamily,
  fontSize: "12px",
  fontWeight: 300,
  color: colorStyling.gray.darkblue,
});

export const CustomAppBar = styled(AppBar)({
  backgroundColor: colorStyling.white.hover,
  boxShadow: "none",
  WebkitBoxShadow: "none",
});

export const CustomTableHead = styled(TableCell)({
  fontWeight: 600,
});

export const CustomMenuItem = styled(MenuItem)({
  fontFamily: "Inter",
  fontSize: 12,
  fontWeight: 500,
});

export const CustomTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: colorStyling.primary,
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: colorStyling.primary,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: grey[300],
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: colorStyling.primary,
    },
  },
});

export const CustomCheckbox = styled(Checkbox)({
  "&.Mui-checked": {
    color: colorStyling.primary,
  },
});

export const TypographyXSmall = styled(Typography)({
  fontSize: "10px",
  color: colorStyling.black,
});

export const TypographySmall = styled(Typography)({
  fontSize: "12px",
  color: colorStyling.black,
});

export const TypographyNormal = styled(Typography)({
  fontSize: "14px",
  lineHeight: "22px",
  fontWeight: 400,
  color: colorStyling.black,
});

export const TypographyLarge = styled(Typography)({
  fontSize: "16px",
  lineHeight: "26px",
  color: colorStyling.black,
});

export const TypographyXLarge = styled(Typography)({
  fontSize: "20px",
  lineHeight: "24px",
  color: colorStyling.black,
});

export const Typography2XLarge = styled(Typography)({
  fontSize: "25px",
  lineHeight: "30px",
  color: colorStyling.black,
  fontWeight: 600,
});

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: colorStyling.white.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

class MutedVideo extends HTMLVideoElement {
  constructor() {
    super();
    this.loop = true;
    this.muted = true;
    this.autoplay = true;
  }
}

customElements.define("x-muted", MutedVideo, { extends: "video" });
