import {
  SET_DELETE_USER,
  SET_DELETE_USER_GROUP,
  SET_EDIT_USER,
  SET_EDIT_USER_GROUP,
  SET_ERROR_MESSAGE_USER_DIALOG,
  SET_ERROR_MESSAGE_USER_PAGE,
  SET_ERROR_MESSAGE_USER_PERMISSION,
  SET_ERROR_PROMPT_USER_DIALOG,
  SET_ERROR_PROMPT_USER_PAGE,
  SET_ERROR_PROMPT_USER_PERMISSION,
  SET_LOADING_USER,
  SET_NEW_USER,
  SET_NEW_USER_GROUP,
  SET_PREFETCH_USER,
  SET_PREFETCH_USER_GROUP,
  SET_SUCCESS_MESSAGE_USER_PAGE,
  SET_SUCCESS_MESSAGE_USER_PERMISSION,
  SET_SUCCESS_PROMPT_USER_PAGE,
  SET_SUCCESS_PROMPT_USER_PERMISSION,
  SET_USER,
  SET_USER_FILTER,
  SET_USER_GROUP,
  SET_USER_GROUP_FILTER,
  SET_USER_GROUP_PAGE,
  SET_USER_GROUP_ROWS_PER_PAGE,
  SET_USER_HISTORY,
  SET_USER_PAGE,
  SET_USER_ROWS_PER_PAGE,
  SET_USER_HISTORY_FILTER,
  SET_USER_HISTORY_FIRST_LOAD,
  SET_USER_HISTORY_ROWS_PER_PAGE,
  SET_PASSWORD_ERROR_HANDLER,
} from "../actionTypes/userActionType";

const initialState = {
  users: { items: [], totalItem: 0 },
  userFilters: { sortBy: "idDesc" },
  userGroups: { items: [], totalItem: 0 },
  userGroupFilters: { sortBy: "idDesc" },
  userPage: 0,
  userRowsPerPage: 5,
  userGroupPage: 0,
  userGroupRowsPerPage: 5,
  loadingUser: false,
  isUserPageError: false,
  errorMessageUserPage: "",
  isUserPageSuccess: false,
  successMessageUserPage: "",
  isUserPermissionError: false,
  errorMessageUserPermission: "",
  isUserPermissionSuccess: false,
  successMessageUserPermission: "",
  isErrorPromptUserDialog: false,
  errorMessageUserDialog: "",
  userHistoryFirstLoad: false,
  userHistory: {
    items: [],
    totalItem: 0,
  },
  userHistoryFilter: {
    name: "",
    type: "",
    recordedAtStart: "",
    sortBy: "idDesc",
  },
  userHistoryRowsPerPage: 10,
  passwordErrorHandler: {
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  },
};

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  let updated;
  switch (type) {
    case SET_USER:
      return { ...state, users: payload };

    case SET_USER_FILTER:
      return { ...state, userFilters: payload };

    case SET_USER_PAGE:
      return { ...state, userPage: payload };

    case SET_USER_ROWS_PER_PAGE:
      return { ...state, userRowsPerPage: payload };

    case SET_USER_GROUP_PAGE:
      return { ...state, userGroupPage: payload };

    case SET_USER_GROUP_ROWS_PER_PAGE:
      return { ...state, userGroupRowsPerPage: payload };

    case SET_USER_HISTORY:
      return { ...state, userHistory: payload };

    case SET_USER_HISTORY_FIRST_LOAD:
      return { ...state, userHistoryFirstLoad: payload };

    case SET_USER_HISTORY_FILTER:
      return { ...state, userHistoryFilter: payload };

    case SET_USER_HISTORY_ROWS_PER_PAGE:
      return { ...state, userHistoryRowsPerPage: payload };

    case SET_USER_GROUP:
      return { ...state, userGroups: payload };

    case SET_USER_GROUP_FILTER:
      return { ...state, userGroupFilters: payload };

    case SET_NEW_USER:
      const listUser =
        state.userPage === 0
          ? [payload, ...state.users.items]
          : [
              ...state.users.items.slice(
                0,
                state.userPage * state.userRowsPerPage
              ),
              payload,
              ...state.users.items.slice(
                state.userPage * state.userRowsPerPage
              ),
            ];
      return {
        ...state,
        users: {
          items: listUser,
          totalItem: state.users.totalItem + 1,
        },
      };

    case SET_PREFETCH_USER:
      let addedPrefetchUsers = [...state.users.items, ...payload.items];
      return {
        ...state,
        users: {
          items: addedPrefetchUsers,
          totalItem: payload.totalItem,
        },
      };

    case SET_PREFETCH_USER_GROUP:
      let addedPrefetchUserGroups = [
        ...state.userGroups.items,
        ...payload.items,
      ];
      return {
        ...state,
        userGroups: {
          items: addedPrefetchUserGroups,
          totalItem: payload.totalItem,
        },
      };

    case SET_NEW_USER_GROUP:
      const listUserGroup =
        state.userGroupPage === 0
          ? [payload, ...state.userGroups.items]
          : [
              ...state.userGroups.items.slice(
                0,
                state.userGroupPage * state.userGroupRowsPerPage
              ),
              payload,
              ...state.userGroups.items.slice(
                state.userGroupPage * state.userGroupRowsPerPage
              ),
            ];
      return {
        ...state,
        userGroups: {
          items: listUserGroup,
          totalItem: state.userGroups.totalItem + 1,
        },
      };

    case SET_DELETE_USER:
      const usersState = state.users.items;
      const filteredUser = usersState.filter(
        (user) => user.id.toString() !== payload.toString()
      );
      return {
        ...state,
        users: { items: filteredUser, totalItem: state.users.totalItem - 1 },
      };

    case SET_DELETE_USER_GROUP:
      const userGroupssState = state.userGroups.items;
      const filteredUserGroup = userGroupssState.filter(
        (group) => group.id.toString() !== payload.toString()
      );
      return {
        ...state,
        userGroups: {
          items: filteredUserGroup,
          totalItem: state.userGroups.totalItem - 1,
        },
      };

    case SET_EDIT_USER:
      const data = state.users.items;
      const objIndex = data.findIndex((obj) => obj.id === payload.id);
      data[objIndex] = payload;
      return {
        ...state,
        users: { items: data, totalItem: state.users.totalItem },
      };

    case SET_EDIT_USER_GROUP:
      const dataGroup = state.userGroups.items;
      const objIndexGroup = dataGroup.findIndex((obj) => obj.id === payload.id);
      dataGroup[objIndexGroup] = payload;
      return {
        ...state,
        userGroups: {
          items: dataGroup,
          totalItem: state.userGroups.totalItem,
        },
      };

    case SET_LOADING_USER:
      return { ...state, loadingUser: payload };

    case SET_ERROR_PROMPT_USER_PAGE:
      return { ...state, isUserPageError: payload };

    case SET_ERROR_MESSAGE_USER_PAGE:
      return { ...state, errorMessageUserPage: payload };

    case SET_SUCCESS_PROMPT_USER_PAGE:
      return { ...state, isUserPageSuccess: payload };

    case SET_SUCCESS_MESSAGE_USER_PAGE:
      return { ...state, successMessageUserPage: payload };

    case SET_ERROR_PROMPT_USER_PERMISSION:
      return { ...state, isUserPermissionError: payload };

    case SET_ERROR_MESSAGE_USER_PERMISSION:
      return { ...state, errorMessageUserPermission: payload };

    case SET_SUCCESS_PROMPT_USER_PERMISSION:
      return { ...state, isUserPermissionSuccess: payload };

    case SET_SUCCESS_MESSAGE_USER_PERMISSION:
      return { ...state, successMessageUserPermission: payload };

    case SET_ERROR_PROMPT_USER_DIALOG:
      return { ...state, isErrorPromptUserDialog: payload };

    case SET_ERROR_MESSAGE_USER_DIALOG:
      return { ...state, errorMessageUserDialog: payload };

    case SET_PASSWORD_ERROR_HANDLER:
      const { oldPassword, newPassword, confirmPassword } = payload;
      if (payload["reset"]) {
        return { ...state, passwordErrorHandler: {
          oldPassword: false,
          newPassword: false,
          confirmPassword: false,
        }};
      }

      updated = state.passwordErrorHandler;
      if (oldPassword !== undefined) {
        updated.oldPassword = oldPassword;
      }
      if (newPassword !== undefined) {
        updated.newPassword = newPassword;
      }
      if (confirmPassword !== undefined) {
        updated.confirmPassword = confirmPassword;
      }
      return { ...state, passwordErrorHandler: { ...updated } };

    default:
      return state;
  }
};
