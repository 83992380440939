import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { TypographyNormal } from "../../customComponent";
import { colorStyling } from "../../../helpers/color";
import { getContentReports } from "../../../store/actions/reportingAction";
import ContentReportItem from "./ContentReportItem";
import { SET_CONTENT_REPORT } from "../../../store/actionTypes/reportingActionType";
import { ascending, descending } from "../../../helpers/sorting";
import { parseVariables } from "../../../helpers/localize";

const headerFields = [
  {
    id: "assetName",
    align: "left",
    labelID: "AssetName",
  },
  {
    id: "assetCampaignName",
    align: "left",
    labelID: "CampaignName",
  },
  {
    id: "assetType",
    align: "center",
    labelID: "Type",
  },
  {
    id: "assetDuration",
    align: "right",
    labelID: "TotalPlayTime",
  },
];

const ContentReportList = () => {
  const dispatch = useDispatch();
  const {
    contentReports,
    contentReportFilters,
    loadingContentReport: loading,
    updatingFilter,
  } = useSelector((state) => state.reporting);

  const { t } = useTranslation("contentreport");
  const { t: tCommon } = useTranslation();

  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("assetName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [contentReportSlice, setContentReportSlice] = useState([]);
  const tableDiv = useRef(null);

  const handleChangeSlice = (data) => {
    const dataSlice = data.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
    setContentReportSlice(dataSlice);
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);

    const sorted = isAsc
      ? descending(contentReports, property)
      : ascending(contentReports, property);

    handleChangeSlice(sorted);

    dispatch({ type: SET_CONTENT_REPORT, payload: sorted });
  };

  const fetchData = async (filterObj) => {
    const { nameLike, type, createdAtGte, createdAtLte } = filterObj;
    const filters = { nameLike, type, createdAtGte, createdAtLte };

    let filterString = "";
    if (filters) {
      Object.keys(filters).forEach((item) => {
        if (filters[item]?.length > 0) {
          filterString += `&${item}=${filters[item] + ""}`;
        }
      });
    }

    dispatch(getContentReports(filterString.replace("&", "?")));
  };

  useEffect(() => {
    fetchData(contentReportFilters);
    setPage(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentReportFilters]);

  useEffect(() => {
    handleChangeSlice(contentReports);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, contentReports]);

  const handleChangePagination = (e, page) => {
    setPage(page);
    if (tableDiv.current) {
      tableDiv.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (tableDiv.current) {
      tableDiv.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const displayedRowsLabel = ({ from, to, count }) => {
    return parseVariables(tCommon("table.displayedRowsLabel"), {
      prefix: `${from}–${to}`,
      suffix:
        count !== -1
          ? count
          : `${tCommon("table.displayedRowsMoreThan")} ${to}`,
    });
  };

  return (
    <>
      <Box sx={{ maxHeight: 600 }}>
        {loading && (contentReports.length === 0 || updatingFilter) ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ height: "20vh" }}
          >
            <CircularProgress
              size={20}
              thickness={3}
              sx={{ color: colorStyling.primary }}
            />
            <TypographyNormal
              sx={{ color: colorStyling.primary, mt: 2, fontWeight: 300 }}
            >
              {tCommon("loadingDataList")}
            </TypographyNormal>
          </Box>
        ) : (
          <>
            {!contentReports?.length || contentReports === undefined ? (
              <Paper elevation={2} sx={{ borderRadius: "6px", p: 2 }}>
                <Alert severity="info" variant="outlined">
                  <strong>{t("table.noData")}</strong>
                </Alert>
              </Paper>
            ) : (
              <TableContainer
                ref={tableDiv}
                component={Paper}
                sx={{ overflowY: "auto", maxHeight: 500, boxShadow: 3 }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {t("table.headerNo")}
                      </TableCell>

                      {headerFields.map((header) => (
                        <TableCell align={header.align} key={header.id}>
                          <TableSortLabel
                            active={orderBy === header.id}
                            direction={orderBy === header.id ? order : "asc"}
                            onClick={() => handleSort(header.id)}
                          >
                            {t(`table.header${header.labelID}`)}
                          </TableSortLabel>
                        </TableCell>
                      ))}

                      <TableCell align="center">
                        {t("table.headerAction")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {contentReportSlice.map((data, index) => (
                      <ContentReportItem
                        pageData={{ page, rowsPerPage }}
                        key={data.assetId + data.assetCampaignName}
                        data={data}
                        index={index}
                        filter={contentReportFilters}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        )}
      </Box>

      {(!loading || contentReports?.length > 0) && (
        <Box
          sx={{
            pt: 2,
            pb: 1,
            pr: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Paper component="div" elevation={1} sx={{ px: 1 }}>
            <TablePagination
              component="div"
              count={contentReports.length}
              page={page}
              onPageChange={handleChangePagination}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={displayedRowsLabel}
              labelRowsPerPage={tCommon("table.rowsPerPageLabel")}
            />
          </Paper>
        </Box>
      )}
    </>
  );
};

export default ContentReportList;
