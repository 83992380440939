import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Link, Tooltip } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { TypographyLarge } from "../../customComponent";
import { colorStyling } from "../../../helpers/color";

const GroupBreadcrumb = ({ group }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("group");

  return (
    <>
      <Breadcrumbs
        separator={
          <NavigateNext sx={{ color: colorStyling.primary }} fontSize="small" />
        }
        aria-label="breadcrumb"
      >
        <Link
          sx={{ display: "flex", alignItems: "center" }}
          underline="hover"
          onClick={() => navigate("/groups")}
        >
          <TypographyLarge
            sx={{
              color: colorStyling.primary,
              cursor: "pointer",
              fontWeight: 400,
              ml: 1,
            }}
          >
            {t("mainBreadcrumb")}
          </TypographyLarge>
        </Link>
        <Link underline="always">
          <Tooltip
            title={group?.name?.length > 30 ? group?.name : ""}
            placement="top-start"
          >
            <TypographyLarge
              sx={{ color: colorStyling.primary, cursor: "pointer" }}
            >
              {group?.name?.length > 30
                ? group?.name?.substring(0, 27) + "..."
                : group?.name}
            </TypographyLarge>
          </Tooltip>
        </Link>
      </Breadcrumbs>
    </>
  );
};

export default GroupBreadcrumb;
