import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  Box,
  DialogActions,
  Button,
  DialogContentText,
  Tooltip,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Close";
import {
  activateAnnouncement,
  deactivateAnnouncement,
} from "../../../store/actions/announcementAction";
import { parseVariables } from "../../../helpers/localize";
import parser from "html-react-parser";

const AnnouncementUpdateStatus = ({ announcement }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("announcement");
  const { t: tCommon } = useTranslation();

  const [open, setOpen] = useState(false);

  const handleClickClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      announcement.isActive
        ? deactivateAnnouncement(announcement.id)
        : activateAnnouncement(announcement.id)
    );
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        placement="top"
        title={announcement.isApproved ? "" : t("item.activateDisabledTip")}
      >
        <Box>
          <Button
            variant="contained"
            sx={{ boxShadow: 3, mr: 2 }}
            startIcon={<UpdateIcon fontSize="small" />}
            disabled={announcement.isApproved ? false : true}
            {...(announcement.isApproved && { onClick: () => setOpen(true) })}
          >
            {announcement.isActive ? t("item.turnOFFBtn") : t("item.turnONBtn")}
          </Button>
        </Box>
      </Tooltip>

      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClickClose}>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <DialogContentText
              sx={{
                textAlign: "center",
              }}
            >
              {announcement.isActive
                ? parser(
                    parseVariables(t("item.turnOFFConfirmationText"), {
                      name: announcement.name,
                    })
                  )
                : parser(
                    parseVariables(t("item.turnONConfirmationText"), {
                      name: announcement.name,
                    })
                  )}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button
              variant="outlined"
              fullWidth
              onClick={handleClickClose}
              startIcon={<CancelIcon />}
            >
              {tCommon("cancelBtn")}
            </Button>

            <Button
              variant="contained"
              type="submit"
              fullWidth
              startIcon={<DoneIcon />}
            >
              {tCommon("continueBtn")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default AnnouncementUpdateStatus;
