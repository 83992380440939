export const inputOnlyNumbers = (event) => {
  if (
    (event.key >= '0' && event.key <= '9') ||  // numbers
    event.key === 'Backspace' ||  // backspace
    event.key === 'ArrowUp' ||  // up arrow
    event.key === 'ArrowDown' ||  // down arrow
    event.key === 'ArrowLeft' ||  // left arrow
    event.key === 'ArrowRight'  // right arrow
  ) {
    return; // do nothing, allow the key press
  }

  event.preventDefault(); // prevent default behavior
};
