import { Alert, AlertTitle } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SET_SUCCESS_PROMPT_USER_PAGE } from "../../store/actionTypes/userActionType";

const UserSuccessPrompt = () => {
  const dispatch = useDispatch();
  const {
    isUserPageSuccess: isSuccess,
    successMessageUserPage: successMessage,
  } = useSelector((state) => state.user);

  const { t } = useTranslation("user");

  const handleClose = (event, reason) => {
    dispatch({ type: SET_SUCCESS_PROMPT_USER_PAGE, payload: false });
  };

  return (
    isSuccess && (
      <Alert
        sx={{ my: 2 }}
        severity="success"
        variant="filled"
        onClose={handleClose}
      >
        <AlertTitle>{t("common.successPromptTitle")}</AlertTitle>
        {successMessage}
      </Alert>
    )
  );
};

export default UserSuccessPrompt;
