import React from "react";
import { Navigate } from "react-router-dom";
import { decryptUser } from "../helpers/crypto";
import Role from "../helpers/roles";

export const AuthorizationRoute = ({ children }) => {
  const access_token = sessionStorage.getItem("access_token");

  return access_token ? children : <Navigate to="/login" />;
};

export const AuthorizationLandingRoute = ({ children }) => {
  const access_token = sessionStorage.getItem("access_token");
  const permissionRole = localStorage.getItem("permissionRole");
  const decryptedUserRole = decryptUser(permissionRole);

  if (access_token && permissionRole) {
    if (decryptedUserRole === Role.Eins) return <Navigate to="/users" />;
    else return <Navigate to="/dashboard" />;
  } else return access_token ? children : <Navigate to="/login" />;
};

export const AuthorizationUsersRoute = ({ children }) => {
  const access_token = sessionStorage.getItem("access_token");

  if (access_token) {
    const permissionRole = localStorage.getItem("permissionRole");
    const decryptedUserRole = decryptUser(permissionRole);

    if (decryptedUserRole === Role.User) return <Navigate to="/dashboard" />;
    else return children;
  } else {
    return <Navigate to="/login" />;
  }
};

export const AuthorizationRouteRequestToken = ({ children }) => {
  const requestToken = sessionStorage.getItem("requestToken");

  return requestToken ? children : <Navigate to="/login" />;
};

export const AuthorizationLoginRoute = ({ children }) => {
  const requestToken = sessionStorage.getItem("requestToken");

  return requestToken ? <Navigate to="/authorization/request-otp" /> : children;
};

export const AuthorizationForgotPasswordRoute = ({ children }) => {
  const resetPasswordToken = sessionStorage.getItem("resetPasswordToken");

  return resetPasswordToken ? children : <Navigate to="/login" />;
};

export const AuthorizationSmrtTdRoute = ({ children }) => {
  const access_token = sessionStorage.getItem("access_token");
  const showTD = process.env.REACT_APP_SHOW_TD;
  console.log("TD", showTD);
  if (access_token) {
    const permissionRole = localStorage.getItem("permissionRole");
    const decryptedUserRole = decryptUser(permissionRole);

    if (decryptedUserRole === Role.Admin && showTD === "TRUE") return children;
    else return <Navigate to="/not-found" />;
  } else {
    return <Navigate to="/login" />;
  }
};

export const AuthorizationBplrtSmpRoute = ({ children }) => {
  const access_token = sessionStorage.getItem("access_token");
  const showBPLRT = process.env.REACT_APP_SHOW_BPLRT;
  console.log("BPLRT", showBPLRT);
  if (access_token) {
    const permissionRole = localStorage.getItem("permissionRole");
    const decryptedUserRole = decryptUser(permissionRole);
    const canAccessSMP = decryptUser(
      localStorage.getItem("permissionFlag")
    ).canAccessSMP;

    if (
      (decryptedUserRole === Role.Admin || canAccessSMP) &&
      showBPLRT === "TRUE"
    )
      return children;
    else return <Navigate to="/not-found" />;
  } else {
    return <Navigate to="/login" />;
  }
};
