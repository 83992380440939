export const SET_ANNOUNCEMENT = "SET_ANNOUNCEMENT";
export const SET_ANNOUNCEMENT_FILTER = "SET_ANNOUNCEMENT_FILTER";
export const SET_LOADING_ANNOUNCEMENT = "SET_LOADING_ANNOUNCEMENT";
export const SET_NEW_ANNOUNCEMENT = "SET_NEW_ANNOUNCEMENT";
export const SET_ANNOUNCEMENT_DETAIL = "SET_ANNOUNCEMENT_DETAIL";
export const SET_EDIT_ANNOUNCEMENT = "SET_EDIT_ANNOUNCEMENT";
export const SET_DELETE_ANNOUNCEMENT = "SET_DELETE_ANNOUNCEMENT";
export const SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE =
  "SET_SUCCESS_PROMPT_ANNOUNCEMENT_PAGE";
export const SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE =
  "SET_ERROR_PROMPT_ANNOUNCEMENT_PAGE";
export const SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE =
  "SET_SUCCESS_MESSAGE_ANNOUNCEMENT_PAGE";
export const SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE =
  "SET_ERROR_MESSAGE_ANNOUNCEMENT_PAGE";
export const SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG =
  "SET_ERROR_PROMPT_ANNOUNCEMENT_DIALOG";
export const SET_ERROR_MESSAGE_ANNOUNCEMENT_DIALOG =
  "SET_ERROR_MESSAGE_ANNOUNCEMENT_DIALOG";
export const SET_ANNOUNCEMENT_PAGE = "SET_ANNOUNCEMENT_PAGE";
export const SET_ANNOUNCEMENT_ROWS_PER_PAGE = "SET_ANNOUNCEMENT_ROWS_PER_PAGE";
export const SET_PREFETCH_ANNOUNCEMENT = "SET_PREFETCH_ANNOUNCEMENT";
export const SET_ANNOUNCEMENT_CONTENT = "SET_ANNOUNCEMENT_CONTENT";
export const SET_ANNOUNCEMENT_ASSIGNED_GROUP =
  "SET_ANNOUNCEMENT_ASSIGNED_GROUP";
export const SET_ANNOUNCEMENT_ASSIGNED_VIEWER =
  "SET_ANNOUNCEMENT_ASSIGNED_VIEWER";
