import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Toolbar,
} from "@mui/material";

import Sidebar from "../../sidebar/Sidebar";
import GroupInfo from "./GroupInfo";
import GroupSuccessPrompt from "../GroupSuccessPrompt";
import GroupErrorPrompt from "../GroupErrorPrompt";
import GroupBreadcrumb from "./GroupBreadcrumb";
import DeleteGroup from "./DeleteGroup";
import EditGroup from "./EditGroup";
import { getGroup } from "../../../store/actions/groupAction";
import { decryptUser } from "../../../helpers/crypto";
import AssignedComponentList from "../../assign/AssignedComponentList";
import AssignComponent from "../../assign/AssignComponent";
import CreateInstantSchedule from "../../schedule/CreateInstantSchedule";
import { isEmpty } from "lodash";
import CoreDetailPageLoader from "../../skeletonLoader/CoreDetailPageLoader";

const GroupDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getGroup(id));
  }, [dispatch, id]);

  const {
    group: { [id]: group },
    loadingGroup: loading,
  } = useSelector((state) => state.group);

  const { loggedUser } = useSelector((state) => state.auth);
  const decryptedLoggedUser = decryptUser(loggedUser);
  const permission = decryptedLoggedUser?.permission;
  const schedulePermission = permission.schedule;
  const groupPermission = permission.screenGroup;
  const viewerPermission = permission.screen;

  let isLoading = !loading || +group?.id === +id;

  return (
    <div style={{ display: "flex" }}>
      <Sidebar />

      <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
        <Toolbar />
        <CssBaseline />

        {isEmpty(group) ? (
          <CoreDetailPageLoader infoLine={0} />
        ) : (
          <Container maxWidth="xl">
            <Box sx={{ mb: 2 }}>
              <GroupBreadcrumb group={group} />
            </Box>

            <GroupSuccessPrompt />
            <GroupErrorPrompt />

            {isLoading && (
              <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
                {schedulePermission.create && (
                  <CreateInstantSchedule data={group} type={"group"} />
                )}
                {groupPermission.update && <EditGroup group={group} />}
                {groupPermission.delete && <DeleteGroup group={group} />}
              </Box>
            )}

            <Grid container spacing={2} direction="column">
              <Grid item xs={4}>
                <Paper elevation={2} sx={{ p: 2, borderRadius: "6px" }}>
                  <GroupInfo group={group} loading={!isLoading} />
                </Paper>
              </Grid>

              {isLoading && groupPermission.update && (
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  {/* Assign Viewer */}
                  {viewerPermission.read && (
                    <AssignComponent
                      getName={"viewer"}
                      typeName="group"
                      typeData={group}
                    />
                  )}

                  {/* Assign Schedule */}
                  {schedulePermission.read && (
                    <AssignComponent
                      getName={"schedule"}
                      typeName="group"
                      typeData={group}
                    />
                  )}
                </Box>
              )}
              {isLoading && (
                <>
                  {/* Viewer List */}
                  {viewerPermission.read && (
                    <Grid item xs={4}>
                      <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                        <AssignedComponentList
                          key={"viewer"}
                          type={"viewer"}
                          listData={group?.screens}
                          sourceData={{
                            id: group?.id,
                            name: group?.name,
                            type: "group",
                            editPermission: groupPermission.update,
                          }}
                          loading={!isLoading}
                        />
                      </Paper>
                    </Grid>
                  )}

                  {/* Schedule List */}
                  {schedulePermission.read && (
                    <Grid item xs={4}>
                      <Paper elevation={2} sx={{ borderRadius: "6px" }}>
                        <AssignedComponentList
                          key={"schedule"}
                          type={"schedule"}
                          listData={group?.schedules}
                          sourceData={{
                            id: group?.id,
                            name: group?.name,
                            type: "group",
                            editPermission: groupPermission.update,
                          }}
                          loading={!isLoading}
                        />
                      </Paper>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          </Container>
        )}
      </Box>
    </div>
  );
};

export default GroupDetail;
