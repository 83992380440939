import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  IconButton,
  Divider,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { editOrganization } from "../../store/actions/organizationAction";
import { useRemoteConfig } from "../hooks";
import { colorStyling } from "../../helpers/color";
import parser from "html-react-parser";
import { useTranslation } from "react-i18next";
import { parseVariables } from "../../helpers/localize";

const EditFeatureFlag = ({ open, onClose, organization }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("organization");

  const globalFF = useRemoteConfig("global_feature_flags", JSON.parse);

  const [initFeature, setInitFeature] = useState({});
  const [featureFlag, setFeatureFlag] = useState(organization.features);

  const handleClickClose = () => {
    onClose(false);
  };

  const handleChange = (e, flag) => {
    if (e.target.checked) {
      setFeatureFlag(featureFlag.filter((item) => item !== flag));
    } else {
      setFeatureFlag([...featureFlag, flag]);
    }
  };

  const handleChangeAll = (e) => {
    if (e.target.checked) {
      setFeatureFlag([]);
    } else {
      setFeatureFlag(
        Object.keys(initFeature).filter((key) => key !== "enabled")
      );
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      id: organization.id,
      payload: { featuresBlacklist: featureFlag },
    };

    dispatch(editOrganization(payload));
  };

  useEffect(() => {
    if (open) setInitFeature(globalFF);
  }, [open, globalFF]);

  return (
    <Dialog maxWidth="md" fullWidth onClose={handleClickClose} open={open}>
      <Box component="form" onSubmit={handleSubmit}>
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {parser(
            parseVariables(t("editFlagDialogTitle"), {
              name: organization.name,
            })
          )}
          <IconButton onClick={handleClickClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider />

        <DialogContent>
          <TableContainer
            sx={{ overflowY: "auto", maxHeight: 444, boxShadow: 3, mt: 2 }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{t("form.headerFlag")}</TableCell>
                  <TableCell align="center" width={"20%"}>
                    {t("form.headerActive")}{" "}
                    <Checkbox
                      checked={featureFlag.length === 0}
                      onChange={handleChangeAll}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(initFeature)
                  .filter((key) => key !== "enabled")
                  .map((flag, index) => (
                    <TableRow
                      key={flag}
                      sx={{
                        cursor: "pointer",
                        backgroundColor:
                          (index + 1) % 2 !== 0
                            ? colorStyling.white.hover
                            : colorStyling.white.full,
                      }}
                    >
                      <TableCell>{flag}</TableCell>
                      <TableCell align="center">
                        <Checkbox
                          checked={!featureFlag.includes(flag)}
                          onChange={(e) => handleChange(e, flag)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClickClose} variant="outlined" fullWidth>
            {t("form.cancelBtn")}
          </Button>
          <Button type="submit" variant="contained" fullWidth>
            {t("form.saveBtn")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default EditFeatureFlag;
